import React from 'react';
import { FormattedMessage } from 'react-intl';
import Icon from 'react-fa';
import Backdrop from '../Backdrop';

const Footer = ({ loadingReport, today, openModalAction }) => {
  return (
    <footer className="footerApp">
      <h6 className="report-bug">
        <button className="report" onClick={() => openModalAction('bug')}>
          {' '}
          <Icon name="info-circle" />{' '}
          <FormattedMessage
            id="app.bugreport.information"
            defaultMessage="Clique aqui para falar com a equipe de suporte"
          />
        </button>
      </h6>
      <div className="noMargin">
        <h6 className="copyright">
          &copy; {today.getFullYear()}{' '}
            Wisedev<a href="https://physicaltest-politica.envoy.tec.br/" target="_blank" rel="noopener noreferrer"> - Política de privacidade
          </a>{' '}
          -{' '}
          <FormattedMessage
            id={'app.login.footer.rights'}
            defaultMessage={'Todos os Direitos Reservados'}
          />
        </h6>
      </div>
      {loadingReport && <Backdrop message="Carregando relatório" />}
    </footer>
  );
};

export default Footer;
