import React from 'react';

const IconHalter = (props) => (
  <svg id="Camada_1" data-name="Camada 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 118 118" width={props.width} height={props.height}>
    <circle cx="59" cy="59" r="59" fill="#82b2f5" stroke-width="0"/>
    <g>
      <path d="m58.6,59.68c-5.99,0-11.97.01-17.96-.02-.75,0-.94.19-.93.93.04,4.25.02,8.5.02,12.75,0,1.86-.8,2.66-2.69,2.66-2.84,0-5.69,0-8.53,0-1.83,0-2.63-.8-2.64-2.61q0-1.36-1.35-1.36c-1.86,0-3.71,0-5.57,0-1.67,0-2.53-.87-2.54-2.56,0-7.99,0-15.98,0-23.98,0-1.68.87-2.54,2.54-2.55,2.04,0,4.07-.04,6.11.02.7.02.9-.2.82-.85-.04-.32-.02-.66,0-.99.06-1.21.9-2.13,2.1-2.14,3.2-.03,6.41-.04,9.61,0,1.31.01,2.13.97,2.14,2.38,0,4.31.03,8.62-.02,12.93,0,.83.22,1.02,1.03,1.02,11.91-.03,23.83-.03,35.74,0,.8,0,.99-.19.98-.98-.04-4.25-.02-8.5-.02-12.75,0-1.81.81-2.61,2.64-2.61,2.87,0,5.75,0,8.62,0,1.77,0,2.58.81,2.59,2.57q0,1.41,1.4,1.41c1.83,0,3.65,0,5.48,0,1.69,0,2.58.85,2.58,2.51,0,8.02,0,16.04,0,24.07,0,1.63-.88,2.5-2.5,2.51-2.07,0-4.13.03-6.2-.01-.65-.01-.85.19-.77.8.04.35.02.72,0,1.08-.07,1.17-.9,2.08-2.05,2.09-3.23.04-6.47.04-9.7,0-1.29-.02-2.09-.99-2.09-2.42,0-4.34-.02-8.68.01-13.02,0-.72-.19-.89-.89-.88-5.99.03-11.97.02-17.96.02Z" fill="#2b2b2b" stroke-width="0"/>
      <path d="m30.29,57.38c0-4.4.01-8.79-.01-13.19,0-.65.13-.89.83-.85,1.1.07,2.21.06,3.32,0,.72-.04.89.21.88.9-.03,6.25-.02,12.5-.02,18.75,0,2.6-.02,5.2.01,7.81,0,.63-.17.83-.8.8-1.16-.05-2.33-.05-3.5,0-.63.03-.74-.2-.73-.76.02-4.49.01-8.97.01-13.46Z" fill="#fefefe" stroke-width="0"/>
      <path d="m86.83,43.48c.27,1.37.05,2.76.12,4.13.02.35,0,.71.01,1.06.14.22.08.46.08.7,0,7.1,0,14.2,0,21.29,0,.26.1.54-.12.77-.05.06-.1.12-.15.18-1.61.21-3.22.22-4.83,0-.02-.24-.06-.47-.06-.71,0-7.23,0-14.46,0-21.69,0-.18.03-.36.04-.53.13-.06.22-.02.26.11.03,7.12,0,14.24.01,21.36q0,1.06,1.07,1.07c.89,0,1.78-.01,2.67,0-.98,0-1.96-.04-2.93,0-.63.03-.82-.19-.81-.81.03-2.87,0-5.74.01-8.62.02-4.37-.05-8.73.03-13.1.27-.31.64-.28.99-.3.56-.02,1.13,0,1.69,0q1.58,0,1.58-1.54c0-.77-.01-1.54,0-2.31.01-.38-.04-.79.32-1.06Z" fill="#61b9a6" stroke-width="0"/>
      <path d="m25.87,57.57c0,3.05-.03,6.1.02,9.15.01.73-.21.9-.9.87-1.19-.05-2.39-.03-3.59,0-.43,0-.56-.13-.56-.56.01-6.37.01-12.75,0-19.12,0-.44.15-.56.57-.55,1.26.02,2.52.05,3.77,0,.66-.03.7.27.7.79-.02,3.14,0,6.28,0,9.42Z" fill="#fefefe" stroke-width="0"/>
      <path d="m96.27,51.72v15.64c-.29.29-.66.24-1.01.25-1.22.01-2.44,0-3.67.01-.11,0-.21-.04-.3-.11,0-5.27.01-10.53.02-15.8.23-.23.53-.25.83-.25,1.1-.02,2.2-.02,3.3,0,.3,0,.6.03.83.26Z" fill="#60b9a6" stroke-width="0"/>
      <path d="m82.11,48.7c-.06,0-.13-.01-.19-.02,0,0,0,0,0,0-.16-1.73-.08-3.46-.04-5.18,1.65-.23,3.3-.21,4.94-.02-.01,1.43-.08,2.85-.02,4.28.03.77-.23.95-.95.91-1.19-.05-2.38,0-3.57.02-.05.08-.11.08-.18.01Z" fill="#fdfdfd" stroke-width="0"/>
      <path d="m96.27,51.72c-1.65,0-3.31,0-4.96,0,0-1.25,0-2.5-.04-3.74-.01-.47.15-.61.61-.6,1.28.03,2.56.03,3.84,0,.48-.01.61.15.6.61-.04,1.25-.03,2.5-.04,3.74Z" fill="#fefefe" stroke-width="0"/>
      <path d="m81.76,48.67v22.84c-.3-.26-.18-.52-.18-.74,0-7.05,0-14.11,0-21.16,0-.31-.12-.67.18-.93h0Z" fill="#1c2826" stroke-width="0"/>
      <path d="m86.93,71.44c0-7.5-.01-15-.01-22.5,0-.09.03-.18.05-.26.26.2.18.49.18.75,0,7.18,0,14.36,0,21.54,0,.17-.04.34-.06.51,0,0-.05.02-.08.01-.03,0-.05-.03-.07-.05Z" fill="#182825" stroke-width="0"/>
      <path d="m96.64,51.97v15.33s-.06,0-.09,0v-15.36s.06.02.09.03Z" fill="#242928" stroke-width="0"/>
      <path d="m81.89,43.5c.01,1.73.02,3.46.04,5.18,0,0-.08,0-.08,0,0,0-.08-.02-.08-.02,0,0,0,0,0,0,0-1.44,0-2.87.02-4.31,0-.29-.11-.6.11-.86Z" fill="#61b9a6" stroke-width="0"/>
      <path d="m91.3,67.51c.06,0,.11,0,.17,0,1.61-.08,3.22-.11,4.82.01,0,.06.01.13.02.19-1.68.15-3.35.16-5.03,0,0-.07.02-.13.03-.2Z" fill="#3d5c57" stroke-width="0"/>
      <path d="m91.27,67.7c1.68,0,3.35,0,5.03,0-1.03.42-2.09.13-3.14.16-.62.02-1.29.24-1.89-.17Z" fill="#1d2625" stroke-width="0"/>
      <path d="m96.29,67.52c-1.61,0-3.22,0-4.82-.01,1.08-.33,2.19-.07,3.28-.14.51-.03,1.02,0,1.53,0,.03.05.03.1.01.16Z" fill="#72baab" stroke-width="0"/>
      <path d="m81.94,71.62c1.61,0,3.22,0,4.83,0-.14.27-.4.22-.63.22-1.19,0-2.38,0-3.58,0-.23,0-.49.05-.62-.23Z" fill="#182624" stroke-width="0"/>
      <path d="m82.11,48.7c.06,0,.12,0,.18-.01,0,7.05,0,14.11,0,21.16q0,1.27,1.23,1.27c.95,0,1.9,0,2.86.17-1.28,0-2.55,0-3.83,0-.3,0-.43-.16-.43-.45,0-.24,0-.48,0-.72,0-7.14,0-14.29,0-21.43Z" fill="#5bb8a4" stroke-width="0"/>
    </g>
  </svg>
);

IconHalter.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconHalter;
