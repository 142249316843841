import React, { Component } from 'react';
import SummaryProfileCard from './SummaryProfileCard';
import PanelEvaluated from './PanelEvaluated';

class DashboardEvaluated extends Component {
  render() {
    return (
      <div className="container">
        <SummaryProfileCard {...this.props} />
        <div>
          <PanelEvaluated
            evaluated={this.props.permission.evaluated.id}
            clientId={this.props.permission.client.id}
            permission={true}
          />
        </div>
      </div>
    );
  }
}
export default DashboardEvaluated;
