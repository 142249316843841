import React from 'react';

const IconFinancial = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 505 505"
    width={props.width}
    height={props.height}
  >
    <circle cx="252.5" cy="252.5" r="252.5" fill="#84DBFF" />
    <path fill="#FFF" d="M325.6 86.1v53.6h53.5v279.2H125.9V86.1" />
    <path fill="#E6E9EE" d="M325.6 86.1l53.5 53.6h-53.5" />
    <path
      fill="#4CDBC4"
      d="M245.8 281.9v-12.5c-15.7-1.6-29.7-7.8-42.2-18.7l12.5-14.9c9.6 8.3 19.5 13.3 29.7 14.9v-33.3c-12.9-3.1-22.4-7.1-28.4-12.2-6-5-9-12.5-9-22.4s3.4-18 10.4-24.3c6.9-6.3 15.9-9.7 27-10.3v-8.6h11v8.7c12.6.9 24.5 5.2 35.7 12.9L281.4 177c-7.5-5.4-15.7-8.7-24.5-9.9v32.2h.5c13.1 3.1 22.8 7.3 29 12.5 6.2 5.3 9.3 12.9 9.3 22.9s-3.5 18.1-10.6 24.4-16.5 9.7-28.1 10.3v12.3h-11.2v.2zm-12.1-110.7c-2.9 2.6-4.4 5.8-4.4 9.6s1.1 6.9 3.4 9.1c2.3 2.2 6.6 4.4 13 6.4v-29.7c-5.1.5-9.1 2-12 4.6zm36 75.2c3.2-2.6 4.8-5.9 4.8-9.9s-1.3-7.2-3.8-9.5-7.2-4.5-14.1-6.6v30.9c5.6-.7 9.9-2.3 13.1-4.9z"
    />
    <path
      fill="#E6E9EE"
      d="M166.8 311.5h171.4v13.2H166.8zm0 25.6h171.4v13.2H166.8zm0 25.7h171.4V376H166.8z"
    />
  </svg>
);

IconFinancial.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconFinancial;
