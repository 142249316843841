import React from 'react';

const Image = (props) => (
  <img
    src={props.src}
    alt={props.title}
    title={props.title}
    className={'img-fluid ' + props.className}
  />
);
export default Image;
