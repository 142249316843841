import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';

const initialState = Immutable.from({
  exercises: [],
  exerciseSelected: {},
  loading: false,
  shouldGetExercises: true
});

export default function exercises(state = initialState, action = {}) {
  switch (action.type) {
    case Actions.GET_EXERCISES_PENDING: {
      const newState = state.updateIn(['loading'], () => true);
      return newState;
    }
    case Actions.GET_EXERCISES_REJECTED: {
      const newState = state.updateIn(['loading'], () => false);
      return newState;
    }
    case Actions.GET_EXERCISES: {
      const exercises = state
        .updateIn(['exercises'], () => action.payload)
        .updateIn(['loading'], () => false)
        .updateIn(['shouldGetExercises'], () => false);
      return exercises;
    }
    case Actions.ADD_REGISTER_EXERCISE: {
      const newState = Immutable.merge({
        ...state,
        exercises: [...state.exercises, action.payload]
      });
      return newState;
    }
    case Actions.REGISTER_SELECTED_EXERCISE: {
      const exerciseSelected = state.set('exerciseSelected', action.payload);
      return exerciseSelected;
    }
    case Actions.UPDATE_REGISTER_EXERCISE: {
      const exerciseIndex = state.exercises.findIndex(
        exercise => exercise.id === action.payload.id,
      );
      const newState = state.updateIn(
        ['exercises', exerciseIndex],
        () => action.payload,
      );
      return newState;
    }
    case Actions.EXERCISE_MEDIA_REQUEST:
      const exerciseMediaRequest = state.set('loading', true);
      return exerciseMediaRequest;
    case Actions.EXERCISE_MEDIA_REQUEST_ERROR:
      const exerciseMediaRequestError = state.set('loading', false);
      return exerciseMediaRequestError;
    case Actions.SET_EXERCISE_MEDIA:
      const exerciseIndex = state.exercises.findIndex(
        exercise => exercise.id === action.payload.id,
      );
      const newState = state.updateIn(
        ['exercises', exerciseIndex],
        () => action.payload,
      );
      return newState;
    case Actions.EXERCISE_MEDIA_REJECTED:
      const mediaFail = state.set('loading', false);
      return mediaFail;
    case Actions.DELETE_REGISTER_EXERCISE: {
      const exercises = state.exercises.filter((exercise) => exercise.id !== action.payload.id);
      const newState = state.updateIn(['exercises'], () => exercises);
      return newState;
    }
    default:
      return state;
  }
}
