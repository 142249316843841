import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import promise from 'redux-promise-middleware';
import createHistory from 'history/createBrowserHistory';
// import { createLogger } from 'redux-logger';
import shouldGetMiddleware from '../middleware/shouldget';
import { IS_DEV } from '../constant/constants';
import rootSet from './reducer';

export default function configureStore(initialState) {
  const history = createHistory();
  const composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) ||
    compose;
  let store;
  let middleware;

  if (IS_DEV) {
    middleware = applyMiddleware(
      routerMiddleware(history),
      shouldGetMiddleware,
      promise(),
      thunk,
      // createLogger({
      //   collapsed: true,
      //   diff: true,
      // }), 
    );
    store = createStore(rootSet, initialState, composeEnhancers(middleware));
  } else {
    middleware = applyMiddleware(
      routerMiddleware(history),
      shouldGetMiddleware,
      promise(),
      thunk,
    );
    store = createStore(rootSet, initialState, middleware);
  }

  if (module.hot) {
    module.hot.accept('./reducer', () => {
      const nextRootSet = require('../store/reducer');
      store.replaceSet(nextRootSet);
    });
  }
  return { store, history };
}
