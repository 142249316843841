import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';

const initialState = Immutable.from({
  ethnicities: [],
  ethnicitySelected: {},
  loading: false,
  shouldGetEthnicities: true,
});

export default function ethnicities(state = initialState, action = {}) {
  switch (action.type) {
    case Actions.GET_ETHNICITIES_PENDING:
      const loading = state.updateIn(['loading'], () => true);
      return loading;
    case Actions.GET_ETHNICITIES_REJECTED:
      const rej = state.updateIn(['loading'], () => false);
      return rej;
    case Actions.GET_ETHNICITIES:
      const ethnicities = state
        .updateIn(['ethnicities'], () => action.payload)
        .updateIn(['loading'], () => false)
        .updateIn(['shouldGetEthnicities'], () => false);
      return ethnicities;
    case Actions.ADD_REGISTER_ETHNICITY:
      const newState = Immutable.merge({
        ...state,
        ethnicities: [...state.ethnicities, action.payload],
      });
      return newState;
    case Actions.REGISTER_SELECTED_ETHNICITY:
      const ethnicitySelected = state.set('ethnicitySelected', action.payload);
      return ethnicitySelected;
    case Actions.EDIT_REGISTER_ETHNICITY:
      const ethnicityIndex = state.ethnicities.findIndex(
        ethnicity => ethnicity.id === action.payload.id,
      );
      const newStateEthnicity = state.updateIn(
        ['ethnicities', ethnicityIndex],
        () => action.payload,
      );
      return newStateEthnicity;
    case Actions.DELETE_REGISTER_ETHNICITY:
      const index = state.ethnicities.findIndex(
        ethnicity => ethnicity.id === action.payload.id,
      );
      const deleteEthnicity = state.update('ethnicities', aus => aus.slice(0, index).concat(aus.slice(index + 1)));
      return deleteEthnicity;
    default:
      return state;
  }
}
