import * as Actions from '../../constant/constants';

const initialState = {
  toggle: 1,
};

export default function bcchartsreport(state = initialState, action) {
  switch (action.type) {
    case Actions.TOGGLE_CHART:
      return { ...state, toggle: action.payload };
    default:
      return state;
  }
}
