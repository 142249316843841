import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, CardHeader, CardTitle, CardBody, CardText, Row, Col } from 'reactstrap';
import { URL_SERVER } from '../../constant/constants';
import { Link } from 'react-router-dom';
import Icon from 'react-fa';
import { calculatesAge } from '../../utils/functions';

const SummaryProfileCard = (props) => {
  return (
    <Card className="card-summary-profile">
      <CardHeader>
        <Link to="MyAccount/EditDataProfile" className="card-summary-profile-edit">
          <Icon name="edit" />
        </Link>
        <div className="card-summary-profile-thumb">
          <img
            src={
              !props.person.picture || props.person.picture === ''
                ? require('../../images/avatar.png')
                : URL_SERVER + props.person.picture
            }
            alt={props.name}
          />
        </div>
      </CardHeader>

      <CardBody>
        <CardTitle>{props.name}</CardTitle>

        <div className="card-summary-profile-years">
          <CardText>
            {calculatesAge(props.person.birthday)}{' '}
            <FormattedMessage id="app.global.years" defaultMessage="anos" />
          </CardText>
        </div>

        <Row className="card-summary-profile-infos">
          <Col>
            <div className="info-main">
              {props.person.contact.phone1 ? props.person.contact.phone1 : '-'}
            </div>
            <div className="info-complement">Telefone</div>
          </Col>

          <Col>
            <div className="info-main">
              {props.person.contact.cel ? props.person.contact.cel : '-'}
            </div>
            <div className="info-complement">celular</div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default SummaryProfileCard;
