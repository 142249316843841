import * as Actions from '../../constant/constants';
import socket from '../../socket';
import { MATURATION_DEFAULT_MESSAGES } from '../../constant/defaultMessages';

export function getSexualMaturation(event) {
  return {
    type: Actions.GET_SEXUAL_MATURATIONS,
    payload: socket.sendMessage(Actions.GET_SEXUAL_MATURATIONS, []),
  };
}

export function setSexualMaturation(event) {
  let maturations = [];
  if (typeof event === 'undefined') socket.sendMessage(Actions.GET_SEXUAL_MATURATIONS, []);
  else maturations = event.data.map(maturation => convertMessages(maturation, MATURATION_DEFAULT_MESSAGES));
  return {
    type: Actions.GET_SEXUAL_MATURATIONS,
    payload: maturations,
  };
}

export function convertMessages(data, constMessage) {
  const nameSexualMaturations = data.name;
  const nameSexualMaturationsNoSpace = nameSexualMaturations.replace(/\s|-/g, ''); // TODO: Talvez isso aqui dê problema antes o REGEX era: ( /\s|\-/g)
  data.idMessageName = `app.maturation.${nameSexualMaturationsNoSpace}`;
  data.defaultMessageName = constMessage[nameSexualMaturationsNoSpace] || data.name;
  return data;
}
