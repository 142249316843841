import React from 'react';

const IconFlexibility = (props) => (
  <svg
    className="icon-flexibility"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-57 148.8 496.2 496.2"
    width={props.width}
    height={props.height}
  >
    <path
      className="st0"
      d="M-57 396.9c0-137 111.1-248.1 248.1-248.1s248.1 111.1 248.1 248.1S328.1 645 191.1 645-57 533.9-57 396.9z"
    />
    <path
      className="st1"
      d="M196 214.3c-.2-.8 3.1-2.1 7.4-2.9 4.3-.8 8-.8 8.2 0 .2.8-2.5 1.8-6.8 2.5-4.6 1-8.7 1.2-8.8.4z"
    />
    <path
      className="st2"
      d="M399.3 416.4c3-6.8-27.5-16.4-35.3-22h-12.4c-61.3-32.9-94.2-33.9-94.2-33.9-35.9-21.2-93.2-56.9-108.6-60.9s-26.9 26-12.8 33.7c14 7.6 13.6 11 28.1 17.6 14.4 6.4 69.3 28.7 82.4 33.1 1.8.6 98 26 98 26s3.6 1.8 8.8 6.6c7 6.6 20 11.8 28.3 11.6 6.9-.2 14.5-5 17.7-11.8z"
    />
    <path
      className="st2"
      d="M371.5 401.1s8.8 5 11.8.4c2.6-3.6 3-5.2-19.4-7.4.2 0-.8 3 7.6 7z"
    />
    <path
      className="st3"
      d="M147.6 395.9c20.6-13.4 14-33.9 27.9-59.9 1.8-3.2.2-20.8-4.2-27.5-5-7.4-11.2-14-21-15.4-7.6-1-27.9.4-60.7 33.9-16 14.4-61.1 40.3-80.6 64.9-23 29.1-23.4 36.9-23.4 36.9l39.5 41.9c64.5-38.3 80.6-60.5 102.8-71.5-.1-.1 13.9.5 19.7-3.3z"
    />
    <path
      className="st2"
      d="M180.5 324.8s10.8-15.4 21.6-27.9l-13.8-23c-10 13.4-29.9 22.8-29.9 22.8 16.6 19 15.4 32.5 17 39.5l5.1-11.4z"
    />
    <path
      className="st4"
      d="M243.8 289.5c12.2-3.6 14.6-14.6 15.4-21.6 2.2-21.2-6.4-29.9-18.6-39.1-16.4-12.2-54.5 26.2-58.5 34.7-10.2 21.6 10.2 48.3 20.6 56.5 11.4 9 11.6 2.8 14.6-2.2.2-.2 1.2-.4 1.2-.4 1.2-1.6 2.8-3.8 5.4-5.2 0 0 .6-1.8 1.2-2.6.4-.6.6-.8 1.4-.6 2.2.6 4.2 1.4 5.4 1.2 3.9-.4 6.3-18.9 11.9-20.7z"
    />
    <path
      className="st5"
      d="M215.6 309.4s.6 1.6 6 5.6c.8.2 2.6.4 2.6-1.8 0-1.8-4.6-1.4-8.6-3.8z"
    />
    <path
      className="st6"
      d="M215.6 309.4s-1 3.4 3 8.4c.8.4 1.8.2 2 0 1.8-1.2.8-3 .8-3-5.2-3.8-5.8-5.4-5.8-5.4z"
    />
    <path
      className="st7"
      d="M241.4 225.6c-20-14.8-67.9-5.8-65.7 48.3.2 3.6 2.2 9.4 12 9.4 6.8 0 13-2.2 13-2.2 1.8-.6 3.8-1.4 6-2.2 2.4-.8 8.4-11.6 10.6-10.4 21.8 11.4 37.9 7.2 41.7 2 6.9-14.4 3.7-29.3-17.6-44.9z"
    />
    <path
      className="st4"
      d="M201.5 272.7c-3.6 11-.2 14.2 5.4 17.6l7.2-16 1.4-7.4c-5.5-3.4-10.3-5.2-14 5.8z"
    />
    <path
      className="st1"
      d="M187.7 232.6c-1.6-7.4 7.6-15.4 20.8-17.8s25.2 1.6 26.9 9c1.6 7.4-6.4 12.6-19.6 15.2-13.2 2.4-26.5 1-28.1-6.4z"
    />
    <path
      className="st7"
      d="M217.8 271.9c1-1.8 2-3.2 2.8-4.4 1.4-1.6 2.4-2.6 2.4-2.6l-7.4-2.2c-.6.8-1.2 1.6-2 2.6 0 0 .8.2 2.2.8h-.2c-.2 1.8-.6 4.6-1.8 8.2 0-.2 1 3.2 4-2.4zm-32.9-42.3c-1.8-7.8 8.4-16.4 22.8-19 14.5-2.6 27.7 1.4 29.5 9.4 1.8 7.8-7.2 13.4-21.6 16.2-14.5 2.6-29.1 1.2-30.7-6.6z"
    />
    <path
      className="st2"
      d="M382.7 437c-1.8 8 0 30.9-.2 33.9-.2 7.2-4.2 14.2-11 12.8-5.2-1-9.8-3.6-13-3.2-16.6 1.6-66.5 9-86.6 10.6-4.6.4-9.4.6-14.4.8-19.8.6-8.6-40.3-1.4-39.7 28.9 2 97 6.8 107.4 8.8 0 0 2.4-38.7 9.8-37.9 4.6.5 11.4 5.7 9.4 13.9z"
    />
    <path
      className="st4"
      d="M388.7 446c-1.8 8 0 30.9-.2 33.9-.2 7.2-4.2 14.2-11 12.8-5.2-1-9.8-3.6-13-3.2-16.6 1.6-66.5.6-86.6 2.2-4.6.4-9.4.6-14.4.8-19.8.6-8.6-40.3-1.4-39.7 28.9 2 97 15.2 107.4 17.2 0 0 2.4-38.7 9.8-37.9 4.6.5 11.4 5.7 9.4 13.9z"
    />
    <path
      className="st4"
      d="M405.7 433.4c3.4-6.6-26.4-17.8-34.1-23.8l-12.4-.6c-59.7-35.9-92.4-38.7-92.4-38.7-34.7-23-103.6-63.9-118.8-68.7-15-4.8-28.1 24.6-14.4 33.1s26.4 14.2 40.7 21.4c14 7.2 67.7 32.1 80.8 37.1 1.8.6 96.6 30.9 96.6 30.9s3.6 2 8.4 7c6.6 7 19.4 12.8 27.7 13 6.5.5 14.5-3.9 17.9-10.7z"
    />
    <path
      className="st4"
      d="M378.7 416.8s8.6 5.4 11.8 1c2.8-3.6 3.4-5-19-8.4 0 .2-1 3 7.2 7.4z"
    />
    <path
      className="st8"
      d="M-14.5 428.8c-6.4 30.5 2 70.9 42.1 72.5 34.1 1.4 45.1-13.2 45.1-13.2s25.4-57.3 11-58.7c-11.8-1.2-22-3-32.1-3.6-6.6-.4-28.3.8-44.9 1.6-1 0-17.4-7.4-17.4-7.4-2.2 3.2-3.8 8.8-3.8 8.8z"
    />
    <path
      className="st8"
      d="M199.3 488.5c22.8 3.8 44.7 3.8 64.7 4-2.2-6.6-1.8-33.9.2-39.7-7.2-.8-62.3-7.8-62.3-7.8-17.4-2.8-120.2-21.2-152.7-18-8.4.6-15.6 1.8-21.8 3.2-11.8 2.6-20.4 63.3-4.4 66.5 35.5-.8 113.4-10.2 129.6-11.6 16.3-1.8 46.7 3.4 46.7 3.4z"
    />
  </svg>
);

IconFlexibility.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconFlexibility;
