import { asMutable } from 'seamless-immutable';
import {
  NAVIGATION_OPEN,
  NAVIGATION_CLOSE,
  NAVIGATION_ADD_HISTORY,
  NAVIGATION_CLEAR_HISTORY,
  NAVIGATION_REMOVE_HISTORY,
  NAVIGATION_RESTORE_HISTORY,
} from '../../constant/constants';

/** Close Navigation
 *
 * Fecha a barra de navegação
 * @author Rafael Gomides
 */
export function closeNavigation() {
  return {
    type: NAVIGATION_CLOSE,
  };
}

/** Open Navigation
 *
 * Abre a barra de navegação.
 * Informe 'false' como parametro para que não apareça o botão de voltar.
 * @param {bool} opc Opção de abertura do NavigationBar
 * @author Rafael Gomides
 */
export function openNavigation(opc = true) {
  return {
    type: NAVIGATION_OPEN,
    opc,
  };
}

/** Clear Navigation History
 *
 * Limpa todo o histórico de navegação.
 * @author Rafael Gomides
 */
export function clearNavigationHistory() {
  return {
    type: NAVIGATION_CLEAR_HISTORY,
  };
}

/** Add Navigation
 *
 * Adiciona um novo item a navegação do sistema.
 * Informe o PATH que deseja adicionar e na sequencia passe a props de navegação.
 *
 * Exemplo: `this.props.addNavigation("/Evaluated", this.props.navigation.navigationHistory)`
 * @param {array} prevHistory Array com o histórico de navegação
 * @param {String} url PATHNAME do local que deseja adicionar na Array - Default: window.location.pathname
 * @author Rafael Gomides
 */
export function addNavigation(prevHistory, url = window.location.pathname) {
  const arrHist = asMutable(prevHistory, { deep: true });
  if (arrHist[arrHist.length - 1] !== url) {
    const thisExists = arrHist.indexOf(arrHist.find(v => v === url));
    if (thisExists === -1) {
      arrHist.push(url);
      return {
        type: NAVIGATION_ADD_HISTORY,
        params: arrHist,
      };
    }
    return {
      type: NAVIGATION_OPEN,
      opc: true,
    };
  }
  // Esse return só está aqui, pois sem ele o sistema dá erro.
  return {
    type: NAVIGATION_OPEN,
    opc: true,
  };
}

/** Remove Navigation
 * Remove o último item do histórico
 * Informe a props de navegação.
 * @param {array} prevHistory Array com o histórico de navegação
 * @author Rafael Gomides
 */
export function removeNavigation(prevHistory) {
  const arrHist = asMutable(prevHistory, { deep: true });
  arrHist.pop();
  return {
    type: NAVIGATION_REMOVE_HISTORY,
    params: arrHist,
  };
}

/** Restore Navigation
 * Restaura o histórico de navegação, sobrescrevendo o atual
 * @param {Array} oldHistory Array com os Valores antigos
 */
export function restoreNavigation(oldHistory) {
  return {
    type: NAVIGATION_RESTORE_HISTORY,
    payload: oldHistory,
  };
}

/** Restore All Navigation
 * Restaura o Histórico antigo antes do sistema ter dado refresh
 * Apaga o histórico atual
 * Sobreescreve com um novo
 * @param {Array} oldHistory Array com os valores antigos
 */
export function restoreAllNavigation(oldHistory) {
  return (dispatch) => {
    dispatch(clearNavigationHistory());
    dispatch(restoreNavigation(oldHistory));
  };
}

/** Navigation Grandparent
 *
 * Chama as funções:
 *  - closeNavigation()
 *      - Fecha a barra de navegação
 *  - clearNavigationHistory()
 *      - Limpa todo o histórico de navegação.
 * @author Rafael Gomides
 */
export function navigationGrandParent() {
  return (dispatch) => {
    dispatch(closeNavigation());
    dispatch(clearNavigationHistory());
  };
}

/** Navigation Parent
 *
 * Chama as funções:
 *  - openNavigation(opc)
 *      - Abre a barra de navegação.
 *          - Informe 'false' como parametro para que não apareça o botão de voltar.
 *  - clearNavigationHistory()
 *      - Limpa todo o histórico de navegação.
 *  - addNavigation(url, prevHistory)
 *      - Adiciona um novo item a navegação do sistema.
 *          - Informe o PATH que deseja adicionar e na sequencia passe a props de navegação.
 * @param {bool} opc Opção de abertura do NavigationBar
 * @param {String} url PATHNAME do local que deseja adicionar na Array - Default: window.location.pathname
 * @author Rafael Gomides
 */
export function navigationParent(opc, url = false) {
  return (dispatch) => {
    dispatch(openNavigation(opc));
    dispatch(clearNavigationHistory());
    url ? dispatch(addNavigation([], url)) : dispatch(addNavigation([]));
  };
}

/** Navigation Children Open
 *
 * Chama as funções:
 *  - openNavigation()
 *      - Abre a barra de navegação.
 *          - Informe 'false' como parametro para que não apareça o botão de voltar.
 *  - addNavigation()
 *      - Adiciona um novo item a navegação do sistema.
 *          - Informe o PATH que deseja adicionar e na sequencia passe a props de navegação.
 * @param {array} prevHistory Array com o histórico de navegação
 * @param {String} url PATHNAME do local que deseja adicionar na Array - Default: window.location.pathname
 * @author Rafael Gomides
 */
export function navigationChildrenOpen(prevHistory = [], url = false) {
  return (dispatch) => {
    dispatch(openNavigation());
    url
      ? dispatch(addNavigation(prevHistory, url))
      : dispatch(addNavigation(prevHistory));
  };
}
