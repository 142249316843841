import * as Actions from '../../constant/constants';
import socket from '../../socket';


export function getPresetWorkouts() {
  return {
    type: Actions.GET_PRESET_WORKOUTS,
    payload: socket.sendMessage(Actions.GET_PRESET_WORKOUTS, {})
  };
}

export function setPresetWorkouts(res) {
  return {
    type: Actions.GET_PRESET_WORKOUTS,
    payload: res.data
  };
}

export function selectRegister(presetWorkout) {
  return {
    type: Actions.REGISTER_SELECTED_PRESET_WORKOUT,
    payload: presetWorkout
  };
}

export function addPresetWorkout(res) {
  return {
    type: Actions.ADD_REGISTER_PRESET_WORKOUT,
    payload: res.data
  };
}

export function addRegister(payload) {
  return {
    type: Actions.ADD_REQUEST,
    payload: socket.sendMessage(Actions.ADD_PRESET_WORKOUT, payload)
  };
}

export function editPresetWorkout(res) {
  return {
    type: Actions.UPDATE_REGISTER_PRESET_WORKOUT,
    payload: res.data
  };
}

export function editRegister(payload) {
  return {
    type: Actions.UPDATE_REQUEST,
    payload: socket.sendMessage(Actions.UPDATE_PRESET_WORKOUT, payload),
  };
}

export function deletePresetWorkout(res) {
  return {
    type: Actions.DELETE_REGISTER_PRESET_WORKOUT,
    payload: res.data
  };
}

export function deleteRegister(id) {
  return {
    type: Actions.DELETE_REQUEST,
    payload: socket.sendMessage(Actions.DELETE_PRESET_WORKOUT, { id })
  };
}

export function getOnePresetWorkout(id) {
  return {
    type: Actions.GET_ONE_PRESET_WORKOUT,
    payload: socket.sendMessage(Actions.GET_ONE_PRESET_WORKOUT, { id })
  }
}

export function convertMessages(data) {
  const shortName = data.shortname;
  const shortNameWithoutSpaces = shortName.replace(/\s/g, '').toLocaleLowerCase();
  data.idMessageName = `app.exercise.${shortNameWithoutSpaces}`;
  data.defaultMessageName = data.name;
  return data;
}
