/*
    Constantes por estado
*/

export const Socket = {
  UNINITIATED: 'UNINITIATED',
  TIMEOUT: 'TIMEOUT',
  CONNECTED: 'CONNECTED',
  DISCONNECTED: 'DISCONNECTED',
};

export const publicKey = {
  REQUEST_SUCCESS: true,
  REQUEST_FAILED: false,
  ERROR: true,
  NOT_ERROR: false,
  LOADING: true,
  NOT_LOADING: false,
};

export const Login = {
  AUTHENTICATED: true,
  NOT_AUTHENTICATED: false,
  AUTHENTICATING: true,
  NOT_AUTHENTICATING: false,
  ERROR: true,
  NOT_ERROR: false,
  NOT_TOKEN: null,
  NOT_USER: {
    id: null,
    idsession: null,
    name: null,
    token: null,
    keepconnection: false,
    person: null,
    permission: {
      id: 0,
      person: null,
      client: {
        id: 0,
      },
      evaluator: null,
      evaluated: null,
      user: false,
      registration: false,
      evaluatorperm: false,
      evaluation: false,
      financial: false,
      plan: false,
      blocked: false,
      sysadmin: false,
    },
    permissions: [],
    notifications: [],
    exp: 0,
    iss: 'Althaea Corporation',
  },
  NOT_RESPONSE_FORGOT_PASSWORD: null,
};

export const NewEmailStatus = {
  CONNECTION_ERROR_DATABASE: -1,
  EMAIL_DOES_NOT_EXIST: 0,
  EMAIL_EXIST_AND_SAME_CLIENT: 1,
  EMAIL_EXIST_AND_DIFFERENT_CLIENT: 2,
  EMAIL_EXIST_AND_IS_CLIENT: 1,
  EMAIL_EXIST_AND_NOT_CLIENT: 2,
};
