import * as Actions from '../../constant/constants';
import socket from '../../socket';

export function getAuthors(event) {
  return {
    type: Actions.GET_AUTHORS,
    payload: socket.sendMessage(Actions.GET_AUTHORS, []),
  };
}

export function setAuthors(event) {
  let authors = [];
  if (typeof event === 'undefined') socket.sendMessage(Actions.GET_AUTHORS, []);
  else authors = event.data;
  return {
    type: Actions.GET_AUTHORS,
    payload: authors,
  };
}

export function selectRegister(register) {
  return {
    type: Actions.REGISTER_SELECTED_AUTHOR,
    payload: register,
  };
}

export function addAuthor(event) {
  let author = {};
  author = event.data;
  return {
    type: Actions.ADD_REGISTER_AUTHOR,
    payload: author,
  };
}

export function addRegister(name, type) {
  return {
    type: Actions.ADD_REQUEST,
    payload: socket.sendMessage(Actions.ADD_AUTHOR, { name, type }),
  };
}

export function editAuthor(event) {
  let authorEdit = {};
  authorEdit = event.data;
  return {
    type: Actions.EDIT_REGISTER_AUTHOR,
    payload: authorEdit,
  };
}

export function editRegister(id, name, type) {
  return {
    type: Actions.UPDATE_REQUEST,
    payload: socket.sendMessage(Actions.UPDATE_AUTHOR, { id, name, type }),
  };
}

export function deleteAuthor(event) {
  let authorDelete = {};
  authorDelete = event.data;
  return {
    type: Actions.DELETE_REGISTER_AUTHOR,
    payload: authorDelete,
  };
}

export function deleteRegister(id, name) {
  return {
    type: Actions.DELETE_REQUEST,
    payload: socket.sendMessage(Actions.DELETE_AUTHOR, { id, name }),
  };
}
