import { zeroPad } from "./functions";
import { isNotNumber, validateTimeFormat } from "./validate";

/**
 * Calcula o tempo necessário para percorrer cada quilômetro, com base na velocidade em metros por minuto.
 *
 * @param {number} speedMmin A velocidade em metros por minuto.
 * @returns {number} O tempo necessário para percorrer cada quilômetro.
 *
 * Exemplo de entrada: 200
 * Saída correspondente: 5
 *
 * Neste exemplo, se a velocidade em metros por minuto for 200, o tempo necessário para percorrer cada quilômetro será de 5 minutos.
 */
export function calculateTimeOfEachKilometer(speedMmin) {
  const formattingToMetersPerSeconds = 1000 / speedMmin;
  const formatSec = (formattingToMetersPerSeconds % 1) * 60;
  let result = Math.trunc(formattingToMetersPerSeconds) + (formatSec / 100);
  result = Math.round(result * 100) / 100; // convertendo para duas casas decimais
  return result;
}

/**
 * Calcula vários resultados com base no valor do VO² Máximo, incluindo velocidade em km/h,
 * velocidade em m/min, METs, Kcal/min, a distância percorrida em 30 minutos e o tempo para
 * percorrer cada quilômetro.
 *
 * @param {number} vo2Max O valor do VO² Máximo.
 * @param {number} weight O peso do avaliado.
 * @returns {Object} Um objeto contendo os resultados calculados:
 *                   - speedMmin: A velocidade em metros por minuto.
 *                   - speedKmh: A velocidade em quilômetros por hora.
 *                   - mets: O valor METs (Metabolic Equivalent of Task).
 *                   - kcalmin: A taxa de calorias queimadas por minuto.
 *                   - thirtyMinutes: A distância percorrida em 30 minutos.
 *                   - oneKilometer: O tempo para percorrer cada quilômetro.
 */
export function calculateResultsFromMaxVO2(vo2Max, weight) {
  const speedMmin = (vo2Max - 3.5) / 0.2;
  const speedKmh = speedMmin * 60 / 1000;
  const mets = vo2Max / 3.5;
  const kcalmin = vo2Max * weight / 200;
  const thirtyMinutes = speedMmin * 30;
  const oneKilometer = calculateTimeOfEachKilometer(speedMmin);

  return {
    speedMmin,
    speedKmh,
    mets,
    kcalmin,
    thirtyMinutes,
    oneKilometer
  };
}

/**
 * @typedef {Object} Time
 * @property {number} minutes - Os minutos do período.
 * @property {number} seconds - Os segundos do período.
 */

/**
 * Extrai os minutos e segundos de uma string de tempo no formato "mm:ss".
 * Se o formato da string for inválido, retorna a própria string de tempo.
 *
 * @param {string} value A string de tempo no formato "mm:ss" a ser processada.
 * @returns {Time} Retorna um objeto com as propriedades minutes e seconds caso o formato seja válido.
 *                          Se o formato for inválido, retorna a própria string de tempo.
 */
export function extractHoursMinutesAndSeconds(value) {
  if (validateTimeFormat(value)) return value;
  const [minutes, seconds] = value.split(':').map(Number);
  return { minutes, seconds };
}

/**
 * Converte o tempo fornecido em minutos e segundos em frações de minutos.
 *
 * @param {number} minutes O valor dos minutos.
 * @param {number} seconds O valor dos segundos.
 * @returns {number} O tempo convertido em frações de minutos.
 *
 */
export function convertTimeToFractionsOfMinutes(minutes, seconds) {
  if (isNotNumber(minutes) || isNotNumber(seconds)) return 0;
  return minutes + (seconds / 60);
}

/**
 * Converte um tempo em minutos e frações de minutos para uma string no formato "mm:ss".
 *
 * @param {number} time O tempo em minutos e frações de minutos.
 * @returns {string} Uma string representando o tempo no formato "mm:ss".
 *
 * A função recebe um tempo em minutos e frações de minutos.
 * Em seguida, realiza o cálculo para converter o tempo em horas, minutos e segundos.
 * Por fim, retorna uma string formatada no padrão "mm:ss" representando o tempo.
 *
 * Exemplo de entrada: 0.5
 *
 * Saída correspondente: "00:30"
 *
 * Neste exemplo, o tempo fornecido é de 0.5 minutos, ou seja, 30 segundos.
 * A função converte esse tempo para uma string no formato "mm:ss", resultando em "00:30".
 */
export function convertMinutesToTimeString(time) {
  const minutes = Math.floor(time % 60);
  const seconds = Math.round((time - Math.floor(time)) * 60);
  return `${zeroPad(minutes)}:${zeroPad(seconds)}`;
}

/**
 * Converte uma string de tempo no formato "mm:ss" em minutos e frações de minutos.
 *
 * @param {string} value A string de tempo no formato "mm:ss".
 * @returns {number|string} O tempo convertido em minutos e frações de minutos, ou a string de tempo original em caso de formato inválido.
 *
 */
export function convertTimeStringToMinutes(value) {
  if (validateTimeFormat(value)) return 0;
  const { minutes, seconds } = extractHoursMinutesAndSeconds(value);
  const result = convertTimeToFractionsOfMinutes(minutes, seconds);
  return result;
}

/**
 * Calcula a classificação do resultado do VO² Máximo com base nos limiares de referência,
 * levando em consideração a idade e o gênero.
 *
 * @param {number} vo2Max - O valor do VO² Máximo a ser calculado.
 * @param {number} age - A idade do indivíduo.
 * @param {number} genderIndex - O índice do gênero do indivíduo (0 para masculino, 1 para feminino).
 * @returns {string} - A classificação do resultado de VO² Máximo.
 * 
 */
export function calculateRatingFromVo2Max(vo2Max, age, genderIndex) {
  const rattingMap = ["Fraco", "Regular", "Médio", "Bom", "Excelente", "Não disponível"];
  const gender = genderIndex === 0 ? "male" : "female";
  let classification = rattingMap[5];

  if (age >= 10 && age <= 18) {
    classification = calculateChildrenResultRatingFromVo2Max(vo2Max, age, gender);
  } else {
    classification = calculateAdultResultRatingFromVo2Max(vo2Max, age, gender);
    classification = rattingMap[classification];
  }

  return classification;
}

/**
 * Calcula o rating do resultado do VO² Máximo para adultos com base nos limiares de referência.
 *
 * @param {number} vo2Max - O valor do VO² Máximo a ser calculado.
 * @param {number} age - A idade do adulto.
 * @param {string} gender - O gênero do adulto ('male' para masculino, 'female' para feminino).
 * @returns {number} - O rating do resultado do VO² Máximo do adulto.
 *                    O valor de retorno indica o rating conforme a seguinte escala:
 *                    - 0: Fraco
 *                    - 1: Regular
 *                    - 2: Médio
 *                    - 3: Bom
 *                    - 4: Excelente
 *                    - 5: Não disponível
 */
export function calculateAdultResultRatingFromVo2Max(vo2Max, age, gender) {
  const ageRanges = {
    male: [
      { minAge: 19, maxAge: 29, ratingThresholds: [24, 30, 37, 48] },
      { minAge: 30, maxAge: 39, ratingThresholds: [20, 27, 38, 44] },
      { minAge: 40, maxAge: 49, ratingThresholds: [17, 23, 30, 41] },
      { minAge: 50, maxAge: 59, ratingThresholds: [15, 20, 27, 37] },
      { minAge: 60, maxAge: 69, ratingThresholds: [13, 17, 23, 34] },
    ],
    female: [
      { minAge: 19, maxAge: 29, ratingThresholds: [25, 33, 42, 52] },
      { minAge: 30, maxAge: 39, ratingThresholds: [23, 30, 38, 48] },
      { minAge: 40, maxAge: 49, ratingThresholds: [20, 26, 35, 44] },
      { minAge: 50, maxAge: 59, ratingThresholds: [18, 24, 33, 42] },
      { minAge: 60, maxAge: 69, ratingThresholds: [16, 22, 30, 40] },
    ],
  };

  const ageGroupOfTheEvaluated = ageRanges[gender];
  const ageRangeOfTheEvaluated = ageGroupOfTheEvaluated.findIndex(
    (range) => range.minAge <= age && age <= range.maxAge
  );

  if (ageRangeOfTheEvaluated === -1) return 5;

  const thresholds = ageRanges[gender][ageRangeOfTheEvaluated].ratingThresholds;
  const ratingIndex = thresholds.findIndex((threshold) => vo2Max <= threshold);

  return ratingIndex === -1 ? 4 : ratingIndex;
}

/**
 * Calcula o rating do resultado do VO² Máximo para crianças com base na média de referência.
 *
 * @param {number} vo2Max - O valor do VO² Máximo a ser calculado.
 * @param {number} age - A idade da criança (de 10 a 18 anos).
 * @param {string} gender - O gênero da criança ('male' para masculino, 'female' para feminino).
 * @returns {string} - O rating do resultado do VO² Máximo da criança em relação à média de referência.
 *                    A string de retorno possui o formato: "{fitnessRating}% {comparison} da média ({averageVo2Max} ml/kg/min)".
 */
export function calculateChildrenResultRatingFromVo2Max(vo2Max, age, gender) {
  const average = [
    { male: 43.66, female: 46.33 },
    { male: 39.55, female: 35.62 },
    { male: 38.45, female: 35.00 },
    { male: 42.72, female: 35.74 },
    { male: 40.21, female: 31.63 },
    { male: 42.88, female: 33.81 },
    { male: 41.45, female: 32.61 },
    { male: 43.94, female: 32.91 },
    { male: 44.58, female: 31.40 },
  ];

  const averageVo2Max = average[age - 10][gender];
  const fitnessRating = ((vo2Max / averageVo2Max) - 1) * 100;
  const comparison = fitnessRating >= 0 ? "acima" : "abaixo";
  const formattedFitnessRating = `${Math.abs(fitnessRating.toFixed(2))}% ${comparison} da média (${averageVo2Max.toFixed(2)} ml/kg/min)`;

  return formattedFitnessRating;
}
