import React from 'react';

const IconEthnicities = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width={props.width}
    height={props.height}
  >
    <path
      fill="#E6AF78"
      d="M141.722 325.247l-29.994-11.248c-5.701-2.138-9.479-7.588-9.479-13.678v-11.787h-43.82v11.788c0 6.089-3.777 11.539-9.478 13.677l-29.994 11.248C7.554 329.523 0 340.423 0 352.601v52.79c0 8.067 6.54 14.607 14.607 14.607h146.071v-67.397c0-12.178-7.554-23.078-18.956-27.354z"
    />
    <path
      fill="#D29B6E"
      d="M80.339 310.445c8.201 0 16.023-1.577 23.269-4.333-.793-1.811-1.359-3.73-1.359-5.791v-11.787h-43.82v11.788c0 2.06-.565 3.979-1.359 5.789 7.246 2.757 15.068 4.334 23.269 4.334z"
    />
    <path
      fill="#DBD9DC"
      d="M14.607 419.999h146.071v-67.397c0-12.178-7.554-23.078-18.957-27.354l-28.848-10.818-22.205 22.205c-5.704 5.704-14.953 5.704-20.658 0L47.805 314.43l-28.849 10.818C7.554 329.523 0 340.423 0 352.601v52.79c0 8.068 6.54 14.608 14.607 14.608z"
    />
    <path
      fill="#F0C087"
      d="M80.621 295.838h-.564c-28.08 0-50.843-22.763-50.843-50.843V222.52c0-28.08 22.763-50.843 50.843-50.843h.564c28.08 0 50.843 22.763 50.843 50.843v22.474c0 28.08-22.763 50.844-50.843 50.844z"
    />
    <path
      fill="#E6AF78"
      d="M58.429 244.713v-21.911c0-23.156 15.405-42.692 36.518-48.98-4.631-1.379-9.528-2.145-14.607-2.145-28.235 0-51.125 22.889-51.125 51.125v21.911c0 28.235 22.89 51.125 51.125 51.125 5.08 0 9.976-.766 14.607-2.145-21.114-6.289-36.518-25.825-36.518-48.98z"
    />
    <path
      fill="#EDEBED"
      d="M22.797 355.152L4.611 336.967C1.699 341.549 0 346.919 0 352.601v52.79c0 8.067 6.54 14.607 14.607 14.607h14.607v-49.353c0-5.811-2.308-11.384-6.417-15.493zm50.239-16.26v81.107h14.607v-81.107c-4.516 2.621-10.092 2.621-14.607 0z"
    />
    <circle cx="80.34" cy="171.68" r="29.21" fill="#6E4B53" />
    <path
      fill="#5C414B"
      d="M29.214 222.802v16.39c8.09-3.832 17.998-9.874 25.58-18.865 2.215-2.627 6.004-3.169 9-1.486 9.628 5.409 32.206 15.711 67.67 18.042v-14.081c0-28.236-22.889-51.125-51.125-51.125-28.235 0-51.125 22.889-51.125 51.125z"
    />
    <path
      fill="#503441"
      d="M52.428 179.999c-13.967 9.126-23.214 24.873-23.214 42.802v16.39c8.089-3.831 17.999-9.874 25.58-18.865 1.162-1.378 2.759-2.146 4.445-2.356-5.41-10.281-9.636-23.69-6.811-37.971z"
    />
    <path
      fill="#FAD7A5"
      d="M495.957 307.047l-36.718-16.999c-4.878-2.258-8.021-7.297-8.021-12.858v-26.031H397.19v26.031c0 5.56-3.143 10.599-8.021 12.857l-36.719 17c-9.756 4.516-16.043 14.594-16.043 25.714v87.238h162.085c7.46 0 13.507-6.299 13.507-14.07v-73.167c.001-11.121-6.286-21.199-16.042-25.715z"
    />
    <path
      fill="#DBD9DC"
      d="M495.957 307.046l-36.468-16.883-25.734 25.734c-5.275 5.275-13.827 5.275-19.102 0l-25.734-25.735-36.469 16.884c-9.756 4.517-16.043 14.594-16.043 25.715v87.238l162.086-.001c7.46 0 13.507-6.299 13.507-14.07v-73.167c0-11.121-6.287-21.198-16.043-25.715z"
    />
    <path
      fill="#F0C891"
      d="M397.19 251.159v26.031c0 2.577-.735 5.008-1.946 7.136 8.614 4.688 18.482 7.354 28.96 7.354s20.345-2.665 28.959-7.353c-1.211-2.128-1.946-4.56-1.946-7.137v-26.031H397.19z"
    />
    <path
      fill="#FFE1B4"
      d="M424.204 278.173c-26.109 0-47.275-21.166-47.275-47.275v-40.522h94.55v40.522c0 26.109-21.165 47.275-47.275 47.275z"
    />
    <path
      fill="#FAD7A5"
      d="M403.943 230.898v-40.522h-27.014v40.522c0 26.109 21.166 47.275 47.275 47.275 4.697 0 9.225-.708 13.507-1.984-19.523-5.814-33.768-23.879-33.768-45.291z"
    />
    <path
      fill="#D59F63"
      d="M430.958 156.609h-13.507c-26.109 0-47.275 21.166-47.275 47.275v10.804c0 5.005 5.31 8.403 9.737 6.068 6.852-3.614 14.888-10.356 20.288-17.629 2.181-2.937 6.375-3.373 9.307-1.185 21.43 15.992 51.279 12.316 63.346 9.932 3.145-.621 5.379-3.409 5.379-6.614v-1.377c0-26.109-21.166-47.274-47.275-47.274z"
    />
    <path
      fill="#CD915A"
      d="M379.913 220.756c6.852-3.614 14.888-10.356 20.288-17.629.826-1.113 1.979-1.748 3.205-2.136-9.548-13.291-10.199-28.107-9.013-38.358-14.442 8.09-24.218 23.52-24.218 41.251v10.804c0 5.004 5.31 8.402 9.738 6.068z"
    />
    <path
      fill="#EDEBED"
      d="M478.232 360.855c0-5.374 2.134-10.527 5.934-14.327l25.402-25.402c1.566 3.588 2.432 7.538 2.432 11.635v73.167c0 7.771-6.047 14.07-13.507 14.07h-20.261v-59.143zm-60.781-42.87v102.014h13.507V317.985c-4.176 2.424-9.332 2.424-13.507 0z"
    />
    <path
      fill="#B48764"
      d="M341.659 279.34l-45.724-21.169c-6.074-2.812-9.989-9.087-9.989-16.011v-32.416l-67.28.001v32.416c0 6.924-3.914 13.198-9.989 16.01l-45.725 21.169c-12.149 5.624-19.978 18.174-19.978 32.022v91.115c0 9.677 7.531 17.521 16.82 17.521h185.023c9.29 0 16.82-7.845 16.82-17.521v-91.114c0-13.848-7.829-26.398-19.978-32.023z"
    />
    <path
      fill="#5D5360"
      d="M159.794 419.999l185.023-.001c9.29 0 16.82-7.845 16.82-17.521v-91.114c0-13.849-7.83-26.398-19.978-32.023l-45.413-21.024-32.046 32.046c-6.569 6.569-17.219 6.569-23.788 0l-32.047-32.047-45.414 21.025c-12.149 5.624-19.978 18.173-19.978 32.022v91.115c0 9.677 7.531 17.522 16.821 17.522z"
    />
    <path
      fill="#966D50"
      d="M218.665 209.744v32.416c0 3.209-.916 6.237-2.423 8.887 10.727 5.838 23.015 9.158 36.064 9.158 13.048 0 25.335-3.319 36.063-9.157-1.508-2.65-2.423-5.679-2.423-8.888v-32.416h-67.281z"
    />
    <path
      fill="#C39772"
      d="M252.306 243.385c-32.514 0-58.871-26.358-58.871-58.871v-50.461h117.743v50.461c-.001 32.513-26.359 58.871-58.872 58.871z"
    />
    <path
      fill="#B48764"
      d="M227.075 184.513v-50.461h-33.641v50.461c0 32.514 26.358 58.871 58.871 58.871 5.849 0 11.488-.882 16.82-2.47-24.311-7.241-42.05-29.736-42.05-56.401z"
    />
    <path
      fill="#5C414B"
      d="M260.716 92.001h-16.82c-32.514 0-58.871 26.358-58.871 58.871v13.454c0 6.232 6.613 10.464 12.125 7.557 8.532-4.5 18.54-12.896 25.265-21.953 2.716-3.658 7.938-4.2 11.59-1.475 26.687 19.914 63.858 15.337 78.885 12.369 3.916-.773 6.699-4.245 6.699-8.236v-1.715c-.002-32.514-26.36-58.872-58.873-58.872z"
    />
    <path
      fill="#503441"
      d="M197.15 171.883c8.532-4.5 18.539-12.896 25.264-21.953 1.029-1.386 2.464-2.177 3.992-2.66-11.89-16.551-12.701-35.001-11.224-47.767-17.985 10.075-30.158 29.289-30.158 51.37v13.454c0 6.232 6.613 10.464 12.126 7.556z"
    />
    <path
      fill="#6F6571"
      d="M185.024 346.347c0-6.692-2.658-13.109-7.39-17.841l-31.633-31.633c-1.95 4.468-3.028 9.387-3.028 14.488v91.115c0 9.677 7.531 17.522 16.82 17.522h25.231v-73.651zm134.562 0c0-6.692 2.658-13.109 7.39-17.841l31.633-31.633c1.95 4.468 3.028 9.387 3.028 14.488v91.115c0 9.677-7.531 17.522-16.82 17.522h-25.231v-73.651zm-75.691-53.385v127.037l16.82-.001V292.962c-5.199 3.018-11.62 3.018-16.82 0z"
    />
  </svg>
);

IconEthnicities.defaultProps = {
  width: 170,
  color: 'default',
};

export default IconEthnicities;
