import React from 'react';

const IconAvatarRed = (props) => {
  return (
    <img
      src={require(`../../images/avatar-red.png`)}
      width="125"
      className="icon-avatar icon-avatar--red"
      alt="Avatar"
    />
  );
};

export default IconAvatarRed;
