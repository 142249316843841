import React from 'react';

const IconDoManually = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 508 508"
    width={props.width}
    height={props.height}
  >
    <circle cx="254" cy="254" r="254" fill="#84DBFF" />
    <path
      d="M374.4 88.8H131.2c-5.2 0-9.6 4.4-9.6 9.6v311.2c0 5.2 4.4 9.6 9.6 9.6h243.2c5.2 0 9.6-4.4 9.6-9.6V98.4c0-5.2-4.4-9.6-9.6-9.6z"
      fill="#FFF"
    />
    <path
      d="M275.6 126h64.8v12h-64.8zm-41.2 37.2h106v12h-106zm41.2 37.6h64.8v12h-64.8zM256 238h84.4v12H256zm-24.8 37.2h109.2v12H231.2zm-66-149.2h104.4v12H165.2zm0 37.2h63.2v12h-63.2zm0 37.6h104.4v12H165.2zm0 37.2h84.4v12h-84.4zm0 37.2h60v12h-60z"
      fill="#E6E9EE"
    />
    <path
      fill="#FF7058"
      d="M307.817 361.458L257.19 310.83l107.478-107.478 50.628 50.628z"
    />
    <path
      fill="#E6E9EE"
      d="M415.188 254.062L400.48 268.77l-50.627-50.628 14.708-14.708z"
    />
    <path
      d="M415.2 254l21.6-21.6c3.2-3.2 3.2-8.4 0-12L398 181.6c-3.2-3.2-8.4-3.2-12 0l-21.6 21.6 50.8 50.8z"
      fill="#F1543F"
    />
    <path fill="#FFD05B" d="M256.8 310.8L238.4 380l69.2-18.4" />
    <path fill="#324A5E" d="M244.4 357.6l-6 22.4 22.4-6" />
  </svg>
);

IconDoManually.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconDoManually;
