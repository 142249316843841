import * as Actions from '../../constant/constants';
import socket from '../../socket';

const { MAN, WOMAN } = Actions;

export function getNeuromotorProtocols() {
  return {
    type: Actions.GET_NEUROMOTOR_TESTS,
    payload: socket.sendMessage(Actions.GET_NEUROMOTOR_TESTS, {}),
  }
}

export function setNeuromotorProtocols(res) {
  const data = res.data.map((protocol) => {
    const { genders, ...rest } = protocol;
    const protocolClone = {};

    if (genders === null) {
      protocolClone.male = true;
      protocolClone.female = true;
    }

    if (Array.isArray(genders)) {
      protocolClone.male = genders.find(g => g.id === MAN) !== undefined;
      protocolClone.female = genders.find(g => g.id === WOMAN) !== undefined;
    }

    return {...rest, ...protocolClone};
  });
  return {
    type: Actions.GET_NEUROMOTOR_TESTS,
    payload: data,
  }
}
