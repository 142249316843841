import axios from 'axios';
import * as Actions from '../../constant/constants';
import socket from '../../socket';

// INSERT
export function addExternalEvaluatorsRegister(data = {}) {
  return {
    type: Actions.ADD_REQUEST,
    payload: socket.sendMessage(Actions.ADD_EXTERNAL_EVALUATORS_REGISTER, { ...data }),
  };
}

export function setExternalEvaluatorsRegister(event) {
  let externalEvaluatorsRegister = {};
  if (typeof event !== 'undefined') {
    externalEvaluatorsRegister = event.data;
  }
  return {
    type: Actions.ADD_EXTERNAL_EVALUATORS_REGISTER,
    payload: externalEvaluatorsRegister,
  };
}

// SELECT
export function getExternalEvaluatorsRegisters() {
  return {
    type: Actions.GET_EXTERNAL_EVALUATORS_REGISTERS,
    ws: socket.sendMessage(Actions.GET_EXTERNAL_EVALUATORS_REGISTERS, []),
  };
}

export function setExternalEvaluatorsRegisters(event) {
  let externalEvaluatorsRegisters = [];
  if (typeof event === 'undefined') {
    socket.sendMessage(Actions.GET_EXTERNAL_EVALUATORS_REGISTERS, []);
  } else {
    externalEvaluatorsRegisters = event.data;
    if (externalEvaluatorsRegisters === null) {
      externalEvaluatorsRegisters = [];
    }
  }
  return {
    type: Actions.GET_EXTERNAL_EVALUATORS_REGISTERS,
    payload: externalEvaluatorsRegisters,
  };
}

// SELECT BY ID
export function getExternalEvaluatorsRegistersByClient(idclient) {
  return {
    type: Actions.GET_EXTERNAL_EVALUATORS_REGISTERS,
    ws: socket.sendMessage(Actions.GET_EXTERNAL_EVALUATORS_REGISTERS_BY_CLIENT, {
      idclient,
    }),
  };
}

export function setExternalEvaluatorsRegistersByClient(event) {
  let externalEvaluatorsRegisters = [];
  if (typeof event === 'undefined') {
    socket.sendMessage(Actions.GET_EXTERNAL_EVALUATORS_REGISTERS, []);
  } else {
    externalEvaluatorsRegisters = event.data;
    if (externalEvaluatorsRegisters === null) {
      externalEvaluatorsRegisters = [];
    }
  }
  return {
    type: Actions.GET_EXTERNAL_EVALUATORS_REGISTERS_BY_CLIENT,
    payload: externalEvaluatorsRegisters,
  };
}

// DELETE
export function deleteExternalEvaluatorsRegister(idexternalevaluatorsregister) {
  return {
    type: Actions.UPDATE_REQUEST,
    payload: socket.sendMessage(Actions.DELETE_EXTERNAL_EVALUATORS_REGISTER, {
      idexternalevaluatorsregister,
    }),
  };
}

export function setDeleteExternalEvaluatorsRegister(event) {
  let externalEvaluatorsRegisterDelete = {};
  externalEvaluatorsRegisterDelete = event.data;
  return {
    type: Actions.DELETE_EXTERNAL_EVALUATORS_REGISTER,
    payload: externalEvaluatorsRegisterDelete,
  };
}

// selects an token by its token, if the result is invalid, it will return {id: 0}
export function selectExternalEvaluatorRegisterByToken(token, onSuccess = () => {}) {
  return (dispatch) => {
    dispatch({
      type: Actions.GOT_EXTERNAL_TOKEN_VALIDITY,
      payload: axios
        .post(`${Actions.URL_SERVER}/selectExternalEvaluatorRegisterByToken`, { token })
        .then((response) => {
          if (response.data) {
            dispatch(gotExternalEvaluatorRegisterByToken(response.data));
          } else {
            dispatch(gotExternalEvaluatorRegisterByToken());
          }
        }),
    });
  };
}

export function gotExternalEvaluatorRegisterByToken(selectedRegister = {}) {
  const tokenValidityEvent = new Event(Actions.GOT_EXTERNAL_TOKEN_VALIDITY);
  tokenValidityEvent.data = selectedRegister;
  window.dispatchEvent(tokenValidityEvent);
  return {
    type: Actions.GOT_EXTERNAL_TOKEN_VALIDITY,
    payload: selectedRegister,
  };
}
