export const tableErrorStatusPagSeguro = {
  10003: 'Email inválido',
  10005: 'The accounts of the vendor and buyer can not be related to each other.',
  10009: 'Método de pagamento temporariamente indisponível.',
  10020: 'Método de pagamento inválido.',
  10021: 'Error fetching vendor data from the system.',
  10023: 'Método de pagamento indisponível.',
  10024: 'Unregistered buyer is not allowed.',
  10025: 'O campo Nome não pode ser vazio.',
  10026: 'O campo Email não pode ser vazio.',
  10049: 'O campo Nome é obrigatório.',
  10050: 'O campo Email é obrigatório.',
  11002: 'Tamanho inválido para o campo Email',
  11006: 'URL de tamanho inválido',
  11007: 'redirectURL valor inválido',
  11008: 'Referência de tamanho inválida',
  11013: 'DDD inválido',
  11014: 'Telefone inválido',
  11027: 'A Quantidade de itens ultrapassou o limite',
  11028: 'A Valor do plano é obrigatório',
  11040: 'Padrão da idade inválido. Deve ser um inteiro.',
  11041: 'Idade máxima atingida',
  11042: 'maxUses padrão inválido. Deve ser um Integer.',
  11043: 'maxUses ultrapassou o limite',
  11054: 'abandonURL/reviewURL tamanho inválida',
  11055: 'abandonURL/reviewURL valor inválido',
  11071: 'preApprovalInitialDate valor inválido.',
  11072: 'preApprovalFinalDate valor inválido.',
  11084: 'seller has no credit card payment option.',
  11101: 'preApproval data é obrigatório.',
  11163: 'You must configure a transactions notifications (Notificação de Transações) URL before using this service.',
  11211: 'Pré aprovação não pode ser iniciada duas vezes no mesmo dia.',
  11214: "Pré aprovação não pode ser iniciada duas vezes no mesmo dia, enquanto o status for 'Processando' ou 'Suspenso'.",
  13005: 'A data inicial deve ser menor que o limite permitido.',
  13006: 'A data inicial não pode ultrapassar 180 dias.',
  13007: 'A data inicial deve ser menor ou igual a data final.',
  13008: 'search interval deve ser menor ou igual a 30 dias.',
  13009: 'A data final deve ser menor que o limite permitido.',
  13010: "A data inicial com o formato inválido, use: 'yyyy-MM-ddTHH:mm' (eg. 2010-01-27T17:25).",
  13011: "Data final com formato inválido, use: 'yyyy-MM-ddTHH:mm' (eg. 2010-01-27T17:25).",
  13013: 'Página inválida.',
  13014: 'Máximo de resultados por página com valor inválido (Deve estar entre 1 and 1000).',
  13017: 'Data inicial e Data final são obrigatórios no intervalo.',
  13018: 'O intervalo deve ser entre 1 e 30 dias.',
  13019: 'Intervalo da notificação é obrigatório.',
  13020: 'A página passada é maior que o número de páginas existentes',
  13023: 'Código mínimo de referência inválido (1-255)',
  13024: 'Código máximo de referência inválido (1-255)',
  17008: 'Pré-aprovação não encontrada.',
  17022: 'Status de Pré-aprovação inválido para finalizar a operação.',
  17023: 'Não encontrado a opção de pagamento no cartão de crédito.',
  17024: 'Pré-aprovação não liberada para este cartão',
  17032: "Destinatário inválido para checkout. Verifique o status da conta do destinatário e se é uma conta do vendedor.",
  17033: "Método de pagamento de pré-aprovação deve ser o mesmo da pré-aprovação criada.",
  17035: 'O formato de dias de vencimento é inválido',
  17036: 'O valor de dias de vencimento é inválido. Qualquer valor de 1 a 120 é permitido.',
  17037: 'Os dias de vencimento devem ser menores que os dias para pagamento.',
  17038: 'O formato de dias de expiração é inválido.',
  17039: 'O valor de expiração é inválido. Qualquer valor de 1 a 120 é permitido.',
  17061: 'Plano não encontrado.',
  17063: 'Hash é obrigatório.',
  17065: 'O documento é obrigatório.',
  17066: 'Quantidade de documentos inválida.',
  17067: 'A forma de pagamento é obrigatório.',
  17068: 'A forma de pagamento é inválido.',
  17069: 'Telefone é obrigatório.',
  17070: 'Endereço é obrigatório.',
  17071: 'O remetente é obrigatório.',
  17072: 'A forma de pagamento é obrigatória.',
  17073: 'Cartão de crédito é obrigatório.',
  17074: 'Titular do cartão de crédito obrigatório.',
  17075: 'O token do cartão de crédito é inválido.',
  17078: 'Data de expiração atingida.',
  17079: 'Limite de uso excedido.',
  17080: 'A pré-aprovação está suspensa.',
  17081: 'ordem de pagamento de pré-aprovação não encontrada.',
  17082: 'inválido status da ordem de pagamento de pré-aprovação para executar a operação solicitada. O status do pedido de pagamento de pré-aprovação é 0.',
  17083: 'A pré-aprovação já é 0.',
  17093: 'hash ou IP do remetente é obrigatório.',
  17094: 'There can be no new subscriptions to an inactive plan.',
  19001: 'postalCode inválido Value',
  19002: 'Tamanho inválido para o campo rua',
  19003: 'Tamanho inválido para o campo número do endereço',
  19004: 'Tamanho inválido para o campo complemento',
  19005: 'Tamanho inválido para o campo bairro',
  19006: 'Tamanho inválido para o campo cidade',
  19007: 'Valor inválido para o campo estado',
  19008: 'Valor inválido para o campo país',
  19014: 'Valor inválido para o campo telefone',
  19015: 'Padrão inválido para o campo país',
  30400: 'Data do cartão de crédito inválida',
  30401: 'Token do cartão de crédito inválido',
  30402: 'Token do cartão de crédito inválido',
  30405: 'Bandeira ou data de vencimento inválido para o seu cartão de crédito',
  30406: 'Parâmetro criptografado-cc inválido',
  30407: 'Não foi possível descriptografar o cartão de crédito',
  50103: 'O campo CEP é obrigatório',
  50105: 'Número de endereço não pode estar vazio',
  50106: 'O campo bairro não pode estar vazio',
  50107: 'O campo país não pode estar vazia',
  50108: 'O campo cidade não pode estar vazia',
  50131: 'O endereço IP não segue um padrão válido',
  50134: 'O campo endereço não pode estar vazio',
  53037: 'Token do cartão de crédito é obrigatório.',
  53042: 'Nome do titular do cartão é obrigatório.',
  53047: 'Data de aniversário do titular do cartão é obrigatória.',
  53048: 'O Titular do cartão de crédito tem a data de nascimento inválida',
  53151: 'Valor do desconto não pode ser vazio.',
  53152: 'Valor do desconto ultrapassou o limite. O valor deve ser maior que 0% e menor ou igual a 100%.',
  53153: 'Não encontrado próximo pagamento para esta pré-aprovação.',
  53154: 'Status não pode ser vazio.',
  53155: 'Tipo do desconto é obrigatório.',
  53156: 'Tipo do valor de desconto é inválido.',
  53157: 'Valor do desconto ultrapassou o limite. O valor deve ser maior que 0% e e menor ou igual ao máximo permitido para este pagamento.',
  53158: 'Valor do desconto obrigatório.',
  57038: 'O endereço é obrigatório.',
  61007: 'Tipo do documento é obrigatório.',
  61008: 'Tipo do documento é inválido.',
  61009: 'valor do documento é obrigatório.',
  61010: 'valor do documento is inválido.',
  61011: 'CPF inválido.',
  61012: 'CNPJ inválido.',
}