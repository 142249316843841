import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';

const initialState = Immutable.from({
  fatCategories: [],
  tables: [],
  tableSelected: {},
  loading: false,
  shouldGetTable: true,
  fatPercentagesSelected: [],
});

export default function tablesFatnessIdeal(state = initialState, action = {}) {
  switch (action.type) {
    case Actions.GET_FAT_PERCENTAGES_IDEAL:
      const percentagens = state
        .updateIn(['tables'], () => action.payload)
        .updateIn(['shouldGetTable'], () => false)
        .updateIn(['loading'], () => false);
      return percentagens;
    case Actions.ADD_FAT_PERCENTAGE_IDEAL:
      const newState = Immutable.merge({
        ...state,
        tables: [
          ...state.tables,
          action.payload[Actions.MAN_INDEX],
          action.payload[Actions.WOMAN_INDEX],
        ],
      });
      return newState;
    case Actions.UPDATE_FAT_PERCENTAGE_IDEAL:
      var stateTmp = Immutable.asMutable(state, { deep: true });
      var indexUpdate;
      action.payload.map((t) => {
        indexUpdate = stateTmp.tables.findIndex(table => table.id === t.id);
        if (indexUpdate !== -1) {
          return (stateTmp.tables[indexUpdate] = t);
        }
        return false;
      });
      return Immutable.from(stateTmp);
    case Actions.DELETE_FAT_PERCENTAGE_IDEAL:
      var stateTmp0 = Immutable.asMutable(state, { deep: true });
      stateTmp0.tables = stateTmp0.tables.filter(
        t => action.payload.findIndex(table => t.id === table.id) === -1,
      );
      return Immutable.from(stateTmp0);
    case Actions.DELETE_REGISTER_FAT_PERCENTAGES_IDEAL:
      return state.set('fatPercentagesSelected', action.payload);
    default:
      return state;
  }
}
