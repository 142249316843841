import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';
import { isEmptyObj } from '../../utils/functions';

const initialState = Immutable.from({
  externalEvaluatorsRegisters: [],
  loading: false,
  shouldGetExternalEvaluatorsRegister: true,
  selectedRegisterData: null,
  generatedLink: '',
  lastGeneratedLink: '',
});

export default function externalEvaluatorsRegister(state = initialState, action = {}) {
  switch (action.type) {
    case Actions.GET_EXTERNAL_EVALUATORS_REGISTERS:
      const getExternalEvaluatorsRegisters = {
        ...state,
        shouldGetExternalEvaluatorsRegister: true,
        externalEvaluatorsRegisters: action.payload,
        loading: false,
      };
      return getExternalEvaluatorsRegisters;

    case Actions.ADD_EXTERNAL_EVALUATORS_REGISTER:
      if (typeof action.payload !== 'undefined') {
        if (!isEmptyObj(action.payload)) {
          return {
            ...state,
            externalEvaluatorsRegisters: [
              action.payload,
              ...state.externalEvaluatorsRegisters,
            ],
            generatedLink: `${window.location.origin}/NewExternalEvaluator/${action.payload.token}/`,
            loading: false,
          };
        }
      }
      return state;

    case Actions.DELETE_EXTERNAL_EVALUATORS_REGISTER:
      if (action.payload !== null) {
        const oldExternalEvaluatorsRegisters = [...state.externalEvaluatorsRegisters];
        oldExternalEvaluatorsRegisters.splice(
          oldExternalEvaluatorsRegisters.findIndex(
            oldExternalEvaluatorsRegister => oldExternalEvaluatorsRegister.id === action.payload,
          ),
          1,
        );
        return {
          ...state,
          externalEvaluatorsRegisters: [...oldExternalEvaluatorsRegisters],
          loading: false,
        };
      }
      return state;

    case Actions.GET_EXTERNAL_EVALUATORS_REGISTERS_BY_CLIENT:
      const externalEvaluatorsRegistersByClient = action.payload;
      let lastGeneratedToken = '';
      if (externalEvaluatorsRegistersByClient.length > 0) {
        lastGeneratedToken = externalEvaluatorsRegistersByClient[
          externalEvaluatorsRegistersByClient.length - 1
        ].token;
      }
      const lastGeneratedLink = lastGeneratedToken
        ? `${window.location.origin}/NewExternalEvaluator/${lastGeneratedToken}/`
        : '';
      const getExternalEvaluatorsRegistersByClient = {
        ...state,
        shouldGetExternalEvaluatorsRegister: true,
        externalEvaluatorsRegisters: action.payload,
        lastGeneratedLink,
        loading: false,
      };
      return getExternalEvaluatorsRegistersByClient;

    case Actions.GOT_EXTERNAL_TOKEN_VALIDITY:
      const selectedRegisterData = action.payload;
      const setSelectedRegisterData = {
        ...state,
        selectedRegisterData,
        loading: false,
      };
      return setSelectedRegisterData;
    default:
      return state;
  }
}
