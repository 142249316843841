import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';

const initialState = Immutable.from({
  template: {},
  questions: [],
  isLoadingParQTemplate: false,
  shouldGetParQTemplate: true,
  isLoadingParQQuestions: false,
  shouldGetParQQuestions: true,
});

export default function parq(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_PARQ_TEMPLATE_PENDING: {
      const loading = state.updateIn(['isLoadingParQTemplate'], () => true);
      return loading;
    }
    case Actions.GET_PARQ_TEMPLATE_REJECTED: {
      const loading = state.updateIn(['isLoadingParQTemplate'], () => false);
      return loading;
    }
    case Actions.GET_PARQ_TEMPLATE: {
      const newState = state
        .updateIn(['template'], () => action.payload)
        .updateIn(['isLoadingParQTemplate'], () => false)
        .updateIn(['shouldGetParQTemplate'], () => false);
      return newState;
    }
    case Actions.GET_PARQ_QUESTIONS_PENDING: {
      const loading = state.updateIn(['isLoadingParQQuestions'], () => true);
      return loading;
    }
    case Actions.GET_PARQ_QUESTIONS_REJECTED: {
      const loading = state.updateIn(['isLoadingParQQuestions'], () => false);
      return loading;
    }
    case Actions.GET_PARQ_QUESTIONS: {
      const newState = state
        .updateIn(['questions'], () => action.payload)
        .updateIn(['isLoadingParQQuestions'], () => false)
        .updateIn(['shouldGetParQQuestions'], () => false);
      return newState;
    }
    default:
      return state;
  }
}
