import React from 'react';

const IconNeuromotors = () => {
  return (
    <img
      src={require(`../../images/muscle-icon.png`)}
      width="120"
      alt="Módulo Cardiorrespiratório"
    />
  )
}

export default IconNeuromotors;
