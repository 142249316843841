import React, { Component } from 'react';
import { connect } from 'react-redux';
import { localeChange } from './store/i18n/action';
import { bindActionCreators } from 'redux';
import MenuMain from './components/Menu/MenuMain';
import {
  DEFAULT_LANGUAGE,
  SOCKET_CLOSED_ERROR,
  SOCKET_READY,
  MESSAGE_STATUS_SUCCESS,
  MESSAGE_STATUS_ERROR,
  DATE_EVALUATION_EXISTS_ERROR,
  DATE_EVALUATION_FUTURE_ERROR,
  LOGOUT,
  ISSUE_TOKEN,
  PERMISSION_ERROR,
  ERROR,
  NO_AUTH_ROUTES,
  TOKEN_EXPIRED,
  DISCONNECT_ANOTHER_USER,
  DISCONNECT_CLIENT_USER,
  EVALUATED_NOT_ALLOW_NOTIFY,
  CHANGE_PASS_INVALID_ERROR,
  GET_CLIENT_PLAN,
  GENERATE_GATEWAY_SESSION_ERROR,
  GET_CLIENT_OVERDUE_PAYMENTS,
  LINK_ALREADY_EXIST_ERROR,
  PF,
  PJ,
  GET_FEATURES_VIEWED_BY_PERSON,
  NO_ADD_FOOTER,
} from './constant/constants';
import {
  logoutSocket,
  logoutAndRedirect,
  validateToken,
  reissueToken,
  redirectToLogin,
  enableFreeTrialPeriod,
  allowAccessToThePlansModule,
  findForActivePlanByClientId,
  findForActivePlanByClientIdResponse,
} from './store/login/action';
import { showMessage } from './store/flashMessage/action';
import AlertContainer from 'react-alert';
import { withRouter } from 'react-router-dom';
import MyRoutes from './routes';
import Loading from './components/Loading';
import { closeNavigation } from './store/navigation/action';
import { openModalAction, closeModalAction } from './store/modal/action';
import { getClientOverduePayments } from './store/paymentRecords/action';
import { getFeaturesViewedByPerson, setNewFeatures } from './store/featureViewed/action';
import Footer from './components/Footer/Footer';
import ErrorScreen from './containers/ErrorScreen';
import { getSessions } from './store/usersManagement/action';
import {
  getProfilePermissionLevel,
  dispatchEvent,
  filtersFeatureByProfileType,
  checkNewFeatures,
  filtersAnnouncementsByProfileType,
} from './utils/functions';
import { inTestEnvironment } from './utils/validate';

import { FormattedMessage } from 'react-intl';
import ReportBugDetails from './containers/ReportBugDetails';
import createEventListener from './utils/createEventListener';
import { tableErrorStatusPagSeguro } from './utils/statusErrorPagSeguro';
import AlertPaymentProblem from './components/Alert/AlertPaymentProblem';
import AlertInvalidVoucher from './components/Alert/AlertInvalidVoucher';
import ModalFeaturePreview from './components/Modal/ModalFeaturePreview';
import ModalAnnouncements from './components/Modal/ModalAnnouncements';

import newFeaturesListJSON from './release.json';
import newAnnouncementListJSON from './announcements.json';

class App extends Component {
  constructor(props) {
    super(props);
    this.alertOptions = {
      offset: 14,
      position: 'top right',
      time: 0,
      theme: 'light',
      transition: 'scale',
    };
    this.state = {
      openAlert: false,
      paymentProblem: false,
      alertAnimation: 'alert-open',
      openAlertInvalidVoucher: false,
      alertInvalidVoucherAnimation: 'alert-open',
      isOpenModalFeaturePreview: false,
      isOpenModalAnnouncements: false,
    };
    global.msg = null;
  }

  componentWillMount() {
    createEventListener();
    window.addEventListener(SOCKET_CLOSED_ERROR, this.getSocket);
    window.addEventListener(TOKEN_EXPIRED, this.tokenExpired);
    window.addEventListener(DISCONNECT_ANOTHER_USER, this.disconnectAnotherUser);
    window.addEventListener(DISCONNECT_CLIENT_USER, this.disconectByClient);
    window.addEventListener(SOCKET_READY, this.socketConnected);
    window.addEventListener(LOGOUT, this.logout);
    window.addEventListener(ISSUE_TOKEN, this.updateProfile);
    window.addEventListener(ERROR, this.parseExternalErrorMessages);
    window.addEventListener(GET_CLIENT_PLAN, this.handleGetClientPlan);
    window.addEventListener(GET_CLIENT_OVERDUE_PAYMENTS, this.validatePaymentProblem);
    window.addEventListener(GET_FEATURES_VIEWED_BY_PERSON, this.getFeaturesViewedByPersonResponse);

    let route = '';

    if (this.props.router.location !== null) route = this.props.router.location.pathname;

    if (!this.isNoAuthRoute(route)) {
      if (window.localStorage.token) {
        this.props.validateToken(window.localStorage.token, '/');
      } else {
        this.props.redirectToLogin();
      }
    }
  }

  componentDidMount() {
    if (!this.props.profile.isAuthenticated) {
      do {
        if (this.props.router.location !== null) {
          if (this.isNoAuthRoute(this.props.router.location.pathname)) {
            break;
          }
        }
      } while (false);
    }
  }

  componentDidUpdate(prevState) {
    if (this.props.router.location && prevState.router.location) {
      const prevStatePathname = prevState.router.location.pathname;
      const { pathname } = this.props.router.location;

      if (prevStatePathname !== pathname) {
        if (pathname !== '/Login') {
          this.isNewlyRegisteredClient();
          const path = pathname.split('/');

          if (path[1] === 'MyAccount') {
            this.getClientPlan();

            if (this.state.paymentProblem) {
              this.handleActionAlert('openAlert', 'alertAnimation', true);
            }
          }

          if (path[1] === '') {
            this.getClientPlan();
          }
        }
      }
    }

    if (prevState.profile.user !== this.props.profile.user) {
      this.checkClientAdminAccessToPlansModule();
      this.getFeatureViewed();
      this.showNewAnnouncements();
    }
  }

  componentWillUnmount() {
    window.removeEventListener(SOCKET_CLOSED_ERROR, this.getSocket);
    window.removeEventListener(TOKEN_EXPIRED, this.tokenExpired);
    window.removeEventListener(DISCONNECT_ANOTHER_USER, this.disconnectAnotherUser);
    window.removeEventListener(DISCONNECT_CLIENT_USER, this.disconectByClient);
    window.removeEventListener(SOCKET_READY, this.socketConnected);
    window.removeEventListener(LOGOUT, this.logout);
    window.removeEventListener(ISSUE_TOKEN, this.updateProfile);
    window.removeEventListener(GET_CLIENT_PLAN, this.handleGetClientPlan);
    window.removeEventListener(GET_CLIENT_OVERDUE_PAYMENTS, this.validatePaymentProblem);
    window.removeEventListener(GET_FEATURES_VIEWED_BY_PERSON, this.getFeaturesViewedByPersonResponse);
  }

  handleGetClientPlan = (res) => {
    const { user } = this.props.profile;
    
    this.checkVoucherValidity(user);
    this.validateFreeTrialPeriod(res);

    if (user.permission.client.fase_cadastro === 2) {
      this.props.findForActivePlanByClientIdResponse(res.data);
    }
  }

  handleActionAlert = (stateAlert, stateAnimation, value, close = false, screenTime = 138) => {
    this.handleOpenAlert(stateAlert, value);
    if (close) {
      setTimeout(() => {
        this.handleCloseAlert(stateAlert, stateAnimation);
      }, screenTime * 1000);
    }
  };

  handleOpenAlert = (state, value) => {
    this.setState({
      [state]: value,
    });
  };

  handleCloseAlert = (stateAlert, stateAnimation, closingTime = 0.3) => {
    const animation = 'alert-close';

    this.setState({
      [stateAnimation]: animation
    });

    setTimeout(() => {
      this.handleOpenAlert(stateAlert, false);
      this.setState({
        [stateAnimation]: 'alert-open'
      });
    }, closingTime * 1000);
  };

  getClientPlan = () => {
    const { user } = this.props.profile;
    const isClientAdmin = this.checksIfTheProfileIsClientAdmin();

    if (isClientAdmin && user.permission.client.fase_cadastro === 2) {
      this.props.findForActivePlanByClientId(this.props.clientId);
      this.props.getClientOverduePayments(this.props.clientId);
    }
  }

  isNoAuthRoute(url) {
    const parts = url.split('/');
    if (Array.isArray(parts)) {
      const route = parts[1];
      if (NO_AUTH_ROUTES.indexOf(route) > -1) {
        return true;
      }
    }
    return false;
  }

  defaultErrorMesssage({
    idMessage = '',
    defaultMessage = '',
    isAboutLogout = false,
    status = MESSAGE_STATUS_ERROR,
    callBack = () => {},
  }) {
    if (isAboutLogout) {
      idMessage = 'physicaltest.socket.error';
      callBack = this.props.logoutSocket;
    }

    this.props.showMessage({
      status,
      idMessage,
      defaultMessage,
    });
    callBack();
  }

  parseExternalErrorMessages = (response) => {
    let idMessage;
    let defaultMessage;
    const eventListeners = window.getEventListeners();

    if (response.data.content) {
      let key = Object.keys(response.data.content)[0];

      if (tableErrorStatusPagSeguro[key]) {
        dispatchEvent(`${response.data.event}_ERROR`, {
          defaultMessage: {
            idMessage: `message.error.pagseguro.validate.${key}`,
            defaultMessage: tableErrorStatusPagSeguro[key],
          },
          ...response.data
        });

        if (eventListeners[`${response.data.event}_ERROR`]) return;

        return this.defaultErrorMesssage({
          idMessage: `message.error.pagseguro.validate.${key}`,
          defaultMessage: tableErrorStatusPagSeguro[key],
        });
      }
    }

    dispatchEvent(`${response.data.event}_ERROR`, { ...response.data });
    if (eventListeners[`${response.data.event}_ERROR`]) return;

    switch (response.data.error) {
      case PERMISSION_ERROR:
        idMessage = 'message.error.not.permission';
        defaultMessage = 'Sem permissão, contate o administrador do sistema';
        break;

      case DATE_EVALUATION_EXISTS_ERROR:
        idMessage = 'message.error.date.evaluation.exists';
        defaultMessage = 'Já existe uma avaliação com essa data.';
        break;

      case DATE_EVALUATION_FUTURE_ERROR:
        idMessage = 'message.error.date.evaluation.future';
        defaultMessage = 'Datas futuras não são permitidas.';
        break;

      case CHANGE_PASS_INVALID_ERROR:
        idMessage = 'app.myaccount.changepassword.invalidpassword';
        defaultMessage = 'Senha atual inválida';
        break;

      case EVALUATED_NOT_ALLOW_NOTIFY:
        idMessage = 'message.error.notify';
        defaultMessage = 'Avaliado(a) optou por não receber e-mails';
        break;
      case GENERATE_GATEWAY_SESSION_ERROR:
        idMessage = 'message.error.gateway.session';
        defaultMessage = 'Ocorreu um erro na comunicação com o serviço de pagamento.';
        break;
      case LINK_ALREADY_EXIST_ERROR:
        idMessage = 'app.promotional.links.message.add.error';
        defaultMessage = 'Não foi possível inserir um novo link promocional. Verifique se o código já foi usado anteriormente.';
        break;
      default:
        idMessage = 'message.error';
        defaultMessage = 'Erro, contate o administrador do sistema';
    }
    return this.defaultErrorMesssage({ idMessage, defaultMessage });
  }

  tokenExpired = () => {
    this.defaultErrorMesssage({
      isAboutLogout: true,
      defaultMessage: 'O seu acesso expirou. Por favor, entre no sistema novamente.',
    });
  }

  checksIfTheProfileIsClientAdmin = () => {
    const { user } = this.props.profile;
    const profilePermission = getProfilePermissionLevel(user.permission, user.person);
    return profilePermission.rule === 'clientAdmin';
  }

  isNewlyRegisteredClient() {
    const { user } = this.props.profile;
    const isClientAdmin = this.checksIfTheProfileIsClientAdmin();

    if (isClientAdmin && user.permission.client.fase_cadastro === 1) {
      this.props.history.push('/Subscription');
    }
  }

  validatePaymentProblem = ({ data }) => {
    if (data && data.Count > 0) {
      this.handleActionAlert('openAlert', 'alertAnimation', true, true, 138);
    };
  };

  validateFreeTrialPeriod = ({ data: clientPlan }) => {
    const isClientAdmin = this.checksIfTheProfileIsClientAdmin();
    if (!isClientAdmin || !clientPlan || !clientPlan.plano || !clientPlan.plano.trialPeriodDuration) return;

    const currentTimeDate = new Date().getTime();
    const dayOfPurchase = new Date(clientPlan.created);
    const trialPeriodDuration = Number(clientPlan.plano.trialPeriodDuration);
    const firstPaymentDay = new Date(dayOfPurchase.setDate(dayOfPurchase.getDate() + trialPeriodDuration)).getTime();

    if (trialPeriodDuration > 0 && currentTimeDate < firstPaymentDay) {
      this.props.enableFreeTrialPeriod(true);
    }
  };

  checkVoucherValidity = (user) => {
    const { voucher } = this.props;
    const registrationPhase = user.permission.client.fase_cadastro;
    if (registrationPhase === 1 && voucher) {
      const expireDate = new Date(voucher.expireDate);
      const currentDate = new Date();
      const invalidVoucher = expireDate.getTime() < currentDate.getTime(); 
      if (invalidVoucher) {
        this.handleActionAlert('openAlertInvalidVoucher', 'alertInvalidVoucherAnimation', true, true, 60);
      }
    }
  }

  resetAlertsState = () => {
    this.setState({
      openAlert: false,
      paymentProblem: false,
      openAlertInvalidVoucher: false,
    })
  }

  getFeatureViewed = () => {
    const { profile } = this.props;
    if (profile && profile.user !== undefined && profile.user !== null && profile.user.person) {
      this.props.getFeaturesViewedByPerson(this.props.profile.user.person.id);
    }
  }

  getFeaturesViewedByPersonResponse = ({ data }) => {
    if (!Array.isArray(data)) return;
    const { user } = this.props.profile;
    const profilePermission = getProfilePermissionLevel(user.permission, user.person);
    const newFeaturesList = filtersFeatureByProfileType(newFeaturesListJSON, profilePermission.rule);
    const featuresViewed = data.map(d => d.version);
    if (checkNewFeatures(newFeaturesList, featuresViewed)) {
      this.props.setNewFeatures(true);
    }
  }

  checkClientAdminAccessToPlansModule = () => {
    const { accountType } = this.props;
    const isClientAdmin = this.checksIfTheProfileIsClientAdmin();

    if (!isClientAdmin) {
      this.props.allowAccessToThePlansModule(false);
      return;
    }

    if (accountType === PF || accountType === PJ) {
      this.props.allowAccessToThePlansModule(true);
    } else {
      this.props.allowAccessToThePlansModule(false);
    }
  }

  toggleModal = (stateName) => {
    this.setState(
      {
        [stateName]: !this.state[stateName]
      },
      () => {
        if (this.state[stateName]) {
          this.props.openModalAction();
        } else if (!this.state[stateName]) {
          this.props.closeModalAction();
        }
      }
    );
  };

  getAnnouncementViewedStore = () => {
    try {
      const announcementViewedStore = JSON.parse(
        localStorage.getItem('pht_announcements'),
      );
      if (Array.isArray(announcementViewedStore)) {
        return announcementViewedStore;
      }
      return [];
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  hasNewAnnouncements = () => {
    const { user } = this.props.profile;
    const profilePermission = getProfilePermissionLevel(user.permission, user.person);
    const newAnnouncementList = filtersAnnouncementsByProfileType(
      newAnnouncementListJSON,
      profilePermission.rule,
    );
    const announcementViewed = this.getAnnouncementViewedStore();

    if (Array.isArray(newAnnouncementList)) {
      const announcementNotViewed = newAnnouncementList.filter(
        (i) => !announcementViewed.includes(i.id),
      );
      return announcementNotViewed.length > 0;
    }

    return false;
  }

  showNewAnnouncements = () => {
    if (this.hasNewAnnouncements() && !this.state.isOpenModalAnnouncements) {
      this.toggleModal('isOpenModalAnnouncements');
    }
  }

  disconnectAnotherUser = () => {
    this.defaultErrorMesssage({
      isAboutLogout: true,
      defaultMessage:
        'O seu acesso foi interrompido, pois outro computador entrou no sistema com o seu usuário.',
    });
  }

  disconectByClient = (response = {}) => {
    if (response.data.idsession === this.props.profile.user.idsession) {
      this.defaultErrorMesssage({
        isAboutLogout: true,
        defaultMessage: 'Você foi deslogado pelo administrador do sistema.',
      });
    }
    if (response.data.callerid === this.props.profile.user.person.contact.id) {
      this.props.getSessions(response.data.idclient);
    }
  }

  getSocket = (response) => {
    //! TODO: Estudar e entender melhor esse erro
    // this.props.showMessage({
    //   status: MESSAGE_STATUS_ERROR,
    //   idMessage: 'physicaltest.socket.error',
    //   defaultMessage: 'Servidor desconectado, tente novamente',
    // });
    this.props.logoutSocket();
  }

  socketConnected = () => {
    global.msg.removeAll();
    this.props.showMessage({
      idMessage: 'lalala',
      defaultMessage: 'Servidor de volta ao ar',
      status: MESSAGE_STATUS_SUCCESS,
    });
  }

  showPaymentProblemMessage = () => {
    this.props.showMessage({
      status: MESSAGE_STATUS_ERROR,
      idMessage: 'physicaltest.payment.error',
      defaultMessage:
        'O cadastro de avaliados/avaliadores está bloqueado temporariamente. Entre em contato com o administrador da sua conta.',
    });
  };

  updateProfile = (response) => {
    this.props.reissueToken(response.data);
  }

  _renderApp() {
    if (this.props.profile.isAuthenticated) {
      return (
        <div>
          <MenuMain
            {...this.props.profile.user}
            accountType={this.props.profile.accountType}
            onLocaleChange={this.props.localeChange}
            toggleModalFeaturePreview={() => this.toggleModal('isOpenModalFeaturePreview')}
            toggleModalAnnouncements={() => this.toggleModal('isOpenModalAnnouncements')} 
            hasNewFeatures={this.props.hasNewFeatures}
            logout={(e) => {
              e.preventDefault();
              this.props.logoutSocket(() => {
                this.resetAlertsState();
                this.props.closeNavigation.bind(this);
              }, this.props.profile.user.idsession);
            }}
          />
        </div>
      );
    }
  }

  render() {
    var locale = '';
    if (!window.localStorage.locale) {
      locale = DEFAULT_LANGUAGE;
      window.localStorage.setItem('locale', locale);
    }
    if (this.props.isAuthenticating || !this.props.profile) {
      return (
        <div className="App">
          <Loading />
        </div>
      );
    }
    return (
      <ErrorScreen>
        <div className="App">
          {inTestEnvironment() && (
            <div className="text-center w-100 text-white bg-danger font-weight-bold p-1">
              <FormattedMessage
                id="app.global.test.environment.warning"
                defaultMessage="Atenção: Este é o ambiente de teste. Para acessar nossa plataforma principal,"
              />
              <a href="https://physicaltest.com.br" className="text-lowercase text-white">
                {' '}<u><FormattedMessage id="app.global.click.here" defaultMessage="Clique aqui" /></u>.
              </a>
            </div>
          )}
          <div>
            <AlertContainer ref={(a) => (global.msg = a)} {...this.alertOptions} />
          </div>{' '}
          {this._renderApp()}
          <MyRoutes
            isNotSysAdmin={this.props.profile.user.permission.sysadmin === false}
            planModuleIsEnabled={this.props.planModuleIsEnabled}
            registrationPhase={this.props.registrationPhase}
            paymentProblem={this.state.paymentProblem}
            showPaymentProblemMessage={this.showPaymentProblemMessage}
          />
          {this.state.isOpenModalFeaturePreview && (
            <ModalFeaturePreview
              closeModalFeaturePreview={() => this.toggleModal('isOpenModalFeaturePreview')}
            />
          )}
          {this.state.isOpenModalAnnouncements && (
            <ModalAnnouncements
              closeModalAnnouncements={() => this.toggleModal('isOpenModalAnnouncements')}
            />
          )}
          {this.state.openAlert && (
            <AlertPaymentProblem
              animation={this.state.alertAnimation}
              onClose={() => this.handleCloseAlert('openAlert', 'alertAnimation')}
            />
          )}
          {this.state.openAlertInvalidVoucher && (
            <AlertInvalidVoucher
              animation={this.state.alertInvalidVoucherAnimation}
              onClose={() => this.handleCloseAlert('openAlertInvalidVoucher', 'alertInvalidVoucherAnimation')}
            />
          )}
          <ReportBugDetails user={this.props.profile} />
        </div>
        {!NO_ADD_FOOTER.some((r) => this.props.history.location.pathname.startsWith(r)) && (
          <Footer
            loadingReport={this.props.loadingReport}
            openModalAction={this.props.openModalAction.bind(this)}
            today={new Date()}
          />
        )}
      </ErrorScreen>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    intl: state.intl,
    router: state.router,
    registrationPhase: state.profile.user?.permission?.client?.fase_cadastro,
    message: state.flashMessage,
    isAuthenticating: state.profile.isAuthenticating,
    loadingReport: state.util.loadingReport.loading,
    accountType: state.profile.accountType,
    planModuleIsEnabled: state.profile.planModuleIsEnabled,
    clientPlan: state.profile.plan,
    clientId: state.profile.user.permission.client?.id,
    voucher: state.profile.user.permission.voucher,
    hasNewFeatures: state.featureViewed.hasNewFeatures,
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      logoutSocket,
      logoutAndRedirect,
      validateToken,
      redirectToLogin,
      reissueToken,
      showMessage,
      localeChange,
      closeNavigation,
      openModalAction,
      closeModalAction,
      getSessions,
      setNewFeatures,
      enableFreeTrialPeriod,
      getClientOverduePayments,
      getFeaturesViewedByPerson,
      allowAccessToThePlansModule,
      findForActivePlanByClientId,
      findForActivePlanByClientIdResponse,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
