import React from 'react';

const IconAnamnese = (props) => (
  <svg
    className="icon-anamnese"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-49 141 512 512"
    width={props.width}
    height={props.height}
  >
    <circle className="st0" cx="207" cy="397" r="256" />
    <path
      className="st1"
      d="M109.7 542.2h194.5c8.6 0 15.6-7 15.6-15.6V267.5c0-8.6-7-15.6-15.6-15.6H109.7c-8.6 0-15.6 7-15.6 15.6v259.1c0 8.6 7 15.6 15.6 15.6z"
    />
    <path
      className="st2"
      d="M304.3 251.8h-97.8v290.4h97.8c8.6 0 15.6-7 15.6-15.6V267.5c0-8.7-7-15.7-15.6-15.7z"
    />
    <path className="st3" d="M109.6 269.4h194.8v251.7H109.6z" />
    <path className="st4" d="M206.4 269.4h98v251.7h-98z" />
    <path
      className="st5"
      d="M259.7 240.1h-24.6C233 226.5 221.2 216 207 216s-26 10.5-28.1 24.1h-24.6c-8 0-14.5 6.5-14.5 14.5v19.2c0 8 6.5 14.5 14.5 14.5h105.4c8 0 14.5-6.5 14.5-14.5v-19.2c0-8-6.5-14.5-14.5-14.5zm-52.7.9c-3.3 0-6-2.7-6-6s2.7-6 6-6 6 2.7 6 6-2.7 6-6 6z"
    />
    <path
      className="st6"
      d="M259.7 240.1h-24.6C233 226.5 221.2 216 207 216h-.6v13c.2 0 .4-.1.6-.1 3.3 0 6 2.7 6 6s-2.7 6-6 6c-.2 0-.4 0-.6-.1v47.5h53.3c8 0 14.5-6.5 14.5-14.5v-19.2c0-8-6.5-14.5-14.5-14.5z"
    />
    <path
      className="st7"
      d="M263.9 345.3H150.1c-2.9 0-5.2-2.3-5.2-5.2s2.3-5.2 5.2-5.2h113.8c2.9 0 5.2 2.3 5.2 5.2 0 2.9-2.4 5.2-5.2 5.2zm0 28.4H150.1c-2.9 0-5.2-2.3-5.2-5.2s2.3-5.2 5.2-5.2h113.8c2.9 0 5.2 2.3 5.2 5.2 0 2.9-2.4 5.2-5.2 5.2zm0 28.5H150.1c-2.9 0-5.2-2.3-5.2-5.2s2.3-5.2 5.2-5.2h113.8c2.9 0 5.2 2.3 5.2 5.2s-2.4 5.2-5.2 5.2zm0 28.4H150.1c-2.9 0-5.2-2.3-5.2-5.2s2.3-5.2 5.2-5.2h113.8c2.9 0 5.2 2.3 5.2 5.2s-2.4 5.2-5.2 5.2z"
    />
    <path
      className="st8"
      d="M263.9 334.9h-57.5v10.3h57.5c2.9 0 5.2-2.3 5.2-5.2 0-2.7-2.4-5.1-5.2-5.1zm0 28.5h-57.5v10.3h57.5c2.9 0 5.2-2.3 5.2-5.2 0-2.8-2.4-5.1-5.2-5.1zm0 28.4h-57.5v10.3h57.5c2.9 0 5.2-2.3 5.2-5.2s-2.4-5.1-5.2-5.1zm0 28.5h-57.5v10.3h57.5c2.9 0 5.2-2.3 5.2-5.2s-2.4-5.1-5.2-5.1z"
    />
  </svg>
);

IconAnamnese.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconAnamnese;
