import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Actions from '../../constant/constants';

const initialState = {
  status: Actions.MESSAGE_STATUS_NULL,
  idMessage: '',
  defaultMessage: '',
};
export default function Flash(state = initialState, action) {
  switch (action.type) {
    case Actions.MESSAGE_STATUS_SUCCESS:
    case Actions.MESSAGE_STATUS_REQUEST:
    case Actions.MESSAGE_STATUS_ERROR:
      showAlert(action.payload);
      break;
    case Actions.ADD_REQUEST_PENDING:
      showAlert({
        status: Actions.MESSAGE_STATUS_REQUEST,
        idMessage: 'page.measures.flashMessage.addfailed',
        defaultMessage: 'cadastrando',
      });
      break;
    case Actions.ADD_REQUEST_REJECTED:
      showAlert({
        status: Actions.MESSAGE_STATUS_ERROR,
        idMessage: 'physicaltest.flashMessage.addfailed',
        defaultMessage: 'erro ao cadastrar, verifique a conexão',
      });
      break;
    case Actions.ADD_REQUEST_FULFILLED:
      break;
    case Actions.UPDATE_REQUEST_PENDING:
      showAlert({
        status: Actions.MESSAGE_STATUS_REQUEST,
        idMessage: 'app.physicaltest.flashMessage.updatepending',
        defaultMessage: 'aplicando alterações',
      });
      break;
    case Actions.CHANGE_PLAN_SUB_PENDING:
      showAlert({
        status: Actions.MESSAGE_STATUS_REQUEST,
        idMessage: 'app.physicaltest.flashMessage.updatepending',
        defaultMessage: 'aplicando alterações',
      });
      break;
    case Actions.CREDIT_CARD_UPDATE_PENDING:
      showAlert({
        status: Actions.MESSAGE_STATUS_REQUEST,
        idMessage: 'app.physicaltest.flashMessage.updatepayment',
        defaultMessage: 'atualizando os dados de pagamento',
      });
      break;
    case Actions.UPDATE_REQUEST_REJECTED:
      showAlert({
        status: Actions.MESSAGE_STATUS_ERROR,
        idMessage: 'physicaltest.flashMessage.updatefailed',
        defaultMessage: 'erro ao alterar, tente novamente',
      });
      break;
    case Actions.UPDATE_REQUEST_FULFILLED:
      break;
    case Actions.DELETE_REQUEST_PENDING:
      showAlert({
        status: Actions.MESSAGE_STATUS_REQUEST,
        idMessage: 'physicaltest.flashMessage.deletepending',
        defaultMessage: 'excluíndo',
      });
      break;
    case Actions.DELETE_REQUEST_REJECTED:
      showAlert({
        status: Actions.MESSAGE_STATUS_ERROR,
        idMessage: 'physicaltest.flashMessage.deletefailed',
        defaultMessage: 'erro ao excluir, tente novamente',
      });
      break;
    case Actions.DELETE_REQUEST_FULFILLED:
      break;
    case Actions.GET_AUTHORS_REJECTED:
      showAlert({
        status: Actions.MESSAGE_STATUS_ERROR,
        idMessage: 'page.authors.flashMessage.getfailed',
        defaultMessage: 'Não foi possível carregar os autores, verifique a conexão',
      });
      break;
    case Actions.GET_CLASSIFICATIONS_REJECTED:
      showAlert({
        status: Actions.MESSAGE_STATUS_ERROR,
        idMessage: 'page.classifications.flashMessage.getfailed',
        defaultMessage:
          'Não foi possível carregar as classificações, verifique a conexão',
      });
      break;
    case Actions.GET_ETHNICITIES_REJECTED:
      showAlert({
        status: Actions.MESSAGE_STATUS_ERROR,
        idMessage: 'page.ethnicity.flashMessage.getfailed',
        defaultMessage: 'Não foi possível carregar as etnias, verifique a conexão',
      });
      break;
    case Actions.GET_GOALS_REJECTED:
      showAlert({
        status: Actions.MESSAGE_STATUS_ERROR,
        idMessage: 'page.goals.flashMessage.getfailed',
        defaultMessage: 'Não foi possível carregar os objetivos, verifique a conexão',
      });
      break;
    case Actions.GET_MEASURES_REJECTED:
      showAlert({
        status: Actions.MESSAGE_STATUS_ERROR,
        idMessage: 'page.measures.flashMessage.getfailed',
        defaultMessage: 'Não foi possível carregar as medidas, verifique a conexão',
      });
      break;
    case Actions.GET_EVALUATORS_REJECTED:
      showAlert({
        status: Actions.MESSAGE_STATUS_ERROR,
        idMessage: 'page.evaluators.flashMessage.getfailed',
        defaultMessage: 'Não foi possível carregar os avaliadores, verifique a conexão',
      });
      break;
    case Actions.GET_EVALUATED_REJECTED:
      showAlert({
        status: Actions.MESSAGE_STATUS_ERROR,
        idMessage: 'page.evaluated.flashMessage.getfailed',
        defaultMessage: 'Não foi possível carregar os avaliados, verifique a conexão',
      });
      break;
    case Actions.GET_GENDERS_REJECTED:
      showAlert({
        status: Actions.MESSAGE_STATUS_ERROR,
        idMessage: 'page.classifications.flashMessage.getfailed',
        defaultMessage: 'Não foi possível carregar os sexos, verifique a conexão',
      });
      break;
    case Actions.GET_SEXUAL_MATURATIONS_REJECTED:
      showAlert({
        status: Actions.MESSAGE_STATUS_ERROR,
        idMessage: 'page.sexualmaturations.flashMessage.getfailed',
        defaultMessage:
          'Não foi possível carregar as maturações sexuais, verifique a conexão',
      });
      break;
    case Actions.GET_FORMULA_METHODS_REJECTED:
      showAlert({
        status: Actions.MESSAGE_STATUS_ERROR,
        idMessage: 'page.formulas.flashMessage.getfailed',
        defaultMessage: 'Não foi possível carregar as equações, verifique a conexão',
      });
      break;
    case Actions.PERMISSION_ERROR:
      showAlert({
        status: Actions.MESSAGE_STATUS_ERROR,
        idMessage: 'message.error.not.permission',
        defaultMessage: 'Permissão negada, verifique com o adminitrador',
      });
      break;
    default:
  }
  return state;
}

function showAlert(message) {
  var messageId = message.idMessage;
  var defaultMessage = message.defaultMessage;
  var status = message.status,
    typeStatus = '',
    timeMessage = 0;
  if (status === Actions.MESSAGE_STATUS_SUCCESS) {
    typeStatus = 'success';
    timeMessage = 8000;
  } else if (status === Actions.MESSAGE_STATUS_REQUEST) {
    typeStatus = 'info';
    timeMessage = 8000;
  } else {
    typeStatus = 'error';
    timeMessage = 9666;
  }
  global.msg.show(<FormattedMessage id={messageId} defaultMessage={defaultMessage} />, {
    time: timeMessage,
    type: typeStatus,
  });
}
