import React from 'react';

const IconSeeAllMeasures = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 31.646 31.646"
    width={props.width}
    height={props.height}
  >
    <path d="M1.176 7.4H8.58V0H1.175v7.4zm1.29-6.11h4.822v4.822H2.466V1.29zm-1.29 14.214H8.58V8.1H1.175v7.404zm1.29-6.112h4.822v4.82H2.466v-4.82zm-1.29 14.18H8.58V16.17H1.175v7.403zm1.29-6.112h4.822v4.822H2.466V17.46zm-1.29 14.186H8.58v-7.403H1.175v7.403zm1.29-6.113h4.822v4.822H2.466v-4.822zm8.097-24.045h19.423v1.45H10.563zm0 2.58h13.943v1.45H10.563zm0 5.078h19.423v1.45H10.563zm0 2.58h13.943v1.45H10.563zm.483 5.72H30.47v1.45H11.047zm0 2.58H24.99v1.45H11.046zm0 5.32H30.47v1.45H11.047zm0 2.58H24.99v1.45H11.046z" />
  </svg>
);

IconSeeAllMeasures.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconSeeAllMeasures;
