import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';

const initialState = Immutable.from({
  muscleGroups: [],
  loading: false,
  shouldGetMuscleGroups: true
});

export default function muscleGroups(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_MUSCLE_GROUPS_PENDING: {
      const loading = state.updateIn(['loading'], () => true);
      return loading;
    }
    case Actions.GET_MUSCLE_GROUPS_REJECTED: {
      const loading = state.updateIn(['loading'], () => false);
      return loading;
    }
    case Actions.GET_MUSCLE_GROUPS: {
      const newState = state
        .updateIn(['muscleGroups'], () => action.payload)
        .updateIn(['loading'], () => false)
        .updateIn(['shouldGetMuscleGroups'], () => false);
      return newState;
    }
    default:
      return state;
  }
}
