import React from 'react';

const IconAuthors = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512.001 512.001"
    width={props.width}
    height={props.height}
  >
    <path
      fill="#694B4B"
      d="M88.222 159.632c-82.142 0-73.034 99.89-72.659 172.277.014 2.734 1.446 5.198 3.788 6.61 9.102 5.487 34.1 18.316 68.871 18.316s59.769-12.829 68.871-18.316c2.341-1.412 3.774-3.876 3.788-6.61.375-72.387 9.483-172.277-72.659-172.277z"
    />
    <path
      fill="#5A4146"
      d="M82.93 159.838c-76.395 4.283-67.733 101.295-67.367 172.071.014 2.734 1.446 5.199 3.788 6.61 9.102 5.487 34.1 18.316 68.871 18.316l20.759-145.308"
    />
    <path
      fill="#694B4B"
      d="M88.222 159.632c-46.706 0-49.949 56.436-49.949 56.436 26.921-4.541 43.463 6.811 70.708-4.541L88.222 356.835c34.772 0 59.769-12.829 68.871-18.316 2.341-1.412 3.774-3.876 3.788-6.61.375-72.387 9.483-172.277-72.659-172.277z"
    />
    <path
      fill="#785550"
      d="M160.881 331.909c.322-62.201 7.037-144.651-43.183-166.639-.876 4.55-2.463 17.253 1.661 25.498 10.379 20.759 20.759 20.759 31.137 36.327 10.379 57.085-19.831 117.674-29.865 125.923 17.778-4.228 30.522-10.919 36.462-14.499 2.341-1.411 3.774-3.876 3.788-6.61z"
    />
    <path
      fill="#E6AF78"
      d="M166.864 347.653l-46.307-19.294c-3.867-1.611-6.387-5.39-6.387-9.581v-12.541H62.275v12.541c0 4.191-2.52 7.97-6.387 9.581L9.581 347.653C3.779 350.071 0 355.74 0 362.025v20.759c0 5.732 4.647 10.379 10.379 10.379h155.686c5.732 0 10.379-4.647 10.379-10.379v-20.759c.001-6.285-3.778-11.954-9.58-14.372z"
    />
    <path
      fill="#EFF2FA"
      d="M166.864 347.653l-44.773-18.656-33.869 14.618-33.869-14.617-44.772 18.656C3.779 350.071 0 355.74 0 362.025v20.759c0 5.732 4.647 10.379 10.379 10.379h155.686c5.732 0 10.379-4.647 10.379-10.379v-20.759c.001-6.285-3.778-11.954-9.58-14.372z"
    />
    <path
      fill="#D29B6E"
      d="M114.17 306.237H62.275v12.541c0 4.191-2.52 7.97-6.387 9.581l-7.232 3.014c55.345 14.112 65.514-25.136 65.514-25.136z"
    />
    <g fill="#E4EAF6">
      <path d="M25.294 367.729L4.464 351.16C1.673 354.014 0 357.874 0 362.025v20.759c0 5.732 4.647 10.379 10.379 10.379h20.759v-13.276c-.001-4.73-2.15-9.203-5.844-12.158zm151.151 15.054v-20.759c0-4.119-1.645-7.952-4.397-10.8l-20.898 16.504c-3.693 2.954-5.843 7.427-5.843 12.157v13.275h20.759c5.732.002 10.379-4.645 10.379-10.377zm-87.444 0h-1.557c-2.436 0-4.411-1.975-4.411-4.411v-34.745h10.379v34.744c0 2.437-1.975 4.412-4.411 4.412z" />
      <path d="M88.222 343.616L58.52 321.34c-1.907-1.43-4.623-.965-5.945 1.018l-5.869 8.803 18.21 25.494c1.384 1.938 4.112 2.317 5.972.829l17.334-13.868zm0 0l29.702-22.276c1.907-1.43 4.623-.965 5.945 1.018l5.869 8.803-18.21 25.494c-1.384 1.938-4.112 2.317-5.972.829l-17.334-13.868z" />
    </g>
    <path
      fill="#F0C087"
      d="M114.17 206.339c-25.948 25.947-76.87 5.188-76.87 46.704l3.222 30.581c.622 6.841 4.588 12.931 10.591 16.267l29.548 16.415c4.702 2.612 10.419 2.612 15.121 0l29.548-16.415c6.005-3.336 9.97-9.427 10.591-16.267l3.72-36.32c.265-2.582.321-5.179.234-7.769-1.94-13.21-20.516-22.818-25.705-33.196z"
    />
    <path
      fill="#E6AF78"
      d="M37.3 253.043l3.222 30.581c.622 6.841 4.588 12.931 10.591 16.267l29.548 16.415c3.263 1.813 7.005 2.282 10.567 1.579 0 0-14.897-4.515-23.764-28.516-2.716-7.352-6.487-46.274-3.243-51.031 6.412-9.402 42.165-10.379 52.729-27.822-.522-.647-1.004-1.296-1.444-1.945-.035-.052-.078-.105-.114-.158-.459-.69-.875-1.382-1.223-2.077-25.947 25.95-76.869 5.191-76.869 46.707z"
    />
    <path
      fill="#E4EAF6"
      d="M126.106 382.783h-13.493c-4.872 0-8.822 3.95-8.822 8.822v1.557h31.137v-1.557c0-4.873-3.95-8.822-8.822-8.822z"
    />
    <path fill="#E6AF78" d="M442.78 323.524v-28.502h-73.293v28.502l36.646 32.575" />
    <path
      fill="#EFF2FA"
      d="M499.443 336.857l-50.385-11.855c-.761-.179-1.461-.483-2.115-.851l-40.81 23.806L366.915 323c-1.016.956-2.275 1.666-3.706 2.003l-50.385 11.855c-7.358 1.731-12.557 8.296-12.557 15.855v31.89c0 4.497 3.646 8.144 8.144 8.144h195.447c4.497 0 8.144-3.646 8.144-8.144v-31.89c-.002-7.56-5.202-14.125-12.559-15.856z"
    />
    <path
      fill="#D29B6E"
      d="M369.487 295.022v30.073c46.165 16.632 73.293-24.995 73.293-24.995v-5.078h-73.293z"
    />
    <path
      fill="#F0C087"
      d="M353.2 201.37l3.549 78.063c.332 7.293 3.907 14.055 9.747 18.435l16.836 12.627c4.229 3.172 9.373 4.886 14.658 4.886h16.287c5.286 0 10.43-1.715 14.658-4.886l16.836-12.627c5.84-4.38 9.416-11.143 9.747-18.435l3.549-78.063H353.2z"
    />
    <path
      fill="#E6AF78"
      d="M393.918 217.657c16.287 0 40.718-4.071 46.513-16.287H353.2l3.548 78.063c.332 7.293 3.907 14.055 9.747 18.435l16.836 12.627c4.229 3.172 9.373 4.886 14.658 4.886h8.144c-8.144 0-24.431-16.287-24.431-36.647v-48.862c0-4.071 4.072-12.215 12.216-12.215z"
    />
    <path
      fill="#E4EAF6"
      d="M482.107 366.319l26.385-23.702c2.211 2.8 3.508 6.322 3.508 10.094v31.89c0 4.497-3.646 8.144-8.144 8.144h-28.502v-11.28c0-5.778 2.454-11.284 6.753-15.146z"
    />
    <path fill="#5B5D6E" d="M416.313 392.745h-20.36l2.546-36.646h15.269" />
    <path
      fill="#515262"
      d="M416.313 347.955h-20.36v2.352c0 5.448 4.416 9.863 9.863 9.863h.632c5.448 0 9.863-4.416 9.863-9.863v-2.352h.002z"
    />
    <path
      fill="#E4EAF6"
      d="M368.914 314.46l37.218 33.496s-10.423 5.261-23.286 15.867c-2.653 2.188-6.667 1.301-8.09-1.829l-17.486-38.468 5.532-8.297c1.378-2.067 4.267-2.43 6.112-.769zm74.437 0l-37.218 33.496s10.423 5.261 23.286 15.867c2.653 2.188 6.667 1.301 8.09-1.829l17.486-38.468-5.532-8.297c-1.377-2.067-4.266-2.43-6.112-.769z"
    />
    <path
      fill="#785550"
      d="M430.818 174.394l3.818 26.976c17.425 3.485 19.936 30.83 20.298 38.641.066 1.417.518 2.787 1.287 3.979l6.649 10.313s-2.276-17.051 8.144-28.502c-.001 0 3.576-67.694-40.196-51.407z"
    />
    <path
      fill="#F0C087"
      d="M466.954 252.562l-4.599 18.393c-.545 2.179-2.503 3.708-4.749 3.708-2.469 0-4.551-1.838-4.858-4.288l-2.351-18.812c-.629-5.031 3.294-9.474 8.363-9.474h.017c5.484-.001 9.507 5.153 8.177 10.473z"
    />
    <path
      fill="#694B4B"
      d="M357.101 167.099l6.023 4.114c-27.23 20.105-22.141 54.588-22.141 54.588 8.144 8.144 8.144 28.502 8.144 28.502l8.144-8.144s-3.143-23.525 12.216-32.574c14.251-8.398 26.721-4.071 39.445-4.071 34.101 0 43.645-12.852 41.991-28.502-.856-8.099-13.238-24.967-44.789-24.431-12.735.216-36.647 4.072-49.033 10.518z"
    />
    <path
      fill="#5A4146"
      d="M354.726 205.951s-4.326-17.051 8.398-34.738c-27.23 20.105-22.141 54.588-22.141 54.588 8.144 8.144 8.144 28.502 8.144 28.502l8.144-8.144s-3.143-23.525 12.215-32.574c14.251-8.398 26.721-4.071 39.445-4.071 5.546 0 10.379-.372 14.684-1.008-26.262.881-41.743-19.011-68.889-2.555z"
    />
    <path
      fill="#E6AF78"
      d="M345.311 252.562l4.599 18.393c.545 2.179 2.503 3.708 4.75 3.708 2.469 0 4.551-1.838 4.858-4.288l2.351-18.812c.629-5.031-3.294-9.474-8.364-9.474h-.017c-5.482-.001-9.507 5.153-8.177 10.473z"
    />
    <path
      fill="#E4EAF6"
      d="M454.995 384.602h-20.36c-4.497 0-8.144 3.646-8.144 8.144h36.646c.001-4.498-3.645-8.144-8.142-8.144z"
    />
    <path
      fill="#694B4B"
      d="M256.155 57.5c-139.642 0-124.158 169.814-123.52 292.871.024 4.647 2.458 8.838 6.439 11.237 15.474 9.328 57.97 31.137 117.081 31.137s101.607-21.809 117.081-31.137c3.98-2.4 6.415-6.59 6.439-11.237.638-123.058 16.122-292.871-123.52-292.871z"
    />
    <path
      fill="#5A4146"
      d="M247.157 57.849C117.286 65.13 132.011 230.051 132.635 350.37c.024 4.647 2.458 8.838 6.439 11.237 15.474 9.328 57.97 31.137 117.081 31.137l35.289-247.023"
    />
    <path
      fill="#694B4B"
      d="M256.155 57.5c-79.4 0-84.914 95.942-84.914 95.942 45.765-7.719 73.886 11.579 120.203-7.719l-35.289 247.023c59.111 0 101.607-21.809 117.081-31.137 3.98-2.4 6.415-6.59 6.439-11.237.638-123.059 16.122-292.872-123.52-292.872z"
    />
    <path
      fill="#785550"
      d="M379.675 350.372c.548-105.742 11.962-245.906-73.41-283.286-1.489 7.736-4.185 29.331 2.823 43.348 17.645 35.289 35.289 35.289 52.933 61.756 17.644 97.045-33.714 200.047-50.771 214.068 30.222-7.188 51.887-18.562 61.985-24.649 3.981-2.4 6.416-6.59 6.44-11.237z"
    />
    <path
      fill="#E6AF78"
      d="M389.846 377.137l-78.722-32.801c-6.575-2.739-10.858-9.164-10.858-16.288v-21.32h-88.222v21.32c0 7.123-4.283 13.548-10.858 16.288l-78.722 32.801c-9.862 4.109-16.287 13.746-16.287 24.431v35.289c0 9.744 7.9 17.645 17.644 17.645h264.667c9.744 0 17.644-7.9 17.644-17.645v-35.289c.001-10.686-6.424-20.322-16.286-24.431z"
    />
    <path
      fill="#B4E1FA"
      d="M389.846 377.137l-76.114-31.714-57.577 24.849-57.577-24.849-76.114 31.714c-9.862 4.109-16.287 13.746-16.287 24.431v35.289c0 9.744 7.9 17.645 17.644 17.645h264.667c9.744 0 17.644-7.9 17.644-17.645v-35.289c.001-10.686-6.424-20.322-16.286-24.431z"
    />
    <path
      fill="#D29B6E"
      d="M300.266 306.728h-88.222v21.32c0 7.123-4.283 13.548-10.858 16.288l-12.295 5.124c94.087 23.989 111.375-42.732 111.375-42.732z"
    />
    <g fill="#A0D2F0">
      <path d="M149.178 411.265l-35.411-28.167c-4.744 4.852-7.588 11.413-7.588 18.469v35.289c0 9.744 7.9 17.644 17.644 17.644h35.289v-22.568c-.002-8.04-3.656-15.644-9.934-20.667zm256.955 25.591v-35.289c0-7.002-2.797-13.518-7.475-18.359l-35.526 28.057c-6.278 5.023-9.933 12.627-9.933 20.667V454.5h35.289c9.745.001 17.645-7.899 17.645-17.644zm-149.978 0c-4.872 0-8.822-3.95-8.822-8.822v-57.742h17.644v57.743c0 4.872-3.95 8.821-8.822 8.821z" />
      <path d="M256.155 370.272l-50.494-37.871c-3.241-2.431-7.86-1.64-10.107 1.731l-9.977 14.966 30.957 43.339c2.353 3.295 6.991 3.938 10.152 1.409l29.469-23.574zm0 0l50.494-37.871c3.241-2.431 7.86-1.64 10.107 1.731l9.977 14.966-30.957 43.339c-2.353 3.295-6.991 3.938-10.152 1.409l-29.469-23.574z" />
    </g>
    <path
      fill="#F0C087"
      d="M300.266 136.9c-44.111 44.111-130.679 8.822-130.679 79.4l5.479 51.989c1.056 11.629 7.799 21.983 18.006 27.653l50.231 27.906c7.994 4.441 17.713 4.441 25.707 0l50.231-27.906c10.207-5.672 16.949-16.025 18.006-27.653l6.324-61.745c.45-4.388.546-8.803.397-13.208-3.303-22.457-34.88-38.792-43.702-56.436z"
    />
    <path
      fill="#E6AF78"
      d="M169.587 216.3l5.479 51.989c1.056 11.629 7.799 21.983 18.006 27.653l50.231 27.906c5.547 3.081 11.908 3.88 17.963 2.685 0 0-25.326-7.674-40.398-48.477-4.617-12.5-11.028-78.665-5.514-86.752 10.899-15.985 71.681-17.645 89.641-47.298-.888-1.101-1.707-2.203-2.455-3.308-.061-.089-.133-.179-.193-.268-.78-1.172-1.488-2.348-2.079-3.53-44.113 44.111-130.681 8.822-130.681 79.4z"
    />
    <path
      fill="#A0D2F0"
      d="M326.733 436.856h-35.289c-4.872 0-8.822 3.95-8.822 8.822v8.822h52.933v-8.822c0-4.872-3.95-8.822-8.822-8.822z"
    />
  </svg>
);

IconAuthors.defaultProps = {
  width: 140,
  color: 'default',
};

export default IconAuthors;
