import React from 'react';
import Image from './Images/Image';
import { FormattedMessage } from 'react-intl';

const Backdrop = (props) => (
  <div className="backdrop-app">
    <div className="backdrop-app_container">
      <Image className="backdrop-app_img" src={require('../images/loading.svg')} />
      <p className="backdrop-app_text">
        {props.message || (
          <FormattedMessage id="app.global.loading" defaultMessage="Carregando" />
        )}
      </p>
    </div>
  </div>
);
export default Backdrop;
