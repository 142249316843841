import * as Actions from '../../constant/constants';
import socket from '../../socket';

export function getTemplate() {
  return {
    type: Actions.GET_PARQ_TEMPLATE,
    payload: socket.sendMessage(Actions.GET_PARQ_TEMPLATE, {}),
  };
}

export function setTemplate({ data }) {
  return {
    type: Actions.GET_PARQ_TEMPLATE,
    payload: data,
  };
}

export function getAllQuestions() {
  return {
    type: Actions.GET_PARQ_QUESTIONS,
    payload: socket.sendMessage(Actions.GET_PARQ_QUESTIONS, {}),
  };
}

export function setQuestions({ data }) {
  return {
    type: Actions.GET_PARQ_QUESTIONS,
    payload: data,
  };
}
