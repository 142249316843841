import React from 'react';

const IconCardTotalEvaluations = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 61.858 61.858 "
    width={props.width}
    height={props.height}
  >
    <g fill="#010002">
      <path d="M50.097.014c-9.917.874-17.235 5.824-21.5 12.98-2.86 3.584-8.57 14.526-10.648 20.254-3.77 7.123-7.05 15.598-9.62 23.238-1.578 4.672 5.48 7.72 7.06 3.027 1.92-5.716 4.24-11.896 6.87-17.63 2.6 5.827 5.25 11.636 8.09 17.353 2.2 4.438 8.83.546 6.63-3.877-2.926-5.89-5.64-11.87-8.32-17.87.034-.116.08-.23.113-.344.445.127.937.166 1.48.063 4.095-.77 8.19-1.536 12.29-2.305 1.75-.33 2.422-2.247 2.146-3.78-.828-4.598-3.447-7.796-6.707-10.82-.485-1.648-2.1-3.104-3.89-4.55 3.63-5.87 9.56-9.057 17.275-9.737C55.31 5.68 54-.33 50.096.012zm-14.924 26.13c1.013 1.053 1.875 2.162 2.526 3.446l-5.95 1.115c1-1.61 2.15-3.13 3.42-4.562z" />
      <circle cx="43.653" cy="15.635" r="5.275" />
    </g>
  </svg>
);

IconCardTotalEvaluations.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconCardTotalEvaluations;
