import axios from 'axios';
import { asMutable } from 'seamless-immutable';
import { push } from 'react-router-redux';
import * as Actions from '../../constant/constants';
import socket from '../../socket';
import {
  GENDERS_DEFAULT_MESSAGES,
  GOALS_DEFAULT_MESSAGES,
} from '../../constant/defaultMessages';
import { Err } from '../../utils/conlog';
import { removeAccents } from '../../utils/functions';

export function getEvaluated(idclient = 0) {
  return {
    type: Actions.GET_EVALUATED,
    payload: socket.sendMessage(Actions.GET_EVALUATED, {
      idclient,
    }),
  };
}

export function setEvaluated(event) {
  let evaluated = [];
  if (typeof event === 'undefined') {
    socket.sendMessage(Actions.GET_EVALUATED, []);
  } else if (event.data !== null) {
    evaluated = event.data.map((evaluated) => {
      convertMessages(evaluated, GENDERS_DEFAULT_MESSAGES);
      return evaluated;
    });
  }
  return {
    type: Actions.GET_EVALUATED,
    payload: evaluated,
  };
}

export function getOneEvaluated(idclient, idevaluated) {
  return {
    type: Actions.GET_ONE_EVALUATED,
    payload: socket.sendMessage(Actions.GET_ONE_EVALUATED, {
      idclient,
      idevaluated,
    }),
  };
}

export function goToPage(page) {
  return (dispatch, getState) => {
    const evaluated = getState().data.evaluatedSet;

    if (evaluated.evaluation.totalPages < page) {
      dispatch({
        type: Actions.NOT_EVALUATIONS,
      });
      return;
    }
    if (
      evaluated.evaluation.evaluations[`page${page}`] !== null
      && evaluated.evaluation.evaluations[`page${page}`]
    ) {
      dispatch({
        type: Actions.SET_CURRENT_PAGE_EVALUATIONS,
        payload: {
          evaluations: asMutable(evaluated.evaluation.evaluations[`page${page}`], {
            deep: true,
          }),
          page,
        },
      });
      return;
    }
    const offset = evaluated.evaluation.limit * (page - 1);
    dispatch({
      type: Actions.GET_EVALUATIONS,
      payload: socket.sendMessage(Actions.GET_EVALUATIONS, {
        idevaluated: evaluated.evaluatedSelected.id,
        limit: evaluated.evaluation.limit,
        offset,
        page,
      }),
    });
  };
}

export function setCurrentPage(response) {
  return {
    type: Actions.SET_CURRENT_PAGE_EVALUATIONS,
    payload: response.data,
  };
}

// SELECT REGISTER
export function selectRegister(register, redirect = '') {
  return (dispatch) => {
    let registerEvaluated;
    if (redirect) {
      dispatch(push(redirect));
    }
    if (register.goals) {
      registerEvaluated = register.goals.map((goal) => {
        convertMessagesGoals(goal, GOALS_DEFAULT_MESSAGES);
        return goal;
      });
    }
    registerEvaluated = convertMessages(register, GENDERS_DEFAULT_MESSAGES);
    dispatch({
      type: Actions.REGISTER_SELECTED_EVALUATED,
      payload: registerEvaluated,
    });
  };
}

// SET PICTURE
export function setPicture(event, token) {
  let data;
  data = event.data;
  return (dispatch) => {
    if (data.person.picture) {
      dispatch({
        type: Actions.SET_PICTURE_REQUEST,
        payload: axios({
          method: 'post',
          url: `${Actions.URL_SERVER}/profile/${data.person.id}`,
          data: {
            idperson: data.person.id,
            token,
            img: data.person.picture,
          },
        })
          .then((response) => {
            data.person = response.data;
            dispatch(addEvaluated(data));
          })
          .catch((error) => {
            Err(error);
          }),
      });
    } else {
      dispatch(addEvaluated(data));
    }
  };
}

// ADD EVALUATED
export function addEvaluated(data) {
  return (dispatch) => {
    const evaluated = convertMessages(data, GENDERS_DEFAULT_MESSAGES);
    dispatch({
      type: Actions.ADD_REGISTER_EVALUATED,
      payload: evaluated,
    });
  };
}

export function addRegister(data, idclient, formatteddate, maturation, reavaliationDays) {
  return {
    type: Actions.ADD_REQUEST,
    payload: socket.sendMessage(Actions.ADD_EVALUATED, {
      birthday: formatteddate,
      cel: data.cellphone,
      city: data.city,
      complement: data.complement,
      country: data.country.value,
      doc: data.document,
      email: data.email,
      password: data.password,
      emergencyname: data.emergencyName,
      emergencyphone: data.emergencyPhone,
      goals: data.goals,
      idclassification: data.classifications.id,
      idclient,
      idethnicity: data.ethnicities.id,
      idgender: data.gender.id,
      idsexualmaturation: maturation,
      locale: data.locale,
      name: data.nameComplete,
      district: data.district,
      note: data.note,
      number: data.number,
      phone1: data.phoneOne,
      phone2: data.phoneTwo,
      picture: data.croppedImg,
      postalcode: data.postalcode,
      reavaliationdays: reavaliationDays,
      state: data.region,
      street: data.street,
      timezone: data.timezone,
      instagram: data.instagram,
      twitter: data.twitter,
      facebook: data.facebook,
      linkedin: data.linkedin,
    }),
  };
}

// EDIT EVALUATED
export function editEvaluated(data) {
  let evaluatedEdit = {};
  evaluatedEdit = convertMessages(data, GENDERS_DEFAULT_MESSAGES);
  return {
    type: Actions.EDIT_REGISTER_EVALUATED,
    payload: evaluatedEdit,
  };
}

export function editRegister(
  data,
  idclient,
  formatteddate,
  maturation,
  idperson,
  idevaluated,
  idaddress,
  idcontact,
  img,
  reavaliationDays,
) {
  return {
    type: Actions.UPDATE_REQUEST,
    payload: socket.sendMessage(Actions.UPDATE_EVALUATED, {
      birthday: formatteddate,
      cel: data.cellphone,
      city: data.city,
      complement: data.complement,
      country: data.country.value,
      doc: data.document,
      email: data.email,
      emergencyname: data.emergencyName,
      emergencyphone: data.emergencyPhone,
      facebook: data.facebook,
      goals: data.goals,
      id: idevaluated,
      idaddress,
      idclassification: data.classifications.id,
      idclient,
      idcontact,
      idethnicity: data.ethnicities.id,
      idgender: data.gender.id,
      idperson,
      idsexualmaturation: maturation,
      instagram: data.instagram,
      linkedin: data.linkedin,
      locale: data.locale,
      name: data.nameComplete,
      district: data.district,
      note: data.note,
      number: data.number,
      phone1: data.phoneOne,
      phone2: data.phoneTwo,
      picture: img,
      postalcode: data.postalcode,
      reavaliationdays: reavaliationDays,
      state: data.region,
      street: data.street,
      timezone: data.timezone,
      twitter: data.twitter,
    }),
  };
}

export function setPictureEdit(event, token, idclient) {
  const verify = /^\s*data:([a-z]+\/[a-z0-9\-+]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,[a-z0-9!$&',()*+,;=\-._~:@/?%\s]*\s*$/i;
  const test = verify.test(event.data.person.picture);
  let data;
  data = event.data;
  return (dispatch) => {
    if (test) {
      dispatch({
        type: Actions.SET_PICTURE_REQUEST,
        payload: axios({
          method: 'post',
          url: `${Actions.URL_SERVER}/profile/${data.person.id}`,
          data: {
            idperson: data.person.id,
            token,
            img: data.person.picture,
          },
        })
          .then((response) => {
            data.person = response.data;
            dispatch(editEvaluated(data, idclient));
          })
          .catch((error) => {
            Err(error);
          }),
      });
    } else {
      dispatch(editEvaluated(data, idclient));
    }
  };
}

// DELETE EVALUATED
export function deleteEvaluated(event) {
  let evaluatorDelete = {};
  evaluatorDelete = event.data;
  return {
    type: Actions.DELETE_REGISTER_EVALUATED,
    payload: evaluatorDelete,
  };
}
export function deleteRegister(idperson, idevaluated) {
  return {
    type: Actions.DELETE_REQUEST,
    payload: socket.sendMessage(Actions.DELETE_EVALUATED, {
      idperson,
      idevaluated,
    }),
  };
}

export function setEvaluatedPerson(
  idclient,
  idperson,
  data,
  formatteddate,
  img,
  maturation,
  idaddress,
  idcontact,
  reavaliationDays,
) {
  return {
    type: Actions.SET_EVALUATED_PERSON_REQUEST,
    payload: socket.sendMessage(Actions.SET_EVALUATED_PERSON, {
      name: data.nameComplete,
      birthday: formatteddate,
      cel: data.cellphone,
      city: data.city,
      complement: data.complement,
      country: data.country.value,
      doc: data.document,
      email: data.email,
      emergencyname: data.emergencyName,
      emergencyphone: data.emergencyPhone,
      goals: data.goals,
      idaddress,
      idclassification: data.classifications.id,
      idclient,
      idcontact,
      idethnicity: data.ethnicities.id,
      idgender: data.gender.id,
      idperson,
      idsexualmaturation: maturation,
      locale: data.locale,
      district: data.district,
      note: data.note,
      number: data.number,
      phone1: data.phoneOne,
      phone2: data.phoneTwo,
      picture: img,
      postalcode: data.postalcode,
      reavaliationdays: reavaliationDays,
      state: data.region,
      street: data.street,
      timezone: data.timezone,
    }),
  };
}

export function getOneEvaluation(idClient, idEvaluated, idEvaluation) {
  return {
    type: Actions.GET_ONE_EVALUATION,
    payload: socket.sendMessage(Actions.GET_ONE_EVALUATION, {
      idclient: parseInt(idClient),
      idevaluated: parseInt(idEvaluated),
      idevaluation: parseInt(idEvaluation),
    }),
  };
}

export function setInfoEvaluated(event) {
  let dataSelected = event.data.goals.map((goal) => {
    convertMessagesGoals(goal, GOALS_DEFAULT_MESSAGES);
    return goal;
  });
  dataSelected = convertMessages(event.data, GENDERS_DEFAULT_MESSAGES);
  return {
    type: Actions.REGISTER_SELECTED_EVALUATED,
    payload: dataSelected,
  };
}

// EVALUATION SELECTED
export function evaluationSelected(event) {
  return (dispatch) => {
    const { data } = event;
    window.setTimeout(() => {
      dispatch(
        push(`/PhysicalAssessment/${data.client.id}/${data.evaluated.id}/${data.id}`),
      );
    }, 100);
    dispatch({
      type: Actions.ADD_EVALUATION,
      payload: data,
    });
  };
}

export function setEvaluatorNewEvaluation(response) {
  return {
    type: Actions.UPDATE_EVALUATION_EVALUATOR,
    payload: response,
  };
}

export function setEvaluatorEvaluation(response) {
  return (dispatch, getState) => {
    const { evaluators } = getState().data.evaluatorsSet;
    const indexEvaluator = evaluators.findIndex(evaluator => evaluator.id === response);
    dispatch({
      type: Actions.UPDATE_EVALUATION_EVALUATOR,
      payload: evaluators[indexEvaluator],
    });
  };
}

// Protocolos
export function getProtocolsFiltered(age, gender, ethnicity, classification, maturation) {
  return {
    type: Actions.GET_FORMULAS_FILTERED,
    payload: socket.sendMessage(Actions.GET_FORMULAS_FILTERED, {
      age,
      idgender: gender,
      idethnicity: ethnicity,
      idclassification: classification,
      idmaturation: maturation,
    }),
  };
}
export function getOneFormula(formula) {
  return {
    type: Actions.GET_ONE_FORMULA,
    payload: socket.sendMessage(Actions.GET_ONE_FORMULA, {
      id: formula,
    }),
  };
}
export function getOneNeuroFormula(id) {
  return {
    type: Actions.GET_ONE_NEUROMOTOR_TEST,
    payload: socket.sendMessage(Actions.GET_ONE_NEUROMOTOR_TEST, { id }),
  };
}
export function selectRegisterProtocol(protocol) {
  return {
    type: Actions.SELECTED_REGISTER_PROTOCOL,
    payload: protocol,
  };
}
export function selectCardioProtocol(protocol) {
  return {
    type: Actions.SELECTED_CARDIO_PROTOCOL,
    payload: protocol,
  };
}
export function selectNeuroProtocol(protocol) {
  return {
    type: Actions.SELECTED_NEURO_PROTOCOL,
    payload: protocol,
  };
}

// SELECIONAR AVALIAÇÃO
export function selectRegisterEvaluation(register) {
  return {
    type: Actions.SELECTED_REGISTER_EVALUATION,
    payload: register,
  };
}

export function resetRegisteredProtocol() {
  return {
    type: Actions.RESET_REGISTERED_PROTOCOL,
  };
}

// Body Composition
export function getBodyComposition(idevaluation) {
  return {
    type: Actions.GET_BODY_COMPOSITION_REQUEST,
    payload: socket.sendMessage(Actions.GET_BODY_COMPOSITION, {
      idevaluation,
    }),
  };
}
export function setBodyComposition(response) {
  return {
    type: Actions.GET_BODY_COMPOSITION,
    payload: response,
  };
}

// Cardiorespiratory
export function getCardiorespiratory(idevaluation) {
  return {
    type: Actions.GET_CARDIO_REQUEST,
    payload: socket.sendMessage(Actions.GET_CARDIO, { idevaluation }),
  };
}

export function setCardiorespiratory(res) {
  return {
    type: Actions.GET_CARDIO,
    payload: res,
  };
}

export function getCardioVariablesEvaluation(idevaluation) {
  return {
    type: Actions.GET_CARDIO_VARIABLES_EVALUATION,
    payload: socket.sendMessage(Actions.GET_CARDIO_VARIABLES_EVALUATION, { idevaluation }),
  }
}

// Neuromotor
export function getNeuromotorEvaluationResult(idevaluation, idtesteneuromotor) {
  return {
    type: Actions.GET_NEUROMOTOR_EVALUATION_RESULT,
    payload: socket.sendMessage(Actions.GET_NEUROMOTOR_EVALUATION_RESULT, { idevaluation, idtesteneuromotor }),
  };
}

export function getAllNeuromotorEvaluationResults(idevaluation) {
  return {
    type: Actions.GET_ALL_NEUROMOTOR_EVALUATION_RESULTS,
    payload: socket.sendMessage(Actions.GET_ALL_NEUROMOTOR_EVALUATION_RESULTS, { idevaluation }),
  };
}

export function setNeuromotorEvaluationResult(res) {
  return {
    type: Actions.GET_NEUROMOTOR_EVALUATION_RESULT,
    payload: res,
  };
}

export function getNeuromotorEvaluationResultError() {
  return {
    type: Actions.GET_NEUROMOTOR_EVALUATION_RESULT_ERROR,
    payload: {},
  };
}

// EDITAR AVALIAÇÃO DO AVALIADO
// redireciona para o edit e seleciona a avaliação
export function editEvaluationSelect(register) {
  return (dispatch) => {
    window.setTimeout(() => {
      dispatch(
        push(
          `/PhysicalAssessment/${register.client.id}/${register.evaluated.id}/${register.id}/Edit`,
        ),
      );
    }, 200);
    dispatch({
      type: Actions.SELECTED_REGISTER_EVALUATION,
      payload: register,
    });
  };
}
export function updateEvaluationDate(response) {
  return {
    type: Actions.UPDATE_EVALUATION_DATE,
    payload: response,
  };
}
export function updateMeasuresEvaluation(response) {
  return {
    type: Actions.UPDATE_EVALUATION_MEASURES,
    payload: response,
  };
}
export function updateBodyCompositionEvaluation(response) {
  return {
    type: Actions.UPDATE_EVALUATION_BODY_COMPOSITION,
    payload: response,
  };
}
export function updateAnamnesisEvaluation(response) {
  return {
    type: Actions.UPDATE_EVALUATION_ANAMNESIS,
    payload: response,
  };
}
export function updateParQEvaluation(res) {
  return {
    type: Actions.UPDATE_EVALUATION_PARQ,
    payload: res,
  };
}
export function updateCardioVariablesEvaluation(res) {
  return {
    type: Actions.UPDATE_EVALUATION_CARDIO_VARIABLES,
    payload: res,
  };
}
export function updateNeuroVariablesEvaluation(res) {
  return {
    type: Actions.UPDATE_EVALUATION_NEURO_VARIABLES,
    payload: res,
  };
}
export function updateEvaluationAlertReport(alertReport) {
  return {
    type: Actions.UPDATE_EVALUATION_ALERT_REPORT,
    payload: alertReport,
  };
}

// Excluir avaliação avaliado
export function clearHistoryEvaluation(page) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.DELETE_EVALUATION,
      payload: page,
    });
    dispatch(goToPage(getState().data.evaluatedSet.evaluation.page));
  };
}

export function deleteEvaluation(idclient, idevaluated, idevaluation) {
  return {
    type: Actions.DELETE_EVALUATION,
    payload: socket.sendMessage(Actions.DELETE_EVALUATION, {
      idclient,
      idevaluation,
      idevaluated,
    }),
  };
}

// Relatório Avaliação
export function reportEvaluationSelected(register) {
  return (dispatch) => {
    window.setTimeout(() => {
      dispatch(
        push(
          `/PhysicalAssessment/${register.client.id}/${register.evaluated.id}/${register.id}/Report`,
        ),
      );
    }, 200);
    dispatch({
      type: Actions.SELECTED_REGISTER_EVALUATION,
      payload: register,
    });
  };
}

export function sendEmailReport(idclient, idevaluated, idevaluation, url = '') {
  return {
    type: Actions.SEND_REPORT_EVALUATION,
    payload: socket.sendMessage(Actions.SEND_REPORT_EVALUATION, {
      idclient,
      idevaluation,
      idevaluated,
      url,
    }),
  };
}

export function sendComparisonsEmail(idclient, idevaluated, idevaluation, url = '') {
  return {
    type: Actions.SEND_REPORT_COMPARISON,
    payload: socket.sendMessage(Actions.SEND_REPORT_COMPARISON, {
      idclient,
      idevaluation,
      idevaluated,
      url,
    }),
  };
}

// Resultados composição comporal
export const changeResultsComposition = (
  percentfatcurrent,
  percentfatminideal,
  percentfatmaxideal,
  weightcurrent,
  heightcurrent,
  femoralBiepicondilo,
  biestiloide,
) => ({
  type: Actions.CALCULATE_BODY_COMPOSITION,
  percentfatcurrent,
  percentfatminideal,
  percentfatmaxideal,
  weightcurrent,
  heightcurrent,
  femoralBiepicondilo,
  biestiloide,
});

// Resultados cardiorrespiratório
export const changeResultsCardiorespiratory = (data) => {
  return {
    type: Actions.CALCULATE_CARDIORESPIRATORY,
    payload: {
      vo2max: data.vo2Max,
      classification: data.classification,
      speedkmh: data.speedKmh,
      speedmmin: data.speedMmin,
      mets: data.mets,
      kcalmin: data.kcalmin,
      thirtytest: data.thirtyMinutes,
      onekilometer: data.oneKilometer,
    }
  };
};

// Resultados cardiorrespiratório
export const changeResultsNeuromotor = (data) => {
  return {
    type: Actions.CALCULATE_NEUROMOTOR,
    payload: { classification: data.classification }
  };
};

function convertMessages(data, constMessage) {
  const nameField = data.person.gender.name;

  const nameFieldNoSpace = nameField.replace(/\s/g, '');
  data.person.gender.idMessageGender = `app.global.gender.${nameFieldNoSpace.toLowerCase()}`;
  data.person.gender.defaultMessageGender = constMessage[nameFieldNoSpace] || data.person.gender.name;
  return data;
}

/** Convert Message Goals
 * Converte os objetivos registrados para multiplos idiomas.
 * Edit: Fiz uma alteração que possivelmente vai impedir que o erro app.goal retorne
 *   Mas assim que o sistema for otimizado para multiplos idiomas, essa alteração será
 *   refeita.
 */
function convertMessagesGoals(data) {
  const nameGoal = removeAccents(data.name);

  const nameGoalDot = nameGoal.replace(/[^a-zA-Z0-9]/g, '.');
  // nameGoalNoSpace = nameGoal.replace( /\s/g, '' );
  data.idMessageName = `app.goal.${nameGoalDot.toLowerCase()}`;
  data.defaultMessageName = data.name;
  return data;
}
