import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-intl-redux';
import { addLocaleData } from 'react-intl';
import { ConnectedRouter } from 'react-router-redux';
import moment from 'moment';
import App from './App';
import configureStore from './store/configureStore';
// import registerServiceWorker from './registerServiceWorker';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import pt from 'react-intl/locale-data/pt';
import messages from './i18n/messages';
import './styles/themes/default/style-default.css';
import * as serviceWorker from './serviceWorker';
import * as CONST from './constant/constants';

/** Error Tracking
 * Configuração: https://www.youtube.com/watch?v=D060ACRPj6I
 */
import Raven from 'raven-js';
import { sentry_url } from './config';

if (!CONST.IS_DEV) {
  Raven.config(sentry_url, {
    release: '1.5-beta',
  }).install();
}

require('moment/min/locales.min');

addLocaleData([...en, ...es, ...pt]);

/*
  Verifica se possui alguma linguagem setada no localStorage para ja iniciar o login com a linguagem certa,
  caso não tenha seta a linguagem do navegador
*/
var locale = '';

if (window.localStorage.locale) {
  locale = window.localStorage.locale;
} else {
  locale = CONST.DEFAULT_LANGUAGE;
  window.localStorage.setItem('locale', locale);
}

var strings = messages[locale] ? messages[locale] : messages['pt-BR'];

const initialState = {
  intl: {
    defaultLocale: 'pt-BR',
    locale: locale,
    messages: strings,
  },
};

var l = locale.split('-')[0];
moment.locale(l);

const target = document.getElementById('root');
const { store, history } = configureStore(initialState);

ReactDOM.render(
  <Provider store={store} onError={(err) => {
    if (err.code === 'MISSING_TRANSLATION') {
      console.info('Missing translation', err.message);
      return;
    }
    throw err;
  }}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  target,
);

serviceWorker.register();
