/** ReportBugDetails
 * @author Rafael Gomides
 * @copyright Grupo KRS
 * @description Modal de Report de Bugs
 */
/** Importações */
// Principal
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Componentes
import Loading from '../components/Loading';
import ModalDialog from '../components/Modal/ModalDialog';
import { FormattedMessage } from 'react-intl';
// Utilitários
import { closeModalAction } from '../store/modal/action';
import { addReportBug } from '../store/reportBug/action';
import { validateRequired, validateEmail } from '../utils/validate';
import ReportBugForm from '../components/ReportBug/ReportBugForm';
import { ADDED_REPORT_BUG, ADDED_REPORT_BUG_ERROR, MESSAGE_STATUS_ERROR, MESSAGE_STATUS_SUCCESS } from '../constant/constants';
import { showMessage } from '../store/flashMessage/action';

/** Classe */
class ReportBugDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      phone: '',
      email: '',
      description: '',
      section: '',
      validateName: '',
      validateDescription: '',
      validateEmail: '',
      type: '',
      loading: false,
    };
    this.handleAddBugReport = this.handleAddBugReport.bind(this);
  }

  /** REACT LIFECYCLE **/
  componentDidMount() {
    window.addEventListener(ADDED_REPORT_BUG, this.handleAddBugReport);
    window.addEventListener(ADDED_REPORT_BUG_ERROR, this.handleAddBugReportError);
  }

  componentWillUnmount() {
    window.removeEventListener(ADDED_REPORT_BUG, this.handleAddBugReport);
    window.removeEventListener(ADDED_REPORT_BUG_ERROR, this.handleAddBugReportError);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let nextState = prevState;
    //TODO: Desativado temporariamente. Rever nos próximos comits
    /*if(nextProps.profile.isAuthenticated !== false && prevState.name === ''){
      nextState = {
        name: nextProps.profile.user.name,
        email: nextProps.profile.user.person.contact.email
      }
    }*/
    if (nextProps.modals.type !== prevState.type) {
      nextState = {
        ...nextState,
        type: nextProps.modals.type,
      };
    }

    return nextState;
  }

  /** METODOS **/

  /**
   * handle Add BugReport
   *
   * Trata as ações que acontecem depois que um bug é reportado
   */
  handleAddBugReport() {
    setTimeout(() => {
      this.props.showMessage({
        status: MESSAGE_STATUS_SUCCESS,
        idMessage: 'page.authors.flashMessage.add',
        defaultMessage: 'Mensagem enviada. Em breve retornaremos seu contato.',
      });
      setTimeout(() => {
        this.handlerCloseModal();
      }, 500);
    }, 500);
    this.setState({
      loading: false
    });
  }

  /**
   * handle Add BugReport error
   *
   * Trata as ações que devem acontecer após um erro no reporte de bugs
   */
  handleAddBugReportError() {    
    setTimeout(() => {
      this.props.showMessage({
        status: MESSAGE_STATUS_ERROR,
        idMessage: 'app.bugreport.added.error',
        defaultMessage: 'Ocorreu um erro ao enviar a sua mensagem. Envie sua mensagem para suporte@physicaltest.com.br',
      });
      setTimeout(() => {
        this.handlerCloseModal();
      }, 500);
    }, 500);
    this.setState({
      loading: false
    });
  }

  /**
   * Handler close modal
   *
   * Handler para tratar as ações que acontecerão quando o modal fechar
   */
  handlerCloseModal() {
    this.setState({
      name: '',
      phone: '',
      email: '',
      description: '',
      section: '',
      validateName: '',
      validateDescription: '',
      validateEmail: '',
      type: '',
    });
    this.props.closeModalAction();
  }

  /**
   * Handle change
   *
   * Handler genérico para manipular o estado
   * @param {Event} event
   */
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  /**
   * Handle submit
   *
   * Handler para o submit do formulário
   */
  handleSubmit() {
    let name = this.validate(this.state.name, 'validateName');
    let email = this.validate(this.state.email, 'validateEmail');
    let description = this.validate(this.state.description, 'validateDescription');
    let section = this.state.section;
    
    this.setState({
      loading: true,
    });

    if (name && email && description) {
      const bugReport = new ReportBug(
        undefined,
        section,
        this.state.name,
        this.state.email,
        this.state.description,
        '',
      );

      if (this.props.profile.user !== undefined) {
        if (this.props.profile.user.id > 0) {
          bugReport.person = { id: this.props.profile.user.id };
        }
      }

      this.props.addReportBug(bugReport);
    }
  }

  /**
   * Validate
   *
   * Valida os campos obrigatórios
   *
   * @param {String} state
   * @param {String} valid
   */
  validate(state, valid) {
    let val;

    if (typeof state === 'string') {
      if (valid === 'validateEmail') {
        val = validateEmail(state);
      } else {
        val = validateRequired(state);
      }
    } else {
      val = state;
    }
    if (!val) {
      this.setState({ [valid]: 'danger' });
    } else {
      this.setState({ [valid]: '' });
    }
    return val;
  }

  /** RENDERS **/

  /** Render Default
   * Render Padrão do componente
   */
  _renderDefault() {
    return (
      <ModalDialog
        children={this._renderForm()}
        title={
          <FormattedMessage id="app.bugreport.title" defaultMessage="Informar problema" />
        }
        onClickSuccess={this.handleSubmit.bind(this)}
        success={<FormattedMessage id="app.global.submit" defaultMessage="Enviar" />}
        closeModal={this.handlerCloseModal.bind(this)}
        loading={this.state.loading}
        disabled={this.state.loading}
      ></ModalDialog>
    );
  }
  /** Render Loading
   * Mostra a terra de Load do sistema
   */
  _renderLoading(msg) {
    return <Loading message={msg} />;
  }
  /** Render Switch
   * Escolhe o que vai ser renderizado na tela
   * @param {bool} opc Opção a ser renderizada
   */
  _renderSwitch(isBug) {
    if (isBug) {
      return this._renderDefault();
    }
    return '';
  }

  /**
   * Render form
   * Renderiza os campos do formulário
   */
  _renderForm() {
    return (
      <ReportBugForm
        name={this.state.name}
        email={this.state.email}
        description={this.state.description}
        section={this.state.section}
        change={this.handleChange.bind(this)}
        validateName={this.state.validateName}
        validateDescription={this.state.validateDescription}
        validateEmail={this.state.validateEmail}
      ></ReportBugForm>
    );
  }

  /** Render
   * React render padrão
   */
  render() {
    return this._renderSwitch(this.state.type === 'bug');
  }
}
/** Store */
const mapStateToProps = (state) => {
  return {
    modals: state.util.modalSet.modal,
    profile: state.profile,
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      closeModalAction,
      addReportBug,
      showMessage,
    },
    dispatch,
  ),
});

class ReportBug {
  constructor(idpessoa, secao, nome, email, descricao, foto) {
    if (idpessoa !== undefined) this.person = { id: idpessoa };
    this.secao = secao;
    this.nome = nome;
    this.email = email;
    this.descricao = descricao;
    this.foto = foto;
    this.ip = '';
    this.useragent = '';
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportBugDetails);
