import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';
import clone from '../../utils/deepClone';

const initialState = Immutable.from({
  loading: true,
  paymentRecords: [],
  shouldGetPaymentRecords: true,
  lastPaymentRecord: {},
});

export default function paymentRecords(state = initialState, action = {}) {
  switch (action.type) {
    case Actions.GET_PAYMENT_RECORDS:
      const clonePaymentRecord = clone(action.payload);
      const paymentRecord = clonePaymentRecord.length > 0 ? action.payload.sort((a, b) => +b.id - +a.id)[0] : {};
      const paymentRecords = state
        .updateIn(['paymentRecords'], () => action.payload)
        .updateIn(['lastPaymentRecord'], () => paymentRecord)
        .updateIn(['loading'], () => false)
        .updateIn(['shouldGetPaymentRecords'], () => false);
      return paymentRecords;
    case Actions.CHANGE_PLAN_SUB_FULFILLED:
      return state.updateIn(['shouldGetPaymentRecords'], () => true);
    case Actions.CREDIT_CARD_UPDATE_FULFILLED:
      return state.updateIn(['shouldGetPaymentRecords'], () => true);
    default:
      return state;
  }
}
