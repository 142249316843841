import * as Actions from '../../constant/constants';
import socket from '../../socket';
import { ETHNICITIES_DEFAULT_MESSAGES } from '../../constant/defaultMessages';

export function getEthnicities(e) {
  return {
    type: Actions.GET_ETHNICITIES,
    payload: socket.sendMessage(Actions.GET_ETHNICITIES, []),
  };
}

export function setEthnicities(event) {
  let ethnicities = [];
  if (typeof event === 'undefined') socket.sendMessage(Actions.GET_ETHNICITIES, []);
  else ethnicities = event.data.map(ethnicity => convertMessages(ethnicity, ETHNICITIES_DEFAULT_MESSAGES));
  return {
    type: Actions.GET_ETHNICITIES,
    payload: ethnicities,
  };
}

export function selectRegister(register) {
  return {
    type: Actions.REGISTER_SELECTED_ETHNICITY,
    payload: register,
  };
}

export function addEthnicity(event) {
  let ethnicity = {};
  ethnicity = convertMessages(event.data, ETHNICITIES_DEFAULT_MESSAGES);
  return {
    type: Actions.ADD_REGISTER_ETHNICITY,
    payload: ethnicity,
  };
}

export function addRegister(name) {
  return {
    type: Actions.ADD_REQUEST,
    payload: socket.sendMessage(Actions.ADD_ETHNICITY, { name }),
  };
}

export function editEthnicity(event) {
  let ethnicityEdit = {};
  ethnicityEdit = convertMessages(event.data, ETHNICITIES_DEFAULT_MESSAGES);
  return {
    type: Actions.EDIT_REGISTER_ETHNICITY,
    payload: ethnicityEdit,
  };
}

export function editRegister(id, name) {
  return {
    type: Actions.UPDATE_REQUEST,
    payload: socket.sendMessage(Actions.UPDATE_ETHNICITY, { id, name }),
  };
}

export function deleteEthnicity(event) {
  let ethnicityDelete = {};
  ethnicityDelete = event.data;
  return {
    type: Actions.DELETE_REGISTER_ETHNICITY,
    payload: ethnicityDelete,
  };
}

export function deleteRegister(id, name) {
  return {
    type: Actions.DELETE_REQUEST,
    payload: socket.sendMessage(Actions.DELETE_ETHNICITY, { id, name }),
  };
}

export function convertMessages(data, constMessage) {
  const nameEthnicity = data.name;
  const nameEthnicityNoSpace = nameEthnicity.replace(/\s/g, '');
  data.idMessageName = `app.ethnicity.${nameEthnicityNoSpace}`;
  data.defaultMessageName = constMessage[nameEthnicityNoSpace] || data.name;
  return data;
}
