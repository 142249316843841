import * as Actions from '../../constant/constants';
import socket from '../../socket';
import { GENDERS_DEFAULT_MESSAGES } from '../../constant/defaultMessages';

// get users
export function getUsers(idclient) {
  return {
    type: Actions.GET_USERS,
    payload: socket.sendMessage(Actions.GET_USERS, { idclient }),
  };
}

export function setUsers(event) {
  let users = [];
  if (event.data) {
    users = event.data.map((user) => {
      convertMessages(user, GENDERS_DEFAULT_MESSAGES);
      return user;
    });
  }
  return {
    type: Actions.GET_USERS,
    payload: users,
  };
}

export function getUsersStatistics(idclient) {
  return {
    type: Actions.GET_USERS_STATISTICS,
    payload: socket.sendMessage(Actions.GET_USERS_STATISTICS, { idclient }),
  };
}

// get sessions
export function getSessions(idclient) {
  return {
    type: Actions.GET_SESSIONS,
    payload: socket.sendMessage(Actions.GET_SESSIONS, { idclient }),
  };
}

export function setSessions(data) {
  return {
    type: Actions.GET_SESSIONS,
    payload: data,
  };
}

// select register session details
export function selectRegisterSession(register) {
  return {
    type: Actions.REGISTER_SELECTED_SESSION,
    payload: register,
  };
}

export function disconnectSession(idclient, idsession, callerid) {
  return {
    type: Actions.DISCONNECT_SESSION,
    payload: socket.sendMessage(Actions.DISCONNECT_SESSION, {
      idclient,
      idsession,
      callerid,
    }),
  };
}

export function disconnectSessionRegister(data) {
  return {
    type: Actions.DISCONNECT_SESSION,
    payload: data,
  };
}

// add user
export function addUser(data) {
  return {
    type: Actions.ADD_USER,
    payload: data,
  };
}

export function addRegister(data, formateddate, idclient) {
  return {
    type: Actions.ADD_REQUEST,
    payload: socket.sendMessage(Actions.ADD_USER, {
      birthday: formateddate,
      cel: data.cellphone,
      city: '',
      complement: '',
      country: '',
      doc: data.cpf,
      email: data.email,
      evaluation: data.evaluationPerm,
      evaluatorperm: data.evaluatorPerm,
      financial: data.financialPerm,
      idclient,
      idgender: data.gender.id,
      locale: data.locale,
      name: data.nameComplete,
      district: '',
      number: '',
      phone1: data.phoneOne,
      phone2: data.phoneTwo,
      picture: '',
      plan: data.planPerm,
      postalcode: '',
      registration: data.registrationPerm,
      state: '',
      street: '',
      timezone: data.timezone,
      user: data.userPerm,
    }),
  };
}

// transforma pessoa de outro cliente em seu usuário
export function setUserPerson(idclient, idperson, data) {
  return {
    type: Actions.ADD_REQUEST,
    payload: socket.sendMessage(Actions.SET_USER_PERSON, {
      idclient,
      idperson,
      user: data.userPerm,
      plan: data.planPerm,
      financial: data.financial,
      registration: data.registration,
      evaluation: data.evaluationPerm,
      evaluatorperm: data.evaluatorPerm,
    }),
  };
}
export function setUserPersonResponse(data) {
  return {
    type: Actions.SET_USER_PERSON,
    payload: data,
  };
}

// select register user details
export function selectRegister(register) {
  return {
    type: Actions.REGISTER_SELECTED_USER,
    payload: register,
  };
}

// Update Permissions
export function updatePermissions(data) {
  if (data.client) {
    data.idclient = data.client.id;
  }
  return {
    type: Actions.UPDATE_PERMISSION,
    payload: socket.sendMessage(Actions.UPDATE_PERMISSION, data),
  };
}
export function updateUserPermission(data) {
  return {
    type: Actions.UPDATE_PERMISSION,
    payload: data,
  };
}

// Delete User
export function deleteUser(idpermission, idclient, idperson) {
  return {
    type: Actions.DELETE_USER,
    payload: socket.sendMessage(Actions.DELETE_USER, {
      idpermission,
      idclient,
      idperson,
    }),
  };
}
export function deleteUserRegister(data) {
  return {
    type: Actions.DELETE_USER,
    payload: data,
  };
}

function convertMessages(data, constMessage) {
  const nameField = data.person.gender.name;

  const nameFieldNoSpace = nameField.replace(/\s/g, '');
  data.person.gender.idMessageGender = `app.global.gender.${nameFieldNoSpace.toLowerCase()}`;
  data.person.gender.defaultMessageGender =
    constMessage[nameFieldNoSpace] || data.person.gender.name;
  return data;
}
