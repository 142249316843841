import { MALE } from '../constant/constants';
import { roundNumber } from './functions';

// Equações utilizadas no cálculo do resultado da composição corporal

// Cálcula Peso gordo
// Recebe Peso atual e % de gordura atual
export function calcFatWeight(weight, fatPercentage) {
  return weight * (fatPercentage / 100);
}

// Cálcula Peso magro
// Recebe peso atual e peso gordo
export function calcLeanWeight(currentWeight, fatWeight) {
  return currentWeight - fatWeight;
}

// Cálcula Peso magro desejável
// peso magro ideal = peso ideal - peso gordo ideal
export function calcDesirableLeanWeight(currentWeight, fatWeight) {
  return currentWeight - fatWeight;
}

// Cálcula Peso Desejável
// Recebe peso magro e % de gordura ideal
export function calcDesirableWeight(leanWeight, fatPercentageIdeal) {
  return leanWeight / ((100 - fatPercentageIdeal) * 0.01);
}

// Cálcula Peso gordo Desejável (deduzidas pela equipe)
// peso ideal * percentual de gordura ideal/100
export function calcDesirableFatWeight(weightIdeal, fatPercentageIdeal) {
  return (weightIdeal * fatPercentageIdeal) / 100;
}

// Cálcula Peso Residual
// Recebe peso atual
export function calcResidualWeight(currentWeight, genderId) {
  if (genderId === MALE) {
    return currentWeight * 0.241;
  }
  return currentWeight * 0.209;
}

// Cálcula Peso ósseo
// Recebe altura, Diâmetro Biestilóide e Diâmetro Biepicôndilo femural
// Acrescentado height/100 pois recebemos a latura em centimetros e a equação precisa da altura em metros
export function calcBoneWeight(height, biestiloideDiameter, femoralDiameterBiepicondilo) {
  return (
    3.02
    * Math.pow(
      Math.pow(height / 100, 2)
        * (biestiloideDiameter / 100)
        * (femoralDiameterBiepicondilo / 100)
        * 400,
      0.712,
    )
  );
}

// Cálcula Peso Muscular
// Recebe Peso Atual – Peso residual – Peso Ósseo – Peso Gordo
export function calcMuscleWeight(currentWeight, residualWeight, boneWeight, fatWeight) {
  return (
    roundNumber(currentWeight)
    - roundNumber(residualWeight)
    - roundNumber(boneWeight)
    - roundNumber(fatWeight)
  );
}

// Cálcula % Peso Muscular
// Recebe Peso Muscular, Peso Atual
export function percentageMuscleWeight(muscleWeight, currentWeight) {
  return (muscleWeight * 100) / currentWeight;
}

// Conversão de densidade corporal(DG) em porcentagem de gordura(PG)
export function convertsMaleBodyDensity(bodyDensity) {
  return (4.95 / bodyDensity - 4.5) * 100;
}
export function convertsFemaleBodyDensity(bodyDensity) {
  return (5.01 / bodyDensity - 4.57) * 100;
}
