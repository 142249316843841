import { IS_DEV } from '../constant/constants';
import { logException } from '../config';

export function Log(message) {
  // Esse é o unico console.log que deve existir nesse sistema
  if (IS_DEV) {
    console.log(message);
  }
}

export function Err(message) {
  // Esse é o unico console.error que deve existir nesse sistema
  if (IS_DEV) {
    console.error(message);
  } else {
    logException(message);
  }
}
