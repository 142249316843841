import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const RedirectCustom = ({ showProblemMessage, redirectTo, ...props }) => {
  !!showProblemMessage && showProblemMessage();
  return <Redirect to={{ pathname: redirectTo, state: { from: props.location } }} />;
};

export const PrivateRoute = ({ notAllowed, showProblemMessage, component: Component, redirectTo, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        notAllowed ? RedirectCustom({ showProblemMessage, redirectTo, props }) : <Component {...props} />
      }
    />
  );
};
