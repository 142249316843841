import * as Actions from '../../constant/constants';
import socket from '../../socket';

export function addRegister(data) {
  return {
    type: Actions.ADD_REQUEST,
    payload: socket.sendMessage(Actions.ADD_PROMOTIONAL_LINK, data),
  }
}

export function selectRegister(register) {
  return {
    type: Actions.REGISTER_SELECTED_PROMOTIONAL_LINK,
    payload: register,
  };
}

export function deleteRegister(id) {
  return {
    type: Actions.DELETE_REQUEST,
    payload: socket.sendMessage(Actions.DELETE_PROMOTIONAL_LINK, { id }),
  };
}

export function updateRegister(data) {
  return {
    type: Actions.UPDATE_REQUEST,
    payload: socket.sendMessage(Actions.UPDATE_PROMOTIONAL_LINK, data)
  }
}

export function addPromotionalLink({ data }) {
  return {
    type: Actions.ADD_REGISTER_PROMOTIONAL_LINK,
    payload: data,
  };
}

export function getPromotionalLinks() {
  return {
    type: Actions.GET_PROMOTIONAL_LINK,
    payload: socket.sendMessage(Actions.GET_PROMOTIONAL_LINK, []),
  };
}

export function setPromotionalLinks({ data }) {
  return {
    type: Actions.SET_PROMOTIONAL_LINKS,
    payload: data,
  };
}

export function deletePromotionalLink({ data }) {
  return {
    type: Actions.DELETE_REGISTER_PROMOTIONAL_LINK,
    payload: data.id,
  };
}

export function updatePromotionalLink({ data }) {
  return {
    type: Actions.UPDATE_REGISTER_PROMOTIONAL_LINK,
    payload: data,
  };
}

