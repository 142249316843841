import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';

const initialState = Immutable.from({
  goals: [],
  goalSelected: {},
  loading: false,
  shouldGetGoals: true,
});

export default function goals(state = initialState, action = {}) {
  switch (action.type) {
    case Actions.GET_GOALS_PENDING:
      const loading = state.updateIn(['loading'], () => true);
      return loading;
    case Actions.GET_GOALS_REJECTED:
      const rej = state.updateIn(['loading'], () => false);
      return rej;
    case Actions.GET_GOALS:
      const goals = state
        .updateIn(['goals'], () => action.payload)
        .updateIn(['loading'], () => false)
        .updateIn(['shouldGetGoals'], () => false);
      return goals;
    case Actions.ADD_REGISTER_GOAL:
      const newState = Immutable.merge({
        ...state,
        goals: [...state.goals, action.payload],
      });
      return newState;
    case Actions.REGISTER_SELECTED_GOAL:
      const goalSelected = state.set('goalSelected', action.payload);
      return goalSelected;
    case Actions.EDIT_REGISTER_GOAL:
      const goalIndex = state.goals.findIndex(goal => goal.id === action.payload.id);
      const newStateGoal = state.updateIn(['goals', goalIndex], () => action.payload);
      return newStateGoal;
    case Actions.DELETE_REGISTER_GOAL:
      const index = state.goals.findIndex(goal => goal.id === action.payload.id);
      const deleteGoal = state.update('goals', aus => aus.slice(0, index).concat(aus.slice(index + 1)));
      return deleteGoal;
    default:
      return state;
  }
}
