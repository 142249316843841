import moment from 'moment';
import {
  SOCKET_CONSTS,
  SOCKET_READY,
  ISSUE_TOKEN,
  SOCKET_CLOSED_ERROR,
  TOKEN_EXPIRED,
} from '../constant/constants';
import { Log, Err } from '../utils/conlog';

const socket = {
  connection: null,
  info: null,
  token: '',
  intervalId: null,
  timeoutToken: null,
  intervalToken: null,
};

socket.finish = () => {
  socket.token = '';
  if (socket.intervalId) {
    window.clearInterval(socket.intervalId);
  }
  socket.intervalId = null;
  socket.stopTimeOutToken();
  if (socket.connection) {
    socket.connection.close();
  }
  socket.connection = null;
};

socket.newConnection = (data) => {
  socket.info = data;
  if (socket.connection != null) {
    // aqui deve desconectar
    if (
      socket.token === data.token
      && socket.connection.readyState === SOCKET_CONSTS.statusOpen
    ) {
      // already connected
      return;
    }
    socket.connection.close();
    socket.connection = null;
  }
  const url = data.token ? `${SOCKET_CONSTS.url}?token=${data.token}` : SOCKET_CONSTS.url;
  socket.connection = new WebSocket(url);

  socket.connection.onopen = () => {
    socket.token = data.token;
    if (socket.intervalId) {
      window.clearInterval(socket.intervalId);
      window.dispatchEvent(new Event(SOCKET_READY));
    }
    socket.intervalId = null;
    socket.resetTimeoutToken(data);
    Log('[SOCKET] Successfully Conected');
  };

  socket.connection.onclose = () => {
    if (socket.info.token !== '' && !!socket.info.exp) {
      const now = moment();
      const expires = moment.unix(socket.info.exp);
      const diff = expires.diff(now, 'seconds');
      if (diff < 0) {
        window.dispatchEvent(new Event(TOKEN_EXPIRED));
      }
    } else if (socket.token !== '' && !socket.intervalId) {
      window.dispatchEvent(new Event(SOCKET_CLOSED_ERROR));
      socket.intervalId = window.setInterval(
        () => socket.newConnection(socket.info),
        5000,
      );
    }
    socket.stopTimeOutToken();
  };

  socket.connection.onerror = (error) => {
    Err('[SOCKET] An unexpected error occurred.');
    console.error(error);
    socket.stopTimeOutToken();
    window.dispatchEvent(new Event(SOCKET_CLOSED_ERROR));
  };

  socket.connection.onmessage = (e) => {
    const response = JSON.parse(e.data);
    const event = new Event(response.event);
    event.data = response.data;
    event.userid = response.userid;
    window.dispatchEvent(event);
  };
};

const send = (e, data) => {
  const connectionOK = socket.connection && socket.connection.readyState === SOCKET_CONSTS.statusOpen;
  if (connectionOK) {
    socket.connection.send(JSON.stringify({ event: e, data }));
  }
  return connectionOK;
};

socket.sendMessage = (e, data) => new Promise((resolve, reject) => {
  if (socket.connection === null) {
    return reject();
  }
  if (socket.connection.readyState === SOCKET_CONSTS.statusClosed) {
    const info = { ...socket.info };
    socket.finish();
    socket.newConnection(info);
    // return reject();
  }
  if (socket.connection.readyState === SOCKET_CONSTS.statusConnecting) {
    setTimeout(() => {
      if (send(e, data)) {
        resolve(true);
      } else {
        reject();
      }
    }, 500);
  } else if (send(e, data)) {
    resolve(true);
  } else {
    reject();
  }
});

socket.resetTimeoutToken = (response) => {
  socket.stopTimeOutToken();
  const data = typeof response.data === 'undefined' ? socket.info : response.data;
  if (data.keepconnection) {
    return;
  }
  socket.token = data.token;
  const now = moment();
  const expires = moment.unix(data.exp);
  const diff = expires.diff(now, 'seconds');
  if (diff < 0) {
    // expired, go away
    return;
  }
  const timeToReissueToken = diff / 2;
  if (timeToReissueToken > 0) {
    const timeToStartInterval = diff - timeToReissueToken;
    if (timeToStartInterval > 0) {
      socket.timeoutToken = window.setTimeout(
        socket.setIntervalToken,
        timeToStartInterval * 1000,
      );
    } else {
      socket.setIntervalToken();
    }
  } else {
    socket.setIntervalToken();
  }
};

socket.setIntervalToken = () => {
  send(ISSUE_TOKEN, null);
};

socket.stopTimeOutToken = () => {
  if (socket.timeoutToken) {
    window.clearTimeout(socket.timeoutToken);
  }
  if (socket.intervalToken) {
    window.clearInterval(socket.intervalToken);
  }
};

window.addEventListener(ISSUE_TOKEN, socket.resetTimeoutToken);

export default socket;
