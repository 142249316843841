/* eslint-disable guard-for-in */
/* eslint-disable consistent-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-restricted-globals */

import moment from 'moment';
import parsePhoneNumber, { isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { extractHoursMinutesAndSeconds } from './cardiorespiratory';

const { validateBr } = require('js-brasil');

export function validateEmail(value) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\-0-9]{2,}))$/;
  return re.test(value);
}

export function validateRequired(value) {
  return value.length > 0;
}

export function selectRequired(value) {
  return value !== undefined;
}

export function validatePassword(value) {
  // TODO: Fazer voltar a funcionar descomentanto o trecho abaixo.
  // const regex = /^(?!.*1234|.*qwe|.*asdf)[ -~]{8,63}$/;
  // return regex.test(value);
  return true;
}

export function testingPasswordSecurity(value) {
  const password = value.trim();

  const uppercaseRegExp = new RegExp('(?=.*?[A-Z])');
  const lowercaseRegExp = new RegExp('(?=.*?[a-z])');
  const digitsRegExp = new RegExp('(?=.*?[0-9])');
  const specialCharRegExp = new RegExp('(?=.*?[#?!@$%^&*-])');
  const minLengthRegExp = new RegExp('.{8,}');

  const uppercasePassword = uppercaseRegExp.test(password);
  const lowercasePassword = lowercaseRegExp.test(password);
  const digitsPassword = digitsRegExp.test(password);
  const specialCharPassword = specialCharRegExp.test(password);
  const minLengthPassword = minLengthRegExp.test(password);

  const isValid =
    uppercasePassword &&
    lowercasePassword &&
    digitsPassword &&
    specialCharPassword &&
    specialCharPassword &&
    minLengthPassword;

  return {
    isValid,
    passwordInvalidReason: {
      uppercasePassword,
      lowercasePassword,
      digitsPassword,
      specialCharPassword,
      minLengthPassword
    }
  };
}

export function validateDate(date) {
  const val = moment(date, moment.localeData()._longDateFormat.L).isValid();
  return val;
}

export function validatePasswordConfirmation(pass, confirmPass) {
  return pass === confirmPass;
}

export function validateCpf(cpf) {
  return validateBr.cpf(cpf);
}

export function validateCnpj(cnpj) {
  return validateBr.cnpj(cnpj);
}

// got from https://gist.github.com/bgrins/6194623
export function isDataURL(s) {
  return !!s.match(isDataURL.regex);
}

export function validateSocialNetworkUrl(state, prop, substr) {
  const propValue = state[prop];

  // campo pode ficar em branco
  if (propValue === '') return true;

  if (!propValue.includes(substr)) {
    return false;
  }

  return true;
}

export function validatePhoneNumberBR(number) {
  if (number.length < 13) return false;
  if (parsePhoneNumber(number)?.country !== 'BR') return false;

  let splitedNumber = number.split('-');
  let numberWihtoutAreaCode = `${splitedNumber.at(-2)}${splitedNumber.at(-1)}`;

  if (numberWihtoutAreaCode[0] !== '9') return false;

  if (numberWihtoutAreaCode.length === 9) {
    return isPossiblePhoneNumber(number) || isValidPhoneNumber(number);
  }

  return false;
}

export function convertDateFormatting(value) {
  const arrayDate = value.split('/');
  for (const key in arrayDate) {
    if (isNaN(Number(arrayDate[key]))) {
      return undefined;
    }
  }
  arrayDate.reverse();
  const newDate = new Date(+arrayDate[0], +arrayDate[1] - 1, +arrayDate[2]);
  return newDate;
};

export function validateBirthDate(birthDate, minimumAge = 5, maximumAge = 130) {
  const dateIsValid = validateDate(birthDate);
  if (!dateIsValid) return dateIsValid;
  if (Math.sign(minimumAge) !== 1 || Math.sign(maximumAge) !== 1) return;

  const yearOfBirth = convertDateFormatting(birthDate)?.getFullYear();
  const currentDate = new Date().getFullYear();
  const minimumDateAccepted = currentDate - minimumAge;
  const maximumDateAccepted = currentDate - maximumAge;

  // eslint-disable-next-line no-restricted-globals
  if (isNaN(yearOfBirth)) return false;

  // eslint-disable-next-line max-len
  if (yearOfBirth > currentDate || yearOfBirth > minimumDateAccepted || yearOfBirth < maximumDateAccepted) {
    return false;
  }

  return true;
}

export function validateExpirationDate(date, daysLimit = 30) {
  const dateIsValid = validateDate(date);
  if (!dateIsValid) return dateIsValid;
  if (Math.sign(daysLimit) !== 1) return;

  const expirationDate = convertDateFormatting(date);
  const currentDate = new Date();
  const minimumDateAccepted = new Date(new Date().setDate(currentDate.getDate() + 1));
  const maximumDateAccepted = new Date(new Date().setDate(currentDate.getDate() + daysLimit));

  if (isNaN(expirationDate)) return false;

  if (expirationDate < minimumDateAccepted.setHours(0,0,0,0) || expirationDate > maximumDateAccepted) {
    return false;
  }

  return true;
}

/**
 * Valida o formato da string de tempo no padrão "mm:ss".
 *
 * @param {string} value A string que representa o valor do tempo no formato "mm:ss".
 * @returns {boolean} Retorna true se o formato da string estiver incorreto, caso contrário, retorna false.
 */
export function validateTimeFormat(value) {
  const regex = /^([0-9]{2}):([0-9]{2})$/;
  return !regex.test(value);
}

/**
 * Valida o formato da string de tempo no padrão 'ss"cc'.
 *
 * @param {string} value A string que representa o valor do tempo no formato 'ss"cc'.
 * @returns {boolean} Retorna true se o formato da string estiver incorreto, caso contrário, retorna false.
 */
export function validateSecondsAndHundredthsSecondFormat(value) {
  const regex = /^([0-9]{2})"([0-9]{2})$/;
  return !regex.test(value);
}

/**
 * Valida o formato da string de data no padrão "dd/mm/aaaa".
 *
 * @param {string} value A string que representa o valor do tempo no formato "dd/mm/aaaa".
 * @returns {boolean} Retorna true se o formato da string estiver incorreto, caso contrário, retorna false.
 */
export function validateDateFormat(value) {
  const regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
  return !regex.test(value);
}

export function validateTimeInput(value) {
  if (validateTimeFormat(value)) return true;

  const { minutes, seconds } = extractHoursMinutesAndSeconds(value);

  if (minutes > 59 || seconds > 59) return true;
  
  return false;
}

export function isNotNumber(number) {
  return (typeof number !== 'number' || Number.isNaN(number))
}

export function validateDates(initialDate, endDate) {
  let validateInitialDate = validateDateFormat(initialDate);
  let validateEndDate = validateDateFormat(endDate);

  if (!validateInitialDate && !validateEndDate) {
    const formattedInitialDate = convertDateFormatting(initialDate);
    const formattedEndDate = convertDateFormatting(endDate);
    const invalidEndDate = formattedInitialDate.getTime() >= formattedEndDate.getTime();
    validateEndDate = invalidEndDate;
  }

  return { validateInitialDate, validateEndDate };
};

export function validateDatesRange(initialDate, endDate, initialDateLastAccess, endDateLastAccess) {
  const initialDateObj = new Date(initialDate).getTime();
  const endDateObj = new Date(endDate).getTime();
  const initialDateLastAccessObj = new Date(initialDateLastAccess).getTime();
  const endDateLastAccessObj = new Date(endDateLastAccess).getTime();

  const initialDateLastAccessIsValid = !initialDateLastAccessObj || initialDateLastAccessObj >= initialDateObj;
  const endDateLastAccessIsValid = !endDateLastAccessObj || endDateLastAccessObj <= endDateObj;

  return { initialDateLastAccessIsValid, endDateLastAccessIsValid };
}

export function validateDatesLastAccess(initialDateLastAccess, endDateLastAccess) {
  let validateInitialDateLastAccess = true;
  let validateEndDateLastAccess = true;

  if (initialDateLastAccess) {
    validateInitialDateLastAccess = validateDateFormat(initialDateLastAccess);
  }
  if (endDateLastAccess) {
    validateEndDateLastAccess = validateDateFormat(endDateLastAccess);
  }

  if (!validateInitialDateLastAccess || !validateEndDateLastAccess) {
    const formattedInitialDateLastAccess = convertDateFormatting(initialDateLastAccess);
    const formattedEndDateLastAccess = convertDateFormatting(endDateLastAccess);
    validateEndDateLastAccess = formattedEndDateLastAccess.getTime() < formattedInitialDateLastAccess.getTime();
  }

  return { validateInitialDateLastAccess, validateEndDateLastAccess };
};

export function inTestEnvironment() {
  return window.location.hostname === 'physicaltest.grupokrs.com.br';
}
