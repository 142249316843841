import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Card } from 'reactstrap';
import classNames from 'classnames';
import { PJ } from '../../constant/constants';

const BoxDialog = (props) => {
  var boxClass = classNames('box-dialog', props.className, {
    'box-dialog--danger': props.color === 'danger',
    'box-dialog--info': props.color === 'info',
  });
  return (
    <Card className={boxClass}>
      <h1 className="box-dialog__title">
        <FormattedMessage
          id={props.idTitleMain}
          defaultMessage={props.defaultMessageTitleMain}
        />
        <span className="box-dialog__name">{props.person ? props.person.name : props.name}</span>
        <>
          {props.accountType === PJ && (
            <span className="box-dialog__company">{props.person.nomefantasia ? props.person.nomefantasia : props.client.person.nomefantasia}</span>
          )}
        </>
      </h1>
      {props.firstAccess ? (
        <h3 className="box-dialog__subtitle">
          <FormattedMessage
            id={props.firstAccess}
            defaultMessage={props.defaultMessageFirstAccess}
          />
        </h3>
      ) : (
        <h3 className="box-dialog__subtitle">
          <FormattedMessage
            id={props.idSubtitle}
            defaultMessage={props.defaultMessageSubtitle}
          />
        </h3>
      )}
    </Card>
  );
};

export default BoxDialog;
