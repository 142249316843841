/*
  Cria referências a todos os arquivos desse diretório como se fossem IDS, para serem acessados
  ex:
  {
      "./en.json": 22,
      "./pt.json": 23
  }
*/
const req = require.context('./', true, /\.json.*$/);
const messages = {};

/* Função que gera um objeto de referencias das linguagens, para cada ARQUIVO da pasta */
req.keys().forEach((file) => {
  const locale = file.replace('./', '').replace('.json', '');
  messages[locale] = req(file);
  Object.assign(messages[locale], messages[`${locale}-help`]);
});

module.exports = messages;
