import { combineReducers } from 'redux-seamless-immutable';
import { intlReducer } from 'react-intl-redux';
import { routerReducer } from 'react-router-redux';
import { LOGOUT_USER } from '../constant/constants';
import Anamnesis from './anamnesis/reducer';
import ParQ from './parq/reducer';
import Authors from './authors/reducer';
import Canvas from './canvas/reducer';
import Classifications from './classifications/reducer';
import Clients from './clients/reducer';
import Comparison from './comparison/reducer';
import Ethnicity from './ethnicity/reducer';
import Evaluations from './evaluation/reducer';
import ExternalEvaluatorsRegister from './externalEvaluatorsRegister/reducer';
import Evaluators from './evaluator/reducer';
import Evaluted from './evaluated/reducer';
import Flash from './flashMessage/reducer';
import Formulas from './formulas/reducer';
import Genders from './genders/reducer';
import Goals from './goals/reducer';
import Listeners from './listeners/reducer';
import Login from './login/reducer';
import Maturation from './sexualMaturation/reducer';
import Measures from './measures/reducer';
import Methods from './methods/reducer';
import Modal from './modal/reducer';
import Navigation from './navigation/reducer';
import PaymentRecords from './paymentRecords/reducer';
import Person from './checkEmail/reducer';
import Plans from './plans/reducer';
import PublicKey from './login/public_key_reducer';
import TablesFatnessIdeal from './tablesFatnessIdeal/reducer';
import User from './user/reducer';
import UserManagement from './usersManagement/reducer';
import Variables from './variables/reducer';
import CardioVariables from './cardioVariables/reducer';
import NeuroProtocols from './neuroProtocols/reducer';
import Report from './report/reducer';
import BCChartsReport from './bcchart/reducer';
import Subscription from './subscription/reducer';
import Links from './promotionalLinks/reducer';
import Exercises from './exercise/reducer';
import Muscles from './muscle/reducer';
import MuscleGroups from './muscleGroups/reducer';
import ExerciseTypes from './exerciseTypes/reducer';
import PresetWorkouts from './presetWorkout/reducer';
import EvaluatedWorkouts from './evaluatedWorkout/reducer';
import Dashboard from './dashboard/reducer';
import FeatureViewed from './featureViewed/reducer';

const appReducer = combineReducers({
  data: combineReducers({
    anamnesisSet: Anamnesis,
    parQSet: ParQ,
    authorsSet: Authors,
    canvasSet: Canvas,
    classificationsSet: Classifications,
    clientsSet: Clients,
    comparisonSet: Comparison,
    ethnicitySet: Ethnicity,
    evaluatedSet: Evaluted,
    evaluationsSet: Evaluations,
    externalEvaluatorsRegisterSet: ExternalEvaluatorsRegister,
    evaluatorsSet: Evaluators,
    formulasSet: Formulas,
    gendersSet: Genders,
    goalsSet: Goals,
    maturationsSet: Maturation,
    measuresSet: Measures,
    methodsSet: Methods,
    paymentRecordsSet: PaymentRecords,
    clientPlanSet: Plans,
    promotionalLinksSet: Links,
    subscription: Subscription,
    tablesFatnessIdealSet: TablesFatnessIdeal,
    variablesSet: Variables,
    cardioVariablesSet: CardioVariables,
    neuroProtocolsSet: NeuroProtocols,
    exercisesSet: Exercises,
    exerciseTypesSet: ExerciseTypes,
    musclesSet: Muscles,
    muscleGroupsSet: MuscleGroups,
    presetWorkoutsSet: PresetWorkouts,
    evaluatedWorkoutsSet: EvaluatedWorkouts,
    dashboardSet: Dashboard
  }),
  flashMessage: Flash,
  featureViewed: FeatureViewed,
  intl: intlReducer,
  listeners: Listeners,
  navigation: Navigation,
  profile: Login,
  router: routerReducer,
  user: User,
  users: combineReducers({
    usersSet: UserManagement,
  }),
  util: combineReducers({
    checkEmail: Person,
    modalSet: Modal,
    publicKeySet: PublicKey,
    loadingReport: Report,
    bcChartReportSet: BCChartsReport,
  }),
});

export const injectReducer = (store, { key, reducer }) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;

  store.asyncReducers[key] = reducer;
  store.replaceReducer(appReducer(store.asyncReducers));
};

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
