export const MEASURE_TYPES_DEFAULT_MESSAGES = {
  general: 'Geral',
  diameter: 'Diâmetro',
  skinfold: 'Dobra Cutânea',
  perimeter: 'Perímetro',
  other: 'Outra',
};

export const MEASURE_DEFAULT_MESSAGES = {
  weight: 'Peso (kg)',
  height: 'Altura (cm)',
  subscapular: 'Subescapular',
  triceps: 'Tríceps',
  medialthigh: 'Coxa Medial',
  medialcalf: 'Panturrilha Medial',
  biceps: 'Bícipes',
  suprailiac: 'Supra Ilíaca',
  midaxillary: 'Axila Média',
  supraespinal: 'Supra Espinal',
  biestiloide: 'Biestiloide',
  bimalleolar: 'Bimaleolar',
  umeralbiepicondilo: 'Biepicôndilo Umeral',
  femoralbiepicondilo: 'Biepicôndilo Femoral',
  biacromial: 'Biacromial',
  anteroposteriorthorax: 'Tórax Antero Posterior',
  transversethorax: 'Tórax Transverso',
  iliaccrest: 'Crista Ilíaca',
  bitrocanterica: 'Bitrocanterica',
  neck: 'Pescoço',
  chest: 'Tórax',
  abdominal: 'Abdominal',
  waist: 'Cintura',
  hip: 'Quadril',
  rightarmrelaxed: 'Braço Direito Relaxado',
  leftarmrelaxed: 'Braço Esquerdo Relaxado',
  rightarmcontracted: 'Braço Direito Contraído',
  leftarmcontracted: 'Braço Esquerdo Contraído',
  leftforearm: 'Antebraço Esquerdo',
  rightforearm: 'Antebraço Direito',
  thigh: 'Coxa',
  leftthigh: 'Coxa Esquerda',
  rightthigh: 'Coxa Direita',
  leftleg: 'Perna Esquerda',
  rightleg: 'Perna Direita',
};

export const VARIABLES_DEFAULT_MESSAGES = {
  age: 'Idade',
};

export const CLASSIFICATION_DEFAULT_MESSAGES = {
  adult: 'Adulto',
  child: 'Criança',
  teenager: 'Adolescente',
  athlete: 'Atleta',
};

export const ETHNICITIES_DEFAULT_MESSAGES = {
  white: 'Branco',
  yellow: 'Amarelo',
  black: 'Negro',
  hispanic: 'Hispânico',
  japanese: 'Japonês',
};
export const GENDERS_DEFAULT_MESSAGES = {
  male: 'Masculino',
  female: 'Feminino',
};

export const METHODS_DEFAULT_MESSAGES = {};

export const MATURATION_DEFAULT_MESSAGES = {
  pubescent: 'Púbere',
  prepubescent: 'Pré Pubere',
  postpubescent: 'Pós Pubere',
};

export const GOALS_DEFAULT_MESSAGES = {
  physicalconditioning: 'Condicionamento Físico',
  health: 'Saúde',
  musclemassgain: 'Ganho de Massa Muscular',
  stressrelief: 'Alívio de Estresse',
  slimming: 'Emagrecimento',
  posturecorrection: 'Correção Postural',
  medicalrecommendation: 'Recomendação Médica',
  rehabilitation: 'Reabilitação',
  aesthetic: 'Estética',
  leisure: 'Lazer',
  increaseselfesteem: 'Aumentar Auto-Estima',
};

export const THEMES_DEFAULT_MESSAGES = {
  generic: 'Genérico',
};

export const FAT_CATEGORIES_DEFAULT_MESSAGES = {
  verythin: 'Muito Magro',
  thin: 'Magro',
  verygood: 'Muito Bom',
  healthy: 'Saudável',
  overweight: 'Sobrepeso',
  fat: 'Obeso',
  veryfat: 'Muito Obeso',
};
