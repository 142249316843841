import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';

const initialState = Immutable.from({
  links: [],
  linkSelected: {},
  loading: false,
  shouldGetLinks: true,
});

export default function links(state = initialState, action = {}) {
  switch(action.type) {
    case Actions.GET_PROMOTIONAL_LINK_PENDING:
      const loading = state.updateIn(['loading'], () => true);
      return loading;
    case Actions.GET_PROMOTIONAL_LINK_REJECTED:
      const rej = state.updateIn(['loading'], () => false);
      return rej;
    case Actions.ADD_REGISTER_PROMOTIONAL_LINK:
      const addLink = [...state.links, action.payload];
      const newState = Immutable.merge({
        ...state,
        links: addLink.sort((a, b) => b.id - a.id),
      });
      return newState;
    case Actions.SET_PROMOTIONAL_LINKS:
      const links = state
        .updateIn(['links'], () => action.payload.sort((a, b) => b.id - a.id))
        .updateIn(['loading'], () => false)
        .updateIn(['shouldGetLinks'], () => false);
      return links;
    case Actions.DELETE_REGISTER_PROMOTIONAL_LINK:
      const deleteRegister = state.links.filter(l => l.id !== action.payload);
      return state.updateIn(['links'], () => deleteRegister);
    case Actions.UPDATE_REGISTER_PROMOTIONAL_LINK:
      const linkId = action.payload.id;
      const updateLinks = state.links.map(l => l.id === linkId ? ({ ...action.payload }) : l);
      return state.updateIn(['links'], () => updateLinks);
    default:
      return state;
  }
}
