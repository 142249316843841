import React from 'react';

const IconFatPercentageIdeal = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon-fat-percentage"
    viewBox="-223 25 512 512"
    width={props.width}
    height={props.height}
  >
    <circle id="Camada_2" cx="34" cy="281" r="237" className="st0" />
    <g id="Layer_1">
      <path
        d="M232.3 383.2L-59.8 91.1c-2.6-2.6-6.8-2.6-9.4 0l-49.9 49.9c-2.6 2.6-2.6 6.8 0 9.4L173 442.5c2.6 2.6 6.8 2.6 9.4 0l49.9-49.9c2.6-2.6 2.6-6.8 0-9.4z"
        className="st1"
      />
      <path
        d="M-112 133.9l-7.1 7.1c-2.6 2.6-2.6 6.8 0 9.4L173 442.5c2.6 2.6 6.8 2.6 9.4 0l7.1-7.1L-112 133.9z"
        className="st2"
      />
      <path
        d="M-13.2 111.9l-33.7-33.7c-2.6-2.6-6.8-2.6-9.4 0l-97.4 97.4c-.8.8-1.3 1.7-1.6 2.7l-31.2 98.6c-1 3 .4 6.4 3.2 7.8 2.5 1.4 5.8.9 7.8-1.2l162.3-162.3c2.6-2.6 2.6-6.8 0-9.3z"
        className="st3"
      />
      <path
        d="M-145 188.5c.3-1 .9-1.9 1.6-2.7l102.2-102-5.6-5.6c-2.6-2.6-6.8-2.6-9.4 0l-97.4 97.4c-.8.8-1.3 1.7-1.6 2.7l-31.2 98.6c-1 3 .4 6.4 3.2 7.8 2.5 1.4 5.8.9 7.8-1.2l.7-.7 29.7-94.3z"
        className="st4"
      />
      <path
        d="M121.7 246.7L88 213c-2.6-2.6-6.8-2.6-9.4 0L-83.7 375.3c-2 2-2.5 5.3-1.2 7.8 1.5 2.8 4.8 4.1 7.8 3.2l98.6-31.2c1-.3 1.9-.9 2.7-1.6l97.4-97.4c2.7-2.6 2.7-6.8.1-9.4z"
        className="st3"
      />
      <path
        d="M121.7 246.7l-10.9-10.9c2.6 2.6 2.6 6.8 0 9.4l-97.4 97.4c-.8.8-1.7 1.3-2.7 1.6l-92.5 29.3-1.9 1.9c-2 2-2.5 5.3-1.2 7.8 1.5 2.8 4.8 4.1 7.8 3.2l98.6-31.2c1-.3 1.9-.9 2.7-1.6l97.4-97.4c2.7-2.7 2.7-6.9.1-9.5z"
        className="st4"
      />
      <path
        d="M188 309l-99.9-99.9c-7.7-7.7-20.3-7.7-28.1 0l-61.2 61.2c-7.7 7.7-7.7 20.3 0 28.1l99.9 99.9c7.7 7.7 20.3 7.7 28.1 0l61.2-61.2c7.8-7.8 7.8-20.4 0-28.1z"
        className="st5"
      />
      <path
        d="M163.2 320.1L77 233.9c-2.6-2.6-6.8-2.6-9.4 0l-44 44c-2.6 2.6-2.6 6.8 0 9.4l86.2 86.2c2.6 2.6 6.8 2.6 9.4 0l44-44c2.6-2.6 2.6-6.8 0-9.4z"
        className="st6"
      />
      <path
        d="M42.4 277.9l25.3-25.3c2.6-2.6 6.8-2.6 9.4 0l67.4 67.4c2.6 2.6 2.6 6.8 0 9.4l-25.3 25.3c-2.6 2.6-6.8 2.6-9.4 0l-67.4-67.4c-2.6-2.6-2.6-6.8 0-9.4z"
        className="st7"
      />
      <path
        d="M-33.1 181.8c-2.6-2.6-6.8-2.6-9.4 0l-22.6 22.6 9.4 9.4 22.6-22.6c2.6-2.6 2.6-6.8 0-9.4zm19.9 20c-2.6-2.6-6.8-2.6-9.4 0l-22.6 22.6 9.4 9.4 22.6-22.6c2.6-2.7 2.6-6.9 0-9.4zm20 19.9c-2.6-2.6-6.8-2.6-9.4 0l-22.6 22.6 9.4 9.4 22.6-22.6c2.6-2.6 2.6-6.8 0-9.4z"
        className="st5"
      />
    </g>
  </svg>
);

IconFatPercentageIdeal.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconFatPercentageIdeal;
