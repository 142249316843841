import * as Actions from '../../constant/constants';
import * as StateTypes from '../../constant/stateTypes';

const initialState = {
  user: StateTypes.Login.NOT_USER,
  accountType: "",
  isAuthenticated: StateTypes.Login.NOT_AUTHENTICATED,
  isAuthenticating: StateTypes.Login.NOT_AUTHENTICATING,
  error: StateTypes.Login.NOT_ERROR,
  emailRetrieve: null,
  responseForgotPassword: StateTypes.Login.NOT_RESPONSE_FORGOT_PASSWORD,
  changePass: false,
  plan: {},
  loadingPlan: true,
  planModuleIsEnabled: true,
  freeTrialPeriod: false,
  shouldGetLocale: true,
  createAccount: false,
  newRegisterSuccess: false,
  userregistered: false,
  newExternalEvaluatorRegisteredSuccess: false,
  unauthorizedReason: '',
};

export default function login(state = initialState, action) {
  switch (action.type) {
    case Actions.LOGIN_USER_REQUEST:
      return {
        ...state,
        isAuthenticating: StateTypes.Login.AUTHENTICATING,
        error: StateTypes.Login.NOT_ERROR,
      };
    case Actions.VALIDATE_TOKEN_REQUEST:
      return {
        ...state,
        isAuthenticating: StateTypes.Login.AUTHENTICATING,
        error: StateTypes.Login.NOT_ERROR,
      };
    case Actions.LOGIN_USER_FAILURE:
      return {
        ...state,
        unauthorizedReason: 'INVALID_CREDENTIALS',
        isAuthenticating: StateTypes.Login.NOT_AUTHENTICATING,
        isAuthenticated: StateTypes.Login.NOT_AUTHENTICATED,
        user: StateTypes.Login.NOT_USER,
        error: StateTypes.Login.ERROR,
      };
    case Actions.RESPONSE_CODES_UNAUTHORIZED:
      return {
        ...state,
        unauthorizedReason: action.payload.unauthorizedReason,
        username: action.payload.user,
        password: action.payload.pass,
        isAuthenticating: StateTypes.Login.NOT_AUTHENTICATING,
        error: StateTypes.Login.ERROR,
        check: action.payload.connected,
        isAuthenticated: StateTypes.Login.NOT_AUTHENTICATED,
      };
    case Actions.USER_HAS_ALREADY_BEEN_REGISTERED:
      return {
        ...state,
        userregistered: true,
      };
    case Actions.LOGIN_FULFILLED:
      if (typeof action.payload === 'undefined') {
        return state;
      }

      const isSysAdmin = action.payload.permission.sysadmin

      return {
        ...state,
        isAuthenticating: StateTypes.Login.NOT_AUTHENTICATING,
        isAuthenticated: StateTypes.Login.AUTHENTICATED,
        user: action.payload,
        accountType: isSysAdmin ? "SYSADMIN" : Actions.CLIENT_TYPES[action.payload.permission.client.tipoCliente],
        error: StateTypes.Login.NOT_ERROR,
      };
     
    case Actions.LOGOUT_USER:
      return {
        ...state,
        isAuthenticated: StateTypes.Login.NOT_AUTHENTICATED,
        user: StateTypes.Login.NOT_USER,
        error: StateTypes.Login.NOT_ERROR,
      };
    case Actions.LOGOUT_REJECTED:
      return {
        ...state,
        isAuthenticated: StateTypes.Login.NOT_AUTHENTICATED,
        user: StateTypes.Login.NOT_USER,
        error: StateTypes.Login.NOT_ERROR,
      };
    case Actions.UPDATE_PROFILE:
      return Object.assign({}, state, {
        isAuthenticating: StateTypes.Login.NOT_AUTHENTICATING,
        isAuthenticated: StateTypes.Login.AUTHENTICATED,
        user: {
          ...state.user,
          person: action.payload,
        },
        error: StateTypes.Login.NOT_ERROR,
      });
    case Actions.UPDATE_USER_LOCALE:
      return Object.assign({}, state, {
        isAuthenticating: StateTypes.Login.NOT_AUTHENTICATING,
        isAuthenticated: StateTypes.Login.AUTHENTICATED,
        user: {
          ...state.user,
          person: action.payload,
        },
        error: StateTypes.Login.NOT_ERROR,
        shouldGetLocale: false,
      });
    case Actions.RESPONSE_EMAIL_FORGOT_PASSWORD:
      return Object.assign({}, state, {
        user: StateTypes.Login.NOT_USER,
        isAuthenticated: StateTypes.Login.NOT_AUTHENTICATED,
        isAuthenticating: StateTypes.Login.NOT_AUTHENTICATING,
        error: StateTypes.Login.NOT_ERROR,
        emailRetrieve: action.payload,
      });
    case Actions.ACCESS_NEW_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        user: StateTypes.Login.NOT_USER,
        isAuthenticated: StateTypes.Login.NOT_AUTHENTICATED,
        isAuthenticating: StateTypes.Login.NOT_AUTHENTICATING,
        error: StateTypes.Login.NOT_ERROR,
        responseForgotPassword: action.payload,
      });
    case Actions.ACCESS_NEW_PASSWORD_REJECTED:
      return Object.assign({}, state, {
        user: StateTypes.Login.NOT_USER,
        isAuthenticated: StateTypes.Login.NOT_AUTHENTICATED,
        isAuthenticating: StateTypes.Login.NOT_AUTHENTICATING,
        error: StateTypes.Login.NOT_ERROR,
        responseForgotPassword: action.payload,
      });
    case Actions.CHANGE_PASS_FULFILLED:
      return Object.assign({}, state, {
        // user: StateTypes.Login.NOT_USER,
        // isAuthenticated: StateTypes.Login.NOT_AUTHENTICATED,
        isAuthenticating: StateTypes.Login.NOT_AUTHENTICATING,
        responseForgotPassword: true,
        changePass: true,
      });
    case Actions.UPDATE_NOTIFICATION_REQUEST_FULFILLED:
      return Object.assign({}, state, {
        isAuthenticating: StateTypes.Login.NOT_AUTHENTICATING,
        isAuthenticated: StateTypes.Login.AUTHENTICATED,
        user: {
          ...state.user,
          notifications: action.payload,
        },
        error: StateTypes.Login.NOT_ERROR,
      });
    case Actions.UPDATE_NOTIFICATION_FULFILLED:
      return Object.assign({}, state, {
        isAuthenticating: StateTypes.Login.NOT_AUTHENTICATING,
        isAuthenticated: StateTypes.Login.AUTHENTICATED,
        user: {
          ...state.user,
          notifications: action.payload,
        },
        error: StateTypes.Login.NOT_ERROR,
      });
    case Actions.NEW_ACCOUNT_REQUEST:
      return {
        ...state,
        userregistered: false,
        createAccount: action.payload,
      };
    case Actions.NEW_REGISTER_SUCCESS:
      return {
        ...state,
        newRegisterSuccess: action.payload,
      };
    case Actions.EXTERNAL_EVALUATOR_REGISTERED_SUCCESS:
      return {
        ...state,
        newExternalEvaluatorRegisteredSuccess: true,
      };
    case Actions.GET_CLIENT_PLAN_REQUEST_FULFILLED:
      return {
        ...state,
        plan: action.payload,
        loadingPlan: false,
      };
    case Actions.ACCESS_CONTROL_PLANS_MODULE:
      return state.updateIn(['planModuleIsEnabled'], () => action.payload);
    case Actions.CREATE_CLIENT_PLAN_FULFILLED:
      const newSubscriber = state
      .updateIn(['user', 'permission', 'client', 'fase_cadastro'], () => action.payload)
      .updateIn(['user', 'permission', 'voucher'], () => null)
      return newSubscriber;
    case Actions.CANCEL_CLIENT_PLAN:
      return {
        ...state,
        plan: {
          ...state.plan,
          status: action.payload,
        },
      };
    case Actions.UPDATE_BILLING_ADDRESS:
      const newBillingAddress = state
      .updateIn(['plan', 'cliente', 'enderecoFaturamento'], () => action.payload)
      return newBillingAddress;
    case Actions.ENABLE_FREE_TRIAL_PERIOD:
      return state.updateIn(['freeTrialPeriod'], () => action.payload);
    default:
      return state;
  }
}
