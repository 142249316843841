import React from 'react';

const IconRegistrations = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon-registrations"
    viewBox="-223 25 512 512"
    width={props.width}
    height={props.height}
  >
    <path
      d="M49.6 339l-41-17.9h-136.7l-41 17.9c-17.2 7.5-28.1 24.9-27.3 43.7l4.2 98.5c.3 8.1 7 14.5 15.2 14.5H57.6c8.1 0 14.8-6.4 15.2-14.5l4.2-98.5c.7-18.8-10.1-36.2-27.4-43.7z"
      className="st0"
    />
    <path d="M-97.7 268h75.9v75.9h-75.9z" className="st1" />
    <path
      d="M-97.7 290c11.6 5.3 24.5 8.3 38 8.3s26.3-3 38-8.3v-22.1h-75.9l-.1 22.1z"
      className="st2"
    />
    <circle cx="-59.7" cy="70.6" r="45.6" className="st3" />
    <ellipse cx="-59.7" cy="150.3" className="st4" rx="91.1" ry="87.3" />
    <path
      d="M-90.1 161.7c-30.4-30.4-34.9-56.6-34.2-73-16.4 15.8-26.5 37.6-26.5 61.6 0 16.5 4.8 31.8 13.1 45l47.6-33.6z"
      className="st5"
    />
    <path
      d="M26.9 177.2c-.6-.2-4.4-.3-4.4-.3-4 0-7.9-1.6-10.7-4.4l-6.6-6.6c-2.6-2.6-6.1-4.2-9.8-4.4-14.8-.8-55.8-4.5-88.3-20.5-3.5-1.7-7.7-.8-9.8 2.5-8.5 13.4-19.1 23.9-25 29.4-2.8 2.6-6.5 4-10.3 4h-5.5c-.8 0-1.7.1-2.5.3-7.4 1.4-12.4 8.5-12.4 16v5.5c0 7.3 4.6 14.1 11.7 15.8 1.6.4 3.1.5 4.6.4 3.8-.3 7.1 2.4 7.8 6.2 6.5 35.4 37.4 62.2 74.6 62.2s68.1-26.8 74.6-62.2c.7-3.7 4-6.5 7.8-6.2 1.5.1 3 0 4.6-.4 7.1-1.7 11.7-8.5 11.7-15.8v-6.9c0-7.2-5.3-12.4-12.1-14.6z"
      className="st6"
    />
    <path
      d="M-105.3 207.2v-60c-8 11.6-17.1 20.7-22.5 25.6-2.8 2.6-6.5 4-10.3 4h-5.5c-.8 0-1.7.1-2.5.3-7.4 1.4-12.4 8.5-12.4 16v5.5c0 7.3 4.7 14.1 11.7 15.8 1.6.4 3.1.5 4.6.4 3.8-.3 7.1 2.4 7.8 6.2 6.5 35.4 37.4 62.2 74.6 62.2 5.2 0 10.2-.6 15.1-1.5-34.5-7.2-60.6-37.8-60.6-74.5z"
      className="st1"
    />
    <path
      d="M-155.5 382.2l-27.7-33.3c-8.6 8.8-13.6 20.9-13 33.7l4.2 98.5c.3 8.1 7 14.5 15.2 14.5h33.8v-79.5c-.2-12.3-4.6-24.3-12.5-33.9zm146.7-78.5c-3-3-7.8-3-10.7 0l-36.7 36.7c-1.9 1.9-5 1.9-7 0l-36.7-36.7c-3-3-7.8-3-10.7 0l-17.4 17.4 28.1 45.6c2.4 4 7.8 4.8 11.4 1.8l16.8-14.4c1.7-1.5 4.4-.2 4.4 2v139.6h15.2V356.2c0-2.3 2.7-3.5 4.4-2l16.8 14.4c3.5 3 8.9 2.2 11.4-1.8l28.1-45.6-17.4-17.5z"
      className="st7"
    />
    <path
      d="M146.7 322.3H90.9c-6.6 0-12.9 2.6-17.5 7.3l-25.8 25.8h99.1v-33.1z"
      className="st8"
    />
    <path
      d="M196.3 322.3h-55.8c-6.6 0-12.9 2.6-17.5 7.3l-25.8 25.8h99.1v-33.1z"
      className="st9"
    />
    <path
      d="M172.5 329.5l-20.9 20.9c-3.1 3.1-7.3 4.8-11.7 4.8H39.4c-13.7 0-24.8 11.1-24.8 24.8v132.1c0 13.7 11.1 24.8 24.8 24.8h198.2c13.7 0 24.8-11.1 24.8-24.8v-165c0-13.7-11.1-24.8-24.8-24.8H190c-6.5 0-12.8 2.6-17.5 7.2z"
      className="st10"
    />
    <path
      d="M122 388.4H55.9c-4.6 0-8.3 3.7-8.3 8.3v16.5c0 4.6 3.7 8.3 8.3 8.3H122c4.6 0 8.3-3.7 8.3-8.3v-16.5c-.1-4.6-3.8-8.3-8.3-8.3z"
      className="st11"
    />
  </svg>
);

IconRegistrations.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconRegistrations;
