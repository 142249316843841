import React from 'react';

const IconProgress = (props) => (
  <svg
    className="icon-progress"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-227 28 505 505"
    width={props.width}
    height={props.height}
  >
    <circle className="st0" cx="25.5" cy="280.5" r="252.5" />
    <path
      className="st1"
      d="M-130.9 147.1h28.7v15.8h-28.7zm0 55.6h28.7v15.8h-28.7zm0 55.5h28.7V274h-28.7zm0 55.6h28.7v15.8h-28.7zm0 55.6h28.7v15.8h-28.7zM142.9 408h15.8v28.7h-15.8zm-55.6 0h15.8v28.7H87.3zm-55.6 0h15.8v28.7H31.7zm-55.6 0h15.8v28.7h-15.8zm-55.6 0h15.8v28.7h-15.8z"
    />
    <path
      className="st2"
      d="M-43 181.8l69.5 164.4 123.9-100.1 31.5 20.1v14.1l-30.7-19.7L21.7 365.2l-75.6-178.8z"
    />
    <circle className="st3" cx="150.8" cy="253.4" r="23.1" />
    <circle className="st3" cx="24.1" cy="355.7" r="23.1" />
    <path className="st2" d="M-43.516 187.385l-53.74 79.667-9.782-6.6 53.74-79.666z" />
    <circle className="st3" cx="-48.5" cy="184.1" r="23.1" />
    <path
      className="st4"
      d="M-52.8 304.8l90.3-139.1 84.3 188.6 60.1-11.9v12l-67.1 13.3-79.2-177.3-79.7 122.7-54.6 39.9-7-9.5z"
    />
    <circle className="st5" cx="118.3" cy="361" r="23.1" />
    <circle className="st5" cx="36.5" cy="178.1" r="23.1" />
    <circle className="st5" cx="-48.5" cy="308.9" r="23.1" />
    <path className="st1" d="M-110.1 415.8h291.5v-15.7H-94.3V124.3h-15.8z" />
  </svg>
);

IconProgress.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconProgress;
