import React, { Component } from 'react';
import { Row, Col, Card, FormGroup, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

class ReportBugForm extends Component {
  render() {
    return (
      <Card body className="card-internal">
        <Row>
          <Col md={12}>
            <p>Entre em contato com nossa equipe de suporte. Através desse formulário, você pode deixar sua dúvida, sugestão ou reclamação.</p>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="name">
                <FormattedMessage id="app.global.name" defaultMessage="Nome" />
                <span className="text-danger">*</span>
              </Label>
              <input
                id="name"
                name="name"
                className="form-control"
                value={this.props.name}
                onChange={this.props.change}
              />
              {this.props.validateName ? (
                <p className="text-danger">
                  <FormattedMessage
                    id="message.error.required.name"
                    defaultMessage="*preencha o campo nome"
                  />
                </p>
              ) : (
                ''
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="email">
                <FormattedMessage id="app.global.email" defaultMessage="Email" />
                <span className="text-danger">*</span>
              </Label>
              <input
                id="email"
                name="email"
                className="form-control"
                value={this.props.email}
                onChange={this.props.change}
              />
              {this.props.validateEmail ? (
                <p className="text-danger">
                  <FormattedMessage
                    id="message.error.invalid.email"
                    defaultMessage="digite um email válido"
                  />
                </p>
              ) : (
                ''
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label for="section">
                <FormattedMessage
                  id="app.global.app.bugreport.subject"
                  defaultMessage="Assunto"
                />
              </Label>
              <input
                id="section"
                name="section"
                className="form-control"
                value={this.props.section}
                onChange={this.props.change}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Label>
              <FormattedMessage
                id="app.bugreport.description"
                defaultMessage="Descrição do problema"
              />
              <span className="text-danger">*</span>
            </Label>
            <FormGroup>
              <textarea
                rows="4"
                name="description"
                id="description"
                className="form-control"
                value={this.props.description}
                onChange={this.props.change}
              ></textarea>
              {this.props.validateDescription ? (
                <p className="text-danger">
                  <FormattedMessage
                    id="message.error.required.description"
                    defaultMessage="*preencha o campo descrição"
                  />
                </p>
              ) : (
                ''
              )}
            </FormGroup>
          </Col>
        </Row>
      </Card>
    );
  }
}
export default ReportBugForm;
