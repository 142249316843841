import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';

const initialState = Immutable.from({
  shouldGetClients: true,
  clients: [],
  clientSelected: {},
  loading: false,
});

export default function clients(state = initialState, action = {}) {
   const clientsList = Immutable.asMutable(state.clients, { deep: true });

  switch (action.type) {
    case Actions.GET_CLIENT_SAFE_INFO_PENDING:
      const loadingClientSafeInfo = state.updateIn(['loading'], () => true);
      return loadingClientSafeInfo;
    case Actions.GET_CLIENT_SAFE_INFO_REJECTED:
      const rejClientSafeInfo = state.updateIn(['loading'], () => false);
      return rejClientSafeInfo;
    case Actions.GET_CLIENT_SAFE_INFO:
      const clientSafeInfo = state
        .updateIn(['clientSelected'], () => action.payload)
        .updateIn(['loading'], () => false);
      return clientSafeInfo;
    case Actions.GET_CLIENTS_PENDING:
      const loadingClients = state.updateIn(['loading'], () => true);
      return loadingClients;
    case Actions.GET_CLIENTS_REJECTED:
      const rejClients = state.updateIn(['loading'], () => false);
      return rejClients;
    case Actions.GET_CLIENTS:
      const clients = state
        .updateIn(['clients'], () => action.payload)
        .updateIn(['loading'], () => false)
        .updateIn(['shouldGetClients'], () => false);
      return clients;
    case Actions.ADD_REGISTER_CLIENT:
      const newState = Immutable.merge({
        ...state,
        clients: [...state.clients, action.payload],
      });
      return newState;
    case Actions.REGISTER_SELECTED_CLIENT:
      const clientSelected = state.set('clientSelected', action.payload);
      return clientSelected;
    case Actions.UPDATE_REGISTER_CLIENT:
      const clientIndex = state.clients.findIndex(
        (client) => client.id === action.payload.id,
      );
      const newStateClient = state
        .updateIn(['clients', clientIndex], () => action.payload)
        .set('clientSelected', action.payload);
      return newStateClient;
    case Actions.DELETE_REGISTER_CLIENT:
      const index = state.clients.findIndex((client) => client.id === action.payload.id);
      const deleteClient = state.update('clients', (clien) =>
        clien.slice(0, index).concat(clien.slice(index + 1)),
      );
      return deleteClient;
    case Actions.SET_LOGO_REQUEST:
      const setLoadingLogo = state.set('loading', true);
      return setLoadingLogo;
    case Actions.SET_LOGO:
      const changedLogo = state
        .updateIn(['clientSelected', 'logo'], () => action.payload.logo)
        .set('loading', false);
      return changedLogo;
    case Actions.SET_LOGO_REJECTED:
      const logoFail = state.set('loading', false);
      return logoFail;
    case Actions.BLOCK_CLIENT:
      const { id } = action.payload.client;
      const clientBlockedIndex = clientsList.findIndex((client) => client.id === id);
      clientsList[clientBlockedIndex].permissions.blocked = true;
      const newClientList = state.updateIn(['clients'], () =>
        Immutable.from(clientsList),
      );
      return newClientList;
    case Actions.APPROVE_OR_REJECT_CLIENT:
      const approvalClientIndex = clientsList.findIndex((cli) => cli.id === action.payload.id);

      const processedClient = state.updateIn(['clients', approvalClientIndex], () =>
        action.payload
      );
      return processedClient;
    default:
      return state;
  }
}
