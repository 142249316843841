import React, { Component } from 'react';

// Redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Actions
import { showMessage } from '../../store/flashMessage/action';
import {
  addFeatureViewed,
  setNewFeatures,
  setFeaturesViewedByPerson,
} from '../../store/featureViewed/action';

// Components
import Icon from 'react-fa';
import ModalDialog from './ModalDialog';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

// Utils
import moment from 'moment';
import {
  filtersFeatureByProfileType,
  getProfilePermissionLevel,
  checkNewFeatures
} from '../../utils/functions';

import newFeaturesListJSON from '../../release.json';

class ModalFeaturePreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      activeTab: 0,
      selectedResource: {},
      releasesViewed: [],
      newFeaturesList: [],
    };
    this.setActiveTab = this.setActiveTab.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
  }

  componentDidMount() {
    const { user } = this.props.profile;
    const profilePermission = getProfilePermissionLevel(user.permission, user.person);

    const newFeaturesList = filtersFeatureByProfileType(
      newFeaturesListJSON,
      profilePermission.rule,
    );

    let releasesViewed =
      Array.isArray(newFeaturesList) && this.props.shouldGetFeatureViewed
        ? newFeaturesList.map((f) => f.version)
        : [];

    if (Object.keys(this.props.featureViewed).length !== 0) {
      releasesViewed = Array.isArray(this.props.featureViewed)
        ? this.props.featureViewed.map((item) => item.version)
        : [];
    }

    this.setState({ newFeaturesList, releasesViewed });
  }

  componentDidUpdate(prevState) {
    if (prevState.featureViewed !== this.props.featureViewed) {
      const releasesViewed = Array.isArray(this.props.featureViewed)
        ? this.props.featureViewed.map((item) => item.version)
        : [];
      this.setState({ releasesViewed });
    }
  }

  setActiveTab(step) {
    this.setState({ activeTab: step });
  }

  handleChangeTab(step = null, item = {}) {
    if (step !== null) {
      this.setActiveTab(step);
    }

    if (item.version && !this.state.releasesViewed.includes(item.version)) {
      this.setState(
        {
          releasesViewed: [...this.state.releasesViewed, item.version],
        },
        () => {
          this.props.addFeatureViewed(this.props.personId, item.version);
          this.props.setFeaturesViewedByPerson({
            data: [...this.props.featureViewed, { version: item.version }],
          });

          if (
            this.props.hasNewFeatures &&
            !checkNewFeatures(this.state.newFeaturesList, this.state.releasesViewed)
          ) {
            this.props.setNewFeatures(false);
          }
        },
      );
    }
  }

  render() {
    return (
      <ModalDialog
        size="xl"
        isCentered
        backdrop="static"
        className="modal-feature-preview"
        closeModal={this.props.closeModalFeaturePreview}
        toggleModal={this.props.closeModalFeaturePreview}
        title={
          <FormattedMessage
            id="app.newversion.header"
            defaultMessage="Novidades do sistema"
          />
        }
      >
        <Row>
          {this.state.newFeaturesList.length > 0 ? (
            <>
              <Col className="col-4 border-right d-none d-sm-block">
                <Nav
                  role="tablist"
                  id="v-pills-tab"
                  aria-orientation="vertical"
                  className="flex-column nav-pills me-3"
                >
                  {this.state.newFeaturesList.map((item, i) => {
                    const itemViewed = this.state.releasesViewed.includes(item.version);
                    return (
                      <NavItem key={i} className="border-bottom cursor-pointer">
                        <NavLink
                          onClick={() => this.handleChangeTab(i, item)}
                          className={`${
                            this.state.activeTab === i
                              ? 'bg-primary text-white font-weight-bold border-success'
                              : 'text-gray-900'
                          }`}
                        >
                          {item.releaseDate &&
                            (itemViewed ? (
                              <Icon name="eye-slash" className="mr-2" />
                            ) : (
                              <Icon name="eye" className="mr-2" />
                            ))}
                          {item.releaseDate ? (
                            <>
                              <FormattedMessage
                                id="app.global.release"
                                defaultMessage="Lançamento"
                              />{' '}
                              {moment(item.releaseDate).format('DD/MM/YYYY')}
                              {!itemViewed && (
                                <span className="badge badge-pill badge-primary">
                                  <FormattedMessage
                                    id="app.global.new"
                                    defaultMessage="Novo"
                                  />
                                </span>
                              )}
                            </>
                          ) : (
                            <FormattedMessage
                              id="app.global.presentation"
                              defaultMessage="Apresentação"
                            />
                          )}
                        </NavLink>
                      </NavItem>
                    );
                  })}
                </Nav>
              </Col>

              <Col className="col-8 d-none d-sm-block">
                <TabContent
                  activeTab={this.state.activeTab}
                  className="pt-3 pr-3 pb-3 pl-0 mr-0 overflow-y-auto markdown-body"
                >
                  {this.state.newFeaturesList.map((item, i) => (
                    <TabPane key={i} tabId={i}>
                      {item.changes.map((c, ic) => (
                        <React.Fragment key={ic}>
                          {c.title && (
                            <h5 className="mb-3 font-weight-bold">{c.title}</h5>
                          )}
                          {Array.isArray(c.description) ? (
                            c.description.map((d, i) => (
                              <div key={i} dangerouslySetInnerHTML={{ __html: d }} />
                            ))
                          ) : (
                            <div dangerouslySetInnerHTML={{ __html: c.description }} />
                          )}
                        </React.Fragment>
                      ))}
                      {item.version && (
                        <p className="text-center small">v {item.version}</p>
                      )}
                    </TabPane>
                  ))}
                </TabContent>
              </Col>

              <Col className="d-sm-none">
                {this.state.newFeaturesList.map((item, i) => {
                  const itemViewed = this.state.releasesViewed.includes(item.version);
                  return (
                    <details
                      key={i}
                      className={`accordion-features ${i === 0 ? 'pt-2' : ''} pr-2 pl-2`}
                    >
                      <summary
                        onClick={() => this.handleChangeTab(null, item)}
                        className={`accordion-features-title border-left border-right border-bottom ${
                          i === 0 ? 'rounded-top border-top' : ''
                        } ${
                          i + 1 === this.state.newFeaturesList.length
                            ? 'rounded-bottom'
                            : ''
                        }`}
                      >
                        {item.releaseDate ? (
                          <>
                            <FormattedMessage
                              id="app.global.release"
                              defaultMessage="Lançamento"
                            />{' '}
                            {moment(item.releaseDate).format('DD/MM/YYYY')}
                            {!itemViewed && (
                              <span className="badge badge-pill badge-primary">
                                <FormattedMessage
                                  id="app.global.new"
                                  defaultMessage="Novo"
                                />
                              </span>
                            )}
                          </>
                        ) : (
                          <FormattedMessage
                            id="app.global.presentation"
                            defaultMessage="Apresentação"
                          />
                        )}
                        <Icon name="chevron-up" className="accordion-features-icon" />
                      </summary>
                      <div className="accordion-features-body">
                        <div className="border border-top-0 p-2">
                          {item.changes.map((c, i) => (
                            <React.Fragment key={i}>
                              {c.title && (
                                <h5 className="mb-3 font-weight-bold">{c.title}</h5>
                              )}
                              {Array.isArray(c.description) ? (
                                c.description.map((d, i) => (
                                  <div key={i} dangerouslySetInnerHTML={{ __html: d }} />
                                ))
                              ) : (
                                <div
                                  dangerouslySetInnerHTML={{ __html: c.description }}
                                />
                              )}
                            </React.Fragment>
                          ))}
                          {item.version && (
                            <p className="text-center small">v {item.version}</p>
                          )}
                        </div>
                      </div>
                    </details>
                  );
                })}
              </Col>
            </>
          ) : (
            <Col className="text-center">
              <p className="m-4">Sem novas funcionalidades para explorar por enquanto.</p>
            </Col>
          )}
        </Row>
      </ModalDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
    personId: state.profile.user.person.id,
    featureViewed: state.featureViewed.featureViewed,
    hasNewFeatures: state.featureViewed.hasNewFeatures,
    shouldGetFeatureViewed: state.featureViewed.shouldGetFeatureViewed,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      showMessage,
      setNewFeatures,
      addFeatureViewed,
      setFeaturesViewedByPerson,
    },
    dispatch,
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ModalFeaturePreview));
