import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getKey , validateToken } from '../store/login/action';
import { navigationGrandParent } from '../store/navigation/action';
import FormLogin from './FormLogin';
import Footer from '../components/Footer/Footer';
import Loading from '../components/Loading';
import { openModalAction } from '../store/modal/action';
import { getQueryString } from '../utils/functions';
import { UncontrolledCarousel } from 'reactstrap';

var today = new Date();

class Login extends Component {
  componentDidMount() {
    this.props.getKey();
    this.props.navigationGrandParent();
  }

  render() {
    const redirectToSubscription = getQueryString('subscription') === "true" ? true : false;

    if (this.props.profile.isAuthenticating) {
      return <Loading />;
    }
    if (this.props.profile.isAuthenticated) {
      const nextRoute = getQueryString('redirectUrl') || '/';
      this.props.history.push(nextRoute);
      return <Loading />;
    }
    if (window.localStorage.token !== undefined) {
      this.props.validateToken(window.localStorage.token, '/');
      return <Loading />;
    }
    return (
      <div className="App">
        <div className="body-login">
          <div className="box-carousel">
            <UncontrolledCarousel
              slide={false}
              fade={true}
              timeout={10}
              keyboard={false}
              controls={false}
              indicators={false}
              items={[
                {
                  key: 1,
                  altText: 'Imagem de uma corredora',
                  src: require("../images/bg-login-01.jpg")
                },
                {
                  key: 2,
                  altText: 'Imagem de um médico',
                  src: require("../images/bg-login-02.jpg")
                },
                {
                  key: 3,
                  altText: 'Imagem de uma pessoa com uma fita métrica',
                  src: require("../images/bg-login-03.jpg")
                }
              ]}
            />
          </div>
          <div className="container-login">
            <FormLogin redirectToSubscription={redirectToSubscription} />
            <Footer
              openModalAction={this.props.openModalAction.bind(this)}
              today={today}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    publicKey: state.util.publicKeySet.public_key,
    profile: state.profile,
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getKey,
      navigationGrandParent,
      openModalAction,
      validateToken,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
