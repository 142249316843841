/**
  *! created based on lib https://www.npmjs.com/package/geteventlisteners
*/ 

export default function() {
  window._addEventListener = window.addEventListener;
  window._removeEventListener = window.removeEventListener;

  /**
   * [addEventListener description]
   * @param {[type]}  type       [description]
   * @param {[type]}  listener   [description]
   * @param {Boolean} useCapture [description]
   */
  window.addEventListener = function(type, listener, useCapture = false) {
    this._addEventListener(type, listener, useCapture);

    if (!this.eventListenerList) this.eventListenerList = {};
    if (!this.eventListenerList[type]) this.eventListenerList[type] = [];

    this.eventListenerList[type].push({ type, listener, useCapture });
  };

  /**
   * [removeEventListener description]
   * @param {[type]}  type       [description]
   * @param {[type]}  listener   [description]
   * @param {Boolean} useCapture [description]
   */
  window.removeEventListener = function(type, listener, useCapture = false) {
    this._removeEventListener(type, listener, useCapture);

    if (!this.eventListenerList) this.eventListenerList = {};
    if (!this.eventListenerList[type]) this.eventListenerList[type] = [];

    for (let i = 0; i < this.eventListenerList[type].length; i++) {
      if (
        this.eventListenerList[type][i].listener === listener &&
        this.eventListenerList[type][i].useCapture === useCapture
      ) {
        this.eventListenerList[type].splice(i, 1);
        break;
      }
    }

    if (this.eventListenerList[type].length === 0) delete this.eventListenerList[type];
  };

  /**
   * [getEventListeners description]
   * @param  {[type]} type [description]
   * @return {[type]}      [description]
   */
  window.getEventListeners = function(type) {
    if (!this.eventListenerList) this.eventListenerList = {};
    if (type === undefined) return this.eventListenerList;
    return this.eventListenerList[type];
  };
};