import * as Actions from '../../constant/constants';
import socket from '../../socket';

import moment from 'moment';

export function getEvaluatedWorkouts() {
  return {
    type: Actions.GET_EVALUATED_WORKOUTS,
    payload: socket.sendMessage(Actions.GET_EVALUATED_WORKOUTS, {})
  };
}

export function setEvaluatedWorkouts(res) {
  const data = !Array.isArray(res.data)
    ? []
    : res.data.sort((a, b) => {
        return a.updated && b.updated ? moment.utc(b.updated).diff(a.updated) : 1;
      });

  return {
    type: Actions.GET_EVALUATED_WORKOUTS,
    payload: data
  };
}

export function selectRegister(workout) {
  return {
    type: Actions.REGISTER_SELECTED_EVALUATED_WORKOUT,
    payload: workout
  };
}

export function addEvaluatedWorkout(res) {
  return {
    type: Actions.ADD_REGISTER_EVALUATED_WORKOUT,
    payload: res.data
  };
}

export function addRegister(payload) {
  return {
    type: Actions.ADD_REQUEST,
    payload: socket.sendMessage(Actions.ADD_EVALUATED_WORKOUT, payload)
  };
}

export function editEvaluatedWorkout(res) {
  return {
    type: Actions.UPDATE_REGISTER_EVALUATED_WORKOUT,
    payload: res.data
  };
}

export function editRegister(payload) {
  return {
    type: Actions.UPDATE_REQUEST,
    payload: socket.sendMessage(Actions.UPDATE_EVALUATED_WORKOUT, payload)
  };
}

export function deleteEvaluatedWorkout(res) {
  return {
    type: Actions.DELETE_REGISTER_EVALUATED_WORKOUT,
    payload: res.data
  };
}

export function deleteRegister(id) {
  return {
    type: Actions.DELETE_REQUEST,
    payload: socket.sendMessage(Actions.DELETE_EVALUATED_WORKOUT, { id })
  };
}

export function getOneEvaluatedWorkout(id) {
  return {
    type: Actions.GET_ONE_EVALUATED_WORKOUT,
    payload: socket.sendMessage(Actions.GET_ONE_EVALUATED_WORKOUT, { id })
  };
}

export function copyTrainingByWorkout(idevaluated, idpresetworkout, idevaluator) {
  return {
    type: Actions.ADD_REQUEST,
    payload: socket.sendMessage(Actions.COPY_TRAINING_BY_WORKOUT, { idevaluated, idpresetworkout, idevaluator })
  };
}

export function copyTrainingByEvaluated(idevaluatedworkout, idevaluated, idevaluator) {
  return {
    type: Actions.ADD_REQUEST,
    payload: socket.sendMessage(Actions.COPY_TRAINING_BY_EVALUATED, { idevaluatedworkout, idevaluated, idevaluator })
  };
}

export function convertMessages(data) {
  const shortName = data.shortname;
  const shortNameWithoutSpaces = shortName.replace(/\s/g, '').toLocaleLowerCase();
  data.idMessageName = `app.exercise.${shortNameWithoutSpaces}`;
  data.defaultMessageName = data.name;
  return data;
}
