/* eslint-disable import/prefer-default-export */
import { loginUnauthorized } from './action';
import * as Actions from '../../constant/constants';
import { routerActions } from 'react-router-redux';

/**
 * Aqui ficam as tratativas de impedimentos de acesso ao sistema. Recebe a razão pela não autorização do login e também uma função que serve como default do switch, visto que em alguns casos ela pode ser diferente (login:209 e closeAnotherSession:137). Mais ou menos igual a lógica que o back-end utiliza para validar e enviar respostas de status 400+. Toda nova razão de impedimento será um case novo aqui.
 *
 * @param {object} data objeto com os parametros da função loginUnauthorized: err, reason, user, pass e connected
 * @param {function} dispatch função que despacha os eventos repassada para essa
 * @param {function} defaultCase função dispatch a ser chamada caso nenhum case bata
 * @returns void
 */
export const parseUnauthorizedLoginReason = (data, dispatch, defaultCase) => {
  const { err, user, pass, connected, reason } = data;

  switch (reason) {
    case Actions.ANOTHER_SESSION:
      dispatch(loginUnauthorized(err, reason, user, pass, connected));
      break;
    case Actions.PERMISSION_ERROR_CLIENT_BLOCKED:
      dispatch(loginUnauthorized(err, reason, user, '', connected));
      break;
    case Actions.EXPIRED_EXTERNAL_EVALUATOR:
      dispatch(loginUnauthorized(err, reason, user, '', connected));
      break;
    case Actions.FREE_CLIENT_REJECTED:
      dispatch(loginUnauthorized(err, reason, user, '', connected));
      break;
    case Actions.FREE_CLIENT_PENDING:
      dispatch(loginUnauthorized(err, reason, user, '', connected));
      dispatch(routerActions.push('/Login'));
      break;
    case Actions.MUST_UPDATE_PASSWORD:
      dispatch(loginUnauthorized(err, reason, user, '', connected));
      break;
    case Actions.VOUCHER_EXPIRED:
      dispatch(loginUnauthorized(err, reason, user, '', connected));
      break;
    default:
      defaultCase();
      break;
  }
};
