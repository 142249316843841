import React from 'react';

const IconTemplate = (props) => (
  <img
    src={require('../../images/modelos.png')}
    alt="Modelos"
    width={props.width}
    height={props.height}
  />
);

IconTemplate.defaultProps = {
  width: 120,
  height: 120,
  color: 'default',
};

export default IconTemplate;
