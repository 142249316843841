import React from 'react';

const IconLink = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 505 505"
    width={props.width}
    height={props.height}
  >
    <g clipPath="url(#clip0_12_2)">
      <path d="M252.5 505C391.952 505 505 391.952 505 252.5C505 113.048 391.952 0 252.5 0C113.048 0 0 113.048 0 252.5C0 391.952 113.048 505 252.5 505Z" fill="#84DBFF"/>
      <path d="M325.6 86.1V139.7H379.1V418.9H125.9V86.1" fill="white"/>
      <path d="M325.6 86.1L379.1 139.7H325.6" fill="#E6E9EE"/>
      <path d="M166.8 311.5H338.2V324.7H166.8V311.5ZM166.8 337.1H338.2V350.3H166.8V337.1ZM166.8 362.8H338.2V376H166.8V362.8Z" fill="#E6E9EE"/>
      <path d="M242.169 221.831C245.318 224.827 245.318 229.744 242.169 232.74C239.173 235.736 234.256 235.736 231.26 232.74C216.28 217.76 216.28 193.408 231.26 178.429L258.454 151.235C273.434 136.255 297.786 136.255 312.765 151.235C327.745 166.214 327.745 190.566 312.765 205.546L301.319 216.992C301.396 210.693 300.397 204.394 298.246 198.402L301.857 194.714C310.922 185.727 310.922 171.131 301.857 162.143C292.869 153.078 278.273 153.078 269.286 162.143L242.169 189.26C233.104 198.248 233.104 212.844 242.169 221.831ZM263.831 189.26C266.827 186.264 271.744 186.264 274.74 189.26C289.719 204.24 289.719 228.592 274.74 243.571L247.546 270.765C232.566 285.745 208.214 285.745 193.235 270.765C178.255 255.786 178.255 231.434 193.235 216.454L204.681 205.008C204.604 211.307 205.603 217.606 207.754 223.675L204.143 227.286C195.078 236.273 195.078 250.869 204.143 259.857C213.131 268.922 227.727 268.922 236.714 259.857L263.831 232.74C272.896 223.752 272.896 209.156 263.831 200.169C260.682 197.173 260.682 192.256 263.831 189.26Z" fill="#4CDBC4"/>
    </g>
    <defs>
      <clipPath id="clip0_12_2">
        <rect width="505" height="505" fill="white"/>
      </clipPath>
    </defs>
  </svg>
);

IconLink.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconLink;
