import * as Actions from '../../constant/constants';

const initialState = {
  loading: false,
};
export default function report(state = initialState, action) {
  switch (action.type) {
    case Actions.START_REPORT_LOAD:
      return { loading: true };
    case Actions.FINISH_REPORT_LOAD:
      return { loading: false };
    default:
      return state;
  }
}
