export const SYS_VERSION = '1.7-test';
export const LAST_COMMIT = 'e09fd9ffb5747e3159fef1fad2a0c34df3ef1310';
const ws = window.location.protocol === 'http:' ? 'ws:' : 'wss:';
const regex = /(localhost$)/g;
const regex0 = /(balerion.grupo.krs$)/g;
export const IS_STAGE = regex0.test(window.location.hostname);
export const IS_DEV = regex.test(window.location.hostname);
const urlSoc = IS_DEV
  ? `${ws}//${window.location.hostname}:7071/ws/`
  : `${ws}//${window.location.host}/ws/`;
export const SOCKET_CONSTS = {
  url: urlSoc,
  statusConnecting: 0, // The connection is not yet open.The connection is not yet open.
  statusOpen: 1, // The connection is open and ready to communicate.
  statusClosing: 2, // The connection is in the process of closing.
  statusClosed: 3, // The connection is closed.
  timeToReissueToken: 1200, // time in seconds
  intervalToReissueToken: 60, // interval in seconds
};
export const URL_SERVER = IS_DEV
  ? `${window.location.protocol}//${window.location.hostname}:7071`
  : `${window.location.protocol}//${window.location.host}`;
export const URL_CLIENT = IS_DEV
  ? `${window.location.protocol}//${window.location.hostname}:3000`
  : `${window.location.protocol}//${window.location.host}`;
export const CONNECT_SERVER_SUCCESS = 4;
export const RESPONSE_CODES_SUCCESS = 200;
export const RESPONSE_CODES_FAILED = 403;
export const RESPONSE_CODES_UNAUTHORIZED = 401;
export const VALIDATE_TOKEN = 'VALIDATE_TOKEN';
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';
export const PERMISSION_ERROR = 'PERMISSION_ERROR';
export const PERMISSION_ERROR_CLIENT_BLOCKED = 'PERMISSION_ERROR_CLIENT_BLOCKED';
export const PERMISSION_ERROR_SUB_EXPIRED = "PERMISSION_ERROR_SUB_EXPIRED";
export const VOUCHER_EXPIRED = 'VOUCHER_EXPIRED';

export const BLOCK_CLIENT = 'BLOCK_CLIENT';
export const ERROR = 'ERROR';
export const CLOSE_SESSION = 'CLOSE_SESSION';
export const DEFAULT_LANGUAGE = 'pt-BR';
export const REAVALIATION_DAYS = 90;
export const DISCONNECT_ANOTHER_USER = 'DISCONNECT_ANOTHER_USER';
export const DISCONNECT_CLIENT_USER = 'DISCONNECT_CLIENT_USER';
export const USER_HAS_ALREADY_BEEN_REGISTERED = 'USER_HAS_ALREADY_BEEN_REGISTERED';
export const DATE_EVALUATION_EXISTS_ERROR = 'DATE_EVALUATION_EXISTS_ERROR';
export const DATE_EVALUATION_FUTURE_ERROR = 'DATE_EVALUATION_FUTURE_ERROR';
export const EVALUATED_NOT_ALLOW_NOTIFY = 'EVALUATED_NOT_ALLOW_NOTIFY';

export const OFFICIAL_RELEASE_DATE = '2024-10-30 00:00:00';

// NO AUTH ROUTES
export const NO_AUTH_ROUTES = [
  'Login',
  'ForgotPassword',
  'PhysicalAssessmentReport',
  'PhysicalAssessmentComparison',
  'ExternalRegister',
  'NewExternalEvaluator',
  'SetEvaluatedPassword',
];

export const NO_ADD_FOOTER = [
  '/Login',
  '/ForgotPassword',
  '/SetEvaluatedPassword',
];

export const SUBSCRIPTION_STATUS = {
  INITIATED: "Iniciada",
	PENDING: "Pendente",
	ACTIVE: "Ativo",
	PAYMENT_METHOD_CHANGE: "Trocar método de pagamento",
	SUSPENDED: "Suspenso",
	CANCELLED: "Cancelado",
	CANCELLED_BY_RECEIVER: "Cancelado",
	CANCELLED_BY_SENDER: "Cancelado",
	EXPIRED: "Expirado",
};

//  ANAMNESIS
export const ADD_ANAMNESIS_QUESTION = 'ADD_ANAMNESIS_QUESTION';
export const ADD_ANAMNESIS_TEMPLATE = 'ADD_ANAMNESIS_TEMPLATE';
export const GET_ANAMNESIS_QUESTION = 'GET_ANAMNESIS_QUESTION';
export const GET_ANAMNESIS_QUESTIONS = 'GET_ANAMNESIS_QUESTIONS';
export const GET_ANAMNESIS_TEMPLATE = 'GET_ANAMNESIS_TEMPLATE';
export const GET_ANAMNESIS_TEMPLATES = 'GET_ANAMNESIS_TEMPLATES';
export const REMOVE_ANAMNESIS_QUESTION = 'REMOVE_ANAMNESIS_QUESTION';
export const REMOVE_ANAMNESIS_TEMPLATE = 'REMOVE_ANAMNESIS_TEMPLATE';
export const UPDATE_ANAMNESIS_QUESTION = 'UPDATE_ANAMNESIS_QUESTION';
export const UPDATE_ANAMNESIS_TEMPLATE = 'UPDATE_ANAMNESIS_TEMPLATE';
export const SHOULD_GET_ANAMNESIS_TEMPLATE = 'SHOULD_GET_ANAMNESIS_TEMPLATE';
export const SAVE_EVALUATION_ANAMNESIS = 'SAVE_EVALUATION_ANAMNESIS';
export const SET_EVALUATION_TEMPLATE = 'SET_EVALUATION_TEMPLATE';

// PAR-Q
export const GET_PARQ_TEMPLATE = 'GET_PARQ_TEMPLATE';
export const GET_PARQ_TEMPLATE_PENDING = 'GET_PARQ_TEMPLATE_PENDING';
export const GET_PARQ_TEMPLATE_REJECTED = 'GET_PARQ_TEMPLATE_REJECTED';
export const GET_PARQ_QUESTIONS = 'GET_PARQ_QUESTIONS';
export const GET_PARQ_QUESTIONS_PENDING = 'GET_PARQ_QUESTIONS_PENDING';
export const GET_PARQ_QUESTIONS_REJECTED = 'GET_PARQ_QUESTIONS_REJECTED';
export const SAVE_EVALUATION_PARQ = 'SAVE_EVALUATION_PARQ';
export const UPDATE_EVALUATION_PARQ = 'UPDATE_EVALUATION_PARQ';

//  NAVIGATION
export const NAVIGATION_ADD_HISTORY = 'NAVIGATION_ADD_HISTORY';
export const NAVIGATION_CLEAR_HISTORY = 'NAVIGATION_CLEAR_HISTORY';
export const NAVIGATION_CLOSE = 'NAVIGATION_CLOSE';
export const NAVIGATION_OPEN = 'NAVIGATION_OPEN';
export const NAVIGATION_REMOVE_HISTORY = 'NAVIGATION_REMOVE_HISTORY';
export const NAVIGATION_RESTORE_HISTORY = 'NAVIGATION_RESTORE_HISTORY';

//  STATUS MESSAGES
export const MESSAGE_STATUS_NULL = 0;
export const MESSAGE_STATUS_SUCCESS = 1;
export const MESSAGE_STATUS_REQUEST = 2;
export const MESSAGE_STATUS_ERROR = 3;

// MESSAGE TYPES
export const MESSAGE_TYPE_NULL = 0;
export const MESSAGE_TYPE_ADD = 1;
export const MESSAGE_TYPE_UPDATE = 2;
export const MESSAGE_TYPE_DELETE = 3;

export const EMAIL_ALREADY_EXISTS = 'EMAIL_ALREADY_EXISTS';
export const EMAIL_ALREADY_EXISTS_HTTP = 'EMAIL_ALREADY_EXISTS_HTTP';
export const EMAIL_ALREADY_EXISTS_HTTP_PENDING = 'EMAIL_ALREADY_EXISTS_HTTP_PENDING';
export const EMAIL_ALREADY_EXISTS_HTTP_FULFILLED = 'EMAIL_ALREADY_EXISTS_HTTP_FULFILLED';
export const EMAIL_ALREADY_EXISTS_PENDING = 'EMAIL_ALREADY_EXISTS_PENDING';
export const EMAIL_ALREADY_EXISTS_FULFILLED = 'EMAIL_ALREADY_EXISTS_FULFILLED';
export const RECEIVE_STATUS_EMAIL = 'RECEIVE_STATUS_EMAIL';
export const RECEIVE_STATUS_EMAIL_PENDING = 'RECEIVE_STATUS_EMAIL_PENDING';
export const RECEIVE_STATUS_EMAIL_FULFILLED = 'RECEIVE_STATUS_EMAIL_FULFILLED';
export const DESTROY_EMAIL = 'DESTROY_EMAIL';

// SOCKET
export const SOCKET_READY = 'SOCKET_READY';
export const SOCKET_TIMEOUT = 'SOCKET_TIMEOUT';
export const SOCKET_DISCONNECT = 'SOCKET_DISCONNECT';
export const SOCKET_CLOSED_ERROR = 'SOCKET_CLOSED_ERROR';

// REQUEST
export const ADD_REQUEST = 'ADD_REQUEST';
export const ADD_REQUEST_PENDING = 'ADD_REQUEST_PENDING';
export const ADD_REQUEST_REJECTED = 'ADD_REQUEST_REJECTED';
export const ADD_REQUEST_FULFILLED = 'ADD_REQUEST_FULFILLED';
export const UPDATE_REQUEST = 'UPDATE_REQUEST';
export const UPDATE_REQUEST_PENDING = 'UPDATE_REQUEST_PENDING';
export const UPDATE_REQUEST_REJECTED = 'UPDATE_REQUEST_REJECTED';
export const UPDATE_REQUEST_FULFILLED = 'UPDATE_REQUEST_FULFILLED';
export const DELETE_REQUEST = 'DELETE_REQUEST';
export const DELETE_REQUEST_PENDING = 'DELETE_REQUEST_PENDING';
export const DELETE_REQUEST_REJECTED = 'DELETE_REQUEST_REJECTED';
export const DELETE_REQUEST_FULFILLED = 'DELETE_REQUEST_FULFILLED';

// AUTHORS
export const GET_AUTHORS = 'GET_AUTHORS';
export const GET_AUTHORS_PENDING = 'GET_AUTHORS_PENDING';
export const GET_AUTHORS_REJECTED = 'GET_AUTHORS_REJECTED';
export const GET_AUTHORS_FULFILLED = 'GET_AUTHORS_FULFILLED';
export const ADD_AUTHOR = 'ADD_AUTHOR';
export const UPDATE_AUTHOR = 'UPDATE_AUTHOR';
export const DELETE_AUTHOR = 'DELETE_AUTHOR';
export const REGISTER_SELECTED_AUTHOR = 'REGISTER_SELECTED_AUTHOR';
export const ADD_REGISTER_AUTHOR = 'ADD_REGISTER_AUTHOR';
export const EDIT_REGISTER_AUTHOR = 'EDIT_REGISTER_AUTHOR';
export const DELETE_REGISTER_AUTHOR = 'DELETE_REGISTER_AUTHOR';
export const ADD_REGISTER_FAILED_AUTHOR = 'ADD_REGISTER_FAILED_AUTHOR';

// MEASURES
export const GET_MEASURES = 'GET_MEASURES';
export const GET_MEASURES_PENDING = 'GET_MEASURES_PENDING';
export const GET_MEASURES_FULFILLED = 'GET_MEASURES_FULFILLED';
export const GET_MEASURES_REJECTED = 'GET_MEASURES_REJECTED';
export const GET_MEASURE_TYPES = 'GET_MEASURE_TYPES';
export const ADD_MEASURE = 'ADD_MEASURE';
export const MEASURE_ADD_REQUEST = 'MEASURE_ADD_REQUEST';
export const MEASURE_ADD_REQUEST_PENDING = 'MEASURE_ADD_REQUEST_PENDING';
export const MEASURE_ADD_REQUEST_FULFILLED = 'MEASURE_ADD_REQUEST_FULFILLED';
export const MEASURE_ADD_REQUEST_REJECTED = 'MEASURE_ADD_REQUEST_REJECTED';
export const UPDATE_MEASURE = 'UPDATE_MEASURE';
export const MEASURE_UPDATE_REQUEST = 'MEASURE_UPDATE_REQUEST';
export const MEASURE_UPDATE_REQUEST_PENDING = 'MEASURE_UPDATE_REQUEST_PENDING';
export const MEASURE_UPDATE_REQUEST_FULFILLED = 'MEASURE_UPDATE_REQUEST_FULFILLED';
export const MEASURE_UPDATE_REQUEST_REJECTED = 'MEASURE_UPDATE_REQUEST_REJECTED';
export const DELETE_MEASURE = 'DELETE_MEASURE';
export const MEASURE_DELETE_REQUEST = 'MEASURE_DELETE_REQUEST';
export const MEASURE_DELETE_REQUEST_PENDING = 'MEASURE_DELETE_REQUEST_PENDING';
export const MEASURE_DELETE_REQUEST_FULFILLED = 'MEASURE_DELETE_REQUEST_FULFILLED';
export const MEASURE_DELETE_REQUEST_REJECTED = 'MEASURE_DELETE_REQUEST_REJECTED';
export const SELECT_MEASURE_BODY_MAP = 'SELECT_MEASURE_BODY_MAP';
export const REMOVE_MEASURE_BODY_MAP = 'REMOVE_MEASURE_BODY_MAP';
export const SET_VALUE_MEASURE_BODY_MAP = 'SET_VALUE_MEASURE_BODY_MAP';
export const REGISTER_SELECTED_MEASURE = 'REGISTER_SELECTED_MEASURE';
export const ADD_REGISTER_MEASURE = 'ADD_REGISTER_MEASURE';
export const EDIT_REGISTER_MEASURE = 'EDIT_REGISTER_MEASURE';
export const DELETE_REGISTER_MEASURE = 'DELETE_REGISTER_MEASURE';
export const ADD_REGISTER_FAILED_MEASURE = 'ADD_REGISTER_FAILED_MEASURE';
export const SEND_MEASURE = 'SEND_MEASURE';

// MEASURE TYPES
export const GENERAL_TYPE = 'general';
export const SKINFOLD_TYPE = 'skinfold';
export const DIAMETER_TYPE = 'diameter';
export const PERIMETER_TYPE = 'perimeter';
export const OTHER_TYPE = 'other';

// ETHNICITIES
export const GET_ETHNICITIES = 'GET_ETHNICITIES';
export const GET_ETHNICITIES_PENDING = 'GET_ETHNICITIES_PENDING';
export const GET_ETHNICITIES_FULFILLED = 'GET_ETHNICITIES_FULFILLED';
export const GET_ETHNICITIES_REJECTED = 'GET_ETHNICITIES_REJECTED';
export const ADD_ETHNICITY = 'ADD_ETHNICITY';
export const UPDATE_ETHNICITY = 'UPDATE_ETHNICITY';
export const DELETE_ETHNICITY = 'DELETE_ETHNICITY';
export const REGISTER_SELECTED_ETHNICITY = 'REGISTER_SELECTED_ETHNICITY';
export const ADD_REGISTER_ETHNICITY = 'ADD_REGISTER_ETHNICITY';
export const EDIT_REGISTER_ETHNICITY = 'EDIT_REGISTER_ETHNICITY';
export const DELETE_REGISTER_ETHNICITY = 'DELETE_REGISTER_ETHNICITY';
export const ADD_REGISTER_FAILED_ETHNICITY = 'ADD_REGISTER_FAILED_ETHNICITY';

// CLASSIFICATIONS
export const GET_CLASSIFICATIONS = 'GET_CLASSIFICATIONS';
export const GET_CLASSIFICATIONS_PENDING = 'GET_CLASSIFICATIONS_PENDING';
export const GET_CLASSIFICATIONS_FULFILLED = 'GET_CLASSIFICATIONS_FULFILLED';
export const GET_CLASSIFICATIONS_REJECTED = 'GET_CLASSIFICATIONS_REJECTED';
export const ADD_CLASSIFICATION = 'ADD_CLASSIFICATION';
export const ACTION_CLASSIFICATION_REQUEST = 'ACTION_CLASSIFICATION_REQUEST';
export const UPDATE_CLASSIFICATION = 'UPDATE_CLASSIFICATION';
export const DELETE_CLASSIFICATION = 'DELETE_CLASSIFICATION';
export const REGISTER_SELECTED_CLASSIFICATION = 'REGISTER_SELECTED_CLASSIFICATION';
export const ADD_REGISTER_CLASSIFICATION = 'ADD_REGISTER_CLASSIFICATION';
export const EDIT_REGISTER_CLASSIFICATION = 'EDIT_REGISTER_CLASSIFICATION';
export const DELETE_REGISTER_CLASSIFICATION = 'DELETE_REGISTER_CLASSIFICATION';
export const ADD_REGISTER_FAILED_CLASSIFICATION = 'ADD_REGISTER_FAILED_CLASSIFICATION';

// FORMULAS
export const GET_FORMULAS = 'GET_FORMULAS';
export const GET_FORMULAS_PENDING = 'GET_FORMULAS_PENDING';
export const GET_FORMULAS_FULFILLED = 'GET_FORMULAS_FULFILLED';
export const GET_FORMULAS_REJECTED = 'GET_FORMULAS_REJECTED';
export const GET_ONE_FORMULA = 'GET_ONE_FORMULA';
export const GET_ONE_FORMULA_PENDING = 'GET_ONE_FORMULA_PENDING';
export const GET_ONE_FORMULA_FULFILLED = 'GET_ONE_FORMULA_FULFILLED';
export const GET_ONE_FORMULA_REJECTED = 'GET_ONE_FORMULA_REJECTED';
export const ADD_FORMULA = 'ADD_FORMULA';
export const UPDATE_FORMULA = 'UPDATE_FORMULA';
export const DELETE_FORMULA = 'DELETE_FORMULA';
export const REGISTER_SELECTED_FORMULA = 'REGISTER_SELECTED_FORMULA';
export const REGISTER_TEST_FORMULA = 'REGISTER_TEST_FORMULA';
export const ADD_REGISTER_FORMULA = 'ADD_REGISTER_FORMULA';
export const EDIT_REGISTER_FORMULA = 'EDIT_REGISTER_FORMULA';
export const DELETE_REGISTER_FORMULA = 'DELETE_REGISTER_FORMULA';
export const ADD_REGISTER_FAILED_FORMULA = 'ADD_REGISTER_FAILED_FORMULA';
export const FORMULA_OPERATIONS = {
  plus: '+',
  minus: '-',
  multiply: '*',
  divide: '/',
  squareRoot: 'sqrt(x)',
  nthRoot: 'nthRoot(x, 2)',
  exponential: '^2',
  log: 'log(x,10)',
  naturalLog: 'log(x)',
  parentheses: '()',
};
export const REGEX_TREAT_INPUT_FORMULA = /\s|,|_|-|\([^)]*\)/g;
export const REGEX_CHAR_IS_WORD_FORMULA =
  /[a-zA-Z]|[0-9]|\$|\.|[\u00E0-\u00FC]|[\u00C0-\u017F]/;
export const REGEX_INVALID_CHARS_FORMULAS =
  /[a-zA-Z]|[\u00E0-\u00FC]|[\u00C0-\u017F]|,|\$/;
export const REGEX_FIND_X = /\(x(?![a-z0-9]*\$[0-9])/;
export const FORMULA_SEPARATOR_ID = '$'; // influencia os REGEX acima, se trocar provavelmente precisará alterar aqui em cima
export const ANTHROPOMETRY_METHOD = 1;
export const BIOIMPEDANCE_METHOD = 2;
export const CALCULATION_DC = 1;
export const CALCULATION_PG = 2;
export const AGE_ID = 1;
export const GENDER_ID = 2;
export const BODY_COMPOSITION_TYPE = 1;
export const CARDIORESPIRATORY_TYPE = 2;

// FORMULA METHODS
export const GET_FORMULA_METHODS = 'GET_FORMULA_METHODS';
export const GET_FORMULA_METHODS_PENDING = 'GET_FORMULA_METHODS_PENDING';
export const GET_FORMULA_METHODS_FULFILLED = 'GET_FORMULA_METHODS_FULFILLED';
export const GET_FORMULA_METHODS_REJECTED = 'GET_FORMULA_METHODS_REJECTED';

// VARIABLES
export const GET_VARIABLES = 'GET_VARIABLES';
export const GET_VARIABLES_PENDING = 'GET_VARIABLES_PENDING';
export const GET_VARIABLES_REJECTED = 'GET_VARIABLES_REJECTED';
export const GET_CARDIO_VARIABLES = 'GET_CARDIO_VARIABLES';
export const GET_CARDIO_VARIABLES_PENDING = 'GET_CARDIO_VARIABLES_PENDING';
export const GET_CARDIO_VARIABLES_REJECTED = 'GET_CARDIO_VARIABLES_REJECTED';
export const GET_NEUROMOTOR_VARIABLES = 'GET_NEUROMOTOR_VARIABLES';
export const GET_NEUROMOTOR_VARIABLES_PENDING = 'GET_NEUROMOTOR_VARIABLES_PENDING';
export const GET_NEUROMOTOR_VARIABLES_REJECTED = 'GET_NEUROMOTOR_VARIABLES_REJECTED';

// GOALS
export const GET_GOALS = 'GET_GOALS';
export const GET_GOALS_PENDING = 'GET_GOALS_PENDING';
export const GET_GOALS_FULFILLED = 'GET_GOALS_FULFILLED';
export const GET_GOALS_REJECTED = 'GET_GOALS_REJECTED';
export const ADD_GOAL = 'ADD_GOAL';
export const ACTION_GOAL_REQUEST = 'ACTION_GOAL_REQUEST';
export const UPDATE_GOAL = 'UPDATE_GOAL';
export const GOAL_UPDATE_REQUEST = 'GOAL_UPDATE_REQUEST';
export const GOAL_UPDATE_REQUEST_PENDING = 'GOAL_UPDATE_REQUEST_PENDING';
export const GOAL_UPDATE_REQUEST_FULFILLED = 'GOAL_UPDATE_REQUEST_FULFILLED';
export const GOAL_UPDATE_REQUEST_REJECTED = 'GOAL_UPDATE_REQUEST_REJECTED';
export const DELETE_GOAL = 'DELETE_GOAL';
export const GOAL_DELETE_REQUEST = 'GOAL_DELETE_REQUEST';
export const GOAL_DELETE_REQUEST_PENDING = 'GOAL_DELETE_REQUEST_PENDING';
export const GOAL_DELETE_REQUEST_FULFILLED = 'GOAL_DELETE_REQUEST_FULFILLED';
export const GOAL_DELETE_REQUEST_REJECTED = 'GOAL_DELETE_REQUEST_REJECTED';
export const REGISTER_SELECTED_GOAL = 'REGISTER_SELECTED_GOAL';
export const ADD_REGISTER_GOAL = 'ADD_REGISTER_GOAL';
export const EDIT_REGISTER_GOAL = 'EDIT_REGISTER_GOAL';
export const DELETE_REGISTER_GOAL = 'DELETE_REGISTER_GOAL';
export const ADD_REGISTER_FAILED_GOAL = 'ADD_REGISTER_FAILED_GOAL';

// GENDER
export const GET_GENDERS = 'GET_GENDERS';
export const GET_GENDERS_PENDING = 'GET_GENDERS_PENDING';
export const GET_GENDERS_FULFILLED = 'GET_GENDERS_FULFILLED';
export const GET_GENDERS_REJECTED = 'GET_GENDERS_REJECTED';
export const MALE = 1;
export const FEMALE = 2;
export const MALE_TEXT = 'male';
export const FEMALE_TEXT = 'female';

// SEXUAL MATURATION
export const GET_SEXUAL_MATURATIONS = 'GET_SEXUAL_MATURATIONS';
export const GET_SEXUAL_MATURATIONS_PENDING = 'GET_SEXUAL_MATURATIONS_PENDING';
export const GET_SEXUAL_MATURATIONS_FULFILLED = 'GET_SEXUAL_MATURATIONS_FULFILLED';
export const GET_SEXUAL_MATURATIONS_REJECTED = 'GET_SEXUAL_MATURATIONS_REJECTED';

// PHYSICAL TEST
export const GET_PHYSICAL_TESTS = 'GET_PHYSICAL_TESTS';
export const BODY_MEASURES_TEST = 1;
export const COMPOSITION_TEST = 2;
export const ANAMNESIS_TEST = 3;

// EVALUATORS
export const GET_EVALUATORS = 'GET_EVALUATORS';
export const GET_ONE_EVALUATION = 'GET_ONE_EVALUATION';
export const GET_EVALUATORS_PENDING = 'GET_EVALUATORS_PENDING';
export const GET_EVALUATORS_FULFILLED = 'GET_EVALUATORS_FULFILLED';
export const GET_EVALUATORS_REJECTED = 'GET_EVALUATORS_REJECTED';
export const ADD_EVALUATOR = 'ADD_EVALUATOR';
export const ADD_HTTP_EVALUATOR = 'ADD_HTTP_EVALUATOR';
export const ADDED_HTTP_EVALUATOR = 'ADDED_HTTP_EVALUATOR';
export const EVALUATOR_ADD_REQUEST = 'EVALUATOR_ADD_REQUEST';
export const EVALUATOR_ADD_REQUEST_PENDING = 'EVALUATOR_ADD_REQUEST_PENDING';
export const EVALUATOR_ADD_REQUEST_FULFILLED = 'EVALUATOR_ADD_REQUEST_FULFILLED';
export const EVALUATOR_ADD_REQUEST_REJECTED = 'EVALUATOR_ADD_REQUEST_REJECTED';
export const ACTION_EVALUATOR_REQUEST = 'ACTION_EVALUATOR_REQUEST';
export const UPDATE_EVALUATOR = 'UPDATE_EVALUATOR';
export const EVALUATOR_UPDATE_REQUEST = 'EVALUATOR_UPDATE_REQUEST';
export const EVALUATOR_UPDATE_REQUEST_PENDING = 'EVALUATOR_UPDATE_REQUEST_PENDING';
export const EVALUATOR_UPDATE_REQUEST_FULFILLED = 'EVALUATOR_UPDATE_REQUEST_FULFILLED';
export const EVALUATOR_UPDATE_REQUEST_REJECTED = 'EVALUATOR_UPDATE_REQUEST_REJECTED';
export const DELETE_EVALUATOR = 'DELETE_EVALUATOR';
export const EVALUATOR_DELETE_REQUEST = 'EVALUATOR_DELETE_REQUEST';
export const EVALUATOR_DELETE_REQUEST_PENDING = 'EVALUATOR_DELETE_REQUEST_PENDING';
export const EVALUATOR_DELETE_REQUEST_FULFILLED = 'EVALUATOR_DELETE_REQUEST_FULFILLED';
export const EVALUATOR_DELETE_REQUEST_REJECTED = 'EVALUATOR_DELETE_REQUEST_REJECTED';
export const SET_EVALUATOR_PERSON = 'SET_EVALUATOR_PERSON';
export const SET_EVALUATOR_PERSON_REQUEST = 'SET_EVALUATOR_PERSON_REQUEST';
export const SET_EVALUATOR_PERSON_REQUEST_PENDING =
  'SET_EVALUATOR_PERSON_REQUEST_PENDING';
export const SET_EVALUATOR_PERSON_REQUEST_FULFILLED =
  'SET_EVALUATOR_PERSON_REQUEST_FULFILLED';
export const SET_EVALUATOR_PERSON_REQUEST_REJECTED =
  'SET_EVALUATOR_PERSON_REQUEST_REJECTED';
export const REGISTER_SELECTED_EVALUATOR = 'REGISTER_SELECTED_EVALUATOR';
export const ADD_REGISTER_EVALUATOR = 'ADD_REGISTER_EVALUATOR';
export const EDIT_REGISTER_EVALUATOR = 'EDIT_REGISTER_EVALUATOR';
export const DELETE_REGISTER_EVALUATOR = 'DELETE_REGISTER_EVALUATOR';
export const ADD_REGISTER_FAILED_EVALUATOR = 'ADD_REGISTER_FAILED_EVALUATOR';

export const GET_EVALUATOR_EVALUATIONS = 'GET_EVALUATOR_EVALUATIONS';

export const TRANSFER_EVALUATIONS_OWNERSHIP = 'TRANSFER_EVALUATIONS_OWNERSHIP';
export const TRANSFER_EVALUATIONS_OWNERSHIP_PENDING =
  'TRANSFER_EVALUATIONS_OWNERSHIP_PENDING';
export const TRANSFER_EVALUATIONS_OWNERSHIP_FULFILLED =
  'TRANSFER_EVALUATIONS_OWNERSHIP_FULFILLED';
export const TRANSFER_EVALUATIONS_OWNERSHIP_REJECTED =
  'TRANSFER_EVALUATIONS_OWNERSHIP_REJECTED';

export const EXPIRED_EXTERNAL_EVALUATOR = 'EXPIRED_EXTERNAL_EVALUATOR';

// EXTERNAL EVALUATORS REGISTER
export const GET_EXTERNAL_EVALUATORS_REGISTERS = 'GET_EXTERNAL_EVALUATORS_REGISTERS';
export const GET_EXTERNAL_EVALUATORS_REGISTERS_PENDING =
  'GET_EXTERNAL_EVALUATORS_REGISTERS_PENDING';
export const GET_EXTERNAL_EVALUATORS_REGISTERS_FULFILLED =
  'GET_EXTERNAL_EVALUATORS_REGISTERS_FULFILLED';
export const GET_EXTERNAL_EVALUATORS_REGISTERS_REJECTED =
  'GET_EXTERNAL_EVALUATORS_REGISTERS_REJECTED';
export const GET_EXTERNAL_EVALUATORS_REGISTERS_BY_CLIENT =
  'GET_EXTERNAL_EVALUATORS_REGISTERS_BY_CLIENT';
export const GET_EXTERNAL_EVALUATORS_REGISTERS_BY_CLIENT_PENDING =
  'GET_EXTERNAL_EVALUATORS_REGISTERS_BY_CLIENT_PENDING';
export const GET_EXTERNAL_EVALUATORS_REGISTERS_BY_CLIENT_FULFILLED =
  'GET_EXTERNAL_EVALUATORS_REGISTERS_BY_CLIENT_FULFILLED';
export const GET_EXTERNAL_EVALUATORS_REGISTERS_BY_CLIENT_REJECTED =
  'GET_EXTERNAL_EVALUATORS_REGISTERS_BY_CLIENT_REJECTED';
export const EXTERNAL_EVALUATOR_REGISTERED_SUCCESS =
  'EXTERNAL_EVALUATOR_REGISTERED_SUCCESS';
export const ADD_EXTERNAL_EVALUATORS_REGISTER = 'ADD_EXTERNAL_EVALUATORS_REGISTER';
export const ADD_EXTERNAL_EVALUATORS_REGISTER_PENDING =
  'ADD_EXTERNAL_EVALUATORS_REGISTER_PENDING';
export const ADD_EXTERNAL_EVALUATORS_REGISTER_FULFILLED =
  'ADD_EXTERNAL_EVALUATORS_REGISTER_FULFILLED';
export const ADD_EXTERNAL_EVALUATORS_REGISTER_REJECTED =
  'ADD_EXTERNAL_EVALUATORS_REGISTER_REJECTED';
export const DELETE_EXTERNAL_EVALUATORS_REGISTER = 'DELETE_EXTERNAL_EVALUATORS_REGISTER';
export const DELETE_EXTERNAL_EVALUATORS_REGISTER_PENDING =
  'DELETE_EXTERNAL_EVALUATORS_REGISTER_PENDING';
export const DELETE_EXTERNAL_EVALUATORS_REGISTER_FULFILLED =
  'DELETE_EXTERNAL_EVALUATORS_REGISTER_FULFILLED';
export const DELETE_EXTERNAL_EVALUATORS_REGISTER_REJECTED =
  'DELETE_EXTERNAL_EVALUATORS_REGISTER_REJECTED';
export const SELECT_EXTERNAL_EVALUATOR_REGISTER_BY_TOKEN =
  'SELECT_EXTERNAL_EVALUATOR_REGISTER_BY_TOKEN';
export const SELECT_EXTERNAL_EVALUATOR_REGISTER_BY_TOKEN_PENDING =
  'SELECT_EXTERNAL_EVALUATOR_REGISTER_BY_TOKEN_PENDING';
export const SELECT_EXTERNAL_EVALUATOR_REGISTER_BY_TOKEN_FULFILLED =
  'SELECT_EXTERNAL_EVALUATOR_REGISTER_BY_TOKEN_FULFILLED';
export const SELECT_EXTERNAL_EVALUATOR_REGISTER_BY_TOKEN_REJECTED =
  'SELECT_EXTERNAL_EVALUATOR_REGISTER_BY_TOKEN_REJECTED';
export const GOT_EXTERNAL_TOKEN_VALIDITY = 'GOT_EXTERNAL_TOKEN_VALIDITY';

// EVALUATED
export const GET_EVALUATED = 'GET_EVALUATED';
export const GET_EVALUATED_PENDING = 'GET_EVALUATED_PENDING';
export const GET_EVALUATED_FULFILLED = 'GET_EVALUATED_FULFILLED';
export const GET_EVALUATED_REJECTED = 'GET_EVALUATED_REJECTED';
export const GET_ONE_EVALUATED = 'GET_ONE_EVALUATED';
export const ADD_EVALUATED = 'ADD_EVALUATED';
export const EVALUATED_ADD_REQUEST = 'EVALUATED_ADD_REQUEST';
export const EVALUATED_ADD_REQUEST_PENDING = 'EVALUATED_ADD_REQUEST_PENDING';
export const EVALUATED_ADD_REQUEST_FULFILLED = 'EVALUATED_ADD_REQUEST_FULFILLED';
export const EVALUATED_ADD_REQUEST_REJECTED = 'EVALUATED_ADD_REQUEST_REJECTED';
export const ACTION_EVALUATED_REQUEST = 'ACTION_EVALUATED_REQUEST';
export const UPDATE_EVALUATED = 'UPDATE_EVALUATED';
export const EVALUATED_UPDATE_REQUEST = 'EVALUATED_UPDATE_REQUEST';
export const EVALUATED_UPDATE_REQUEST_PENDING = 'EVALUATED_UPDATE_REQUEST_PENDING';
export const EVALUATED_UPDATE_REQUEST_FULFILLED = 'EVALUATED_UPDATE_REQUEST_FULFILLED';
export const EVALUATED_UPDATE_REQUEST_REJECTED = 'EVALUATED_UPDATE_REQUEST_REJECTED';
export const DELETE_EVALUATED = 'DELETE_EVALUATED';
export const EVALUATED_DELETE_REQUEST = 'EVALUATED_DELETE_REQUEST';
export const EVALUATED_DELETE_REQUEST_PENDING = 'EVALUATED_DELETE_REQUEST_PENDING';
export const EVALUATED_DELETE_REQUEST_FULFILLED = 'EVALUATED_DELETE_REQUEST_FULFILLED';
export const EVALUATED_DELETE_REQUEST_REJECTED = 'EVALUATED_DELETE_REQUEST_REJECTED';
export const SET_EVALUATED_PERSON = 'SET_EVALUATED_PERSON';
export const SET_EVALUATED_PERSON_REQUEST = 'SET_EVALUATED_PERSON_REQUEST';
export const SET_EVALUATED_PERSON_REQUEST_PENDING =
  'SET_EVALUATED_PERSON_REQUEST_PENDING';
export const SET_EVALUATED_PERSON_REQUEST_FULFILLED =
  'SET_EVALUATED_PERSON_REQUEST_FULFILLED';
export const SET_EVALUATED_PERSON_REQUEST_REJECTED =
  'SET_EVALUATED_PERSON_REQUEST_REJECTED';
export const REGISTER_SELECTED_EVALUATED = 'REGISTER_SELECTED_EVALUATED';
export const ADD_REGISTER_EVALUATED = 'ADD_REGISTER_EVALUATED';
export const EDIT_REGISTER_EVALUATED = 'EDIT_REGISTER_EVALUATED';
export const DELETE_REGISTER_EVALUATED = 'DELETE_REGISTER_EVALUATED';
export const ADD_REGISTER_FAILED_EVALUATED = 'ADD_REGISTER_FAILED_EVALUATED';
export const SET_PICTURE_REQUEST = 'SET_PICTURE_REQUEST';
export const SET_PICTURE_PENDING = 'SET_PICTURE_PENDING';
export const SET_PICTURE_FULFILLED = 'SET_PICTURE_FULFILLED';
export const SET_CURRENT_PAGE_EVALUATIONS = 'SET_CURRENT_PAGE_EVALUATIONS';
export const GET_INFO_EVALUATED_EVALUATION = 'GET_INFO_EVALUATED_EVALUATION';
export const SEND_REPORT_EVALUATION = 'SEND_REPORT_EVALUATION';
export const REMOVE_PICTURE = 'REMOVE_PICTURE';

// COMPARISONS
export const GET_EVALUATIONS_COMPARISON = 'GET_EVALUATIONS_COMPARISON';
export const GET_EVALUATIONS_COMPARISON_PENDING = 'GET_EVALUATIONS_COMPARISON_PENDING';
export const GET_EVALUATIONS_COMPARISON_REJECTED = 'GET_EVALUATIONS_COMPARISON_REJECTED';
export const GET_EVALUATIONS_COMPARISON_FULFILLED =
  'GET_EVALUATIONS_COMPARISON_FULFILLED';
export const SELECTED_EVALUATIONS_COMPARISONS = 'SELECTED_EVALUATIONS_COMPARISONS';
export const SEND_REPORT_COMPARISON = 'SEND_REPORT_COMPARISON';
export const GET_BODY_COMPOSITION_COMPARISON = 'GET_BODY_COMPOSITION_COMPARISON';
export const SET_BODY_COMPOSITION_COMPARISON = 'SET_BODY_COMPOSITION_COMPARISON';

// PUBLIC KEY
export const GET_PUBLIC_KEY_FULFILLED = 'GET_PUBLIC_KEY_FULFILLED';
export const GET_PUBLIC_KEY_PENDING = 'GET_PUBLIC_KEY_PENDING';
export const GET_PUBLIC_KEY_REJECTED = 'GET_PUBLIC_KEY_REJECTED';
export const GET_PUBLIC_KEY = 'GET_PUBLIC_KEY';

// LOGIN
export const LOGIN_FULFILLED = 'LOGIN_FULFILLED';
export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGIN_SUBMIT = 'LOGIN_SUBMIT';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_REJECTED = 'LOGOUT_REJECTED';
export const ISSUE_TOKEN = 'ISSUE_TOKEN';
export const ASK_PASSWORD = 'ASK_PASSWORD';
export const RESPONSE_EMAIL_FORGOT_PASSWORD = 'RESPONSE_EMAIL_FORGOT_PASSWORD';
export const ACCESS_NEW_PASSWORD_SUCCESS = 'ACCESS_NEW_PASSWORD_SUCCESS';
export const ACCESS_NEW_PASSWORD_REQUEST = 'ACCESS_NEW_PASSWORD_REQUEST';
export const ACCESS_NEW_PASSWORD_REJECTED = 'ACCESS_NEW_PASSWORD_REJECTED';
export const MUST_UPDATE_PASSWORD = 'MUST_UPDATE_PASSWORD';
export const INVALID_CREDENTIALS = 'INVALID_CREDENTIALS';
export const SET_LOCALE = 'SET_LOCALE';
export const UPDATE_USER_LOCALE = 'UPDATE_USER_LOCALE';
export const VALIDATE_TOKEN_REQUEST = 'VALIDATE_TOKEN_REQUEST';

//  NEW ACCOUNT
export const NEW_ACCOUNT_REQUEST = 'NEW_ACCOUNT_REQUEST';
export const SEND_NEW_ACCOUNT_REQUEST = 'SEND_NEW_ACCOUNT_REQUEST';
export const NEW_REGISTER_SUCCESS = 'NEW_REGISTER_SUCCESS';

//  STATUS
export const DESTROY_STATUS = 'DESTROY_STATUS';

// AUTHENTICATED
export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';
export const AUTH = 'AUTHENTICATED';

// MODAL
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

// IMAGES BODY MEASURES
export const HANDLE_IMAGE_FRONT = 'HANDLE_IMAGE_FRONT';
export const HANDLE_IMAGE_RIGHT = 'HANDLE_IMAGE_RIGHT';
export const HANDLE_IMAGE_BEHIND = 'HANDLE_IMAGE_BEHIND';
export const HANDLE_IMAGE_LEFT = 'HANDLE_IMAGE_LEFT';
export const DESTROY_IMAGE = 'DESTROY_IMAGE';

// LISTENERS
export const ADD_LISTENER = 'ADD_LISTENER';
export const REMOVE_LISTENER = 'REMOVE_LISTENER';

// GENDER
export const MAN = 1;
export const WOMAN = 2;
export const MAN_INDEX = 0;
export const WOMAN_INDEX = 1;

// EVALUATION
export const GET_EVALUATIONS = 'GET_EVALUATIONS';
export const GET_EVALUATIONS_PENDING = 'GET_EVALUATIONS_PENDING';
export const GET_EVALUATIONS_REJECTED = 'GET_EVALUATIONS_REJECTED';
export const ADD_EVALUATION = 'ADD_EVALUATION';
export const ADD_EVALUATION_REQUEST = 'ADD_EVALUATION_REQUEST';
export const ADD_EVALUATION_REQUEST_PENDING = 'ADD_EVALUATION_REQUEST_PENDING';
export const ADD_EVALUATION_REQUEST_FULFILLED = 'ADD_EVALUATION_REQUEST_FULFILLED';
export const EVALUATION_EXISTS_TODAY = 'EVALUATION_EXISTS_TODAY';
export const DELETE_EVALUATION = 'DELETE_EVALUATION';
export const DELETE_EVALUATION_REQUEST = 'DELETE_EVALUATION_REQUEST';
export const DELETE_EVALUATION_REQUEST_PENDING = 'DELETE_EVALUATION_REQUEST_PENDING';
export const DELETE_EVALUATION_REQUEST_FULFILLED = 'DELETE_EVALUATION_REQUEST_FULFILLED';
export const UPDATE_EVALUATION_DATE = 'UPDATE_EVALUATION_DATE';
export const UPDATE_EVALUATION_MEASURES = 'UPDATE_EVALUATION_MEASURES';
export const UPDATE_EVALUATION_ANAMNESIS = 'UPDATE_EVALUATION_ANAMNESIS';
export const UPDATE_EVALUATION_ANAMNESIS_ANSWERS = 'UPDATE_EVALUATION_ANAMNESIS_ANSWERS';
export const UPDATE_EVALUATION_BODY_COMPOSITION = 'UPDATE_EVALUATION_BODY_COMPOSITION';
export const UPDATE_EVALUATION_ALERT_REPORT = 'UPDATE_EVALUATION_ALERT_REPORT';
export const UPDATE_EVALUATION_EVALUATOR = 'UPDATE_EVALUATION_EVALUATOR';
export const CHANGE_EVALUATION_DATE = 'CHANGE_EVALUATION_DATE';
export const SAVE_EVALUATION_MEASURES = 'SAVE_EVALUATION_MEASURES';
export const SAVE_EVALUATION_BODY_COMPOSITION = 'SAVE_EVALUATION_BODY_COMPOSITION';
export const GET_EVALUATION_MEASURES = 'GET_EVALUATION_MEASURES';
export const GET_BODY_COMPOSITION = 'GET_BODY_COMPOSITION';
export const GET_BODY_COMPOSITION_REQUEST = 'GET_BODY_COMPOSITION_REQUEST';
export const SELECTED_REGISTER_EVALUATION = 'SELECTED_REGISTER_EVALUATION';
export const CLEAR_EVALUATIONS_EVALUATED = 'CLEAR_EVALUATIONS_EVALUATED';
export const NOT_EVALUATIONS = 'NOT_EVALUATIONS';
export const WEIGHT = 'weight';
export const HEIGHT = 'height';
export const GET_FORMULAS_FILTERED = 'GET_FORMULAS_FILTERED';
export const SELECTED_REGISTER_PROTOCOL = 'SELECTED_REGISTER_PROTOCOL';
export const RESET_REGISTERED_PROTOCOL = 'RESET_REGISTERED_PROTOCOL';
export const GET_NEUROMOTOR_TESTS = 'GET_NEUROMOTOR_TESTS';
export const GET_NEUROMOTOR_TESTS_PENDING = 'GET_NEUROMOTOR_TESTS_PENDING';
export const GET_NEUROMOTOR_TESTS_REJECTED = 'GET_NEUROMOTOR_TESTS_REJECTED';
export const GET_ONE_NEUROMOTOR_TEST = 'GET_ONE_NEUROMOTOR_TEST';
export const MODE_BC_PROTOCOL_APPLY = 1;
export const MODE_BC_MANUALLY = 2;
export const BONE_WEIGHT_FEMORAL_BIEPICONDILO = 'femoral biepicondilo';
export const BONE_WEIGHT_BIESTILOIDE = 'biestiloide';
export const PERCENTAGE_FAT_ID = 2;
export const BODY_DENSITY_ID = 1;
export const CALCULATE_BODY_COMPOSITION = 'CALCULATE_BODY_COMPOSITION';
export const SELECTED_CARDIO_PROTOCOL = 'SELECTED_CARDIO_PROTOCOL';
export const GET_CARDIO = 'GET_CARDIO';
export const GET_CARDIO_REQUEST = 'GET_CARDIO_REQUEST';
export const SAVE_EVALUATION_CARDIO = 'SAVE_EVALUATION_CARDIO';
export const SAVE_EVALUATION_CARDIO_VARIABLES = 'SAVE_EVALUATION_CARDIO_VARIABLES';
export const GET_CARDIO_VARIABLES_EVALUATION = 'GET_CARDIO_VARIABLES_EVALUATION';
export const CALCULATE_CARDIORESPIRATORY = 'CALCULATE_CARDIORESPIRATORY';
export const UPDATE_EVALUATION_CARDIO_VARIABLES = 'UPDATE_EVALUATION_CARDIO_VARIABLES';
export const GET_NEURO = 'GET_NEURO';
export const SELECTED_NEURO_PROTOCOL = 'SELECTED_NEURO_PROTOCOL';
export const SET_PROTOCOLS_NEUROMOTOR = 'SET_PROTOCOLS_NEUROMOTOR';
export const SAVE_EVALUATION_NEURO_VARIABLES = 'SAVE_EVALUATION_NEURO_VARIABLES';
export const GET_EVALUATION_NEURO_VARIABLES = 'GET_EVALUATION_NEURO_VARIABLES';
export const UPDATE_EVALUATION_NEURO_VARIABLES = 'UPDATE_EVALUATION_NEURO_VARIABLES';
export const CALCULATE_NEUROMOTOR = 'CALCULATE_NEUROMOTOR';
export const SAVE_EVALUATION_NEUROMOTOR_RESULT = 'SAVE_EVALUATION_NEUROMOTOR_RESULT';
export const SAVE_EVALUATION_NEUROMOTOR_RESULT_REQUEST = 'SAVE_EVALUATION_NEUROMOTOR_RESULT_REQUEST';
export const GET_NEUROMOTOR_EVALUATION_RESULT = 'GET_NEUROMOTOR_EVALUATION_RESULT';
export const GET_NEUROMOTOR_EVALUATION_RESULT_REQUEST = 'GET_NEUROMOTOR_EVALUATION_RESULT_REQUEST';
export const GET_NEUROMOTOR_EVALUATION_RESULT_PENDING = 'GET_NEUROMOTOR_EVALUATION_RESULT_PENDING';
export const GET_NEUROMOTOR_EVALUATION_RESULT_REJECTED = 'GET_NEUROMOTOR_EVALUATION_RESULT_REJECTED';
export const GET_NEUROMOTOR_EVALUATION_RESULT_ERROR = 'GET_NEUROMOTOR_EVALUATION_RESULT_ERROR';
export const GET_ALL_NEUROMOTOR_EVALUATION_RESULTS = 'GET_ALL_NEUROMOTOR_EVALUATION_RESULTS';
export const GET_ALL_NEUROMOTOR_EVALUATION_RESULTS_REQUEST = 'GET_ALL_NEUROMOTOR_EVALUATION_RESULTS_REQUEST';
export const GET_ALL_NEUROMOTOR_EVALUATION_RESULTS_PENDING = 'GET_ALL_NEUROMOTOR_EVALUATION_RESULTS_PENDING';
export const GET_ALL_NEUROMOTOR_EVALUATION_RESULTS_REJECTED = 'GET_ALL_NEUROMOTOR_EVALUATION_RESULTS_REJECTED';
export const GET_ALL_NEUROMOTOR_EVALUATION_RESULTS_ERROR = 'GET_ALL_NEUROMOTOR_EVALUATION_RESULTS_ERROR';

// SELECT
export const SIMPLE_SELECT_SEARCH = 1;
export const MULTIPLE_SELECT_SEARCH = 2;

// MY ACCOUNT
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const CHANGE_PASS = 'CHANGE_PASS';
export const CHANGE_PASS_INVALID_ERROR = 'CHANGE_PASS_INVALID_ERROR';
export const CHANGE_PASS_FULFILLED = 'CHANGE_PASS_FULFILLED';
export const UPDATE_NOTIFICATION_REQUEST = 'UPDATE_NOTIFICATION_REQUEST';
export const UPDATE_NOTIFICATION_FULFILLED = 'UPDATE_NOTIFICATION_FULFILLED';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const UPDATE_NOTIFICATION_REQUEST_FULFILLED =
  'UPDATE_NOTIFICATION_REQUEST_FULFILLED';

// USERS MANAGEMENT
export const GET_USERS = 'GET_USERS';
export const GET_USERS_REJECTED = 'GET_USERS_REJECTED';
export const GET_USERS_PENDING = 'GET_USERS_PENDING';
export const ADD_USER = 'ADD_USER';
export const DELETE_USER = 'DELETE_USER';
export const REGISTER_SELECTED_USER = 'REGISTER_SELECTED_USER';
export const UPDATE_PERMISSION = 'UPDATE_PERMISSION';
export const SET_USER_PERSON = 'SET_USER_PERSON';
export const GET_USERS_STATISTICS = 'GET_USERS_STATISTICS';

// SESSIONS MANAGEMENT
export const GET_SESSIONS = 'GET_SESSIONS';
export const GET_SESSIONS_PENDING = 'GET_SESSIONS_PENDING';
export const DISCONNECT_SESSION = 'DISCONNECT_SESSION';
export const REGISTER_SELECTED_SESSION = 'REGISTER_SELECTED_SESSION';

// MODULES
export const GET_MODULES = 'GET_MODULES';

//  PLANS
export const GET_PLANS = 'GET_PLANS';
export const GET_ONE_PLAN = 'GET_ONE_PLAN';
export const GET_PLANS_PENDING = 'GET_PLANS_PENDING';
export const GET_PLANS_REJECTED = 'GET_PLANS_REJECTED';
export const CREATE_PLAN = 'CREATE_PLAN';
export const ADD_PLAN = 'ADD_PLAN';
export const ADD_REGISTER_PLAN = 'ADD_REGISTER_PLAN';
export const REGISTER_SELECTED_PLAN = 'REGISTER_SELECTED_PLAN';
export const UPDATE_PLAN_STATUS = 'UPDATE_PLAN_STATUS';
export const UPDATE_REGISTER_PLAN = 'UPDATE_REGISTER_PLAN';
export const DELETE_REGISTER_PLAN = 'DELETE_REGISTER_PLAN';
export const UPDATE_PLAN = 'UPDATE_PLAN';
export const DELETE_PLAN = 'DELETE_PLAN';
export const GET_PAYMENT_RECORDS = 'GET_PAYMENT_RECORDS';
export const UPDATE_SUBSCRIPTION_PAYMENT_DATA = 'UPDATE_SUBSCRIPTION_PAYMENT_DATA';
export const UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD';
export const CREDIT_CARD_UPDATE = 'CREDIT_CARD_UPDATE';
export const CREDIT_CARD_UPDATE_FULFILLED = 'CREDIT_CARD_UPDATE_FULFILLED';
export const CREDIT_CARD_UPDATE_PENDING = 'CREDIT_CARD_UPDATE_PENDING';
export const RETRY_SUBSCRIPTION_PAYMENT = 'RETRY_SUBSCRIPTION_PAYMENT';
export const CHANGE_PLAN_SUB = 'CHANGE_PLAN_SUB';
export const CHANGE_PLAN_SUB_FULFILLED = 'CHANGE_PLAN_SUB_FULFILLED';
export const CHANGE_PLAN_SUB_PENDING = 'CHANGE_PLAN_SUB_PENDING';
export const ENABLE_FREE_TRIAL_PERIOD = 'ENABLE_FREE_TRIAL_PERIOD';
export const GET_CLIENT_OVERDUE_PAYMENTS = 'GET_CLIENT_OVERDUE_PAYMENTS';

// CLIENTS
export const GET_CLIENT_SAFE_INFO = 'GET_CLIENT_SAFE_INFO';
export const GET_CLIENT_SAFE_INFO_PENDING = 'GET_CLIENT_SAFE_INFO_PENDING';
export const GET_CLIENT_SAFE_INFO_REJECTED = 'GET_CLIENT_SAFE_INFO_REJECTED';
export const GET_CLIENTS = 'GET_CLIENTS';
export const GET_CLIENTS_PENDING = 'GET_CLIENTS_PENDING';
export const GET_CLIENTS_FULFILLED = 'GET_CLIENTS_FULFILLED';
export const GET_CLIENTS_REJECTED = 'GET_CLIENTS_REJECTED';
export const ADD_CLIENT = 'ADD_CLIENT';
export const ADD_REGISTER_CLIENT = 'ADD_REGISTER_CLIENT';
export const REGISTER_SELECTED_CLIENT = 'REGISTER_SELECTED_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const UPDATE_REGISTER_CLIENT = 'UPDATE_REGISTER_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const DELETE_REGISTER_CLIENT = 'DELETE_REGISTER_CLIENT';
export const SET_LOGO_REJECTED = 'SET_LOGO_REJECTED';
export const SET_LOGO_REQUEST = 'SET_LOGO_REQUEST';
export const SET_LOGO = 'SET_LOGO';
export const FREE_CLIENT_REJECTED = 'FREE_CLIENT_REJECTED'
export const FREE_CLIENT_PENDING = 'FREE_CLIENT_PENDING'
export const APPROVE_OR_REJECT_CLIENT = 'APPROVE_OR_REJECT_CLIENT';
export const APPROVE_OR_REJECT_CLIENT_ERROR = 'APPROVE_OR_REJECT_CLIENT_ERROR';

export const GET_NEEDS_APPROVAL_CLIENTS = 'GET_NEEDS_APPROVAL_CLIENTS';

// SIGNATURES
export const CREATE_REGISTER_CLIENT_PLAN = 'ADD_REGISTER_SIGNATURE';
export const CREATE_CLIENT_PLAN = 'CREATE_CLIENT_PLAN';
export const GET_CLIENT_PLAN = 'GET_CLIENT_PLAN';
export const GET_CLIENT_PLAN_REQUEST_FULFILLED = 'GET_CLIENT_PLAN_REQUEST_FULFILLED';

export const CANCEL_CLIENT_PLAN = 'CANCEL_CLIENT_PLAN';

// THEMES
export const GET_THEMES = 'GET_THEMES';
export const GET_THEMES_PENDING = 'GET_THEMES_PENDING';
export const GET_THEMES_REJECTED = 'GET_THEMES_REJECTED';

// LANGUAGES
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';

// FAT CATEGORIES
export const GET_FAT_CATEGORIES = 'GET_FAT_CATEGORIES';

// FAT PERCENTAGES
export const GET_FAT_PERCENTAGES = 'GET_FAT_PERCENTAGES';
export const GET_FAT_PERCENTAGES_IDEAL = 'GET_FAT_PERCENTAGES_IDEAL';
export const ADD_FAT_PERCENTAGE_IDEAL = 'ADD_FAT_PERCENTAGE_IDEAL';
export const UPDATE_FAT_PERCENTAGE_IDEAL = 'UPDATE_FAT_PERCENTAGE_IDEAL';
export const DELETE_FAT_PERCENTAGE_IDEAL = 'DELETE_FAT_PERCENTAGE_IDEAL';
export const CLEAR_FAT_PERCENTAGES_IDEAL = 'CLEAR_FAT_PERCENTAGES_IDEAL';
export const SELECTED_FAT_PERCENTAGES_IDEAL = 'SELECTED_FAT_PERCENTAGES_IDEAL';
export const DELETE_REGISTER_FAT_PERCENTAGES_IDEAL =
'DELETE_REGISTER_FAT_PERCENTAGES_IDEAL';
export const MIN_AGE_ADULT = 18;
export const MAX_AGE_ADULT = 100;
export const MIN_AGE_TEENAGE = 0;
export const MAX_AGE_TEENAGE = 17;
export const KEY_SIZE_PADDING = 12;

// REPORT BUG
export const ADD_REPORT_BUG = 'ADD_REPORT_BUG';
export const ADDED_REPORT_BUG = 'ADDED_REPORT_BUG';
export const ADDED_REPORT_BUG_ERROR = 'ADDED_REPORT_BUG_ERROR';

// REPORT LOADING
export const START_REPORT_LOAD = 'START_REPORT_LOAD';
export const FINISH_REPORT_LOAD = 'FINISH_REPORT_LOAD';

// CHART
export const TOGGLE_CHART = 'TOGGLE_CHART';

// BODY MEASURE TYPES
export const BODY_MEASURES = ['diametersDefault', 'othersDefault', 'perimetersDefault', 'skinfoldsDefault'];

export const RESEND_INVITE = 'RESEND_INVITE';

// SIGNATURE OF PLANS
export const GENERATE_GATEWAY_SESSION = 'GENERATE_GATEWAY_SESSION';
export const GENERATE_GATEWAY_SESSION_ERROR = 'GENERATE_GATEWAY_SESSION_ERROR';
export const CREATE_CLIENT_PLAN_FULFILLED = 'CREATE_CLIENT_PLAN_FULFILLED';
export const UPDATE_BILLING_ADDRESS = 'UPDATE_BILLING_ADDRESS';
export const ACCESS_CONTROL_PLANS_MODULE = 'ACCESS_CONTROL_PLANS_MODULE';

// ERRORS SUBSCRIPTION
export const CANCEL_CLIENT_PLAN_ERROR = 'CANCEL_CLIENT_PLAN_ERROR';
export const RETRY_SUBSCRIPTION_PAYMENT_ERROR = 'RETRY_SUBSCRIPTION_PAYMENT_ERROR';
export const CREATE_CLIENT_PLAN_ERROR = 'CREATE_CLIENT_PLAN_ERROR';
export const UPDATE_PAYMENT_METHOD_ERROR = 'UPDATE_PAYMENT_METHOD_ERROR';
export const CHANGE_PLAN_SUB_ERROR = 'CHANGE_PLAN_SUB_ERROR';

export const ANOTHER_SESSION = "ANOTHER_SESSION"

export const CLIENT_TYPES = {
  1: "PF",
  2: "PJ",
  3: "STUDENT",
  4: "UNIVERSITY"
}

export const [PF, PJ, STUDENT, UNIVERSITY] = Object.values(CLIENT_TYPES)

// LINKS
export const GET_PROMOTIONAL_LINK = 'GET_PROMOTIONAL_LINK';
export const GET_PROMOTIONAL_LINK_PENDING = 'GET_PROMOTIONAL_LINK_PENDING';
export const GET_PROMOTIONAL_LINK_REJECTED = 'GET_PROMOTIONAL_LINK_REJECTED';
export const SET_PROMOTIONAL_LINKS = 'SET_PROMOTIONAL_LINKS';
export const REGISTER_SELECTED_PROMOTIONAL_LINK = 'REGISTER_SELECTED_PROMOTIONAL_LINK';
export const DELETE_REGISTER_PROMOTIONAL_LINK = 'DELETE_REGISTER_PROMOTIONAL_LINK';
export const DELETE_PROMOTIONAL_LINK = 'DELETE_PROMOTIONAL_LINK';
export const ADD_REGISTER_PROMOTIONAL_LINK = 'ADD_REGISTER_PROMOTIONAL_LINK';
export const ADD_PROMOTIONAL_LINK = 'ADD_PROMOTIONAL_LINK';
export const UPDATE_REGISTER_PROMOTIONAL_LINK = 'UPDATE_REGISTER_PROMOTIONAL_LINK';
export const UPDATE_PROMOTIONAL_LINK = 'UPDATE_PROMOTIONAL_LINK';
export const LINK_ALREADY_EXIST_ERROR = 'LINK_ALREADY_EXIST_ERROR';

// EXERCISES
export const GET_EXERCISES = 'GET_EXERCISES';
export const GET_EXERCISES_PENDING = 'GET_EXERCISES_PENDING';
export const GET_EXERCISES_REJECTED = 'GET_EXERCISES_REJECTED';
export const ADD_EXERCISE = 'ADD_EXERCISE';
export const ADD_REGISTER_EXERCISE = 'ADD_REGISTER_EXERCISE';
export const UPDATE_EXERCISE = 'UPDATE_EXERCISE';
export const UPDATE_REGISTER_EXERCISE = 'UPDATE_REGISTER_EXERCISE';
export const DELETE_EXERCISE = 'DELETE_EXERCISE';
export const DELETE_REGISTER_EXERCISE = 'DELETE_REGISTER_EXERCISE';
export const GET_EXERCISES_BY_GROUP_TYPE = 'GET_EXERCISES_BY_GROUP_TYPE';
export const GET_ONE_EXERCISE = 'GET_ONE_EXERCISE';
export const REGISTER_SELECTED_EXERCISE = 'REGISTER_SELECTED_EXERCISE';
export const GET_EXERCISE_TYPES = 'GET_EXERCISE_TYPES';
export const GET_EXERCISE_TYPES_PENDING = 'GET_EXERCISE_TYPES_PENDING';
export const GET_EXERCISE_TYPES_REJECTED = 'GET_EXERCISE_TYPES_REJECTED';
export const GET_ONE_EXERCISE_ERROR = 'GET_ONE_EXERCISE_ERROR';
export const EXERCISE_MEDIA_REJECTED = 'EXERCISE_MEDIA_REJECTED';
export const EXERCISE_MEDIA_REQUEST = 'EXERCISE_MEDIA_REQUEST';
export const EXERCISE_MEDIA_REQUEST_ERROR = 'EXERCISE_MEDIA_REQUEST_ERROR';
export const SET_EXERCISE_MEDIA = 'SET_EXERCISE_MEDIA';
export const SET_EXERCISE_MEDIA_ERROR = 'SET_EXERCISE_MEDIA_ERROR';
export const FILE_TYPE_NOT_SUPPORTED = 'FILE_TYPE_NOT_SUPPORTED';
export const FILE_REMOVE_ERROR = 'FILE_REMOVE_ERROR';
export const FILE_IS_NOT_IMAGE = 'FILE_IS_NOT_IMAGE';
export const FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR';
export const FILE_UPDATE_ERROR = 'FILE_UPDATE_ERROR';

export const GET_MUSCLES = 'GET_MUSCLES';
export const GET_MUSCLES_PENDING = 'GET_MUSCLES_PENDING';
export const GET_MUSCLES_REJECTED = 'GET_MUSCLES_REJECTED';
export const GET_MUSCLE_GROUPS = 'GET_MUSCLE_GROUPS';
export const GET_MUSCLE_GROUPS_PENDING = 'GET_MUSCLE_GROUPS_PENDING';
export const GET_MUSCLE_GROUPS_REJECTED = 'GET_MUSCLE_GROUPS_REJECTED';

export const GET_PRESET_WORKOUTS = 'GET_PRESET_WORKOUTS';
export const GET_PRESET_WORKOUTS_PENDING = 'GET_PRESET_WORKOUTS_PENDING';
export const GET_PRESET_WORKOUTS_REJECTED= 'GET_PRESET_WORKOUTS_REJECTED';
export const GET_ONE_PRESET_WORKOUT = 'GET_ONE_PRESET_WORKOUT';
export const GET_PRESET_WORKOUTS_BY_EVALUATED = 'GET_PRESET_WORKOUTS_BY_EVALUATED';
export const ADD_PRESET_WORKOUT = 'ADD_PRESET_WORKOUT';
export const ADD_REGISTER_PRESET_WORKOUT = 'ADD_REGISTER_PRESET_WORKOUT';
export const UPDATE_PRESET_WORKOUT = 'UPDATE_PRESET_WORKOUT';
export const UPDATE_REGISTER_PRESET_WORKOUT = 'UPDATE_REGISTER_PRESET_WORKOUT';
export const DELETE_PRESET_WORKOUT = 'DELETE_PRESET_WORKOUT';
export const DELETE_REGISTER_PRESET_WORKOUT = 'DELETE_REGISTER_PRESET_WORKOUT';
export const REGISTER_SELECTED_PRESET_WORKOUT = 'REGISTER_SELECTED_PRESET_WORKOUT';

export const GET_EVALUATED_WORKOUTS = 'GET_EVALUATED_WORKOUTS';
export const GET_EVALUATED_WORKOUTS_PENDING = 'GET_EVALUATED_WORKOUTS_PENDING';
export const GET_EVALUATED_WORKOUTS_REJECTED = 'GET_EVALUATED_WORKOUTS_REJECTED';
export const GET_ONE_EVALUATED_WORKOUT = 'GET_ONE_EVALUATED_WORKOUT';
export const GET_EVALUATED_WORKOUTS_BY_EVALUATED = 'GET_EVALUATED_WORKOUTS_BY_EVALUATED';
export const ADD_EVALUATED_WORKOUT = 'ADD_EVALUATED_WORKOUT';
export const ADD_REGISTER_EVALUATED_WORKOUT = 'ADD_REGISTER_EVALUATED_WORKOUT';
export const UPDATE_EVALUATED_WORKOUT = 'UPDATE_EVALUATED_WORKOUT';
export const UPDATE_REGISTER_EVALUATED_WORKOUT = 'UPDATE_REGISTER_EVALUATED_WORKOUT';
export const DELETE_EVALUATED_WORKOUT = 'DELETE_EVALUATED_WORKOUT';
export const DELETE_REGISTER_EVALUATED_WORKOUT = 'DELETE_REGISTER_EVALUATED_WORKOUT';
export const REGISTER_SELECTED_EVALUATED_WORKOUT = 'REGISTER_SELECTED_EVALUATED_WORKOUT';
export const COPY_TRAINING_BY_WORKOUT = 'COPY_TRAINING_BY_WORKOUT';
export const COPY_TRAINING_BY_EVALUATED = 'COPY_TRAINING_BY_EVALUATED';
export const COPY_TRAINING_BY_WORKOUT_ERROR = 'COPY_TRAINING_BY_WORKOUT_ERROR';
export const COPY_TRAINING_BY_EVALUATED_ERROR = 'COPY_TRAINING_BY_EVALUATED_ERROR';

export const WEIGHT_TRAINING_EXERCISE_TYPE = 1;
export const CARDIO_EXERCISE_TYPE = 2;
export const TRAINING_LIST = ['A', 'B', 'C', 'D', 'E', 'F'];

export const GET_CLIENT_PLAN_STATISTICS = 'GET_CLIENT_PLAN_STATISTICS';
export const GET_CLIENT_ARREARS_STATISTICS = 'GET_CLIENT_ARREARS_STATISTICS';
export const GET_ACTIVE_EVALUATED_WORKOUT = 'GET_ACTIVE_EVALUATED_WORKOUT';
export const GET_IN_PROGRESS_WORKOUT_SESSIONS = 'GET_IN_PROGRESS_WORKOUT_SESSIONS';
export const ADD_WORKOUT_SESSION = 'ADD_WORKOUT_SESSION';
export const FINISH_WORKOUT_SESSION = 'FINISH_WORKOUT_SESSION';
export const GET_WORKOUT_SESSIONS_EXERCISE = 'GET_WORKOUT_SESSIONS_EXERCISE';
export const START_WORKOUT_SESSION_EXERCISE = 'START_WORKOUT_SESSION_EXERCISE';
export const FINISH_WORKOUT_SESSION_EXERCISE = 'FINISH_WORKOUT_SESSION_EXERCISE';

export const GET_CLIENT_STATISTICS = 'GET_CLIENT_STATISTICS';
export const GET_CLIENT_STATISTICS_PENDING = 'GET_CLIENT_STATISTICS_PENDING';
export const GET_CLIENT_STATISTICS_FULFILLED = 'GET_CLIENT_STATISTICS_FULFILLED';
export const GET_CLIENT_STATISTICS_REJECTED = 'GET_CLIENT_STATISTICS_REJECTED';

export const GET_CLIENT_USAGE_STATISTICS = 'GET_CLIENT_USAGE_STATISTICS';
export const GET_CLIENT_USAGE_STATISTICS_PENDING = 'GET_CLIENT_USAGE_STATISTICS_PENDING';
export const GET_CLIENT_USAGE_STATISTICS_FULFILLED = 'GET_CLIENT_USAGE_STATISTICS_FULFILLED';
export const GET_CLIENT_USAGE_STATISTICS_REJECTED = 'GET_CLIENT_USAGE_STATISTICS_REJECTED';

export const GET_CLIENT_ACCESS_LOG = 'GET_CLIENT_ACCESS_LOG';
export const GET_CLIENT_ACCESS_LOG_PENDING = 'GET_CLIENT_ACCESS_LOG_PENDING';
export const GET_CLIENT_ACCESS_LOG_FULFILLED = 'GET_CLIENT_ACCESS_LOG_FULFILLED';
export const GET_CLIENT_ACCESS_LOG_REJECTED = 'GET_CLIENT_ACCESS_LOG_REJECTED';

export const SELECT_DASHBOARD_CLIENT = 'SELECT_DASHBOARD_CLIENT';

export const SET_NEW_FEATURES = 'SET_NEW_FEATURES';
export const ADD_FEATURE_VIEWED = 'ADD_FEATURE_VIEWED';
export const GET_FEATURES_VIEWED_BY_PERSON = 'GET_FEATURES_VIEWED_BY_PERSON';
export const GET_FEATURES_VIEWED_BY_PERSON_PENDING = 'GET_FEATURES_VIEWED_BY_PERSON_PENDING';
export const GET_FEATURES_VIEWED_BY_PERSON_REJECTED = 'GET_FEATURES_VIEWED_BY_PERSON_REJECTED';
