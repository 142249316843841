import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';

const initialState = Immutable.from({
  neuroProtocols: [],
  loading: false,
  shouldGetNeuroProtocols: true,
});

export default function neuroProtocols(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_NEUROMOTOR_TESTS_PENDING:
      const loading = state.updateIn(['loading'], () => true);
      return loading;
    case Actions.GET_NEUROMOTOR_TESTS_REJECTED:
      const rej = state.updateIn(['loading'], () => false);
      return rej;
    case Actions.GET_NEUROMOTOR_TESTS:
      const variabs = state
        .updateIn(['neuroProtocols'], () => action.payload)
        .updateIn(['loading'], () => false)
        .updateIn(['shouldGetNeuroProtocols'], () => false);
      return variabs;
    default:
      return state;
  }
}
