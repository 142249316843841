import React from 'react';

const IconDotsSixVertical = (props) => (
  <svg
    width={props.width}
    height={props.height}
    fill="#000000"
    viewBox="0 0 256 256"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M108,60A16,16,0,1,1,92,44,16,16,0,0,1,108,60Zm56,16a16,16,0,1,0-16-16A16,16,0,0,0,164,76ZM92,112a16,16,0,1,0,16,16A16,16,0,0,0,92,112Zm72,0a16,16,0,1,0,16,16A16,16,0,0,0,164,112ZM92,180a16,16,0,1,0,16,16A16,16,0,0,0,92,180Zm72,0a16,16,0,1,0,16,16A16,16,0,0,0,164,180Z"></path>
  </svg>
);

IconDotsSixVertical.defaultProps = {
  width: 32,
  height: 32,
  color: 'default'
};

export default IconDotsSixVertical;
