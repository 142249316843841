import {
  HANDLE_IMAGE_FRONT,
  HANDLE_IMAGE_RIGHT,
  HANDLE_IMAGE_BEHIND,
  HANDLE_IMAGE_LEFT,
  DESTROY_IMAGE,
} from '../../constant/constants';

const imgFrontWoman = require('../../images/body-front-woman.png');
const imgFrontMan = require('../../images/body-front-man.png');
const imgRightMan = require('../../images/body-right-man.png');
const imgRightWoman = require('../../images/body-right-woman.png');
const imgBehindMan = require('../../images/body-behind-man.png');
const imgBehindWoman = require('../../images/body-behind-woman.png');
const imgLeftMan = require('../../images/body-left-man.png');
const imgLeftWoman = require('../../images/body-left-woman.png');

const initialState = {
  imgWoman: '',
  imgMan: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case HANDLE_IMAGE_FRONT:
      return { ...state, imgMan: imgFrontMan, imgWoman: imgFrontWoman };
    case HANDLE_IMAGE_RIGHT:
      return { ...state, imgMan: imgRightMan, imgWoman: imgRightWoman };
    case HANDLE_IMAGE_BEHIND:
      return { ...state, imgMan: imgBehindMan, imgWoman: imgBehindWoman };
    case HANDLE_IMAGE_LEFT:
      return { ...state, imgMan: imgLeftMan, imgWoman: imgLeftWoman };
    case DESTROY_IMAGE:
      return { ...state, imgMan: '', imgWoman: '' };
    default:
      return state;
  }
}
