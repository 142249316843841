import { push } from 'react-router-redux';
import * as Actions from '../../constant/constants';
import socket from '../../socket';

export function getFormulas() {
  return {
    type: Actions.GET_FORMULAS,
    payload: socket.sendMessage(Actions.GET_FORMULAS, []),
  };
}

export function setFormulas(event) {
  let formulas = [];
  if (typeof event === 'undefined') socket.sendMessage(Actions.GET_FORMULAS, []);
  else formulas = event.data;
  return {
    type: Actions.GET_FORMULAS,
    payload: formulas,
  };
}

// SELECT REGISTER
export function selectRegisterTest(formula, measures, variables, cardioVariables) {
  return {
    type: Actions.REGISTER_TEST_FORMULA,
    payload: {
      formula,
      measures,
      variables,
      cardioVariables,
    },
  };
}

// SELECT REGISTER
export function selectRegister(formula, redirect = '') {
  return function (dispatch) {
    if (redirect) {
      dispatch(push(redirect));
    }
    dispatch({
      type: Actions.REGISTER_SELECTED_FORMULA,
      payload: formula,
    });
  };
}

// ADD FORMULA
export function addFormula(event) {
  let formula = {};
  formula = event.data;
  return {
    type: Actions.ADD_REGISTER_FORMULA,
    payload: formula,
  };
}

export function addRegister(
  title,
  calculation,
  minage,
  maxage,
  codifiedExpression,
  measures,
  variables,
  classification,
  genders,
  ethnicities,
  maturations,
  type,
  cardioVariablesFields,
  cardioVariables,
  excerpt,
  description,
) {
  return {
    type: Actions.ADD_REQUEST,
    payload: socket.sendMessage(Actions.ADD_FORMULA, {
      idmethod: Actions.ANTHROPOMETRY_METHOD,
      title: title,
      type,
      idtypecalculation: calculation,
      minage,
      maxage,
      expression: codifiedExpression,
      measures,
      variables,
      classifications: classification,
      genders,
      ethnicities,
      sexualmaturations: maturations,
      cardioVariablesFields,
      cardioVariables,
      excerpt,
      description,
    }),
  };
}

// EDIT FORMULA
export function editFormula(event) {
  let formulaEdit = {};
  formulaEdit = event.data;
  return {
    type: Actions.EDIT_REGISTER_FORMULA,
    payload: formulaEdit,
  };
}
export function editRegister(
  idformula,
  title,
  calculation,
  minage,
  maxage,
  codifiedExpression,
  measures,
  variables,
  classification,
  genders,
  ethnicities,
  maturations,
  type,
  cardioVariablesFields,
  cardioVariables,
  excerpt,
  description,
) {
  return {
    type: Actions.UPDATE_REQUEST,
    payload: socket.sendMessage(Actions.UPDATE_FORMULA, {
      idmethod: Actions.ANTHROPOMETRY_METHOD,
      id: idformula,
      title: title,
      type,
      idtypecalculation: calculation,
      minage,
      maxage,
      expression: codifiedExpression,
      measures,
      variables,
      classifications: classification,
      genders,
      ethnicities,
      sexualmaturations: maturations,
      cardioVariablesFields,
      cardioVariables,
      excerpt,
      description,
    }),
  };
}

// DELETE FORMULA
export function deleteFormula(event) {
  let formulaDelete = {};
  formulaDelete = event.data;
  return {
    type: Actions.DELETE_REGISTER_FORMULA,
    payload: formulaDelete,
  };
}
export function deleteRegister(id) {
  return {
    type: Actions.DELETE_REQUEST,
    payload: socket.sendMessage(Actions.DELETE_FORMULA, { id }),
  };
}

// GET ONE FORMULA
export function getOneFormula(idformula) {
  return {
    type: Actions.GET_ONE_FORMULA,
    payload: socket.sendMessage(Actions.GET_ONE_FORMULA, { id: idformula }),
  };
}
