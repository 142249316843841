import React from 'react';

const IconNewEvaluated = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Capa_1"
    className="icon-new-evaluated"
    viewBox="-211 302.9 188.1 188.1"
    width={props.width}
    height={props.height}
  >
    <circle id="SVGID_1_" cx="-116.9" cy="396.9" r="94.1" className="st0" />
    <defs>
      <circle id="SVGID_2_" cx="-116.9" cy="396.9" r="94.1" />
    </defs>
    <path
      d="M-84.3 456.8c-2.1-1.5-4.2-2.9-6.4-4.2-.1 0-.1-.1-.2-.1-10.6-5.9-17.9-17.2-17.9-30.2l-16.1-.1c0 13.1-7.3 24.5-18 30.3-2.3 1.2-4.4 2.6-6.5 4.2-13.4 9.9-22 25.8-22 43.8h109.1c-.1-17.9-8.7-33.8-22-43.7z"
      className="st1"
    />
    <defs>
      <circle id="SVGID_4_" cx="-116.9" cy="396.9" r="94.1" />
    </defs>
    <defs>
      <circle id="SVGID_4_" cx="-116.9" cy="396.9" r="94.1" />
    </defs>
    <path
      d="M-62.4 500.5h-109.1c0-17.9 8.6-33.8 22-43.8 2.1-1.5 4.2-2.9 6.5-4.2 1.8-1 3.4-2.1 5-3.3 5.3 5.5 12.8 8.9 21.1 8.9 8.2 0 15.7-3.4 21-8.9 1.5 1.3 3.2 2.4 4.9 3.3.1 0 .1.1.2.1 2.3 1.2 4.4 2.6 6.4 4.2 13.4 9.9 22 25.8 22 43.7z"
      className="st2"
    />
    <defs>
      <circle id="SVGID_6_" cx="-116.9" cy="396.9" r="94.1" />
    </defs>
    <path
      d="M-158.8 340.9v42.8c0 29 25.4 52.5 41.9 52.5 16.4 0 41.9-23.5 41.9-52.5v-42.8h-83.8z"
      className="st3"
    />
    <defs>
      <circle id="SVGID_8_" cx="-116.9" cy="396.9" r="94.1" />
    </defs>
    <path
      d="M-81.9 333.1c-9.1-11.4-23-18.7-38.5-18.7-27.5 0-49.8 23-49.8 51.3 0 15 6.3 28.6 16.3 37.9-2.6-6.5-4-13.7-4-21.4 0-12.3 3.7-23.6 10-32.2 6.6 2.9 17.3 4 29 2.4 11.6-1.6 21.5-5.4 27.1-10 10.2 9 16.8 23.5 16.8 39.9 0 8.4-1.7 16.3-4.8 23.2 9.8-4.1 17.1-18.6 17.1-35.8-.1-18.6-8.3-33.9-19.2-36.6z"
      className="st4"
    />
    <circle cx="-176.1" cy="456" r="34.9" className="st5" />
    <path
      d="M-168.4 483.4c-19.3 0-34.9-15.7-34.9-34.9 0-7.7 2.5-14.8 6.7-20.6-8.7 6.3-14.4 16.6-14.4 28.2 0 19.3 15.7 34.9 34.9 34.9 11.6 0 21.9-5.7 28.2-14.4-5.7 4.2-12.8 6.8-20.5 6.8z"
      className="st6"
    />
    <path
      d="M-158.4 452.7H-173v-14.4c0-1.1-1-2-2.2-2h-2.2c-1.2 0-2.2.9-2.2 2v14.4H-194c-1.1 0-2 1-2 2.2v2.2c0 1.2.9 2.2 2 2.2h14.4v14.4c0 1.1 1 2 2.2 2h2.2c1.2 0 2.2-.9 2.2-2v-14.4h14.7c1.1 0 2-1 2-2.2v-2.2c-.1-1.2-1-2.2-2.1-2.2z"
      className="st7"
    />
  </svg>
);

IconNewEvaluated.defaultProps = {
  width: 110,
  color: 'default',
};

export default IconNewEvaluated;
