import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';

const initialState = Immutable.from({
  person: {},
  status: null,
  evaluator: null,
  evaluated: null,
  loading: false,
  flashMessage: {
    status: Actions.MESSAGE_STATUS_NULL,
    idMessage: '',
    defaultMessage: '',
  },
});

export default function checkEmail(state = initialState, action) {
  switch (action.type) {
    case Actions.EMAIL_ALREADY_EXISTS_PENDING:
      const loading = state.updateIn(['loading'], () => true);
      return loading;
    case Actions.RECEIVE_STATUS_EMAIL:
      const person = state
        .updateIn(['person'], () => action.payload.person)
        .updateIn(['status'], () => action.payload.status)
        .updateIn(['evaluator'], () => action.payload.evaluator)
        .updateIn(['evaluated'], () => action.payload.evaluated)
        .updateIn(['loading'], () => false);
      return person;
    case Actions.DESTROY_EMAIL:
      return initialState;
    default:
      return state;
  }
}
