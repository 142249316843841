import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';

const initialState = Immutable.from({
  classifications: [],
  classificationSelected: {},
  loading: false,
  shouldGetClassifications: true,
});

export default function classifications(state = initialState, action = {}) {
  switch (action.type) {
    case Actions.GET_CLASSIFICATIONS_PENDING:
      const loading = state.updateIn(['loading'], () => true);
      return loading;
    case Actions.GET_CLASSIFICATIONS_REJECTED:
      const rej = state.updateIn(['loading'], () => false);
      return rej;
    case Actions.GET_CLASSIFICATIONS:
      const classifications = state
        .updateIn(['classifications'], () => action.payload)
        .updateIn(['loading'], () => false)
        .updateIn(['shouldGetClassifications'], () => false);
      return classifications;
    case Actions.ADD_REGISTER_CLASSIFICATION:
      const newState = Immutable.merge({
        ...state,
        classifications: [...state.classifications, action.payload],
      });
      return newState;
    case Actions.REGISTER_SELECTED_CLASSIFICATION:
      const classificationSelected = state.set('classificationSelected', action.payload);
      return classificationSelected;
    case Actions.EDIT_REGISTER_CLASSIFICATION:
      const classificationIndex = state.classifications.findIndex(
        classification => classification.id === action.payload.id,
      );
      const newStateClassification = state.updateIn(
        ['classifications', classificationIndex],
        () => action.payload,
      );
      return newStateClassification;
    case Actions.DELETE_REGISTER_CLASSIFICATION:
      const index = state.classifications.findIndex(
        classification => classification.id === action.payload.id,
      );
      const deleteClassification = state.update('classifications', clss => clss.slice(0, index).concat(clss.slice(index + 1)));
      return deleteClassification;
    default:
      return state;
  }
}
