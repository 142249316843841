import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';

const initialState = Immutable.from({
  evaluationsSelected: {
    evaluations: [],
  },
  loadingEvaluationsSelected: true,
});

export default function comparisons(state = initialState, action = {}) {
  switch (action.type) {
    case Actions.GET_EVALUATIONS_COMPARISON_PENDING:
      const loading = state.updateIn(['loadingEvaluationsSelected'], () => true);
      return loading;
    case Actions.GET_EVALUATIONS_COMPARISON_REJECTED:
      const rej = state.updateIn(['loadingEvaluationsSelected'], () => false);
      return rej;
    case Actions.GET_EVALUATIONS_COMPARISON:
      const evaluations = state.updateIn(['loadingEvaluationsSelected'], () => false);
      return evaluations;
    case Actions.SELECTED_EVALUATIONS_COMPARISONS:
      const evaluationsSelected = state
        .updateIn(['evaluationsSelected', 'evaluations'], () => action.evaluations)
        .updateIn(['loadingEvaluationsSelected'], () => false);
      return evaluationsSelected;
    case Actions.SET_BODY_COMPOSITION_COMPARISON:
      const bodyComposition = state.updateIn(
        ['evaluationsSelected', 'evaluations'],
        () => action.payload,
      );
      return bodyComposition;
    default:
      return state;
  }
}
