import Immutable from 'seamless-immutable';

import * as Actions from '../../constant/constants';

const initialState = Immutable.from({
  subscriptions: [],
  loading: false,
  shouldGetSignatures: true,
  shouldGetSubscriptions: true,
});

export default function subscription(state = initialState, action = {}) {
  switch (action.type) {
    case Actions.CREATE_REGISTER_CLIENT_PLAN:
      const newState = Immutable.merge({
        ...state,
        subscriptions: [...state.subscriptions, action.payload]
      });
      return newState;
    default:
      return state;
  }
}