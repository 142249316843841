import { updateIntl } from 'react-intl-redux';
import moment from 'moment';
import * as Actions from '../../constant/constants';
import messages from '../../i18n/messages';
import socket from '../../socket';

export function localeChange(locale, idperson) {
  const newmessages = messages[locale];
  return function (dispatch) {
    dispatch(updateIntl({ locale, messages: newmessages }));
    window.localStorage.setItem('locale', locale);
    moment.locale(locale);
    dispatch({
      type: Actions.SET_LOCALE,
      payload: socket.sendMessage(Actions.SET_LOCALE, {
        locale,
        idperson,
      }),
    });
  };
}
