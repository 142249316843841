import * as Actions from '../../constant/constants';
import socket from '../../socket';

export function getMuscles() {
  return {
    type: Actions.GET_MUSCLES,
    payload: socket.sendMessage(Actions.GET_MUSCLES, {})
  }
}

export function setMuscles(res) {
  const data = !Array.isArray(res.data) ? [] : res.data.map((muscle) => {
    return convertMessages(muscle);
  });

  return {
    type: Actions.GET_MUSCLES,
    payload: data
  }
}

export function convertMessages(data) {
  const name = data.name;
  const nameWithoutSpaces = name.replace(/\s/g, '').toLocaleLowerCase();
  data.idMessageName = `app.exercise.${nameWithoutSpaces}`;
  data.defaultMessageName = data.name;
  return data;
}
