import * as Actions from '../../constant/constants';
import socket from '../../socket';

export function getClientsStatistics(initialDate, endDate) {
  return {
    type: Actions.GET_CLIENT_STATISTICS,
    payload: socket.sendMessage(Actions.GET_CLIENT_STATISTICS, {
      initialDate,
      endDate
    }),
  };
}

export function setClientStatistics(event){
  let clientStatistics = [];
  clientStatistics = event.data;
  return { type: Actions.GET_CLIENT_STATISTICS, payload: clientStatistics };
}

export function getClientsUsageStatistics(initialDate, endDate) {
  return {
    type: Actions.GET_CLIENT_USAGE_STATISTICS,
    payload: socket.sendMessage(Actions.GET_CLIENT_USAGE_STATISTICS, {
      initialDate,
      endDate
    }),
  };
}

export function getClientAccessLog(idclient) {
  return {
    type: Actions.GET_CLIENT_ACCESS_LOG,
    payload: socket.sendMessage(Actions.GET_CLIENT_ACCESS_LOG, {
      idclient
    }),
  };
}

export function setClientAccessLog(event){
  let clientAccessLog = [];
  clientAccessLog = event.data;
  return { type: Actions.GET_CLIENT_ACCESS_LOG, payload: clientAccessLog };
}

export function setClientSUsageStatistics(event){
  let clientUsageStatistics = [];
  clientUsageStatistics = event.data;
  return { type: Actions.GET_CLIENT_USAGE_STATISTICS, payload: clientUsageStatistics };
}

export function selectClientIdDashboard(idclient){
  return {
    type: Actions.SELECT_DASHBOARD_CLIENT, payload: idclient
  }
}