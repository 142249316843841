import React from 'react';

const IconSearchEvaluation = (props) => (
  <svg
    className="icon-search-evaluation"
    viewBox="-211 302.9 188.1 188.1"
    width={props.width}
    height={props.height}
  >
    <circle id="SVGID_1_" className="st0" cx="-116.9" cy="397" r="94.1" />
    <clipPath id="SVGID_3_">
      <circle id="SVGID_2_" cx="-116.9" cy="397" r="94.1" />
    </clipPath>
    <path
      className="st1"
      d="M-84.3 456.8c-2.1-1.5-4.2-2.9-6.4-4.2-.1 0-.1-.1-.2-.1-10.6-5.9-17.9-17.2-17.9-30.2l-16.1-.1c0 13.1-7.3 24.5-18 30.3-2.3 1.2-4.4 2.6-6.5 4.2-13.4 9.9-22 25.8-22 43.8h109.1c-.1-17.8-8.7-33.7-22-43.7z"
    />
    <clipPath id="SVGID_5_">
      <circle id="SVGID_4_" cx="-116.9" cy="397" r="94.1" />
    </clipPath>
    <path
      className="st2"
      d="M-62.4 500.5h-109.1c0-17.9 8.6-33.8 22-43.8 2.1-1.5 4.2-2.9 6.5-4.2 1.8-1 3.4-2.1 5-3.3 5.3 5.5 12.8 8.9 21.1 8.9 8.2 0 15.7-3.4 21-8.9 1.5 1.3 3.2 2.4 4.9 3.3.1 0 .1.1.2.1 2.3 1.2 4.4 2.6 6.4 4.2 13.4 10 22 25.9 22 43.7z"
    />
    <clipPath id="SVGID_7_">
      <circle id="SVGID_6_" cx="-116.9" cy="397" r="94.1" />
    </clipPath>
    <path
      className="st3"
      d="M-158.9 341v42.8c0 29 25.4 52.5 41.9 52.5 16.4 0 41.9-23.5 41.9-52.5V341h-83.8z"
    />
    <clipPath id="SVGID_9_">
      <circle id="SVGID_8_" cx="-116.9" cy="397" r="94.1" />
    </clipPath>
    <path
      className="st4"
      d="M-81.9 333.2c-9.1-11.4-23-18.7-38.5-18.7-27.5 0-49.8 23-49.8 51.3 0 15 6.3 28.6 16.3 37.9-2.6-6.5-4-13.7-4-21.4 0-12.3 3.7-23.6 10-32.2 6.6 2.9 17.3 4 29 2.4 11.6-1.6 21.5-5.4 27.1-10 10.2 9 16.8 23.5 16.8 39.9 0 8.4-1.7 16.3-4.8 23.2 9.8-4.1 17.1-18.6 17.1-35.8-.1-18.6-8.3-34-19.2-36.6z"
    />
    <circle className="st5" cx="-176.1" cy="456" r="34.9" />
    <path
      className="st6"
      d="M-168.4 483.5c-19.3 0-34.9-15.7-34.9-34.9 0-7.7 2.5-14.8 6.7-20.6-8.7 6.3-14.4 16.6-14.4 28.2 0 19.3 15.7 34.9 34.9 34.9 11.6 0 21.9-5.7 28.2-14.4-5.7 4.1-12.9 6.8-20.5 6.8z"
    />
    <path
      className="st7"
      d="M-157.9 470.2l-10.4-10.3c.1-.1.2-.4.2-.7 1.1-1.8 1.8-3.8 2-6 0-.5.1-1 .1-1.5 0-1.6-.3-3.2-.8-4.8-.7-2.1-1.9-3.9-3.4-5.4-5.6-5.6-15-5.6-20.5 0-1.9 1.9-3.1 4.1-3.8 6.5-.7 2.5-.7 5 0 7.5s1.9 4.7 3.8 6.5c2.8 2.8 6.5 4.2 10.3 4.2 2.9 0 5.6-.8 8-2.4l.4-.2 10.4 10.4c.4.4.8.6 1.2.7h.2c.3 0 .5-.1.7-.2.3-.2.6-.4.9-.7l.5-.5c.3-.3.6-.6.7-.9.1-.2.2-.5.2-.8v-.2c-.1-.3-.3-.8-.7-1.2zm-11.1-15.3c-.5 2-1.6 3.8-3.1 5.4-2.2 2.2-5.2 3.5-8.4 3.5-3.1 0-6.1-1.3-8.4-3.5-4.6-4.3-4.5-12.5 0-16.9 2.3-2.2 5.3-3.5 8.4-3.5 3.3 0 6.1 1.2 8.4 3.5 1.5 1.5 2.6 3.3 3.1 5.4.6 2 .6 4 0 6.1z"
    />
  </svg>
);

IconSearchEvaluation.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconSearchEvaluation;
