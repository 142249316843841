import React, { Component } from 'react';
import { Icon } from 'react-fa';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Image from '../components/Images/Image';
import Checkbox from '../components/Checkbox/Checkbox';
import { FormGroup, InputGroup, InputGroupAddon, Alert, Row } from 'reactstrap';
import {
  requestLogin,
  getKey,
  forgotPassword,
  closeAnotherSession,
  openNewUserRequest,
  sendNewUserRequest,
  closeNewUserRequest,
} from '../store/login/action';
import { openModalAction } from '../store/modal/action';
import { bindActionCreators } from 'redux';
import Recaptcha from 'react-google-invisible-recaptcha';
import { IS_DEV, PERMISSION_ERROR_SUB_EXPIRED,  INVALID_CREDENTIALS, MUST_UPDATE_PASSWORD, VOUCHER_EXPIRED } from '../constant/constants';
import { getQueryString } from '../utils/functions';
import {
  EXPIRED_EXTERNAL_EVALUATOR,
  PERMISSION_ERROR_CLIENT_BLOCKED,
  FREE_CLIENT_PENDING
} from '../constant/constants';

class FormLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: true,
      username: this.props.username || '',
      password: this.props.password || '',
      platform: 'web',
      errorConnect: this.props.errorLogin,
      errorKey: false,
      emailRetrieve: '',
      responseRetrieve: this.props.emailRetrieve,
      check: true,
      unauthorizedReason: this.props.unauthorizedReason || '',
      createAccount: this.props.createAccount,
      newExternalEvaluatorRegisteredSuccess:
        this.props.newExternalEvaluatorRegisteredSuccess,
      newUserName: '',
      newUserEmail: '',
      newRegisterSuccess: this.props.newRegisterSuccess,
      sessionClosed: false,
      userregistered: false,
      showPassword: false,
    };
    this.handleResolved = this.handleResolved.bind(this);
  }

  componentDidMount(){
    if(this.props.redirectToSubscription){
      this.handleOpenCreateNewUser()
    }
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.errorLogin !== this.props.errorLogin) {
      this.setState({
        errorConnect: nextProps.errorLogin,
        password: '',
      });
    }

    if (nextProps.emailRetrieve !== this.props.emailRetrieve) {
      this.setState({
        responseRetrieve: nextProps.emailRetrieve,
      });
    }
    if (nextProps.createAccount !== this.state.createAccount) {
      this.setState({
        createAccount: nextProps.createAccount,
      });
      if (nextProps.createAccount === false) {
        this.setState({
          username: '',
          password: '',
          newUserName: '',
          newUserEmail: '',
        });
      }
    }
    if (nextProps.newRegisterSuccess !== this.state.newRegisterSuccess) {
      this.setState({
        newRegisterSuccess: nextProps.newRegisterSuccess,
      });
    }
    if (nextProps.sessionClosed !== this.state.sessionClosed) {
      this.setState({
        sessionClosed: nextProps.sessionClosed,
      });
    }
    if (nextProps.userregistered !== this.state.userregistered) {
      this.setState({
        userregistered: nextProps.userregistered,
      });
    }
    if (
      nextProps.newExternalEvaluatorRegisteredSuccess !==
      this.state.newExternalEvaluatorRegisteredSuccess
    ) {
      this.setState({
        newExternalEvaluatorRegisteredSuccess:
          nextProps.newExternalEvaluatorRegisteredSuccess,
      });
    }

    if (nextProps.unauthorizedReason !== this.state.unauthorizedReason) {
      this.setState({
        password: '',
      });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    window.localStorage.setItem('loginEmail', this.state.username);
    const queryString = getQueryString('redirectUrl');
    this.props.getKey(() => {
      if (this.props.publicKey.key) {
        this.setState({
          errorKey: false,
        });

        this.props.requestLogin(
          this.state.username,
          this.state.password,
          this.state.platform,
          this.props.publicKey.key,
          this.state.check,
          queryString !== null ? queryString : '/',
        );
      } else {
        this.setState({
          errorKey: true,
        });
        this.props.getKey();
      }
    });
  }

  handleLogin(event) {
    event.preventDefault();
    this.setState({
      login: !this.state.login,
      username: '',
      password: '',
      errorConnect: false,
    });
  }

  retrievePassNewUser(event) {
    event.preventDefault();
    this.props.closeNewUserRequest();
    this.setState({
      createAccount: false,
      login: false,
      username: '',
      password: '',
      errorConnect: false,
    });
  }

  backToLogin(event) {
    event.preventDefault();
    this.setState({
      newRegisterSuccess: false,
      createAccount: false,
      login: true,
      username: '',
      password: '',
      errorConnect: false,
    });
  }

  handleOpenCreateNewUser() {
    this.props.openNewUserRequest();
    this.setState({
      userregistered: false,
    });
  }

  handleSendCreateNewUser(event) {
    event.preventDefault();
    this.props.sendNewUserRequest(this.state.newUserEmail, this.state.newUserName);
  }

  handleUnauthorized = () => {
    this.setState({ unauthorizedReason: '', password: '' });
  };

  handleCancelSubscribe() {
    this.setState({
      userregistered: false,
    });
    this.props.closeNewUserRequest();
  }

  handleSubmitUnauthorized(event) {
    event.preventDefault();
    if (this.props.publicKey.key) {
      this.setState({
        errorKey: false,
      });
      this.props.closeAnotherSession(
        this.props.username,
        this.props.password,
        this.state.platform,
        this.props.check,
        this.props.router,
      );
    } else {
      this.setState({
        errorKey: true,
      });
      this.props.getKey();
    }
  }

  handleSubmitRetrieve(event) {
    event.preventDefault();
    if (this.state.emailRetrieve && this.state.emailRetrieve !== '')
      this.recaptcha.execute();
    else this.recaptcha.reset();
  }

  handleResolved() {
    this.props.forgotPassword(this.state.emailRetrieve, this.recaptcha.getResponse());
  }

  handleCheckbox() {
    this.setState({
      check: !this.state.check,
    });
  }

  _renderContinueRegister() {
    return (
      <FormGroup>
        <Alert color="info">
          <p>
            <FormattedMessage
              id="app.login.continueRegister"
              defaultMessage="Para continuar com o cadastro, vá até a caixa de entrada do e-mail cadastrado e clique no link recebido."
            />
          </p>
          <p>
            <FormattedMessage
              id="app.login.continueRegister"
              defaultMessage="Lembre-se de verificar a pasta de SPAM se o e-mail não chegar na caixa de entrada em até 10 minutos."
            />
          </p>
          <p>
            <small>
              <strong>
                <FormattedMessage
                  id="app.login.continueRegister.expiration"
                  defaultMessage="O link de registro tem validade de 2 horas. Após esse período, será necessária uma nova solicitação."
                />
              </strong>
            </small>
          </p>
        </Alert>
        <div className="text-right">
          <button
            className="login-forgot-password back-signal button-link"
            onClick={this.backToLogin.bind(this)}
          >
            <Icon name="long-arrow-left" />{' '}
            <FormattedMessage
              id="app.login.retrieve.back.login"
              defaultMessage="Fazer login"
            />
          </button>
        </div>
      </FormGroup>
    );
  }

  _renderLoginResponse() {
    if (this.state.newRegisterSuccess) {
      return this._renderContinueRegister();
    } else {
      return this._renderLogin();
    }
  }

  _renderLogin() {
    let loadingStatus = this.props.loading ? '' : 'hidden-xs-up';
    let typeInputUserName = IS_DEV ? 'text' : 'email';
    return (
      <div className="login">
        <form onSubmit={this.handleSubmit.bind(this)}>
          {this.state.newExternalEvaluatorRegisteredSuccess ? (
            <Alert color="success">
              <FormattedMessage
                id="app.external.evaluator.register.success.login"
                defaultMessage="Cadastro efetuado! Agora você pode realizar o login o Physical Test."
              />
            </Alert>
          ) : (
            <div></div>
          )}
          <FormGroup>
            <InputGroup>
              <input
                value={this.state.username}
                onChange={(e) => {
                  this.setState({ username: e.target.value.trim() });
                }}
                type={typeInputUserName}
                className="form-control"
                placeholder={this.props.intl.formatMessage({
                  id: 'app.global.user',
                  defaultMessage: 'E-mail',
                })}
                autoFocus={true}
              />
              <InputGroupAddon className="icon-centralized-login">
                <Icon name="envelope" />
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <input
                value={this.state.password}
                onChange={(e) => {
                  this.setState({ password: e.target.value.trim() });
                }}
                type={this.state.showPassword ? "text" : "password"}
                className="form-control"
                placeholder={this.props.intl.formatMessage({
                  id: 'app.global.password',
                  defaultMessage: 'Senha',
                })}
                minLength="1"
                required
              />
              <InputGroupAddon 
                className="icon-centralized-login"
                onClick={() => this.setState({ showPassword: !this.state.showPassword })}
              >
                <Icon name={this.state.showPassword ? "eye" : "eye-slash"} />
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <button type="submit" className="login-button">
              <FormattedMessage id="app.global.signin" defaultMessage="Entrar" />
            </button>
          </FormGroup>
        </form>

        <div className={loadingStatus}>
          <Image src={require('../images/loading.svg')} />
        </div>

        {/* ERRO LOGIN */}
        {this.props.unauthorizedReason !== '' && this._renderLoginError()}

        {/* ERRO SERVIDOR DESCONECTADO */}
        {this.state.errorKey && this._renderKeyError()}
        <div className="justify-content-center">
          <div className="connected">
            <Checkbox
              name="connected"
              label={this.props.intl.formatMessage({
                id: 'app.login.remember.me',
                defaultMessage: 'Manter-me conectado',
              })}
              value={this.state.check}
              onChange={this.handleCheckbox.bind(this)}
              checked={this.state.check}
            />
          </div>
          <div className="forgot-center">
            <button
              className="button-link login-forgot-password"
              tabIndex="1"
              onClick={this.handleLogin.bind(this)}
            >
              <p className="forgot-center">
                <FormattedMessage
                  id="app.login.password.forgot.label"
                  defaultMessage="Esqueceu a Senha?"
                />
                <br />
                <strong>
                  <FormattedMessage
                    id="app.login.password.forgot"
                    defaultMessage="Clique aqui!"
                  />
                </strong>
              </p>
            </button>
          </div>
          <hr />
          {!this.state.createAccount && (
            <Row className="forgot-center">
              {this._renderNewUser()}
            </Row>
          )}
        </div>
      </div>
    );
  }

  _renderRetrieve() {
    const locale = window.localStorage.locale
      ? window.localStorage.locale
      : navigator.language;
    return (
      <div className="retrieve-password">
        <div>
          {!this.state.responseRetrieve ? (
            <Alert color="info">
              <FormattedMessage
                id="app.login.retrieve"
                defaultMessage="Digite seu endereço de e-mail. Você receberá um link para criar uma nova senha."
              />
            </Alert>
          ) : (
            ''
          )}
        </div>
        <form onSubmit={this.handleSubmitRetrieve.bind(this)}>
          <FormGroup>
            <InputGroup>
              <InputGroupAddon className="icon-centralized-login">
                <Icon name="envelope" />
              </InputGroupAddon>
              <input
                value={this.state.emailRetrieve}
                onChange={(e) => {
                  this.setState({ emailRetrieve: e.target.value.trim() });
                }}
                ref="retrieve"
                className="form-control"
                placeholder="E-mail"
                required
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <button type="submit" className="login-button">
              <FormattedMessage id="app.global.submit" defaultMessage="Enviar" />
            </button>
            <Recaptcha
              ref={(ref) => (this.recaptcha = ref)}
              sitekey="6LcKlmsUAAAAALU4tKFtUi07NRVgApg0CadiVuVa"
              onResolved={this.handleResolved}
              locale={locale}
            />
          </FormGroup>
          {this._responseEmailRetrieve()}
        </form>
        <div className="text-right">
          <button
            className="login-forgot-password back-signal button-link"
            onClick={this.backToLogin.bind(this)}
          >
            <Icon name="long-arrow-left" />{' '}
            <FormattedMessage
              id="app.login.retrieve.back.login"
              defaultMessage="Fazer login"
            />
          </button>
        </div>
      </div>
    );
  }

  _renderUnauthorized() {
    let loadingStatus = this.props.loading ? '' : 'hidden-xs-up';
    return (
      <div className="login">
        <form onSubmit={this.handleSubmitUnauthorized.bind(this)}>
          <FormGroup>
            <Alert color="warning">
              <FormattedMessage
                id="app.login.unauthorized"
                defaultMessage="Existe outro usuário logado no sistema! "
              />
              <p>
                <strong>
                  {' '}
                  <FormattedMessage
                    id="app.login.unauthorized.confirm"
                    defaultMessage="Deseja Cancelar a Sessão dele?"
                  />
                </strong>
              </p>
            </Alert>
          </FormGroup>
          <FormGroup>
            <button type="submit" className="login-button">
              <strong>
                <FormattedMessage
                  id="app.login.signin"
                  defaultMessage="Entrar mesmo assim"
                />
              </strong>
            </button>
          </FormGroup>
        </form>
        <div>
          <button
            className="button-link login-button btn-cancel"
            onClick={this.handleUnauthorized}
          >
            <FormattedMessage id="app.login.cancel" defaultMessage="Cancelar" />
          </button>
        </div>

        <div className={loadingStatus}>
          <Image src={require('../images/loading.svg')} />
        </div>

        {/* ERRO SERVIDOR DESCONECTADO */}
        {this.state.errorKey ? this._renderKeyError() : ''}
      </div>
    );
  }

  _renderNewAccount() {
    let typeInputUserName = IS_DEV ? 'text' : 'email';
    return (
      <div>
        <form onSubmit={this.handleSendCreateNewUser.bind(this)} autoComplete="off">
        <p style={{color: '#fff', textAlign: 'left'}}>Informe seu nome e e-mail para iniciar o cadastro no Physical Test.</p>
          <FormGroup>
            <InputGroup>
              <InputGroupAddon className="icon-centralized-login">
                <Icon name="user" />
              </InputGroupAddon>
              <input
                autoComplete="off"
                key={'nameOf'}
                id={'nameOf'}
                value={this.state.newUserName}
                onChange={(e) => {
                  this.setState({ newUserName: e.target.value.trimStart() });
                }}
                type="text"
                className="form-control"
                placeholder={this.props.intl.formatMessage({
                  id: 'app.global.name',
                  defaultMessage: 'Nome',
                })}
                autoFocus={true}
                required={true}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <InputGroup>
              <InputGroupAddon className="icon-centralized-login">
                <Icon name="envelope" />
              </InputGroupAddon>
              <input
                autoComplete="off"
                key={'emailOf'}
                id={'emailOf'}
                value={this.state.newUserEmail}
                onChange={(e) => {
                  this.setState({ newUserEmail: e.target.value.trim() });
                }}
                type={typeInputUserName}
                className="form-control"
                placeholder={this.props.intl.formatMessage({
                  id: 'app.global.email',
                  defaultMessage: 'Email',
                })}
                required={true}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <button type="submit" className="login-button">
              <FormattedMessage
                id="app.global.sendRequest"
                defaultMessage="Cadastrar"
              />
            </button>
          </FormGroup>
        </form>
        <div>
          <button
            className="button-link login-button btn-cancel"
            onClick={this.handleCancelSubscribe.bind(this)}
          >
            <FormattedMessage id="app.login.cancel" defaultMessage="Cancelar" />
          </button>
        </div>
        {this.state.userregistered ? this._renderUserRegisteredError() : ''}
      </div>
    );
  }

  _renderPage() {
    if (this.state.createAccount) {
      return this._renderNewAccount();
    } else if (this.state.unauthorizedReason === 'ANOTHER_SESSION') {
      return this._renderUnauthorized();
    } else {
      if (this.state.login === true) {
        return this._renderLoginResponse();
      } else if (this.state.login === false) {
        return this._renderRetrieve();
      }
    }
  }

  _renderKeyError() {
    return (
      <Alert color="danger">
        <FormattedMessage
          id="message.error.server.disconnected"
          defaultMessage="Servidor desconectado, tente novamente!"
        />
      </Alert>
    );
  }

  _renderLoginError() {
    if (window.localStorage.loginEmail && this.state.username === '') {
      this.setState(
        {
          username: window.localStorage.getItem('loginEmail'),
          password: '',
        },
        window.localStorage.removeItem('loginEmail'),
      );
    }
    //TODO adicionar um componente ad-hoc para estes ifs abaixo após o merge do loginPolice.js
    if (
      this.state.errorConnect &&
      this.state.unauthorizedReason === INVALID_CREDENTIALS
    ) {
      return (
        <Alert color="danger">
          *{' '}
          <FormattedMessage
            id="app.login.invalid"
            defaultMessage="Usuário ou Senha inválidos"
          />
        </Alert>
      );
    } else if (
      this.state.errorConnect &&
      this.state.unauthorizedReason === EXPIRED_EXTERNAL_EVALUATOR
    ) {
      return (
        <Alert color="danger">
          <FormattedMessage
            id="app.login.accountExpired"
            defaultMessage="O tempo limite de uso desta conta expirou."
          />
        </Alert>
      );
    } else if (
      this.state.errorConnect &&
      this.state.unauthorizedReason === PERMISSION_ERROR_CLIENT_BLOCKED
    ) {
      return (
        <Alert color="danger">
          <FormattedMessage
            id="app.login.clientBlocked"
            defaultMessage="Conta bloqueada. Entre em contato com o administrador do sistema."
          />
        </Alert>
      );
    } else if (this.state.errorConnect && this.state.unauthorizedReason === FREE_CLIENT_PENDING) {
      return (
        <Alert color="warning">
          <FormattedMessage
            id="app.login.unauthorized.message.await.approval"
            defaultMessage="O seu cadastro foi enviado para aprovação. Você receberá um e-mail com os dados de acesso quando o seu cadastro for aprovado."
          />
          <br />
          <small className="font-weight-bold">
            <FormattedMessage
              id="app.login.unauthorized.estimated.time.approval"
              defaultMessage="*A aprovação pode demorar até 48 horas."
            />
          </small>
        </Alert>
      );
    } else if (
        this.state.errorConnect &&
        (this.state.unauthorizedReason === PERMISSION_ERROR_SUB_EXPIRED ||
          this.state.unauthorizedReason === VOUCHER_EXPIRED)
      ) {
       return (
         <Alert color="danger">
           <FormattedMessage
             id="app.login.subExpired"
             defaultMessage="A assinatura atrelada ao seu cadastro possui pendências que impedem o acesso ao sistema."
           />
         </Alert>
       );
    } else if (
      this.state.errorConnect &&
      this.state.unauthorizedReason === MUST_UPDATE_PASSWORD
    ) {
      return (
        <Alert color="warning">
          <FormattedMessage
            id="app.login.mustUpdatePassword"
            defaultMessage="Usuário ou senha inválidos. Devido a uma recente atualização na autenticação de usuários, será necessário criar outra senha para acessar o sistema. Clique em 'Esqueceu a senha?' logo abaixo para realizar o procedimento."
          />
        </Alert>
      );
    } 
  }

  _renderUserRegisteredError() {
    return (
      <div>
        <br />
        <Alert color="danger">
          <FormattedMessage
            id="app.login.unauthorized"
            defaultMessage="Usuário já registrado"
          />
        </Alert>
        <div className="forgot-center">
          <button
            className="button-link login-forgot-password"
            tabIndex="1"
            onClick={this.retrievePassNewUser.bind(this)}
          >
            <p className="forgot-center">
              <FormattedMessage
                id="app.login.password.forgot.label"
                defaultMessage="Redefina sua senha clicando aqui"
              />
            </p>
          </button>
        </div>
      </div>
    );
  }

  _responseEmailRetrieve() {
    if (this.state.responseRetrieve) {
      return (
        <Alert color="success">
          <Icon name="check" />{' '}
          <FormattedMessage
            id="app.login.retrieve.email.success"
            defaultMessage="Email enviado com sucesso. Verifique sua caixa de entrada."
          />
        </Alert>
      );
    }

    if (this.state.responseRetrieve === false) {
      return (
        <Alert color="danger">
          <Icon name="ban" />{' '}
          <FormattedMessage
            id="app.login.retrieve.email.error"
            defaultMessage="Email não encontrado. Tente novamente."
          />
        </Alert>
      );
    }
  }

  _renderNewUser() {
    return (
      <div className="create-account-center">
        <button
          className="button-link login-create-account"
          tabIndex="1"
          onClick={this.handleOpenCreateNewUser.bind(this)}
        >
          <strong>
            <Row className="create-account-center create-account-click">
              <FormattedMessage
                id="app.login.createaccount.click"
                defaultMessage="Inscreva-se!"
              />
            </Row>
          </strong>
        </button>
      </div>
    );
  }

  _renderForcedLogout() {
    return (
      <Alert color="warning">
        <Icon name="check" />{' '}
        <FormattedMessage
          id="app.forced.logout"
          defaultMessage="Sua sessão foi removida por inatividade."
        />
      </Alert>
    );
  }

  render() {
    return (
      <div className="login-container">
        <div className="logo-login">
          <h1>
            <Image src={require('../images/logo-final_branco.png')} />            
          </h1>
          {this._renderPage()}
        </div>
        {this.state.sessionClosed ? this._renderForcedLogout() : ''}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loading: state.profile.isAuthenticating,
    errorLogin: state.profile.error,
    emailRetrieve: state.profile.emailRetrieve,
    publicKey: state.util.publicKeySet.public_key,
    unauthorizedReason: state.profile.unauthorizedReason,
    username: state.profile.username,
    password: state.profile.password,
    check: state.profile.check,
    modals: state.util.modalSet.modal,
    createAccount: state.profile.createAccount,
    newRegisterSuccess: state.profile.newRegisterSuccess,
    sessionClosed: state.listeners.sessionClosed,
    userregistered: state.profile.userregistered,
    newExternalEvaluatorRegisteredSuccess:
      state.profile.newExternalEvaluatorRegisteredSuccess,
  };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      requestLogin,
      getKey,
      forgotPassword,
      closeAnotherSession,
      openNewUserRequest,
      sendNewUserRequest,
      closeNewUserRequest,
      openModalAction,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(FormLogin));
