import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Nav,
  NavItem,
  NavDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarToggler,
  Collapse,
  NavLink,
} from 'reactstrap';
import { LinkContainer, IndexLinkContainer } from 'react-router-bootstrap';
import { Icon } from 'react-fa';
import { STUDENT, UNIVERSITY, URL_SERVER } from '../../constant/constants';
import { getProfilePermissionLevel } from '../../utils/functions';
import IconDumbbell from '../Icons/IconDumbbell';

export default class MenuMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dd1: false,
      dd2: false,
      isOpen: false,
      locale: false,
      ddReport: false,
      ddExercise: false,
    };
  }

  dropdownToggle = (dropdown) => {
    this.setState({
      [dropdown]: !this.state[dropdown],
    });
  };

  dropdownToggleLocale = () => {
    this.setState({
      locale: !this.state.locale,
    });
  };

  toggleNavbar = () => {
    if (window.innerWidth < 1199) {
      this.setState({
        isOpen: !this.state.isOpen,
      });
    }
  };

  dropdownToggleReport = () => {
    this.setState({ ddReport: !this.state.ddReport });
  };

  getRoleColors() {
    const { rule } = getProfilePermissionLevel(this.props.permission, this.props.person);

    const roles = {
      clientEvaluator: 'menu-main-client_eval',
      evaluated: 'menu-main-evaluated',
      client: 'menu-main-client',
      evaluator: 'menu-main-evaluator',
      clientAdmin: 'menu-main-client_admin',
      sysadmin: 'menu-main-sysadmin',
      freeClient: 'menu-main-freeClient',
    };

    if (this.props.accountType === STUDENT || this.props.accountType === UNIVERSITY)
      return roles.freeClient;

    if (roles.hasOwnProperty(rule)) return roles[rule];
  }

  render() {
    const name = this.props.person.name.split(' ');
    const firstName = name[0];
    const setRoleMenuBorderColor = this.getRoleColors();

    return (
      <nav className={`menu-main navbar navbar-expand-xl ${setRoleMenuBorderColor}`}>
        <IndexLinkContainer to="/">
          <NavLink className="navbar-brand">
            <img
              src={require('../../images/logo-final_branco.png')}
              alt="Physical Test"
              width="128"
              className="brand"
            />
          </NavLink>
        </IndexLinkContainer>

        <NavbarToggler className="mr-2" onClick={this.toggleNavbar} />

        <Collapse isOpen={this.state.isOpen} navbar>
          <ul className="navbar-nav navbar-main ml-auto">
            <IndexLinkContainer to="/">
              <NavItem onClick={this.toggleNavbar}>
                <NavLink>
                  <Icon name="home" className="menu-bar-icon" />{' '}
                  <FormattedMessage id="app.global.home" defaultMessage="Inicio" />
                </NavLink>
              </NavItem>
            </IndexLinkContainer>
            {this.props.permission.sysadmin ? 
							<LinkContainer to="/DashBoardClients">
									<NavItem onClick={this.toggleNavbar}> 
										<NavLink>
											<Icon name="area-chart" className="menu-bar-icon" /> <FormattedMessage
												id="app.global.dashboard"
												defaultMessage="dashboard"
											/>
										</NavLink>
									</NavItem>
							</LinkContainer> 
						: ''}
            {this.props.permission.evaluator && this.props.permission.client ? (
              <LinkContainer to="/Evaluated">
                <NavItem onClick={this.toggleNavbar}>
                  <NavLink>
                    <Icon name="users" className="menu-bar-icon" />{' '}
                    <FormattedMessage
                      id="app.global.evaluated"
                      defaultMessage="Avaliados"
                    />
                  </NavLink>
                </NavItem>
              </LinkContainer>
            ) : (
              ''
            )}
            {this.props.permission.evaluatorperm && this.props.permission.client ? (
              <LinkContainer to="/Evaluators">
                <NavItem onClick={this.toggleNavbar}>
                  <NavLink>
                    <Icon name="id-card" className="menu-bar-icon" />{' '}
                    <FormattedMessage
                      id="app.global.evaluators"
                      defaultMessage="Avaliadores"
                    />
                  </NavLink>
                </NavItem>
              </LinkContainer>
            ) : (
              ''
            )}
            {this.props.permission.registration ? (
              <LinkContainer to="/Registration">
                <NavItem onClick={this.toggleNavbar}>
                  <NavLink>
                    <Icon name="book" className="menu-bar-icon" />{' '}
                    <FormattedMessage
                      id="app.global.registrations"
                      defaultMessage="Cadastros"
                    />
                  </NavLink>
                </NavItem>
              </LinkContainer>
            ) : (
              ''
            )}
            {this.props.permission.evaluator && this.props.permission.client && (
              <Nav navbar>
                <NavDropdown
                  isOpen={this.state.ddExercise}
                  toggle={() => this.dropdownToggle('ddExercise')}
                  className="dropitem-adjust"
                >
                  <DropdownToggle color="primary" nav caret>
                    <IconDumbbell className="mr-2" />
                    <FormattedMessage id="app.global.workouts" defaultMessage="Treinos" />
                  </DropdownToggle>

                  <DropdownMenu className="dropitem-adjust" onClick={() => this.dropdownToggle('ddExercise')}>

                    <LinkContainer to="/PresetWorkouts" className="dropdown-item">
                      <NavLink>
                        <FormattedMessage
                          id="app.preset.workout"
                          defaultMessage="Ficha de treino"
                        />
                      </NavLink>
                    </LinkContainer>

                    <DropdownItem divider />

                    <LinkContainer to="/PresetWorkoutsEvaluated" className="dropdown-item">
                      <NavLink>
                        <FormattedMessage
                          id="app.preset.workouts.evaluated"
                          defaultMessage="Ficha de treino do aluno"
                        />
                      </NavLink>
                    </LinkContainer>

                  </DropdownMenu>
                </NavDropdown>
              </Nav>
            )}
            {/*this.props.permission.client === null  ?
							<LinkContainer to="/DashboardUsers">
								<NavItem onClick={this.toggleNavbar}>
									<NavLink>
										<Icon name="cogs" className="menu-bar-icon" /> <FormattedMessage
										id="app.global.users"
										defaultMessage="sistema"
										/>
									</NavLink>
								</NavItem>
							</LinkContainer>	
							: "" */}
            {this.props.permission.plan && this.props.permission.client === null ? (
              <Nav navbar>
                <NavDropdown
                  isOpen={this.state.dd2}
                  toggle={() => this.dropdownToggle('dd2')}
                  className="dropitem-adjust"
                >
                  <DropdownToggle color="primary" nav caret>
                    <Icon name="users" className="menu-bar-icon" />{' '}
                    <FormattedMessage id="app.global.clients" defaultMessage="Clientes" />
                  </DropdownToggle>
                  <DropdownMenu
                    className="dropitem-adjust"
                    onClick={() => this.dropdownToggle('dd2')}
                  >
                    <LinkContainer to="/Clients" className="dropdown-item">
                        <NavLink>
                          <FormattedMessage
                            id="app.global.clients"
                            defaultMessage="Clientes"
                          />
                        </NavLink>
                    </LinkContainer>

                    <DropdownItem divider />
                    <LinkContainer to="/ClientsApproval" className="dropdown-item">
                        <NavLink>
                          <FormattedMessage
                            id="app.global.clientsApproval"
                            defaultMessage="Aprovação de clientes"
                          />
                        </NavLink>
                    </LinkContainer>
                  </DropdownMenu>
                </NavDropdown>
              </Nav>
            ) : (
              ''
            )}
            {this.props.permission.user && this.props.permission.client ? (
              <LinkContainer to="/DashboardUsers">
                <NavItem onClick={this.toggleNavbar}>
                  <NavLink>
                    <Icon name="users" className="menu-bar-icon" />{' '}
                    <FormattedMessage id="app.global.users" defaultMessage="Usuários" />
                  </NavLink>
                </NavItem>
              </LinkContainer>
            ) : (
              ''
            )}
            {this.props.permission.user && this.props.permission.client ? (
              <Nav navbar>
                <NavDropdown
                  isOpen={this.state.ddReport}
                  toggle={this.dropdownToggleReport}
                  className="dropitem-adjust"
                >
                  <DropdownToggle color="primary" nav caret>
                    <Icon name="file" className="menu-bar-icon mr-2" />
                    <FormattedMessage
                      id="app.global.reports"
                      defaultMessage="Relatórios"
                    />
                  </DropdownToggle>
                  <DropdownMenu
                    className="dropitem-adjust"
                    onClick={() => this.setState({ ddReport: false })}
                  >
                    <LinkContainer to="/Reports/Measures" className="dropdown-item">
                      <NavLink>
                        <FormattedMessage
                          id="app.global.measures"
                          defaultMessage="Medidas"
                        />
                      </NavLink>
                    </LinkContainer>
                  </DropdownMenu>
                </NavDropdown>
              </Nav>
            ) : (
              ''
            )}
            {/*futura page sysadmin */}
            {/* {this.props.permission.sysadmin ? (
              <LinkContainer to="/SysAdministration">
                <NavItem onClick={this.toggleNavbar}>
                  <NavLink>
                    <Icon name="cog" className="menu-bar-icon" />{' '}
                    <FormattedMessage
                      id="app.global.sysadmin"
                      defaultMessage="Administração"
                    />
                  </NavLink>
                </NavItem>
              </LinkContainer>
            ) : (
              ''
            )} */}
            {this.props.permission.plan && this.props.permission.client === null ? (
              <LinkContainer to="/Plans">
                <NavItem onClick={this.toggleNavbar}>
                  <NavLink>
                    <Icon name="tasks" className="menu-bar-icon" />{' '}
                    <FormattedMessage id="app.global.plans" defaultMessage="Planos" />
                  </NavLink>
                </NavItem>
              </LinkContainer>
            ) : (
              ''
            )}

            {this.props.permission.plan && this.props.permission.client === null && (
              <LinkContainer to="/PromotionalLinks">
                <NavItem onClick={this.toggleNavbar}>
                  <NavLink>
                    <Icon rotate="270" name="link" className="menu-bar-icon" />{' '}
                    <FormattedMessage id="app.global.promotional.links" defaultMessage="Links" />
                  </NavLink>
                </NavItem>
              </LinkContainer>
            )}
          </ul>

          <Nav navbar className="navbar-profile">
            {/* <NavDropdown className="" isOpen={this.state.locale} toggle={this.dropdownToggleLocale}>
							 <DropdownToggle color="primary" nav caret>
							 		<span className={`locale locale-${this.props.person.locale}`}></span>
        					 </DropdownToggle>
							 <DropdownMenu>
								<div  className="dropdown-item" onClick={() => this.props.onLocaleChange('pt-BR',this.props.person.id)}>
									<span className="locale locale-pt-BR"></span>
									<FormattedMessage
									id="app.global.locale.pt"
									defaultMessage="PT"
									/>
								</div>
								<DropdownItem divider />
								<div  className="dropdown-item" onClick={() => this.props.onLocaleChange('en-US',this.props.person.id)}>
									<span className="locale locale-en-US"></span>
									<FormattedMessage
									id="app.global.locale.en"
									defaultMessage="EN"
									/>
								</div>
								<DropdownItem divider />
								<div  className="dropdown-item" onClick={() => this.props.onLocaleChange('es-AR',this.props.person.id)}>
									<span className="locale locale-es-AR"></span>
									<FormattedMessage
									id="app.global.locale.es"
									defaultMessage="ES"
									/>
								</div>
							</DropdownMenu>
						</NavDropdown> */}

            <NavDropdown
              isOpen={this.state.dd1}
              toggle={() => this.dropdownToggle('dd1')}
              className="dropitem-adjust"
            >
              <DropdownToggle color="primary" nav caret>
                <img
                  src={
                    !this.props.person.picture || this.props.person.picture === ''
                      ? require('../../images/avatar.png')
                      : URL_SERVER + this.props.person.picture
                  }
                  className="navbar-image-profile"
                  alt=""
                />{' '}
                {firstName}
              </DropdownToggle>
              <DropdownMenu
                className="dropitem-adjust"
                onClick={() => this.dropdownToggle('dd1')}
              >
                <LinkContainer to="/MyAccount" className="dropdown-item">
                  <NavLink>
                    <FormattedMessage
                      id="app.global.myaccount"
                      defaultMessage="Minha Conta"
                    />
                  </NavLink>
                </LinkContainer>
                <DropdownItem divider />
                <button
                  onClick={this.props.toggleModalFeaturePreview}
                  className={`dropdown-item position-relative ${this.props.hasNewFeatures ? 'bullet-point' : ''}`}
                >
                  <FormattedMessage id="app.global.news" defaultMessage="Novidades" />
                </button>
                <DropdownItem divider />
                <button
                  onClick={this.props.toggleModalAnnouncements}
                  className="dropdown-item position-relative"
                >
                  <FormattedMessage
                    id="app.global.announcements"
                    defaultMessage="Comunicados"
                  />
                </button>
                <DropdownItem divider />
                <button onClick={this.props.logout} className="dropdown-item">
                  <Icon name="power-off" />{' '}
                  <FormattedMessage id="app.global.out" defaultMessage="Sair" />
                </button>
              </DropdownMenu>
            </NavDropdown>
          </Nav>
        </Collapse>
      </nav>
    );
  }
}
