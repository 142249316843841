import * as Actions from '../constant/constants';
import { addAuthor, deleteAuthor, editAuthor, setAuthors } from '../store/authors/action';
import {
  addClassification,
  deleteClassification,
  editClassification,
  setClassifications,
} from '../store/classifications/action';
import {
  addEthnicity,
  deleteEthnicity,
  editEthnicity,
  setEthnicities,
} from '../store/ethnicity/action';
import { addGoal, deleteGoal, editGoal, setGoals } from '../store/goals/action';
import {
  addMeasure,
  deleteMeasure,
  editMeasure,
  setMeasures,
  getMeasuresTypes,
} from '../store/measures/action';
import {
  addEvaluator,
  deleteEvaluator,
  editEvaluator,
  setEvaluators,
} from '../store/evaluator/action';
import { setSexualMaturation } from '../store/sexualMaturation/action';
import { setGenders } from '../store/genders/action';
import {
  setPicture,
  deleteEvaluated,
  setEvaluated,
  setPictureEdit,
} from '../store/evaluated/action';
import { setUsers, addUser } from '../store/usersManagement/action';
import { updateUserLocale } from '../store/login/action';
import {
  setFormulas,
  addFormula,
  editFormula,
  deleteFormula,
} from '../store/formulas/action';
import {
  getFatPercentagesIdeal,
  setUpdateFatPercentageIdeal,
  setAddFatPercentageIdeal,
  deleteFatPercentagesIdeal,
} from '../store/tablesFatnessIdeal/action';
import { setVariables } from '../store/variables/action';
import { setCardioVariables } from '../store/cardioVariables/action';
import { setNeuromotorProtocols } from '../store/neuroProtocols/action';
import {
  setQuestions,
  setOneQuestion,
  setUpdatedQuestion,
  setTemplates,
  setOneTemplate,
  setUpdateTemplate,
} from '../store/anamnesis/action';

import {
  setExternalEvaluatorsRegister,
  setExternalEvaluatorsRegisters,
  setExternalEvaluatorsRegistersByClient,
  setDeleteExternalEvaluatorsRegister,
} from '../store/externalEvaluatorsRegister/action';

import {
  setClients,
  setClientSafeInfo,
  setBlockedClient,
  setProcessedClient,
} from '../store/clients/action';
import { addPlan, deletePlan, editPlan, setPlans } from '../store/plans/action';
import { setPaymentRecords } from '../store/paymentRecords/action';

import {
  setPromotionalLinks,
  deletePromotionalLink,
  addPromotionalLink,
  updatePromotionalLink,
} from '../store/promotionalLinks/action';

import {
  addExercise,
  deleteExercise,
  editExercise,
  setExercises,
} from '../store/exercise/action';
import { setMuscles } from '../store/muscle/action';
import { setExerciseTypes } from '../store/exerciseTypes/action';
import { setMuscleGroups } from '../store/muscleGroups/action';

import {
  addPresetWorkout,
  deletePresetWorkout,
  editPresetWorkout,
  setPresetWorkouts,
} from '../store/presetWorkout/action';

import {
  addEvaluatedWorkout,
  deleteEvaluatedWorkout,
  editEvaluatedWorkout,
  setEvaluatedWorkouts,
} from '../store/evaluatedWorkout/action';

import {
  setClientStatistics,
  setClientSUsageStatistics,
  setClientAccessLog,
} from '../store/dashboard/action';

import { setFeaturesViewedByPerson } from '../store/featureViewed/action';

import {
  setTemplate as setParQTemplate,
  setQuestions as setParQQuestions,
} from '../store/parq/action';

function createActionToListener(event, func) {
  return {
    type: Actions.ADD_LISTENER,
    payload: {
      event,
      func,
    },
  };
}

const shouldGetMiddleware = (store) => (next) => (action) => {
  const state = store.getState();
  switch (action.type) {
    case Actions.GET_ANAMNESIS_QUESTIONS:
      if (state.data.anamnesisSet.shouldGetAnamnesis) {
        store.dispatch(
          createActionToListener(Actions.GET_ANAMNESIS_QUESTIONS, (e) =>
            store.dispatch(setQuestions(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.ADD_ANAMNESIS_QUESTION, (e) =>
            store.dispatch(setOneQuestion(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.UPDATE_ANAMNESIS_QUESTION, (e) =>
            store.dispatch(setUpdatedQuestion(e)),
          ),
        );
      }
      break;
    case Actions.GET_ANAMNESIS_TEMPLATES:
      if (state.data.anamnesisSet.shouldGetAnamnesis) {
        store.dispatch(
          createActionToListener(Actions.GET_ANAMNESIS_TEMPLATES, (e) =>
            store.dispatch(setTemplates(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.ADD_ANAMNESIS_TEMPLATE, (e) =>
            store.dispatch(setOneTemplate(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.UPDATE_ANAMNESIS_TEMPLATE, (e) =>
            store.dispatch(setUpdateTemplate(e)),
          ),
        );
      }
      break;
    case Actions.GET_PARQ_TEMPLATE:
      if (state.data.parQSet.shouldGetParQTemplate) {
        store.dispatch(
          createActionToListener(Actions.GET_PARQ_TEMPLATE, (e) =>
            store.dispatch(setParQTemplate(e)),
          ),
        );
      }
      break;
    case Actions.GET_PARQ_QUESTIONS:
      if (state.data.parQSet.shouldGetParQQuestions) {
        store.dispatch(
          createActionToListener(Actions.GET_PARQ_QUESTIONS, (e) =>
            store.dispatch(setParQQuestions(e)),
          ),
        );
      }
      break;
    case Actions.GET_AUTHORS:
      if (state.data.authorsSet.shouldGetAuthors) {
        store.dispatch(
          createActionToListener(Actions.GET_AUTHORS, (e) =>
            store.dispatch(setAuthors(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.ADD_AUTHOR, (e) => store.dispatch(addAuthor(e))),
        );
        store.dispatch(
          createActionToListener(Actions.UPDATE_AUTHOR, (e) =>
            store.dispatch(editAuthor(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.DELETE_AUTHOR, (e) =>
            store.dispatch(deleteAuthor(e)),
          ),
        );
      }
      break;
    case Actions.GET_CLASSIFICATIONS:
      if (state.data.classificationsSet.shouldGetClassifications) {
        store.dispatch(
          createActionToListener(Actions.GET_CLASSIFICATIONS, (e) =>
            store.dispatch(setClassifications(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.ADD_CLASSIFICATION, (e) =>
            store.dispatch(addClassification(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.UPDATE_CLASSIFICATION, (e) =>
            store.dispatch(editClassification(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.DELETE_CLASSIFICATION, (e) =>
            store.dispatch(deleteClassification(e)),
          ),
        );
      }
      break;
    case Actions.GET_ETHNICITIES:
      if (state.data.ethnicitySet.shouldGetEthnicities) {
        store.dispatch(
          createActionToListener(Actions.GET_ETHNICITIES, (e) =>
            store.dispatch(setEthnicities(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.ADD_ETHNICITY, (e) =>
            store.dispatch(addEthnicity(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.UPDATE_ETHNICITY, (e) =>
            store.dispatch(editEthnicity(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.DELETE_ETHNICITY, (e) =>
            store.dispatch(deleteEthnicity(e)),
          ),
        );
      }
      break;
    case Actions.GET_GOALS:
      if (state.data.goalsSet.shouldGetGoals) {
        store.dispatch(
          createActionToListener(Actions.GET_GOALS, (e) => store.dispatch(setGoals(e))),
        );
        store.dispatch(
          createActionToListener(Actions.ADD_GOAL, (e) => store.dispatch(addGoal(e))),
        );
        store.dispatch(
          createActionToListener(Actions.UPDATE_GOAL, (e) => store.dispatch(editGoal(e))),
        );
        store.dispatch(
          createActionToListener(Actions.DELETE_GOAL, (e) =>
            store.dispatch(deleteGoal(e)),
          ),
        );
      }
      break;
    case Actions.GET_PLANS:
      if (state.data.clientPlanSet.shouldGetPlans) {
        store.dispatch(
          createActionToListener(Actions.GET_PLANS, (e) => store.dispatch(setPlans(e))),
        );
        store.dispatch(
          createActionToListener(Actions.CREATE_PLAN, ({ data }) =>
            store.dispatch(addPlan(data.content)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.UPDATE_PLAN, ({ data }) =>
            store.dispatch(editPlan(data.content)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.UPDATE_PLAN_STATUS, ({ data }) =>
            store.dispatch(deletePlan(data.content)),
          ),
        );
      }
      break;
    case Actions.GET_MEASURES:
      if (state.data.measuresSet.shouldGetMeasures) {
        store.dispatch(
          createActionToListener(Actions.GET_MEASURES, (e) =>
            store.dispatch(setMeasures(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.ADD_MEASURE, (e) =>
            store.dispatch(addMeasure(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.UPDATE_MEASURE, (e) =>
            store.dispatch(editMeasure(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.DELETE_MEASURE, (e) =>
            store.dispatch(deleteMeasure(e)),
          ),
        );
      }
      break;
    case Actions.GET_MEASURE_TYPES:
      if (state.data.measuresSet.shouldGetTypes) {
        store.dispatch(
          createActionToListener(Actions.GET_MEASURE_TYPES, (e) =>
            store.dispatch(getMeasuresTypes(e)),
          ),
        );
      }
      break;
    case Actions.GET_FORMULAS:
      if (state.data.formulasSet.shouldGetFormulas) {
        store.dispatch(
          createActionToListener(Actions.GET_FORMULAS, (e) =>
            store.dispatch(setFormulas(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.ADD_FORMULA, (e) =>
            store.dispatch(addFormula(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.UPDATE_FORMULA, (e) =>
            store.dispatch(editFormula(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.DELETE_FORMULA, (e) =>
            store.dispatch(deleteFormula(e)),
          ),
        );
      }
      break;
    case Actions.GET_VARIABLES:
      if (state.data.variablesSet.shouldGetVariables) {
        store.dispatch(
          createActionToListener(Actions.GET_VARIABLES, (e) =>
            store.dispatch(setVariables(e)),
          ),
        );
      }
      break;
    case Actions.GET_CARDIO_VARIABLES:
      if (state.data.cardioVariablesSet.shouldGetCardioVariables) {
        store.dispatch(
          createActionToListener(Actions.GET_CARDIO_VARIABLES, (e) =>
            store.dispatch(setCardioVariables(e)),
          ),
        );
      }
      break;
    case Actions.GET_NEUROMOTOR_TESTS:
      if (state.data.neuroProtocolsSet.shouldGetNeuroProtocols) {
        store.dispatch(
          createActionToListener(Actions.GET_NEUROMOTOR_TESTS, (e) =>
            store.dispatch(setNeuromotorProtocols(e)),
          ),
        );
      }
      break;
    case Actions.GET_CLIENTS:
      if (state.data.clientsSet.shouldGetClients) {
        store.dispatch(
          createActionToListener(Actions.GET_CLIENTS, (e) =>
            store.dispatch(setClients(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.GET_CLIENT_SAFE_INFO, (e) =>
            store.dispatch(setClientSafeInfo(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.BLOCK_CLIENT, (e) =>
            store.dispatch(setBlockedClient(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.APPROVE_OR_REJECT_CLIENT, (e) =>
            store.dispatch(setProcessedClient(e)),
          ),
        );
      }
      break;
    case Actions.GET_EVALUATORS:
      if (state.data.evaluatorsSet.shouldGetEvaluators) {
        store.dispatch(
          createActionToListener(Actions.GET_EVALUATORS, (e) =>
            store.dispatch(setEvaluators(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.ADD_EVALUATOR, (e) =>
            store.dispatch(addEvaluator(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.UPDATE_EVALUATOR, (e) =>
            store.dispatch(editEvaluator(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.DELETE_EVALUATOR, (e) =>
            store.dispatch(deleteEvaluator(e)),
          ),
        );
      }
      break;
    case Actions.GET_EXTERNAL_EVALUATORS_REGISTERS:
      if (state.data.externalEvaluatorsRegisterSet.shouldGetExternalEvaluatorsRegister) {
        store.dispatch(
          createActionToListener(Actions.GET_EXTERNAL_EVALUATORS_REGISTERS, (e) =>
            store.dispatch(setExternalEvaluatorsRegisters(e)),
          ),
        );
        store.dispatch(
          createActionToListener(
            Actions.GET_EXTERNAL_EVALUATORS_REGISTERS_BY_CLIENT,
            (e) => store.dispatch(setExternalEvaluatorsRegistersByClient(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.ADD_EXTERNAL_EVALUATORS_REGISTER, (e) =>
            store.dispatch(setExternalEvaluatorsRegister(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.DELETE_EXTERNAL_EVALUATORS_REGISTER, (e) =>
            store.dispatch(setDeleteExternalEvaluatorsRegister(e)),
          ),
        );
      }
      break;
    case Actions.GET_GENDERS:
      if (state.data.gendersSet.shouldGet) {
        store.dispatch(
          createActionToListener(Actions.GET_GENDERS, (e) =>
            store.dispatch(setGenders(e)),
          ),
        );
      }
      break;
    case Actions.GET_SEXUAL_MATURATIONS:
      if (state.data.maturationsSet.shouldGetMaturations) {
        store.dispatch(
          createActionToListener(Actions.GET_SEXUAL_MATURATIONS, (e) =>
            store.dispatch(setSexualMaturation(e)),
          ),
        );
      }
      break;
    case Actions.GET_EVALUATED:
      if (state.data.evaluatedSet.shouldGetEvaluated) {
        store.dispatch(
          createActionToListener(Actions.GET_EVALUATED, (e) =>
            store.dispatch(setEvaluated(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.ADD_EVALUATED, (e) =>
            store.dispatch(setPicture(e, state.profile.user.token)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.UPDATE_EVALUATED, (e) =>
            store.dispatch(
              setPictureEdit(
                e,
                state.profile.user.token,
                state.profile.user.permission.client.id,
              ),
            ),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.DELETE_EVALUATED, (e) =>
            store.dispatch(deleteEvaluated(e)),
          ),
        );
      }
      break;
    case Actions.GET_USERS:
      if (state.users.usersSet.shouldGetUsers) {
        store.dispatch(
          createActionToListener(Actions.GET_USERS, (e) => store.dispatch(setUsers(e))),
        );
        store.dispatch(
          createActionToListener(Actions.ADD_USER, (e) => store.dispatch(addUser(e))),
        );
        store.dispatch(
          createActionToListener(Actions.DELETE_USER, (e) =>
            store.dispatch(deleteEvaluated(e)),
          ),
        );
      }
      break;
    case Actions.GET_FAT_PERCENTAGES_IDEAL:
      if (state.data.tablesFatnessIdealSet.shouldGetTable) {
        store.dispatch(
          createActionToListener(Actions.GET_FAT_PERCENTAGES_IDEAL, (e) =>
            store.dispatch(getFatPercentagesIdeal(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.ADD_FAT_PERCENTAGE_IDEAL, (e) =>
            store.dispatch(setAddFatPercentageIdeal(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.UPDATE_FAT_PERCENTAGE_IDEAL, (e) =>
            store.dispatch(setUpdateFatPercentageIdeal(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.DELETE_FAT_PERCENTAGE_IDEAL, (e) =>
            store.dispatch(deleteFatPercentagesIdeal(e)),
          ),
        );
      }
      break;
    case Actions.SET_LOCALE:
      if (state.profile.shouldGetLocale) {
        store.dispatch(
          createActionToListener(Actions.SET_LOCALE, (e) =>
            store.dispatch(updateUserLocale(e)),
          ),
        );
      }
      break;
    case Actions.GET_PAYMENT_RECORDS:
      if (state.data.paymentRecordsSet.shouldGetPaymentRecords) {
        store.dispatch(
          createActionToListener(Actions.GET_PAYMENT_RECORDS, (e) =>
            store.dispatch(setPaymentRecords(e)),
          ),
        );
      }
      break;
    case Actions.GET_PROMOTIONAL_LINK:
      if (state.data.measuresSet.shouldGetMeasures) {
        store.dispatch(
          createActionToListener(Actions.GET_PROMOTIONAL_LINK, (e) =>
            store.dispatch(setPromotionalLinks(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.DELETE_PROMOTIONAL_LINK, (e) =>
            store.dispatch(deletePromotionalLink(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.ADD_PROMOTIONAL_LINK, (e) =>
            store.dispatch(addPromotionalLink(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.UPDATE_PROMOTIONAL_LINK, (e) =>
            store.dispatch(updatePromotionalLink(e)),
          ),
        );
      }
      break;
    case Actions.GET_EXERCISES:
      if (state.data.exercisesSet.shouldGetExercises) {
        store.dispatch(
          createActionToListener(Actions.GET_EXERCISES, (e) =>
            store.dispatch(setExercises(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.ADD_EXERCISE, (e) =>
            store.dispatch(addExercise(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.UPDATE_EXERCISE, (e) =>
            store.dispatch(editExercise(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.DELETE_EXERCISE, (e) =>
            store.dispatch(deleteExercise(e)),
          ),
        );
      }
      break;
    case Actions.GET_MUSCLES:
      if (state.data.musclesSet.shouldGetMuscles) {
        store.dispatch(
          createActionToListener(Actions.GET_MUSCLES, (e) =>
            store.dispatch(setMuscles(e)),
          ),
        );
      }
      break;
    case Actions.GET_MUSCLE_GROUPS:
      if (state.data.muscleGroupsSet.shouldGetMuscleGroups) {
        store.dispatch(
          createActionToListener(Actions.GET_MUSCLE_GROUPS, (e) =>
            store.dispatch(setMuscleGroups(e)),
          ),
        );
      }
      break;
    case Actions.GET_EXERCISE_TYPES:
      if (state.data.exerciseTypesSet.shouldGetExerciseTypes) {
        store.dispatch(
          createActionToListener(Actions.GET_EXERCISE_TYPES, (e) =>
            store.dispatch(setExerciseTypes(e)),
          ),
        );
      }
      break;
    case Actions.GET_PRESET_WORKOUTS:
      if (state.data.presetWorkoutsSet.shouldGetPresetWorkouts) {
        store.dispatch(
          createActionToListener(Actions.GET_PRESET_WORKOUTS, (e) =>
            store.dispatch(setPresetWorkouts(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.ADD_PRESET_WORKOUT, (e) =>
            store.dispatch(addPresetWorkout(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.UPDATE_PRESET_WORKOUT, (e) =>
            store.dispatch(editPresetWorkout(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.DELETE_PRESET_WORKOUT, (e) =>
            store.dispatch(deletePresetWorkout(e)),
          ),
        );
      }
      break;
    case Actions.GET_EVALUATED_WORKOUTS:
      if (state.data.evaluatedWorkoutsSet.shouldGetEvaluatedWorkouts) {
        store.dispatch(
          createActionToListener(Actions.GET_EVALUATED_WORKOUTS, (e) =>
            store.dispatch(setEvaluatedWorkouts(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.ADD_EVALUATED_WORKOUT, (e) =>
            store.dispatch(addEvaluatedWorkout(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.UPDATE_EVALUATED_WORKOUT, (e) =>
            store.dispatch(editEvaluatedWorkout(e)),
          ),
        );
        store.dispatch(
          createActionToListener(Actions.DELETE_EVALUATED_WORKOUT, (e) =>
            store.dispatch(deleteEvaluatedWorkout(e)),
          ),
        );
      }
      break;
    case Actions.GET_CLIENT_STATISTICS:
      if (state.data.dashboardSet.shouldGetClientStatistics) {
        store.dispatch(
          createActionToListener(Actions.GET_CLIENT_STATISTICS, (e) =>
            store.dispatch(setClientStatistics(e)),
          ),
        );
      }
      break;
    case Actions.GET_CLIENT_USAGE_STATISTICS:
      if (state.data.dashboardSet.shouldGetClientUsageStatistics) {
        store.dispatch(
          createActionToListener(Actions.GET_CLIENT_USAGE_STATISTICS, (e) =>
            store.dispatch(setClientSUsageStatistics(e)),
          ),
        );
      }
      break;
    case Actions.GET_CLIENT_ACCESS_LOG:
      store.dispatch(
        createActionToListener(Actions.GET_CLIENT_ACCESS_LOG, (e) =>
          store.dispatch(setClientAccessLog(e)),
        ),
      );
      break;
    case Actions.GET_FEATURES_VIEWED_BY_PERSON:
      if (state.featureViewed.shouldGetFeatureViewed) {
        store.dispatch(
          createActionToListener(Actions.GET_FEATURES_VIEWED_BY_PERSON, (e) =>
            store.dispatch(setFeaturesViewedByPerson(e)),
          ),
        );
      }
      break;
    default:
  }
  next(action);
};

export default shouldGetMiddleware;
