import React from 'react';

const IconGoals = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 491.52 491.52"
    width={props.width}
    height={props.height}
  >
    <circle cx="245.76" cy="245.76" r="245.76" fill="#45B39C" />
    <path fill="#3A556A" d="M150.518 361.523h83.799v25.052h-83.799z" />
    <path
      fill="#EBF0F3"
      d="M192.417 104.96c-3.683 0-6.669 2.985-6.669 6.669v249.879h13.338V111.629c0-3.684-2.986-6.669-6.669-6.669z"
    />
    <path
      fill="#E56353"
      d="M341.002 206.755c-50.506-14.744-91.41 14.743-141.916 0 .006-36.149.006-54.225 0-90.368 50.506 14.744 91.41-14.741 141.916 0 .001 36.143 0 54.219 0 90.368z"
    />
    <path
      fill="#D15241"
      d="M267.955 182.57c-13.562 10.059-26.49 19.955-40.496 28.39 38.316.758 72.809-16.096 113.543-4.205 0-33.134.002-51.122 0-81.72-20.826 23.041-50.518 40.826-73.047 57.535z"
    />
  </svg>
);

IconGoals.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconGoals;
