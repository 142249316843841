import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';

const initialState = Immutable.from({
  authors: [],
  authorSelected: {},
  loading: false,
  shouldGetAuthors: true,
});

export default function authors(state = initialState, action = {}) {
  switch (action.type) {
    case Actions.GET_AUTHORS_PENDING:
      const loadingAuthors = state.updateIn(['loading'], () => true);
      return loadingAuthors;
    case Actions.GET_AUTHORS_REJECTED:
      const rejAuths = state.updateIn(['loading'], () => false);
      return rejAuths;
    case Actions.GET_AUTHORS:
      const authors = state
        .updateIn(['authors'], () => action.payload)
        .updateIn(['loading'], () => false)
        .updateIn(['shouldGetAuthors'], () => false);
      return authors;
    case Actions.ADD_REGISTER_AUTHOR:
      const newState = Immutable.merge({
        ...state,
        authors: [...state.authors, action.payload],
      });
      return newState;
    case Actions.REGISTER_SELECTED_AUTHOR:
      const authorSelected = state.set('authorSelected', action.payload);
      return authorSelected;
    case Actions.EDIT_REGISTER_AUTHOR:
      const authorIndex = state.authors.findIndex(
        author => author.id === action.payload.id,
      );
      const newStateAuthor = state.updateIn(
        ['authors', authorIndex],
        () => action.payload,
      );
      return newStateAuthor;
    case Actions.DELETE_REGISTER_AUTHOR:
      const index = state.authors.findIndex(author => author.id === action.payload.id);
      const deleteAuthor = state.update('authors', aus => aus.slice(0, index).concat(aus.slice(index + 1)));
      return deleteAuthor;
    default:
      return state;
  }
}
