import React from 'react';
// import {FormattedMessage} from 'react-intl'; TODO: Nunca usado
// import {Card} from 'reactstrap'; TODO: Nunca usado
import classNames from 'classnames';

const AssistentDialog = (props) => {
  const { assDialSize = '' } = props;
  const assPref =
    assDialSize.length > 0 ? `assistent-dialog ${assDialSize}` : 'assistent-dialog';
  var assistentClass = classNames(assPref, props.className, {
    'assistent--danger': props.color === 'danger',
    'assistent--info': props.color === 'info',
  });
  return <div className={assistentClass}>{props.children}</div>;
};

export default AssistentDialog;
