import * as Actions from '../../constant/constants';

const initialState = {
  modal: {
    status: false,
    type: null,
    bug: false,
  },
};
export default function (state = initialState, action) {
  switch (action.type) {
    case Actions.OPEN_MODAL:
      return { ...state, modal: action.payload };
    case Actions.CLOSE_MODAL:
      return { ...state, modal: action.payload };
    default:
      return state;
  }
}
