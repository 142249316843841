import * as Actions from '../../constant/constants';
import socket from '../../socket';
import {
  MEASURE_TYPES_DEFAULT_MESSAGES,
  MEASURE_DEFAULT_MESSAGES,
} from '../../constant/defaultMessages';

export function getMeasures(event) {
  return {
    type: Actions.GET_MEASURES,
    payload: socket.sendMessage(Actions.GET_MEASURES, []),
  };
}

export function setMeasures(event) {
  let measures = [];
  if (typeof event === 'undefined') socket.sendMessage(Actions.GET_MEASURES, []);
  else {
    measures = event.data.map((measure) => {
      convertMessages(measure, MEASURE_DEFAULT_MESSAGES, MEASURE_TYPES_DEFAULT_MESSAGES);
      return measure;
    });
  }
  return {
    type: Actions.GET_MEASURES,
    payload: measures,
  };
}

export function sendMeasure(data) {
  return {
    type: Actions.SEND_MEASURE,
    payload: socket.sendMessage(Actions.SEND_MEASURE, data),
  };
}

export function getMeasuresTypes(event) {
  let measureTypes = [];
  if (typeof event === 'undefined') socket.sendMessage(Actions.GET_MEASURE_TYPES, []);
  else measureTypes = event.data;
  const translationMeasures = measureTypes.map((type) => {
    const defaultMessage = MEASURE_TYPES_DEFAULT_MESSAGES[type.measuretypename];
    type.idMessageName = `app.measure.${type.measuretypename}`;
    type.defaultMessageName = defaultMessage;
    return type;
  });
  return {
    type: Actions.GET_MEASURE_TYPES,
    payload: translationMeasures,
  };
}

export function selectRegister(register) {
  return {
    type: Actions.REGISTER_SELECTED_MEASURE,
    payload: register,
  };
}

export function selectMeasureBodyMap(measure, value) {
  return {
    type: Actions.SELECT_MEASURE_BODY_MAP,
    payload: {
      measure,
      value,
    },
  };
}

export function addMeasure(event) {
  let measure = {};
  measure = event.data;
  convertMessages(measure, MEASURE_DEFAULT_MESSAGES, MEASURE_TYPES_DEFAULT_MESSAGES);
  return {
    type: Actions.ADD_REGISTER_MEASURE,
    payload: measure,
  };
}

export function addRegister(data, vision) {
  let order = !isNaN(data.order) ? parseInt(data.order, 10) : 0;
  if (order === null) {
    order = 0;
  }
  return {
    type: Actions.ADD_REQUEST,
    payload: socket.sendMessage(Actions.ADD_MEASURE, {
      measure: data.nameMeasure,
      idmeasuretype: data.type.idmeasuretype,
      order,
      positions: [
        {
          idgender: 1, vision: data.vision, x: data.positionManX, y: data.positionManY,
        },
        {
          idgender: 2,
          vision: data.vision,
          x: data.positionWomanX,
          y: data.positionWomanY,
        },
      ],
    }),
  };
}

export function editMeasure(event) {
  let measureEdit = {};
  measureEdit = event.data;
  convertMessages(measureEdit, MEASURE_DEFAULT_MESSAGES, MEASURE_TYPES_DEFAULT_MESSAGES);
  return {
    type: Actions.EDIT_REGISTER_MEASURE,
    payload: measureEdit,
  };
}

export function editRegister(data, vision) {
  let order = !isNaN(data.order) ? parseInt(data.order, 10) : 0;
  if (order === null) {
    order = 0;
  }
  return {
    type: Actions.UPDATE_REQUEST,
    payload: socket.sendMessage(Actions.UPDATE_MEASURE, {
      id: data.id,
      measure: data.nameMeasure,
      idmeasuretype: data.type.idmeasuretype,
      order,
      positions: [
        {
          idgender: 1, vision: data.vision, x: data.positionManX, y: data.positionManY,
        },
        {
          idgender: 2,
          vision: data.vision,
          x: data.positionWomanX,
          y: data.positionWomanY,
        },
      ],
    }),
  };
}

export function deleteMeasure(event) {
  return {
    type: Actions.DELETE_REGISTER_MEASURE,
    payload: event.data,
  };
}

export function deleteRegister(data) {
  let order = !isNaN(data.order) ? parseInt(data.order, 10) : 0;
  if (order === null) {
    order = 0;
  }
  return {
    type: Actions.DELETE_REQUEST,
    payload: socket.sendMessage(Actions.DELETE_MEASURE, {
      id: data.id,
      measure: data.measure,
      order,
      idmeasuretype: data.measuretype.idmeasuretype,
      positions: [
        {
          idgender: data.idgender, vision: data.vision, x: data.x, y: data.y,
        },
        {
          idgender: data.idgender, vision: data.vision, x: data.x, y: data.y,
        },
      ],
    }),
  };
}

export function handleValueMeasure(measure, value) {
  return {
    type: Actions.SET_VALUE_MEASURE_BODY_MAP,
    payload: {
      measure,
      value,
    },
  };
}

export function convertMessages(data, constMessage, constTypes) {
  const nameMeasure = data.measure;
  const nameMeasureNoSpace = nameMeasure.replace(/\s/g, '');
  data.idMessageName = `app.measure.${data.measuretype.measuretypename}.${nameMeasureNoSpace}`.toLowerCase();
  data.defaultMessageName = constMessage[nameMeasureNoSpace] || data.measure;
  data.measuretype.idMessageType = `app.measure.${data.measuretype.measuretypename}`;
  data.measuretype.defaultMessageType = constTypes[data.measuretype.measuretypename];
  data.idHelp = `app.measure.${
    data.measuretype.measuretypename
  }.${
    nameMeasureNoSpace
  }.help`;
  data.defaultMessageHelp = constMessage[nameMeasureNoSpace];
  return data;
}
