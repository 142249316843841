import Raven from 'raven-js';

const sentry_key = '3c6ed469462c48a089f7a54881efd4d8';
const sentry_app = '1318768';
export const sentry_url = `https://${sentry_key}@sentry.io/${sentry_app}`;

export function logException(exception, context) {
  Raven.captureException(exception, {
    extra: context,
  });
  /* eslint no-console:0 */
  window && window.console && console.error && console.error(exception);
}
