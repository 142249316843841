import * as Actions from '../../constant/constants';
import socket from '../../socket';

export function getPaymentRecords(id) {
  return {
    type: Actions.GET_PAYMENT_RECORDS,
    payload: socket.sendMessage(Actions.GET_PAYMENT_RECORDS, {idcliente: id})
  }
}

export function setPaymentRecords(event) {
  return {
    type: Actions.GET_PAYMENT_RECORDS,
    payload: event.data,
  };
}

export function getClientOverduePayments(id) {
  return {
    type: Actions.GET_CLIENT_OVERDUE_PAYMENTS,
    payload: socket.sendMessage(Actions.GET_CLIENT_OVERDUE_PAYMENTS, { idcliente: id })
  }
}

export function retrySubscriptionPayment(id) {
  return {
    type: Actions.RETRY_SUBSCRIPTION_PAYMENT,
    payload: socket.sendMessage(Actions.RETRY_SUBSCRIPTION_PAYMENT, {idcliente: id})
  }
}
