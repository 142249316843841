/** Anamnesis reducer
 * @author Rafael Gomides
 * @copyright Grupo KRS
 * @description Reducer padrão das Anamneses
 */
/** Importações */
import {
  ADD_ANAMNESIS_QUESTION,
  REMOVE_ANAMNESIS_QUESTION,
  UPDATE_ANAMNESIS_QUESTION,
  GET_ANAMNESIS_QUESTION,
  GET_ANAMNESIS_QUESTIONS,
  ADD_ANAMNESIS_TEMPLATE,
  REMOVE_ANAMNESIS_TEMPLATE,
  GET_ANAMNESIS_TEMPLATES,
  GET_ANAMNESIS_TEMPLATE,
  UPDATE_ANAMNESIS_TEMPLATE,
  SHOULD_GET_ANAMNESIS_TEMPLATE,
} from '../../constant/constants';
import { isEmptyObj } from '../../utils/functions';
/** Estado Inicial */
const initialState = {
  questions: [],
  questionSelected: {},
  templates: [],
  templateSelected: {},
  evaluationTemplateSelected: {},
  shouldGetAnamnesis: true,
};
export default function Anamnesis(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_ANAMNESIS_QUESTION:
      if (typeof action.payload !== 'undefined') {
        if (!isEmptyObj(action.payload)) {
          return {
            ...state,
            questions: [action.payload, ...state.questions].sort(action.sortQuestions),
          };
        }
      }
      return state;
    case REMOVE_ANAMNESIS_QUESTION: {
      if (action.payload !== null) {
        const oldQuestionsState = [...state.questions];
        oldQuestionsState.splice(
          oldQuestionsState.findIndex(
            questions => questions.question.id === action.payload,
          ),
          1,
        );
        return {
          ...state,
          questions: [...oldQuestionsState],
        };
      }
      return state;
    }
    case UPDATE_ANAMNESIS_QUESTION: {
      if (typeof action.payload !== 'undefined') {
        const stateQuestions = [...state.questions];
        stateQuestions.splice(
          stateQuestions.findIndex(
            questions => questions.question.id === action.payload.question.id,
          ),
          1,
          action.payload,
        );
        return {
          ...state,
          questions: [...stateQuestions],
        };
      }
      return state;
    }
    case UPDATE_ANAMNESIS_TEMPLATE: {
      if (typeof action.payload !== 'undefined') {
        const stateTemplates = [...state.templates];
        stateTemplates.splice(
          stateTemplates.findIndex(
            templates => templates.template.id === action.payload.template.id,
          ),
          1,
          action.payload,
        );
        return {
          ...state,
          templates: [...stateTemplates],
        };
      }
      return state;
    }
    case GET_ANAMNESIS_QUESTION: {
      const getAnamnesisQuestion = {
        ...state,
        questionSelected: action.payload,
      };
      return getAnamnesisQuestion;
    }
    case GET_ANAMNESIS_QUESTIONS: {
      const getAnamnesisQuestions = {
        ...state,
        shouldGetAnamnesis: true,
        questions: action.payload,
      };
      return getAnamnesisQuestions;
    }
    case ADD_ANAMNESIS_TEMPLATE: {
      if (typeof action.payload !== 'undefined') {
        if (!isEmptyObj(action.payload)) {
          return {
            ...state,
            templates: [action.payload, ...state.templates].sort(action.sortTemplates),
          };
        }
      }
      return state;
    }
    case REMOVE_ANAMNESIS_TEMPLATE: {
      if (action.payload !== null) {
        const oldTemplatesState = [...state.templates];
        oldTemplatesState.splice(
          oldTemplatesState.findIndex(
            templates => templates.template.id === action.payload,
          ),
          1,
        );
        return {
          ...state,
          templates: [...oldTemplatesState],
        };
      }
      return state;
    }
    case GET_ANAMNESIS_TEMPLATE: {
      const getAnamnesisTemplate = {
        ...state,
        templateSelected: action.payload.template,
      };
      return getAnamnesisTemplate;
    }
    case GET_ANAMNESIS_TEMPLATES: {
      const getAnamnesisTemplates = {
        ...state,
        shouldGetAnamnesis: true,
        templates: action.payload,
      };
      return getAnamnesisTemplates;
    }
    case SHOULD_GET_ANAMNESIS_TEMPLATE: {
      const shouldGetAnamnesisTemplate = {
        ...state,
        shouldGetAnamnesis: true,
      };
      return shouldGetAnamnesisTemplate;
    }
    default:
      return state;
  }
}
