import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';

const initialState = Immutable.from({
  cardioVariables: [],
  loading: false,
  shouldGetCardioVariables: true,
});

export default function cardioVariables(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_CARDIO_VARIABLES_PENDING:
      const loading = state.updateIn(['loading'], () => true);
      return loading;
    case Actions.GET_CARDIO_VARIABLES_REJECTED:
      const rej = state.updateIn(['loading'], () => false);
      return rej;
    case Actions.GET_CARDIO_VARIABLES:
      const variabs = state
        .updateIn(['cardioVariables'], () => action.payload)
        .updateIn(['loading'], () => false)
        .updateIn(['shouldGetCardioVariables'], () => false);
      return variabs;
    default:
      return state;
  }
}
