import Axios from 'axios';
import * as Actions from '../../constant/constants';
import socket from '../../socket';


export function getExercises() {
  return {
    type: Actions.GET_EXERCISES,
    payload: socket.sendMessage(Actions.GET_EXERCISES, {})
  };
}

export function setExercises(res) {
  const data = !Array.isArray(res.data) ? [] : res.data.map((exercise) => {
    return convertMessages(exercise);
  });

  return {
    type: Actions.GET_EXERCISES,
    payload: data
  };
}

export function selectRegister(exercise) {
  return {
    type: Actions.REGISTER_SELECTED_EXERCISE,
    payload: exercise
  };
}

export function addExercise(res) {
  return {
    type: Actions.ADD_REGISTER_EXERCISE,
    payload: res.data
  };
}

export function addRegister(props) {
  return {
    type: Actions.ADD_REQUEST,
    payload: socket.sendMessage(Actions.ADD_EXERCISE, {
      name: props.name,
      shortname: props.shortname,
      description: props.description,
      instructions: props.instructions,
      videoid: props.videoId,
      idexercisetype: props.idExerciseType,
      idmusclegroup: props.idMuscleGroup,
      muscles: props.muscles
    })
  };
}

export function editExercise(res) {
  return {
    type: Actions.UPDATE_REGISTER_EXERCISE,
    payload: res.data
  };
}

export function editRegister(props) {
  return {
    type: Actions.UPDATE_REQUEST,
    payload: socket.sendMessage(Actions.UPDATE_EXERCISE, {
      id: props.id,
      name: props.name,
      shortname: props.shortname,
      description: props.description,
      instructions: props.instructions,
      videoid: props.videoId,
      media: props.media,
      idexercisetype: props.idExerciseType,
      idmusclegroup: props.idMuscleGroup,
      muscles: props.muscles
    }),
  };
}

export function deleteExercise(res) {
  return {
    type: Actions.DELETE_REGISTER_EXERCISE,
    payload: res.data
  };
}

export function deleteRegister(id) {
  return {
    type: Actions.DELETE_REQUEST,
    payload: socket.sendMessage(Actions.DELETE_EXERCISE, { id })
  };
}

export function getOneExercise(id) {
  return {
    type: Actions.GET_ONE_EXERCISE,
    payload: socket.sendMessage(Actions.GET_ONE_EXERCISE, { id })
  }
}

export function getExercisesTypes() {
  return {
    type: Actions.GET_EXERCISE_TYPES,
    payload: socket.sendMessage(Actions.GET_EXERCISE_TYPES, {})
  };
}

export function getExercisesByGroupType(idmusclegroup, idexercisetype, idclient) {
  return {
    type: Actions.GET_EXERCISES_BY_GROUP_TYPE,
    payload: socket.sendMessage(Actions.GET_EXERCISES_BY_GROUP_TYPE, {
      idmusclegroup,
      idexercisetype,
      idclient
    })
  }
}

export function handleMedia(idexercise, token, img) {
  const imagesTestingRegex =
    /^\s*data:([a-z]+\/[a-z0-9\-+]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,[a-z0-9!$&',()*+,;=\-._~:@/?%\s]*\s*$/i;
  const imageIsValid = imagesTestingRegex.test(img);

  return (dispatch) => {
    if (img && !imageIsValid) {
      dispatch(setHandleMediaError({ error: 'Invalid image' }));
      return;
    }

    dispatch({
      type: Actions.EXERCISE_MEDIA_REQUEST,
      payload: Axios({
        method: 'POST',
        url: `${Actions.URL_SERVER}/exercise/updateMedia`,
        data: { idexercise, token, img },
      })
        .then((res) => {
          dispatch(setHandleMediaSuccess(res.data));
          const sucessEvent = new Event(Actions.SET_EXERCISE_MEDIA);
          sucessEvent.data = res.data;
          window.dispatchEvent(sucessEvent);
        })
        .catch((err) => {
          dispatch(setHandleMediaError(err));
          const errorEvent = new Event(Actions.SET_EXERCISE_MEDIA_ERROR);
          errorEvent.data = err.response.data;
          window.dispatchEvent(errorEvent);
        }),
    });
  };
}

export function setHandleMediaSuccess(dataReceivedAfterUpdate) {  
  return {
    type: Actions.SET_EXERCISE_MEDIA,
    payload: dataReceivedAfterUpdate,
  };
}

export function setHandleMediaError(err) {
  return {
    type: Actions.EXERCISE_MEDIA_REQUEST_ERROR,
    payload: err,
  };
}


export function convertMessages(data) {
  const shortName = data.shortname;
  const shortNameWithoutSpaces = shortName.replace(/\s/g, '').toLocaleLowerCase();
  data.idMessageName = `app.exercise.${shortNameWithoutSpaces}`;
  data.defaultMessageName = data.name;
  return data;
}
