import React from 'react';
import { Icon } from 'react-fa';

const Checkbox = ({ name, value, onChange, checked, label, ...props }) => {
  return (
    <label className="checkbox" htmlFor={name}>
      <input
        className="custom-checkbox"
        type="checkbox"
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      {label}
      <span className="icons">
        <Icon name="square" className="icon-unchecked" />
        <Icon name="check-square" className="icon-checked" />
      </span>
      {props.description && (
        <small className="form-text text-muted">
          {props.description}
        </small>
      )}
    </label>
  );
};

export default Checkbox;
