import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';

const initialState = Immutable.from({ genders: [], loading: false, shouldGet: true });

export default function genders(state = initialState, action = {}) {
  switch (action.type) {
    case Actions.GET_GENDERS_PENDING:
      const loading = state.updateIn(['loading'], () => true);
      return loading;
    case Actions.GET_GENDERS_REJECTED:
      const rej = state.updateIn(['loading'], () => false);
      return rej;
    case Actions.GET_GENDERS:
      const genders = state
        .updateIn(['genders'], () => action.payload)
        .updateIn(['loading'], () => false)
        .updateIn(['shouldGet'], () => false);
      return genders;
    default:
      return state;
  }
}
