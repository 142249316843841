import Axios from 'axios';
import * as Actions from '../../constant/constants';

export function addReportBug(data) {
  return (dispatch) => {
    dispatch({
      type: Actions.ADD_REPORT_BUG,
      payload: Axios({
        method: 'POST',
        url: `${Actions.URL_SERVER}/api/reportBug`,
        data,
      }).then((response) => {
        window.dispatchEvent(
          new CustomEvent(Actions.ADDED_REPORT_BUG, {
            response,
          }),
        );
      }).catch(error => {
        window.dispatchEvent(
          new CustomEvent(Actions.ADDED_REPORT_BUG_ERROR, {
            error,
          }),
        );
      }),
    });
  };
}
