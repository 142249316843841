import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';

import clone from '../../utils/deepClone';

const initialState = Immutable.from({
  presetWorkouts: [],
  presetWorkoutSelected: {},
  loading: false,
  shouldGetPresetWorkouts: true
});

export default function presetWorkouts(state = initialState, action = {}) {
  switch (action.type) {
    case Actions.GET_PRESET_WORKOUTS_PENDING: {
      const newState = state.updateIn(['loading'], () => true);
      return newState;
    }
    case Actions.GET_PRESET_WORKOUTS_REJECTED: {
      const newState = state.updateIn(['loading'], () => false);
      return newState;
    }
    case Actions.GET_PRESET_WORKOUTS: {
      const presetWorkouts = state
        .updateIn(['presetWorkouts'], () => action.payload)
        .updateIn(['loading'], () => false)
        .updateIn(['shouldGetPresetWorkouts'], () => false);
      return presetWorkouts;
    }
    case Actions.ADD_REGISTER_PRESET_WORKOUT: {
      const newState = Immutable.merge({
        ...state,
        presetWorkouts: [...state.presetWorkouts, action.payload]
      });
      return newState;
    }
    case Actions.REGISTER_SELECTED_PRESET_WORKOUT: {
      const presetWorkoutSelected = state.set('presetWorkoutSelected', action.payload);
      return presetWorkoutSelected;
    }
    case Actions.UPDATE_REGISTER_PRESET_WORKOUT: {
      const presetWorkouts = state.presetWorkouts;
      for (const workout of presetWorkouts) {
        if (workout.id === action.payload.id) {
          Object.assign(clone(workout), clone(action.payload));
          break;
        }
      }
      const newState = state.updateIn(['presetWorkouts'], () => presetWorkouts);
      return newState;
    }
    case Actions.DELETE_REGISTER_PRESET_WORKOUT: {
      const presetWorkouts = state.presetWorkouts.filter((workout) => workout.id !== action.payload.id);
      const newState = state.updateIn(['presetWorkouts'], () => presetWorkouts);
      return newState;
    }
    default:
      return state;
  }
}
