/* eslint-disable no-case-declarations */
import Immutable from 'seamless-immutable';
import moment from 'moment';
import * as Actions from '../../constant/constants';
import {
  calcFatWeight,
  calcLeanWeight,
  calcDesirableWeight,
  calcResidualWeight,
  calcBoneWeight,
  calcMuscleWeight,
  percentageMuscleWeight,
  calcDesirableFatWeight,
  calcDesirableLeanWeight,
} from '../../utils/bodyComposition';
import { isToday } from '../../utils/functions';
import clone from '../../utils/deepClone';

const evaluationInitial = {
  active: true,
  bodycomposition: null,
  cardiorespiratory: null,
  neuromotor: { isLoadingResults: false },
  client: null,
  created: '',
  evaluated: null,
  evaluationdate: null,
  evaluator: null,
  id: 0,
  imc: 0,
  measures: null,
  updated: '',
  template: {},
  anamnesisAnswers: [],
  alertReport: false,
};

const initialState = Immutable.from({
  evaluated: [],
  evaluatedSelected: {},
  evaluationSelected: evaluationInitial,
  protocolSelected: {},
  protocolCardioSelected: {},
  protocolNeuroSelected: {},
  protocolsNeuromotor: [],
  loading: true,
  error: false,
  shouldGetEvaluated: true,
  evaluation: {
    lastEvaluation: null,
    evaluations: {},
    limit: 10,
    page: 0,
    totalPages: 0,
    loadingEvaluations: true,
    currentPage: null,
  },
});

export default function evaluated(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_EVALUATED_PENDING:
      const loading = state.updateIn(['loading'], () => true);
      return loading;
    case Actions.GET_EVALUATED_REJECTED:
      const loadingRej = state.updateIn(['loading'], () => false);
      return loadingRej;
    case Actions.GET_EVALUATED:
      const evaluated = state
        .updateIn(['evaluated'], () => action.payload)
        .updateIn(['loading'], () => false)
        .updateIn(['shouldGetEvaluated'], () => false);
      return evaluated;
    case Actions.GET_EVALUATIONS_PENDING:
      const loadPen = state.updateIn(['evaluation', 'loadingEvaluations'], () => true);
      return loadPen;
    case Actions.GET_EVALUATIONS_REJECTED:
      const loadRej = state.updateIn(['evaluation', 'loadingEvaluations'], () => false);
      return loadRej;
    case Actions.ADD_REGISTER_EVALUATED:
      const newState = Immutable.merge({
        ...state,
        evaluated: [...state.evaluated, action.payload],
      });
      return newState;
    case Actions.REGISTER_SELECTED_EVALUATED:
      const evaluatedSelected = state
        .set('evaluatedSelected', action.payload)
        .set(
          'evaluation',
          createEvaluationState(state.evaluation, action.payload.totalevaluations, 1),
        );
      return evaluatedSelected;
    case Actions.EDIT_REGISTER_EVALUATED:
      const evaluatedIndex = state.evaluated.findIndex(
        evaluated => evaluated.id === action.payload.id,
      );
      const newStateFormula = state
        .updateIn(['evaluated', evaluatedIndex], () => action.payload)
        .set('evaluatedSelected', action.payload);
      return newStateFormula;
    case Actions.DELETE_REGISTER_EVALUATED:
      const index = state.evaluated.findIndex(
        evaluated => evaluated.id === action.payload.id,
      );
      const deleteFormula = state.update('evaluated', aus => aus.slice(0, index).concat(aus.slice(index + 1)));
      return deleteFormula;
    case Actions.DESTROY_STATUS:
      return state;
    case Actions.ADD_EVALUATION:
      return addEvaluation(state, action.payload);
    case Actions.SELECTED_REGISTER_EVALUATION:
      const alertReport = state.evaluationSelected.id === action.payload.id
        ? state.evaluationSelected.alertReport
        : false;

      const evaluation = state
        .set('evaluationSelected', { ...action.payload, neuromotor: { isLoadingResults: false }, alertReport })
        .set('protocolNeuroSelected', {});
      return evaluation;
    case Actions.SELECTED_REGISTER_PROTOCOL:
      const protocolSelected = state
        .set('protocolSelected', action.payload)
        .updateIn(
          ['evaluationSelected', 'bodycomposition', 'formula'],
          () => action.payload,
        );
      return protocolSelected;
    case Actions.UPDATE_EVALUATION_EVALUATOR:
      const updateEvaluator = state.updateIn(
        ['evaluationSelected', 'evaluator'],
        () => action.payload,
      );
      return updateEvaluator;
    case Actions.RESET_REGISTERED_PROTOCOL:
      const protocolReseted = state.set('protocolSelected', {});
      return protocolReseted;
    case Actions.GET_BODY_COMPOSITION:
      const bcomposition = state
        .updateIn(['evaluationSelected', 'bodycomposition'], () => action.payload)
        .set('protocolSelected', action.payload.formula);
      return bcomposition;
    case Actions.UPDATE_EVALUATION_DATE:
      const newevaluationdate = state
        .updateIn(
          ['evaluationSelected', 'evaluationdate'],
          () => action.payload.evaluationdate,
        )
        .updateIn(['evaluatedSelected', 'wasAvaliatedToday'], () => checkEvaluationToday(state, action.payload.evaluationdate))
        .updateIn(
          ['evaluatedSelected', 'reavaliationdate'],
          () => action.payload.reavaliationdate,
        );
      return newevaluationdate;
    case Actions.UPDATE_EVALUATION_MEASURES:
      var stateTmp;
      var idEvaluation = action.payload.id;
      var { measures } = action.payload;
      var { imc } = action.payload;

      // Atualiza a avaliação que está selecionada
      stateTmp = state
        .updateIn(['evaluationSelected', 'measures'], () => measures)
        .updateIn(['evaluationSelected', 'imc'], () => imc);

      // Se houver última avaliação e for a mesma que a atualizada
      if (
        state.evaluation.lastEvaluation
        && state.evaluation.lastEvaluation.id === idEvaluation
      ) {
        stateTmp = stateTmp
          .updateIn(['evaluation', 'lastEvaluation', 'measures'], () => measures)
          .updateIn(['evaluation', 'lastEvaluation', 'imc'], () => imc);
      }

      // Caso a pessoa tenha vindo do painel do avaliado tanto evaluations quanto currentPage foram preenchidas precisam ser atualizadas
      if (
        typeof state.evaluation.evaluations !== 'undefined'
        && state.evaluation.currentPage
      ) {
        // Encontra o index da avaliação no currentPage
        const indexCurrent = state.evaluation.currentPage.findIndex(
          evaluation => evaluation.id === idEvaluation,
        );

        const obj = state.evaluation.evaluations;
        // Este objeto é o que guarda as avaliações por page. Ex: page1: {}, page2:{}

        // Percorremos o objeto procurando pelas pages que não sejam nulas, para encontrarmos o índice da avaliação que necessita ser atualizada.
        for (const page in obj) {
          // Caso o objeto seja diferente de null , entra e procura o índice
          if (obj[page] !== null) {
            const indexUpdate = obj[page].findIndex(
              evaluation => evaluation.id === idEvaluation,
            );

            if (indexUpdate === -1) return;

            // Se o índice for diferente de -1, atualiza tanto o evaluations quanto o currentPage a avaliação que foi editada
            stateTmp = stateTmp
              .updateIn(
                ['evaluation', 'evaluations', page, indexUpdate, 'measures'],
                () => measures,
              )
              .updateIn(
                ['evaluation', 'evaluations', page, indexUpdate, 'imc'],
                () => imc,
              )
              .updateIn(
                ['evaluation', 'currentPage', indexCurrent, 'measures'],
                () => measures,
              )
              .updateIn(['evaluation', 'currentPage', indexCurrent, 'imc'], () => imc);
          }
        }
      }

      return stateTmp;
    case Actions.UPDATE_EVALUATION_BODY_COMPOSITION:
      var stateTmp0 = state;
      var idEvaluation0 = action.payload.idevaluation;

      // Se houver última avaliação e for a mesma que a atualizada
      if (
        state.evaluation.lastEvaluation
        && state.evaluation.lastEvaluation.id === idEvaluation0
      ) {
        stateTmp0 = state.updateIn(
          ['evaluation', 'lastEvaluation', 'bodycomposition'],
          () => action.payload,
        );
      }

      // Caso a pessoa tenha vindo do painel do avaliado tanto evaluations quanto currentPage foram preenchidas precisam ser atualizadas
      if (
        typeof state.evaluation.evaluations !== 'undefined'
        && state.evaluation.currentPage
      ) {
        // Encontra o index da avaliação no currentPage
        const indexCurrent = state.evaluation.currentPage.findIndex(
          evaluation => evaluation.id === idEvaluation0,
        );

        const obj0 = state.evaluation.evaluations;
        // Este objeto é o que guarda as avaliações por page. Ex: page1: {}, page2:{}

        // Percorremos o objeto procurando pelas pages que não sejam nulas, para encontrarmos o índice da avaliação que necessita ser atualizada.
        for (const page in obj0) {
          // Caso o 0eto seja diferente de null , entra e procura o índice
          if (obj0[page] !== null) {
            const indexUpdate = obj0[page].findIndex(
              evaluation => evaluation.id === idEvaluation0,
            );

            if (indexUpdate === -1) return;

            // Se o índice for diferente de -1, atualiza tanto o evaluations quanto o currentPage a avaliação que foi editada
            stateTmp0 = stateTmp0
              .updateIn(
                ['evaluation', 'evaluations', page, indexUpdate, 'bodycomposition'],
                () => action.payload,
              )
              .updateIn(
                ['evaluation', 'currentPage', indexCurrent, 'bodycomposition'],
                () => action.payload,
              );
          }
        }
      }
      return stateTmp0;
    case Actions.UPDATE_EVALUATION_ANAMNESIS:
      const newAnamnesis = state
        .updateIn(['evaluationSelected', 'template'], () => action.payload.template)
        .updateIn(
          ['evaluationSelected', 'anamnesisAnswers'],
          () => action.payload.anamnesisAnswers,
        );

      return newAnamnesis;
    case Actions.UPDATE_EVALUATION_PARQ: {
      const newState = state
        .updateIn(['evaluationSelected', 'template'], () => action.payload.template)
        .updateIn(
          ['evaluationSelected', 'parQAnswers'],
          () => action.payload.parQAnswers,
        );
      return newState;
    }
    case Actions.UPDATE_EVALUATION_ALERT_REPORT:
      const evaluationWithAlertReportChanged = state.updateIn(
        ['evaluationSelected', 'alertReport'],
        () => action.payload,
      );

      return evaluationWithAlertReportChanged;
    case Actions.DELETE_EVALUATION:
      const totalEvaluations = state.evaluatedSelected.totalevaluations - 1;
      let deleteEvaluation = state
        .set(
          'evaluation',
          createEvaluationState(state.evaluation, totalEvaluations, action.payload),
        )
        .updateIn(['evaluatedSelected', 'totalevaluations'], () => totalEvaluations)
        .updateIn(['evaluatedSelected', 'wasAvaliatedToday'], () => {
          const secondEvaluationFromCurrentPage = state.evaluation.currentPage[1];
          const secondEvaluationDateFromCurrentPage = secondEvaluationFromCurrentPage
            ? secondEvaluationFromCurrentPage.evaluationdate
            : null;

          checkEvaluationToday(state, secondEvaluationDateFromCurrentPage);
        })
        .updateIn(['evaluatedSelected', 'reavaliationdate'], () => updateDateNextEvaluation(state));
      if (totalEvaluations === 0) {
        deleteEvaluation = deleteEvaluation.set('evaluationSelected', evaluationInitial);
      }
      return deleteEvaluation;
    case Actions.NOT_EVALUATIONS:
      const notEvaluations = state.updateIn(
        ['evaluation', 'loadingEvaluations'],
        () => false,
      );
      return notEvaluations;
    case Actions.SET_CURRENT_PAGE_EVALUATIONS:
      const curPage = state
        .set('evaluation', updateEvaluationsPage(state, action.payload))
        .updateIn(['evaluation', 'loadingEvaluations'], () => false);
      return curPage;
    case Actions.CALCULATE_BODY_COMPOSITION:
      const resultComposition = { ...state.evaluationSelected.bodycomposition };
      // id do genero do avaliado usado nas equações
      const gender = state.evaluatedSelected.person.gender.id;
      const {
        percentfatcurrent, percentfatminideal, percentfatmaxideal, biestiloide, femoralBiepicondilo, weightcurrent,
      } = action;

      // Peso gordo atual
      const fatmasscurrent = calcFatWeight(weightcurrent, percentfatcurrent);
      // Peso magro atual
      const fatfreemasscurrent = calcLeanWeight(weightcurrent, fatmasscurrent);

      // PESO DESEJADO
      // Peso desejado(mínimo)
      const weightminideal = calcDesirableWeight(fatfreemasscurrent, percentfatminideal);
      // Peso desejado(máximo)
      const weightmaxideal = calcDesirableWeight(fatfreemasscurrent, percentfatmaxideal);
      // Peso gordo minimo desejado
      const fatmassminideal = calcDesirableFatWeight(weightminideal, percentfatminideal);
      // Peso gordo máximo desejado
      const fatmassmaxideal = calcDesirableFatWeight(weightmaxideal, percentfatmaxideal);
      // Peso magro mínimo desejado
      const fatfreemassminideal = calcDesirableLeanWeight(weightminideal, fatmassminideal);
      // Peso magro máximo desejado
      const fatfreemassmaxideal = calcDesirableLeanWeight(weightmaxideal, fatmassmaxideal);

      // DISTRIBUIÇÃO DO PESO MAGRO
      // Peso ósseo
      const osseoweight = biestiloide !== 0 && femoralBiepicondilo !== 0
        ? calcBoneWeight(action.heightcurrent, biestiloide, femoralBiepicondilo)
        : 0;
      // Peso residual
      const residualweight = calcResidualWeight(weightcurrent, gender);
      // Peso Muscular
      const muscleweight = calcMuscleWeight(
        weightcurrent,
        residualweight,
        osseoweight,
        fatmasscurrent,
      );

      // //Porcentagem de peso muscular
      const percentmuscleweight = percentageMuscleWeight(muscleweight, weightcurrent);

      // Atribuindo os valores ao objeto salvo

      // percentuais de gordura
      resultComposition.percentfatcurrent = percentfatcurrent;
      resultComposition.percentfatminideal = percentfatminideal;
      resultComposition.percentfatmaxideal = percentfatmaxideal;
      // //peso
      resultComposition.weightcurrent = weightcurrent;
      resultComposition.weightminideal = weightminideal;
      resultComposition.weightmaxideal = weightmaxideal;
      // //peso magro
      resultComposition.fatfreemasscurrent = fatfreemasscurrent;
      resultComposition.fatfreemassminideal = fatfreemassminideal;
      resultComposition.fatfreemassmaxideal = fatfreemassmaxideal;
      // //peso gordo
      resultComposition.fatmasscurrent = fatmasscurrent;
      resultComposition.fatmassminideal = fatmassminideal;
      resultComposition.fatmassmaxideal = fatmassmaxideal;
      // //distribuicao do peso magro
      resultComposition.muscleweight = muscleweight;
      resultComposition.residualweight = residualweight;
      resultComposition.osseoweight = osseoweight;
      resultComposition.percentmuscleweight = percentmuscleweight;

      const newResultBC = state.updateIn(
        ['evaluationSelected', 'bodycomposition'],
        () => resultComposition,
      );
      return newResultBC;
    case Actions.SET_EVALUATION_TEMPLATE: {
      const evaluationTemplateSelected = {
        ...state,
        evaluationSelected: {
          ...state.evaluatedSelected,
          evaluationTemplateSelected: action.payload,
        },
      };
      return evaluationTemplateSelected;
    }
    case Actions.GET_CARDIO:
      const cardioFormulaResults = state
        .updateIn(['evaluationSelected', 'cardiorespiratory'], () => action.payload)
        .set('protocolCardioSelected', action.payload.formula);
      return cardioFormulaResults;
    case Actions.SELECTED_CARDIO_PROTOCOL:
      const protocolCardioSelected = state
        .set('protocolCardioSelected', action.payload)
        .updateIn(['evaluationSelected', 'cardiorespiratory', 'formula'], () => action.payload);
      return protocolCardioSelected;
    case Actions.CALCULATE_CARDIORESPIRATORY:
      const resultCardiorespiratory = { ...state.evaluationSelected.cardiorespiratory, ...action.payload };
      const newResultC = state.updateIn(['evaluationSelected', 'cardiorespiratory'], () => resultCardiorespiratory);
      return newResultC;
    case Actions.UPDATE_EVALUATION_CARDIO_VARIABLES:
      let updateEvaluationCardioVariables = state
        .updateIn(['evaluationSelected', 'cardioVariablesEvaluation'], () => action.payload);
      return updateEvaluationCardioVariables;
    case Actions.SELECTED_NEURO_PROTOCOL:
    case Actions.GET_ONE_NEUROMOTOR_TEST:
      const protocolNeuroSelected = state
        .set('protocolNeuroSelected', action.payload);
      return protocolNeuroSelected;
    case Actions.UPDATE_EVALUATION_NEURO_VARIABLES:
      {
        const neuromotorTestEvaluationClone = clone(state.evaluationSelected.neuromotorTestEvaluation || []);
        const indexVariableNeuroUpdate = neuromotorTestEvaluationClone.findIndex((i) => i.id === action.payload.id);

        if (indexVariableNeuroUpdate !== -1) {
          neuromotorTestEvaluationClone[indexVariableNeuroUpdate] = action.payload;
        } else if (indexVariableNeuroUpdate === -1) {
          neuromotorTestEvaluationClone.push(action.payload);
        }

        return state
          .updateIn(['evaluationSelected', 'neuromotorTestEvaluation'], () => neuromotorTestEvaluationClone);
      }
    case Actions.CALCULATE_NEUROMOTOR:
      {
        const neuromotorTestEvaluationClone = clone(state.evaluationSelected.neuromotor);
        const formulaId = clone(state.protocolNeuroSelected.code);
    
        neuromotorTestEvaluationClone.classification = {
          ...neuromotorTestEvaluationClone.classification,
          [`${formulaId}`]: action.payload.classification
        };

        return state.updateIn(['evaluationSelected', 'neuromotor'], () => neuromotorTestEvaluationClone);
      }
    case Actions.GET_NEUROMOTOR_EVALUATION_RESULT:
      {
        const neuromotorTestEvaluationClone = clone(state.evaluationSelected.neuromotor || {});
        const formulaId = clone(state.protocolNeuroSelected.code);
        const classification = action.payload.classification !== "" ? action.payload.classification : '-';
        
        neuromotorTestEvaluationClone.isLoadingResults = false;
        neuromotorTestEvaluationClone.classification = {
          ...neuromotorTestEvaluationClone.classification,
          [`${formulaId}`]: classification
        };

        return state.updateIn(['evaluationSelected', 'neuromotor'], () => neuromotorTestEvaluationClone);
      }
    case Actions.GET_NEUROMOTOR_EVALUATION_RESULT_PENDING:
      return state.updateIn(['evaluationSelected', 'neuromotor', 'isLoadingResults'], () => true);
    case Actions.GET_NEUROMOTOR_EVALUATION_RESULT_ERROR:
    case Actions.GET_NEUROMOTOR_EVALUATION_RESULT_REJECTED:
      return state.updateIn(['evaluationSelected', 'neuromotor', 'isLoadingResults'], () => false);
    default:
      return state;
  }
}

function checkEvaluationToday(state, newDate = null) {
  const evaluationdate = newDate || state.evaluationSelected.evaluationdate;

  return Immutable.from(isToday(evaluationdate));
}

function updateDateNextEvaluation(state) {
  if (state.evaluation.currentPage.length > 1) {
    if (state.evaluation.currentPage[0].id === state.evaluationSelected.id) {
      const evaluationDate = moment(state.evaluation.currentPage[1].evaluationdate);
      const reavaliationdays = state.evaluatedSelected.reavaliationdays > 0
        ? state.evaluatedSelected.reavaliationdays
        : 0;
      return Immutable.from(evaluationDate.add(reavaliationdays, 'days').format());
    }
    return Immutable.from(state.evaluatedSelected.reavaliationdate);
  }
  return null;
}

function createEvaluationState(state, totalEvaluations, pg) {
  const s = {
    lastEvaluation: state.lastEvaluation || null,
    evaluations: {},
    limit: state.limit,
    page: 1,
    totalPages: Math.ceil(totalEvaluations / state.limit),
    loadingEvaluations: true,
    currentPage: null,
  };
  for (let i = 1; i <= s.totalPages; i++) {
    s.evaluations[`page${i}`] = null;
  }
  s.page = Math.min(pg, s.totalPages);
  return Immutable.from(s);
}

function updateEvaluationsPage(state, payload) {
  const ev = Immutable.asMutable(state.evaluation, { deep: true });
  ev.page = payload.page;
  ev.currentPage = payload.evaluations;
  ev.loadingEvaluations = false;
  if (ev.evaluations[`page${payload.page}`] === null) {
    ev.evaluations[`page${payload.page}`] = payload.evaluations.slice();
  }
  if (payload.page === 1 && payload.evaluations.length > 0) {
    ev.lastEvaluation = payload.evaluations[0];
  }

  if (payload.evaluations.length === 0) {
    ev.lastEvaluation = null;
  }
  return Immutable.from(ev);
}

function addEvaluation(state, payload) {
  const stateTmp = Immutable.asMutable(state, { deep: true });
  stateTmp.evaluationSelected = { ...payload, neuromotor: { isLoadingResults: false }};
  stateTmp.protocolNeuroSelected = {};
  stateTmp.evaluatedSelected.totalevaluations++;
  for (const key in stateTmp.evaluation.evaluations) {
    if (key !== 'page1') {
      stateTmp.evaluation.evaluations[key] = null;
    }
  }

  stateTmp.evaluation.page = 1;
  stateTmp.evaluation.totalPages = Math.ceil(
    stateTmp.evaluatedSelected.totalevaluations / stateTmp.evaluation.limit,
  );
  for (let i = 1; i <= stateTmp.evaluation.totalPages; i++) {
    if (typeof stateTmp.evaluation.evaluations[`page${i}`] === 'undefined') stateTmp.evaluation.evaluations[`page${i}`] = null;
  }

  if (stateTmp.evaluation.evaluations.page1) {
    stateTmp.evaluation.evaluations.page1.unshift(payload);
    stateTmp.evaluation.evaluations.page1 = stateTmp.evaluation.evaluations.page1.slice(0, stateTmp.evaluation.limit);
    stateTmp.evaluation.currentPage = stateTmp.evaluation.evaluations.page1;
  }
  stateTmp.evaluation.lastEvaluation = payload;
  const idevaluated = stateTmp.evaluatedSelected.id;
  const lengthEvaluated = stateTmp.evaluated.length;
  for (let i = 0; i < lengthEvaluated; i++) {
    if (stateTmp.evaluated[i].id === idevaluated) {
      stateTmp.evaluated[i].totalevaluations = stateTmp.evaluatedSelected.totalevaluations;
      break;
    }
  }
  return Immutable.from(stateTmp);
}
