import React, { Component } from 'react';

// Redux
import { connect } from 'react-redux';

// Components
import Icon from 'react-fa';
import ModalDialog from './ModalDialog';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

// Utils
import {
  getProfilePermissionLevel,
  filtersAnnouncementsByProfileType,
} from '../../utils/functions';

import newAnnouncementListJSON from '../../announcements.json';

class ModalAnnouncements extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      announcementViewed: [],
      announcementNotViewed: [],
      newAnnouncementList: [],
    };
    this.setActiveTab = this.setActiveTab.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);
    this.setViewedAnnouncement = this.setViewedAnnouncement.bind(this);
  }

  componentDidMount() {
    const { user } = this.props.profile;
    const profilePermission = getProfilePermissionLevel(user.permission, user.person);

    let activeTab = 0;
    let announcementNotViewed = [];
    let announcementViewed = this.getAnnouncementViewedStore();
    const newAnnouncementList = this.removeOldAnnouncements(filtersAnnouncementsByProfileType(
      newAnnouncementListJSON,
      profilePermission.rule,
    ));

    if (Array.isArray(newAnnouncementList) && newAnnouncementList.length > 0) {
      announcementNotViewed = newAnnouncementList.reduce((acc, item) => {
        if (!announcementViewed.includes(item.id)) {
          acc = [...acc, item.id];
        }
        return acc;
      }, []);
      const indexLastAnnouncementNotSeen = newAnnouncementList.findLastIndex(
        (i) => !announcementViewed.includes(i.id),
      );
      const idLastAnnouncementNotSeen =
        indexLastAnnouncementNotSeen > -1
          ? newAnnouncementList[indexLastAnnouncementNotSeen].id
          : null;

      if (idLastAnnouncementNotSeen !== null) {
        const announcementViewed1 = [...announcementViewed, idLastAnnouncementNotSeen];
        activeTab = indexLastAnnouncementNotSeen;
        localStorage.setItem('pht_announcements', JSON.stringify(announcementViewed1));
      }
    }

    this.setState({
      newAnnouncementList,
      announcementViewed,
      announcementNotViewed,
      activeTab,
    });
  }

  removeOldAnnouncements(announcementList) {
    if(Array.isArray(announcementList)){
      return announcementList.filter(a => {
        const now = new Date();
        const announcementExpire = a.announcementExpire;
        if(announcementExpire === undefined || announcementExpire === ""){
          return true;
        }
        const announcementExpireDate = new Date(announcementExpire);
        if(isNaN(announcementExpireDate.getTime())){
          return false;
        }
        if(announcementExpireDate.getTime() <= now.getTime()){
          return false;
        }
        return true;
      });
    }
    return announcementList;
  }

  setActiveTab(step) {
    this.setState({ activeTab: step });
  }

  getAnnouncementViewedStore() {
    try {
      const announcementViewedStore = JSON.parse(
        localStorage.getItem('pht_announcements'),
      );
      if (Array.isArray(announcementViewedStore)) {
        return announcementViewedStore;
      }
      return [];
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  setViewedAnnouncement(id) {
    if (!this.state.announcementViewed.includes(id)) {
      const announcementViewed = [...this.state.announcementViewed, id];
      this.setState(
        {
          announcementViewed,
        },
        () => {
          localStorage.setItem('pht_announcements', JSON.stringify(announcementViewed));
        },
      );
    }
  }

  handleChangeTab(step = null, item) {
    if (step !== null) {
      this.setActiveTab(step);
    }

    this.setViewedAnnouncement(item.id);
  }

  render() {
    return (
      <ModalDialog
        size="xl"
        isCentered
        backdrop="static"
        className="modal-feature-preview"
        closeModal={this.props.closeModalAnnouncements}
        toggleModal={this.props.closeModalAnnouncements}
        title={
          <FormattedMessage id="app.global.announcements" defaultMessage="Comunicados" />
        }
      >
        <Row>
          {this.state.newAnnouncementList.length > 0 ? (
            <>
              <Col className="col-4 border-right d-none d-sm-block">
                <Nav
                  role="tablist"
                  id="v-pills-tab"
                  aria-orientation="vertical"
                  className="flex-column nav-pills me-3"
                >
                  {this.state.newAnnouncementList.map((item, i) => {
                    const isNew = this.state.announcementNotViewed.includes(item.id);
                    return (
                      <NavItem key={i} className="border-bottom cursor-pointer">
                        <NavLink
                          onClick={() => this.handleChangeTab(i, item)}
                          className={`${
                            this.state.activeTab === i
                              ? 'bg-primary text-white font-weight-bold border-success'
                              : 'text-gray-900'
                          }`}
                        >
                          {!!item.announcementTitle && <span>{item.announcementTitle}</span>}
                          {isNew && (
                            <span className="badge badge-pill badge-primary">
                              <FormattedMessage
                                id="app.global.new"
                                defaultMessage="Novo"
                              />
                            </span>
                          )}
                        </NavLink>
                      </NavItem>
                    );
                  })}
                </Nav>
              </Col>

              <Col className="col-8 d-none d-sm-block">
                <TabContent
                  activeTab={this.state.activeTab}
                  className="pt-3 pr-3 pb-3 pl-0 mr-0 overflow-y-auto markdown-body"
                >
                  {this.state.newAnnouncementList.map((item, i) => (
                    <TabPane key={i} tabId={i}>
                      {item.announcements.map((c, ic) => (
                        <React.Fragment key={ic}>
                          {c.title && (
                            <h5 className="mb-3 font-weight-bold">{c.title}</h5>
                          )}
                          {Array.isArray(c.description) ? (
                            c.description.map((d, i) => (
                              <div key={i} dangerouslySetInnerHTML={{ __html: d }} />
                            ))
                          ) : (
                            <div dangerouslySetInnerHTML={{ __html: c.description }} />
                          )}
                        </React.Fragment>
                      ))}
                    </TabPane>
                  ))}
                </TabContent>
              </Col>

              <Col className="d-sm-none">
                {this.state.newAnnouncementList.map((item, i) => {
                  const isNew = this.state.announcementNotViewed.includes(item.id);
                  return (
                    <details
                      key={i}
                      className={`accordion-features ${i === 0 ? 'pt-2' : ''} pr-2 pl-2`}
                      open={
                        this.state.newAnnouncementList.length !==
                          this.state.announcementViewed.length &&
                        this.state.activeTab === i
                      }
                    >
                      <summary
                        onClick={() => this.handleChangeTab(null, item)}
                        className={`accordion-features-title border-left border-right border-bottom ${
                          i === 0 ? 'rounded-top border-top' : ''
                        } ${
                          i + 1 === this.state.newAnnouncementList.length
                            ? 'rounded-bottom'
                            : ''
                        }`}
                      >
                        {!!item.announcementTitle && <span>{item.announcementTitle}</span>}
                        {isNew && (
                          <span className="badge badge-pill badge-primary">
                            <FormattedMessage id="app.global.new" defaultMessage="Novo" />
                          </span>
                        )}
                        <Icon name="chevron-up" className="accordion-features-icon" />
                      </summary>
                      <div className="accordion-features-body">
                        <div className="border border-top-0 p-2">
                          {item.announcements.map((c, i) => (
                            <React.Fragment key={i}>
                              {c.title && (
                                <h5 className="mb-3 font-weight-bold">{c.title}</h5>
                              )}
                              {Array.isArray(c.description) ? (
                                c.description.map((d, i) => (
                                  <div key={i} dangerouslySetInnerHTML={{ __html: d }} />
                                ))
                              ) : (
                                <div
                                  dangerouslySetInnerHTML={{ __html: c.description }}
                                />
                              )}
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    </details>
                  );
                })}
              </Col>
            </>
          ) : (
            <Col className="text-center">
              <p className="m-4">Sem novos comunicados para explorar por enquanto.</p>
            </Col>
          )}
        </Row>
      </ModalDialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile,
  };
};

export default connect(mapStateToProps)(injectIntl(ModalAnnouncements));
