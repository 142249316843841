import * as Actions from '../../constant/constants';
import socket from '../../socket';
import { GENDERS_DEFAULT_MESSAGES } from '../../constant/defaultMessages';

export function getGenders() {
  return {
    type: Actions.GET_GENDERS,
    payload: socket.sendMessage(Actions.GET_GENDERS, []),
  };
}

export function getGendersDefault() {
  return {
    type: Actions.GET_GENDERS,
    payload: [
      {
        defaultMessageName: 'masculino',
        id: 1,
        idMessageName: 'app.global.gender.male',
        name: 'male',
      },
      {
        defaultMessageName: 'feminino',
        id: 2,
        idMessageName: 'app.global.gender.female',
        name: 'female',
      },
    ],
  };
}

export function setGenders(event) {
  let genders = [];
  if (typeof event === 'undefined') socket.sendMessage(Actions.GET_GENDERS, []);
  else genders = event.data.map(gender => convertMessages(gender, GENDERS_DEFAULT_MESSAGES));
  return {
    type: Actions.GET_GENDERS,
    payload: genders,
  };
}

function convertMessages(data, constMessage) {
  const nameGender = data.name;
  const nameGenderNoSpace = nameGender.replace(/\s/g, '');
  data.idMessageName = `app.global.gender.${nameGenderNoSpace}`;
  data.defaultMessageName = constMessage[nameGenderNoSpace] || data.name;
  return data;
}
