import * as Actions from '../../constant/constants';
import * as StateTypes from '../../constant/stateTypes';

const initialState = {
  public_key: {
    error: StateTypes.publicKey.NOT_ERROR,
    loading: StateTypes.publicKey.NOT_LOADING,
    success: StateTypes.publicKey.REQUEST_FAILED,
    key: '',
  },
};

export default function getPublicKey(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_PUBLIC_KEY_FULFILLED:
      var publicKeyString = JSON.parse(action.payload.request.responseText);
      return {
        ...state,
        public_key: {
          ...state.public_key,
          loading: StateTypes.publicKey.NOT_LOADING,
          error: StateTypes.publicKey.NOT_ERROR,
          success: StateTypes.publicKey.REQUEST_SUCCESS,
          key: publicKeyString.public_key,
        },
      };
    case Actions.GET_PUBLIC_KEY_PENDING:
      return {
        ...state,
        public_key: {
          ...state.public_key,
          loading: StateTypes.publicKey.LOADING,
          error: StateTypes.publicKey.NOT_ERROR,
        },
      };
    case Actions.GET_PUBLIC_KEY_REJECTED:
      return {
        ...state,
        public_key: {
          ...state.public_key,
          loading: StateTypes.publicKey.NOT_LOADING,
          error: StateTypes.publicKey.ERROR,
          success: StateTypes.publicKey.REQUEST_FAILED,
        },
      };
    default:
      return state;
  }
}
