import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, CardBody, CardFooter, CardTitle } from 'reactstrap';
import { Link } from 'react-router-dom';

const BoxRoute = (props) => {
  return (
    <Card className={`card-box ${props.classe}`}>
      <Link to={props.link}>
        <CardBody>{props.children}</CardBody>
        <CardFooter className={props.classNameFooter}>
          <CardTitle>
            <FormattedMessage
              id={props.idMessage}
              defaultMessage={props.defaultMessage}
            />
            {props.label && (
              <>
                <span className="small ml-2">{props.label}</span>
              </>
            )}
          </CardTitle>
        </CardFooter>
      </Link>
    </Card>
  );
};

export default BoxRoute;
