import * as Actions from '../../constant/constants';
import socket from '../../socket';
import { GOALS_DEFAULT_MESSAGES } from '../../constant/defaultMessages';

export function getGoals(e) {
  return {
    type: Actions.GET_GOALS,
    payload: socket.sendMessage(Actions.GET_GOALS, []),
  };
}

export function setGoals(event) {
  let goals = [];
  if (typeof event === 'undefined') socket.sendMessage(Actions.GET_GOALS, []);
  else goals = event.data.map(goal => convertMessages(goal, GOALS_DEFAULT_MESSAGES));
  return {
    type: Actions.GET_GOALS,
    payload: goals,
  };
}

export function selectRegister(register) {
  return {
    type: Actions.REGISTER_SELECTED_GOAL,
    payload: register,
  };
}

export function addGoal(event) {
  let goal = {};
  goal = convertMessages(event.data, GOALS_DEFAULT_MESSAGES);
  return {
    type: Actions.ADD_REGISTER_GOAL,
    payload: goal,
  };
}

export function addRegister(name) {
  return {
    type: Actions.ADD_REQUEST,
    payload: socket.sendMessage(Actions.ADD_GOAL, { name }),
  };
}

export function editGoal(event) {
  let goalEdit = {};
  goalEdit = convertMessages(event.data, GOALS_DEFAULT_MESSAGES);
  return {
    type: Actions.EDIT_REGISTER_GOAL,
    payload: goalEdit,
  };
}

export function editRegister(id, name) {
  return {
    type: Actions.UPDATE_REQUEST,
    payload: socket.sendMessage(Actions.UPDATE_GOAL, { id, name }),
  };
}

export function deleteGoal(event) {
  let goalDelete = {};
  goalDelete = event.data;
  return {
    type: Actions.DELETE_REGISTER_GOAL,
    payload: goalDelete,
  };
}

export function deleteRegister(id, name) {
  return {
    type: Actions.DELETE_REQUEST,
    payload: socket.sendMessage(Actions.DELETE_GOAL, { id, name }),
  };
}

export function convertMessages(data, constMessage = GOALS_DEFAULT_MESSAGES) {
  // TODO: Pode ser que dê bosta por conta da remoção da / na expressão regular
  const nameGoal = data.name;
  const nameGoalDot = nameGoal.replace(/\s|-/g, '.');
  const nameGoalNoSpace = nameGoal.replace(/\s|-/g, '');
  data.idMessageName = `app.goal.${nameGoalDot}`;
  data.defaultMessageName = constMessage[nameGoalNoSpace] || data.name;
  return data;
}
