import React from 'react';

const IconFormulas = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width={props.width}
    height={props.height}
  >
    <circle cx="256" cy="256" r="256" fill="#A0EBFF" />
    <path fill="#71C6DE" d="M136.463 122.849h239.068v286.976H136.463z" />
    <path fill="#FFF" d="M309.494 395.346H150.989V122.849H362.45v216.515" />
    <path fill="#B6F0FF" d="M309.489 342.38h52.961l-52.961 52.966" />
    <path fill="#48AFCC" d="M347.653 380.549l14.797-38.169-52.961 52.966" />
    <path
      fill="#FC611F"
      d="M294.461 108.14c0-20.844-16.896-37.74-37.74-37.74s-37.745 16.896-37.745 37.74h-27.013v44.242H320.03V108.14h-25.569zm-82.897 29.209c-3.968 0-7.188-3.22-7.188-7.188s3.22-7.188 7.188-7.188c3.968 0 7.188 3.22 7.188 7.188s-3.215 7.188-7.188 7.188zm29.045-29.209c0-8.883 7.224-16.108 16.108-16.108s16.108 7.224 16.108 16.108h-32.216zm60.375 29.209c-3.968 0-7.188-3.22-7.188-7.188s3.22-7.188 7.188-7.188c3.968 0 7.188 3.22 7.188 7.188s-3.215 7.188-7.188 7.188z"
    />
    <path
      fill="#B6F0FF"
      d="M174.438 178.944v6.748h163.123v-6.748H174.438zm0 25.743h163.123v-6.748H174.438v6.748zm0 18.996h163.123v-6.748H174.438v6.748zm0 18.995h163.123v-6.748H174.438v6.748zm0 18.995h163.123v-6.748H174.438v6.748zm0 18.995h163.123v-6.748H174.438v6.748zm0 18.995h163.123v-6.748H174.438v6.748zm0 18.996h163.123v-6.748H174.438v6.748z"
    />
    <path
      fill="#E34E10"
      d="M294.461 108.14c0-20.844-16.896-37.74-37.74-37.74l-.721.072v21.632l.722-.072c8.883 0 16.108 7.224 16.108 16.108H256v44.242h64.036V108.14h-25.575zm6.523 29.209c-3.968 0-7.188-3.22-7.188-7.188s3.22-7.188 7.188-7.188c3.968 0 7.188 3.22 7.188 7.188s-3.215 7.188-7.188 7.188z"
    />
  </svg>
);

IconFormulas.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconFormulas;
