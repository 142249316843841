import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';

const initialState = Immutable.from({
  types: [],
  measures: [],
  measureSelected: {},
  measuresBodyMap: [],
  loading: false,
  shouldGetMeasures: true,
  shouldGetTypes: true,
});

export default function measures(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_MEASURES_PENDING:
      const loading = state.updateIn(['loading'], () => true);
      return loading;
    case Actions.GET_MEASURES_REJECTED:
      const rej = state.updateIn(['loading'], () => false);
      return rej;
    case Actions.GET_MEASURES:
      const measures = state
        .updateIn(['measures'], () => action.payload)
        .updateIn(['loading'], () => false)
        .updateIn(['shouldGetMeasures'], () => false);
      return measures;
    case Actions.GET_MEASURE_TYPES:
      const typesMeasure = state
        .updateIn(['types'], () => action.payload)
        .updateIn(['shouldGetTypes'], () => false);
      return typesMeasure;
    case Actions.ADD_REGISTER_MEASURE:
      const newState = Immutable.merge({
        ...state,
        measures: [...state.measures, action.payload],
      });
      return newState;
    case Actions.REGISTER_SELECTED_MEASURE:
      const selectedMeasure = state.set('measureSelected', action.payload);
      return selectedMeasure;
    case Actions.EDIT_REGISTER_MEASURE:
      const measureIndex = state.measures.findIndex(
        measure => measure.id === action.payload.id,
      );
      const newStateMeasure = state.updateIn(
        ['measures', measureIndex],
        () => action.payload,
      );
      return newStateMeasure;
    case Actions.DELETE_REGISTER_MEASURE:
      const index = state.measures.findIndex(
        measure => measure.id === action.payload.id,
      );
      const deleteMeasure = state.update('measures', aus => aus.slice(0, index).concat(aus.slice(index + 1)));
      return deleteMeasure;
    default:
      return state;
  }
}
