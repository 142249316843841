import React from 'react';
import BoxRoute from '../Cards/BoxRoute';
import HeaderDialog from '../Headers/HeaderDialog';
import { Row, Col } from 'reactstrap';
import {
  IconClients,
  IconAddPerson,
  IconRegistrations,
  IconAvatarRed,
  IconModelPlans,
  IconFinancial,
  IconLink,
} from '../Icons/Icons';

const NotClientRender = (props) => {
  let clientsPermission = props.permission.user;
  let financialPermission = props.permission.financial;
  let plansPermission = props.permission.plan;
  let registrationPermission = props.permission.registration;

  return (
    <div className="container">
      <HeaderDialog
        name={props.name}
        idTitleMain="app.home.greeting"
        defaultMessageTitleMain="Olá"
        idSubtitle="app.home.phrase.action"
        defaultMessageSubtitle="O que você gostaria de fazer?"
        color="danger"
      >
        {' '}
        <IconAvatarRed />{' '}
      </HeaderDialog>
      <Row>
        {/*CLIENTS*/}
        {clientsPermission ? (
          <Col className="col-lg" md={6}>
            <BoxRoute
              defaultMessage="Clientes"
              idMessage="app.global.clients"
              link="/Clients"
            >
              <IconClients />
            </BoxRoute>
          </Col>
        ) : (
          ''
        )}
        {/*CLIENTS*/}

        {/*NEW CLIENT*/}
        {clientsPermission ? (
          <Col className="col-lg" md={6}>
            <BoxRoute
              defaultMessage="Novo Cliente"
              idMessage="app.global.client.new"
              link="/NewClient"
            >
              <IconAddPerson />
            </BoxRoute>
          </Col>
        ) : (
          ''
        )}
        {/*FINISH NEW CLIENT*/}

        {/*PLANS*/}
        {plansPermission ? (
          <Col className="col-lg" md={6}>
            <BoxRoute
              defaultMessage="Links"
              idMessage="app.global.model.plans"
              link="/Plans"
            >
              <IconModelPlans />
            </BoxRoute>
          </Col>
        ) : (
          ''
        )}
        {/*FINISH PLANS*/}

        {/*PLANS*/}
        {plansPermission && (
          <Col className="col-lg" md={6}>
            <BoxRoute
              defaultMessage="Links"
              idMessage="app.global.promotional.links"
              link="/PromotionalLinks"
            >
              <IconLink />
            </BoxRoute>
          </Col>
        )}
        {/*FINISH PLANS*/}

        {/*REGISTRATION*/}
        {registrationPermission ? (
          <Col className="col-lg" md={6}>
            <BoxRoute
              defaultMessage="Cadastros"
              idMessage="app.global.registrations"
              link="/Registration"
            >
              <IconRegistrations />
            </BoxRoute>
          </Col>
        ) : (
          ''
        )}
        {/*FINISH REGISTRATION*/}

        {/*FINANCIAL*/}
        {financialPermission ? (
          <Col className="col-lg" md={6}>
            <BoxRoute
              defaultMessage="Financeiro"
              idMessage="app.global.financial"
              link="/DashBoardClients"
            >
              <IconFinancial />
            </BoxRoute>
          </Col>
        ) : (
          ''
        )}
        {/*FINISH FINANCIAL*/}
      </Row>
    </div>
  );
};

export default NotClientRender;
