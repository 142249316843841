import React from 'react';

const IconMeasures = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon-measures"
    viewBox="0 0 512 512"
    width={props.width}
    height={props.height}
  >
    <circle cx="256" cy="256" r="256" fill="#5A7EBF" />
    <path
      fill="#2E50A2"
      d="M500.299 179.371L370.502 49.574 49.574 370.502l129.797 129.797c88.004 27.522 187.929 6.44 257.648-63.279 69.72-69.72 90.802-169.645 63.28-257.649z"
    />
    <path
      fill="#F7DC4D"
      d="M370.502 49.574L49.574 370.502l91.924 91.924 320.928-320.928-91.924-91.924zm7.992 83.932c-6.157-6.157-6.157-16.14 0-22.298 6.158-6.157 16.141-6.157 22.298 0s6.157 16.14 0 22.298c-6.157 6.157-16.14 6.157-22.298 0z"
    />
    <path
      fill="#EA971E"
      d="M416.464 95.536l-15.672 15.672c6.157 6.157 6.157 16.14 0 22.298-6.157 6.157-16.141 6.157-22.298 0L95.536 416.464l45.962 45.962 320.928-320.928-45.962-45.962z"
    />
    <path
      fill="#57565C"
      d="M100.435217 361.354322l-20.85945-20.85945 7.071-7.071 20.85945 20.85945zm26.43796-26.427764l-20.85945-20.85945 7.071-7.071 20.85945 20.85945zm26.420628-26.420793l-20.85945-20.85945 7.071-7.071 20.85945 20.85945zm26.427222-26.4285l-20.85945-20.85945 7.071-7.071 20.85945 20.85945zm26.420808-26.437872l-20.85945-20.85945 7.071-7.071 20.85945 20.85945zm26.437845-26.42985l-20.85945-20.85945 7.071-7.071 20.85945 20.85945zm26.42718-26.420457l-20.85945-20.85945 7.071-7.071 20.85945 20.85945zm26.420714-26.422778l-20.85945-20.85945 7.071-7.071 20.85945 20.85945zm26.437844-26.42975l-20.85945-20.85945 7.071-7.071 20.85945 20.85945z"
    />
  </svg>
);

IconMeasures.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconMeasures;
