// import React, {Component} from 'react';
// import Loading from '../components/Loading';
import { asyncComponent } from 'react-async-component';

export const AddMeasure = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/AddMeasure').default);
  })),
});
export const Authors = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/Authors').default);
  })),
});
export const BodyMeasures = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/BodyMeasures').default);
  })),
});
export const PaymentHistory = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/PaymentHistory').default);
  })),
});
export const ChangePassword = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/ChangePassword').default);
  })),
});
export const Classification = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/Classification').default);
  })),
});
export const Clients = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/Clients').default);
  })),
});

export const ClientsApproval = asyncComponent({
  resolve: () =>
    new Promise((resolve) =>
      require([], (require) => {
        resolve(require('../containers/ClientsApproval').default);
      }),
    ),
});
export const Comparisons = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/Comparisons').default);
  })),
});
export const DashboardFinancial = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/DashboardFinancial').default);
  })),
});
export const DashboardClients = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/DashboardClients').default);
  })),
});
export const Plans = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/Plans').default);
  })),
});
export const DashboardUsers = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/DashboardUsers').default);
  })),
});
export const DataEvaluated = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/DataEvaluated').default);
  })),
});
export const EditClient = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/EditClient').default);
  })),
});
export const EditDataProfile = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/EditDataProfile').default);
  })),
});
export const EditMeasure = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/EditMeasure').default);
  })),
});
export const Ethnicity = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/Ethnicity').default);
  })),
});
export const Evaluated = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/Evaluated').default);
  })),
});
export const EvaluatedImc = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/EvaluatedImc').default);
  })),
});
export const Evaluators = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/Evaluators').default);
  })),
});
export const ExternalRegister = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/ExternalRegister').default);
  })),
});
export const ForgotPassword = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/ForgotPassword').default);
  })),
});
export const FormulaEditor = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/FormulaEditor').default);
  })),
});
export const Formulas = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/Formulas').default);
  })),
});
export const Goals = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/Goals').default);
  })),
});
export const Measures = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/Measures').default);
  })),
});
export const MyAccount = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/MyAccount').default);
  })),
});
export const NewClient = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/NewClient').default);
  })),
});
export const NewEvaluated = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/NewEvaluated').default);
  })),
});
export const NewEvaluator = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/NewEvaluator').default);
  })),
});
export const NewExternalEvaluator = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/NewExternalEvaluator').default);
  })),
});
export const NewPlan = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/NewPlan').default);
  })),
});
export const NewTableFatness = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/NewTableFatness').default);
  })),
});
export const NewUser = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/NewUser').default);
  })),
});
export const Notifications = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/Notifications').default);
  })),
});
export const PhysicalAssessmentMeasures = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/PhysicalAssessmentMeasures').default);
  })),
});
export const PhysicalAssessmentComposition = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/PhysicalAssessmentComposition').default);
  })),
});
export const PhysicalAssessmentAnamnesis = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/PhysicalAssessmentAnamnesis').default);
  })),
});
export const PhysicalAssessmentParQ = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/PhysicalAssessmentParQ').default);
  })),
});
export const PhysicalAssessmentCardiorespiratory = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/PhysicalAssessmentCardiorespiratory').default);
  })),
});
export const PhysicalAssessmentNeuromotors = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/PhysicalAssessmentNeuromotors').default);
  })),
});
export const PhysicalAssessmentNew = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/PhysicalAssessmentNew').default);
  })),
});
export const ProfileClient = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/ProfileClient').default);
  })),
});
export const ProfileEvaluated = asyncComponent({
  // resolve: () => import('../containers/ProfileEvaluated').then(module => module.default),
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/ProfileEvaluated').default);
  })) /* ,
    LoadingComponent: () => <Loading /> */,
});
export const ProfilePlan = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/ProfilePlan').default);
  })),
});
export const Registration = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/Registration').default);
  })),
});
export const ReportEvaluation = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/ReportEvaluation').default);
  })),
});

export const PhysicalAssessmentReport = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/PhysicalAssessmentReport').default);
  })),
});

export const SessionControl = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/SessionControl').default);
  })),
});
export const TablesFatnessIdeal = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/TablesFatnessIdeal').default);
  })),
});
export const UserPermissions = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/UserPermissions').default);
  })),
});
export const UsersManagement = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/UsersManagement').default);
  })),
});
export const ManageExternalEvaluators = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/ManageExternalEvaluators').default);
  })),
});
export const Anamnesis = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/Anamnesis').default);
  })),
});
export const MeasuresReport = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/MeasuresReport').default);
  })),
});
export const Subscription = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/SubscriptionForm').default);
  })),
});
export const PromotionalLinks = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/PromotionalLinks').default);
  })),
});
export const NewPromotionalLink = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/NewPromotionalLink').default);
  })),
});
export const EditExercise = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/EditExercise').default);
  })),
});
export const Exercises = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/Exercises').default);
  })),
});
export const EditPresetWorkout = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/EditPresetWorkout').default);
  })),
});
export const EditPresetWorkoutEvaluated = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/EditPresetWorkoutEvaluated').default);
  })),
});
export const PresetWorkoutsEvaluated = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/PresetWorkoutsEvaluated').default);
  })),
});
export const PresetWorkouts = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/PresetWorkouts').default);
  })),
});
export const WorkoutEvaluated = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/WorkoutEvaluated').default);
  })),
});
export const SetEvaluatedPassword = asyncComponent({
  resolve: () => new Promise(resolve => require([], (require) => {
    resolve(require('../containers/SetEvaluatedPassword').default);
  })),
});