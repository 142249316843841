import React from 'react';

const IconPlans = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 505 505"
    width={props.width}
    height={props.height}
  >
    <circle cx="252.5" cy="252.5" r="252.5" fill="#54C0EB" />
    <path fill="#FFF" d="M179.4 418.9v-53.6h-53.5V86.1h253.2v332.8" />
    <path
      d="M179.4 418.9l-53.5-53.6h53.5M150 217.4h204.9v12.5H150zm82.4-32.4h122.5v12.5H232.4zm0-32.4h122.5v12.5H232.4zm0-32.3h122.5v12.5H232.4zM150 249.7h204.9v12.5H150zm0 32.4h204.9v12.5H150zm0 32.3h96.6v12.5H150z"
      fill="#E6E9EE"
    />
    <path
      d="M186.7 159c4.7-2.3 8.5-8.1 10.1-14.7 1.3-.3 2.3-1.7 2.3-3.4 0-1.3-.6-2.5-1.5-3.1v-.2c0-11-6.9-17.3-15.3-17.3-8.5 0-15.3 6.3-15.3 17.3v.2c-.9.6-1.5 1.7-1.5 3.1 0 1.7 1 3.2 2.3 3.4 1.5 6.4 5.1 12 9.6 14.5-15.4 2-27.3 15.1-27.3 31v.4c8.5 4.6 19.4 7.3 31.3 7.3 11.9 0 22.8-2.7 31.3-7.3v-.4c-.1-15.5-11.3-28.3-26-30.8z"
      fill="#4CDBC4"
    />
  </svg>
);

IconPlans.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconPlans;
