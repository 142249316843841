import React from 'react';

const IconClipboard = (props) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 120 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_10_19" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.7361 20C76.9095 19.0262 77 18.0237 77 17C77 7.61116 69.3888 0 60 0C50.6112 0 43 7.61116 43 17C43 18.0237 43.0905 19.0262 43.2639 20H14C6.26801 20 0 26.268 0 34V146C0 153.732 6.26801 160 14 160H106C113.732 160 120 153.732 120 146V34C120 26.268 113.732 20 106 20H76.7361ZM68 17C68 21.4183 64.4183 25 60 25C55.5817 25 52 21.4183 52 17C52 12.5817 55.5817 9 60 9C64.4183 9 68 12.5817 68 17ZM16 30C12.6863 30 10 32.6863 10 36V144C10 147.314 12.6863 150 16 150H104C107.314 150 110 147.314 110 144V36C110 32.6863 107.314 30 104 30H90V36C90 38.2091 88.2091 40 86 40H34C31.7909 40 30 38.2091 30 36V30H16Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M76.7361 20C76.9095 19.0262 77 18.0237 77 17C77 7.61116 69.3888 0 60 0C50.6112 0 43 7.61116 43 17C43 18.0237 43.0905 19.0262 43.2639 20H14C6.26801 20 0 26.268 0 34V146C0 153.732 6.26801 160 14 160H106C113.732 160 120 153.732 120 146V34C120 26.268 113.732 20 106 20H76.7361ZM68 17C68 21.4183 64.4183 25 60 25C55.5817 25 52 21.4183 52 17C52 12.5817 55.5817 9 60 9C64.4183 9 68 12.5817 68 17ZM16 30C12.6863 30 10 32.6863 10 36V144C10 147.314 12.6863 150 16 150H104C107.314 150 110 147.314 110 144V36C110 32.6863 107.314 30 104 30H90V36C90 38.2091 88.2091 40 86 40H34C31.7909 40 30 38.2091 30 36V30H16Z"
      fill="black"
    />
    <path
      d="M76.7361 20L75.7516 19.8247L75.5424 21H76.7361V20ZM43.2639 20V21H44.4576L44.2484 19.8247L43.2639 20ZM90 30V29H89V30H90ZM30 30H31V29H30V30ZM77.7207 20.1753C77.9043 19.1439 78 18.0827 78 17H76C76 17.9646 75.9148 18.9085 75.7516 19.8247L77.7207 20.1753ZM78 17C78 7.05887 69.9411 -1 60 -1V1C68.8366 1 76 8.16344 76 17H78ZM60 -1C50.0589 -1 42 7.05887 42 17H44C44 8.16344 51.1634 1 60 1V-1ZM42 17C42 18.0827 42.0957 19.1439 42.2794 20.1753L44.2484 19.8247C44.0852 18.9085 44 17.9646 44 17H42ZM14 21H43.2639V19H14V21ZM1 34C1 26.8203 6.8203 21 14 21V19C5.71573 19 -1 25.7157 -1 34H1ZM1 146V34H-1V146H1ZM14 159C6.8203 159 1 153.18 1 146H-1C-1 154.284 5.71573 161 14 161V159ZM106 159H14V161H106V159ZM119 146C119 153.18 113.18 159 106 159V161C114.284 161 121 154.284 121 146H119ZM119 34V146H121V34H119ZM106 21C113.18 21 119 26.8203 119 34H121C121 25.7157 114.284 19 106 19V21ZM76.7361 21H106V19H76.7361V21ZM60 26C64.9706 26 69 21.9706 69 17H67C67 20.866 63.866 24 60 24V26ZM51 17C51 21.9706 55.0294 26 60 26V24C56.134 24 53 20.866 53 17H51ZM60 8C55.0294 8 51 12.0294 51 17H53C53 13.134 56.134 10 60 10V8ZM69 17C69 12.0294 64.9706 8 60 8V10C63.866 10 67 13.134 67 17H69ZM11 36C11 33.2386 13.2386 31 16 31V29C12.134 29 9 32.134 9 36H11ZM11 144V36H9V144H11ZM16 149C13.2386 149 11 146.761 11 144H9C9 147.866 12.134 151 16 151V149ZM104 149H16V151H104V149ZM109 144C109 146.761 106.761 149 104 149V151C107.866 151 111 147.866 111 144H109ZM109 36V144H111V36H109ZM104 31C106.761 31 109 33.2386 109 36H111C111 32.134 107.866 29 104 29V31ZM90 31H104V29H90V31ZM91 36V30H89V36H91ZM86 41C88.7614 41 91 38.7614 91 36H89C89 37.6569 87.6569 39 86 39V41ZM34 41H86V39H34V41ZM29 36C29 38.7614 31.2386 41 34 41V39C32.3431 39 31 37.6569 31 36H29ZM29 30V36H31V30H29ZM16 31H30V29H16V31Z"
      fill="black"
      mask="url(#path-1-inside-1_10_19)"
    />
  </svg>
);

IconClipboard.defaultProps = {
  width: 30,
  height: 40,
  color: 'default',
};

export default IconClipboard;
