import React from 'react';

const IconNewClient = (props) => (
  <svg
    className="icon-new-client"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-223 25 512 512"
    width={props.width}
    height={props.height}
  >
    <circle className="st0" cx="140.4" cy="157.1" r="132.1" />
    <path
      className="st1"
      d="M169.3 260.4c-73 0-132.1-59.2-132.1-132.1 0-29.1 9.5-55.9 25.5-77.8-32.9 24-54.4 62.8-54.4 106.7 0 73 59.2 132.1 132.1 132.1 43.9 0 82.6-21.5 106.7-54.4-21.9 15.9-48.7 25.5-77.8 25.5z"
    />
    <path
      className="st2"
      d="M207.2 144.7h-55.4V90.2c0-4.1-3.7-7.4-8.3-7.4h-8.3c-4.6 0-8.3 3.3-8.3 7.4v54.5H72.6c-4.2 0-7.5 3.7-7.5 8.3v8.3c0 4.6 3.4 8.3 7.5 8.3H127V224c0 4.1 3.7 7.4 8.3 7.4h8.3c4.6 0 8.3-3.3 8.3-7.4v-54.5h55.4c4.2 0 7.5-3.7 7.5-8.3V153c-.1-4.6-3.4-8.3-7.6-8.3z"
    />
    <path
      className="st1"
      d="M83.5 335.6l-47.1-18.5h-167l-47.1 18.5c-18 7.1-29.6 24.8-28.8 44.2l5.7 142.6c.3 8.1 7 14.6 15.2 14.6h277c8.1 0 14.8-6.4 15.2-14.6l5.7-142.6c.8-19.4-10.8-37.1-28.8-44.2z"
    />
    <path className="st3" d="M-58.2 370l-9.7 167h41.6L-36 370z" />
    <path
      className="st2"
      d="M-40.2 377.6H-54c-4.6 0-8.3-3.7-8.3-8.3v-22.1h30.4v22.1c0 4.6-3.7 8.3-8.3 8.3z"
    />
    <path className="st4" d="M-100.3 256.1H6v91.1h-106.3z" />
    <path
      className="st5"
      d="M-100.3 284.4c15.1 10.8 33.4 17.2 53.1 17.2S-9 295.3 6 284.4V256h-106.3v28.4z"
    />
    <path
      className="st6"
      d="M-47.1 66.5c-37.9 2-59.5 22.8-59.5 22.8s-31.6 0-31.6 38c0 30.4 7.6 60.5 7.6 60.5H44C59.2 104.3 23.1 62.7-47.1 66.5z"
    />
    <path
      className="st7"
      d="M-92.7 156.4c-27.9-34.9-13.9-67.1-13.9-67.1s-31.6 0-31.6 38c0 30.4 7.6 60.5 7.6 60.5h38l-.1-31.4z"
    />
    <path
      className="st8"
      d="M36.4 180.2h-7.6l-10-18.3c-1.6-2.9-4.9-4.4-8.1-3.8-50 9.1-82.9-2.5-95.3-8.3-3.3-1.5-7.1-.5-9.2 2.4-7.7 10.7-16.2 19.2-21.2 23.9-2.8 2.6-6.5 4-10.3 4h-5.5c-.8 0-1.7.1-2.5.3-7.4 1.4-12.4 8.5-12.4 16v5.5c0 7.3 4.7 14.1 11.7 15.8 1.6.4 3.1.5 4.6.4 3.8-.3 7.1 2.4 7.7 6.2 6.5 35.4 37.4 62.2 74.6 62.2s68.1-26.8 74.6-62.2c.7-3.6 3.8-6.2 7.4-6.1h1.6c8.4 0 15.2-6.8 15.2-15.2v-7.6c-.1-8.4-6.9-15.2-15.3-15.2z"
    />
    <path
      className="st4"
      d="M-92.7 210.5v-59.4c-.4.4-.9.7-1.2 1.2-7.7 10.7-16.2 19.2-21.2 23.9-2.8 2.6-6.5 4-10.3 4h-5.5c-.8 0-1.7.1-2.5.3-7.4 1.4-12.4 8.5-12.4 16v5.5c0 7.3 4.6 14.1 11.7 15.8 1.6.4 3.1.5 4.6.4 3.8-.3 7.1 2.4 7.8 6.2 6.5 35.4 37.4 62.2 74.6 62.2 5.2 0 10.2-.6 15.1-1.5-34.6-7.3-60.7-37.9-60.7-74.6z"
    />
    <path
      className="st0"
      d="M-47.1 347.4l-55.4-48.5c-3-2.6-7.5-2.5-10.4.3l-17.8 17.8 35.3 52.9c5.3 8 16.6 9.1 23.4 2.3l24.9-24.8zm0 0L8.3 299c3-2.6 7.5-2.5 10.4.3l17.8 17.8L1.1 370c-5.3 8-16.6 9.1-23.4 2.3l-24.8-24.9zm119.6 39.8l33-33c4.6 7.5 7.1 16.3 6.7 25.6l-5.7 142.6c-.3 8.1-7 14.6-15.2 14.6H59.2V419.4c0-12 4.8-23.6 13.3-32.2zm-239.2 0l-33-33c-4.6 7.5-7.1 16.3-6.7 25.6l5.7 142.6c.3 8.1 7 14.6 15.2 14.6h32.2V419.4c-.1-12-4.9-23.6-13.4-32.2zm44.3 51.1h36.6c4.6 0 8.3-3.7 8.3-8.3v-6.3c0-4.6-3.7-8.3-8.3-8.3h-36.6c-4.6 0-8.3 3.7-8.3 8.3v6.3c.1 4.6 3.8 8.3 8.3 8.3z"
    />
  </svg>
);

IconNewClient.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconNewClient;
