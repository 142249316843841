import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';

const initialState = Immutable.from({
  users: [],
  userSelected: {},
  sessions: [],
  sessionSelected: {},
  shouldGetUsers: true,
  shouldGetSessions: true,
  loading: false,
  loadingSessions: false,
});

export default function usersManagement(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_USERS_PENDING:
      const loading = state.updateIn(['loading'], () => true);
      return loading;
    case Actions.GET_USERS_REJECTED:
      const loadingRej = state.updateIn(['loading'], () => false);
      return loadingRej;
    case Actions.GET_USERS:
      const users = state
        .updateIn(['users'], () => action.payload)
        .updateIn(['loading'], () => false)
        .updateIn(['shouldGetUsers'], () => false);
      return users;
    case Actions.ADD_USER:
      const newState = Immutable.merge({
        ...state,
        users: [...state.users, action.payload.data],
      });
      return newState;
    case Actions.SET_USER_PERSON:
      const userSet = Immutable.merge({
        ...state,
        users: [...state.users, action.payload],
      });
      return userSet;
    case Actions.REGISTER_SELECTED_USER:
      const selectedUser = state.set('userSelected', action.payload);
      return selectedUser;
    case Actions.UPDATE_PERMISSION:
      const indexUserPermission = state.users.findIndex(
        (user) => user.id === action.payload.id,
      );
      const updatePermission = state.updateIn(
        ['users', indexUserPermission],
        () => action.payload,
      );
      return updatePermission;
    case Actions.DELETE_USER:
      const indexUserDelete = state.users.findIndex(
        user => user.id === action.payload.idpermission,
      );
      const deleteUser = state.update('users', user => user.slice(0, indexUserDelete).concat(user.slice(indexUserDelete + 1)));
      return deleteUser;
    case Actions.GET_SESSIONS_PENDING:
      const loadingSessions = state.set('loadingSessions', true);
      return loadingSessions;
    case Actions.GET_SESSIONS:
      const sessionsLoad = state
        .updateIn(['sessions'], () => action.payload)
        .set('loadingSessions', false)
        .set('shouldGetSessions', true);
      return sessionsLoad;
    case Actions.REGISTER_SELECTED_SESSION:
      const selectedSession = state.set('sessionSelected', action.payload);
      return selectedSession;
    case Actions.DISCONNECT_SESSION:
      return state;
    default:
      return state;
  }
}
