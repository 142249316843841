import {
  NAVIGATION_CLOSE,
  NAVIGATION_OPEN,
  NAVIGATION_CLEAR_HISTORY,
  NAVIGATION_ADD_HISTORY,
  NAVIGATION_REMOVE_HISTORY,
  NAVIGATION_RESTORE_HISTORY,
} from '../../constant/constants';

const initialState = {
  isVisible: true,
  patternUrl: false,
  btnBackIsActive: true,
  navigationHistory: [],
};

export default function Navigation(state = initialState, action = {}) {
  switch (action.type) {
    case NAVIGATION_CLOSE:
      const close = {
        ...state,
        isVisible: false,
      };
      return close;
    case NAVIGATION_OPEN:
      const open = {
        ...state,
        isVisible: true,
        btnBackIsActive: action.opc,
      };
      return open;
    case NAVIGATION_CLEAR_HISTORY:
      const historyClear = {
        ...state,
        navigationHistory: [],
      };
      return historyClear;
    case NAVIGATION_ADD_HISTORY:
      const history = {
        ...state,
        navigationHistory: action.params,
      };
      return history;
    case NAVIGATION_REMOVE_HISTORY:
      const historyRemove = {
        ...state,
        navigationHistory: action.params,
      };
      return historyRemove;
    case NAVIGATION_RESTORE_HISTORY:
      const historyRestore = {
        ...state,
        navigationHistory: action.payload,
      };
      return historyRestore;
    default:
      return state;
  }
}
