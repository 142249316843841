/* eslint-disable no-case-declarations */
import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';

const initialState = Immutable.from({
  evaluators: [],
  evaluatorSelected: {},
  loading: true,
  shouldGetEvaluators: true,
});

export default function evaluators(state = initialState, action = {}) {
  switch (action.type) {
    case Actions.GET_EVALUATORS_PENDING:
      const loading = state.updateIn(['loading'], () => true);
      return loading;
    case Actions.GET_EVALUATORS_REJECTED:
      const rej = state.updateIn(['loading'], () => false);
      return rej;
    case Actions.GET_EVALUATORS:
      const evaluators = state
        .updateIn(['evaluators'], () => action.payload)
        .updateIn(['loading'], () => false)
        .updateIn(['shouldGetEvaluators'], () => false);
      return evaluators;
    case Actions.ADD_REGISTER_EVALUATOR:
      const newState = Immutable.merge({
        ...state,
        evaluators: [...state.evaluators, action.payload],
      });
      return newState;
    case Actions.REGISTER_SELECTED_EVALUATOR:
      const evaluatorSelected = state.set('evaluatorSelected', action.payload);
      return evaluatorSelected;
    case Actions.ADDED_HTTP_EVALUATOR:
      const newStateAfterHttpInsert = Immutable.merge({
        ...state,
        evaluators: [...state.evaluators, action.payload],
      });
      return newStateAfterHttpInsert;
    case Actions.EDIT_REGISTER_EVALUATOR:
      const evaluatorIndex = state.evaluators.findIndex(
        (evaluator) => evaluator.id === action.payload.id,
      );
      const newStateFormula = state.updateIn(
        ['evaluators', evaluatorIndex],
        () => action.payload,
      );
      return newStateFormula;
    case Actions.DELETE_REGISTER_EVALUATOR:
      const index = state.evaluators.findIndex(
        (evaluator) => evaluator.id === action.payload.id,
      );
      const deleteFormula = state.update('evaluators', (aus) =>
        aus.slice(0, index).concat(aus.slice(index + 1)),
      );
      return deleteFormula;
    case Actions.SET_EVALUATOR_PERSON_REQUEST_PENDING:
      const loadingSet = state.updateIn(['loading'], () => true);
      return loadingSet;
    case Actions.SET_EVALUATOR_PERSON_REQUEST_FULFILLED:
      const loadingSetFul = state.updateIn(['loading'], () => false);
      return loadingSetFul;
    default:
      return state;
  }
}
