import React from 'react';

const IconQuestion = (props) => (
  <img
    src={require('../../images/perguntass.png')}
    alt="Perguntas"
    width={props.width}
    height={props.height}
  />
);

IconQuestion.defaultProps = {
  width: 120,
  height: 120,
  color: 'default',
};

export default IconQuestion;
