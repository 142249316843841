import * as Actions from '../../constant/constants';
import socket from '../../socket';

export function getPlans() {
  return {
    type: Actions.GET_PLANS,
    payload: socket.sendMessage(Actions.GET_PLANS, []),
  };
}

export function setPlans(event) {
  let plans = [];
  if (typeof event === 'undefined') {
    socket.sendMessage(Actions.GET_PLANS, []);
  } else {
    plans = event.data;
  }
  return {
    type: Actions.GET_PLANS,
    payload: plans,
  };
}

export function deletePlan(e) {

  return {
    type: Actions.DELETE_REGISTER_PLAN,
    payload: e,
  };
}

export function addPlan(e) {
  return {
    type: Actions.ADD_REGISTER_PLAN,
    payload: e,
  };
}

export function deleteRegister(planId) {
  return {
    type: Actions.DELETE_REQUEST,
    payload: socket.sendMessage(Actions.UPDATE_PLAN_STATUS, { planId, status: false }),
  };
}

export function addRegister(data) {
  return {
    type: Actions.ADD_REQUEST,
    payload: socket.sendMessage(Actions.CREATE_PLAN, data),
  };
}

export function editPlan(e) {

  return {
    type: Actions.UPDATE_REGISTER_PLAN,
    payload: e,
  };
}

export function editRegister(payload) {
  return {
    type: Actions.UPDATE_REQUEST,
    payload: socket.sendMessage(Actions.UPDATE_PLAN, payload),
  };
}

export function selectRegister(register) {
  return {
    type: Actions.REGISTER_SELECTED_PLAN,
    payload: register,
  };
}

export function cancelRegister(idcliente) {
  return {
    type: Actions.CANCEL_CLIENT_PLAN,
    payload: socket.sendMessage(Actions.CANCEL_CLIENT_PLAN, { idcliente }),
  };
}
