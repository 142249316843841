import React from 'react';
// import PanelHome from './PanelHome';
import BoxRoute from '../Cards/BoxRoute';
import HeaderDialog from '../Headers/HeaderDialog';
import DashboardEvaluated from './DashboardEvaluated';
import { Row, Col } from 'reactstrap';
import {
  IconEvaluation,
  IconNewEvaluated,
  IconEvaluated,
  IconClients,
  IconAddPerson,
  IconRegistrations,
  IconAvatarRed,
} from '../Icons/Icons';
import { getProfilePermissionLevel } from '../../utils/functions';

const ClientPermissionRender = (props) => {
  //O título é renderizado caso possua uma permissão, se não é usuário e aparece o dashboard TODO: Nunca usado
  // let oneOrMorePermissions = props.permission.evaluation || props.permission.evaluator || props.permission.evaluatorperm || props.permission.financial || props.permission.registration || props.permission.user;

  //Como a prioridade de atalhos é fazer avaliações, caso a pessoa possua essa permissão, as outras não aparecem, se não as outras aparecerão conforme verdadeiro

  // let makeEvaluationsPermission = props.permission.evaluation && props.permission.evaluator;
  // let manageEvaluators = props.permission.evaluatorperm && !props.permission.evaluation && !props.permission.evaluator;
  // let registrationPermission = props.permission.registration && !props.permission.evaluation && !props.permission.evaluator;
  // let managerUsers = props.permission.user && !props.permission.evaluation && !props.permission.evaluator;
  // let justEvaluated = !props.permission.evaluation && !props.permission.evaluator && !props.permission.evaluatorperm && !props.permission.financial && !props.permission.plan && !props.permission.registration && !props.permission.user;
  const {
    permissions: {
      makeEvaluationsPermission,
      manageEvaluators,
      registrationPermission,
      managerUsers,
      justEvaluated,
    },
  } = getProfilePermissionLevel(props.permission, props.person);
  return (
    <div>
      {!justEvaluated ? (
        <div className="container">
          <HeaderDialog
            client={props.permission.client}
            person={props.person}
            accountType={props.accountType}
            idTitleMain="app.home.greeting"
            defaultMessageTitleMain="Olá"
            idSubtitle="app.home.phrase.action"
            defaultMessageSubtitle="O que você gostaria de fazer?"
            color="danger"
            firstAccess={props.firstAccess}
          >
            {' '}
            <IconAvatarRed />{' '}
          </HeaderDialog>
          <Row className="justify-content-center">
            {/*PHYSICAL ASSESSMENT*/}
            {makeEvaluationsPermission ? (
              <Col xl={3} className="col-lg" md={6} xs={6}>
                <BoxRoute
                  defaultMessage="Avaliação Física"
                  idMessage="app.global.physical.assessment"
                  link="/Evaluated"
                >
                  <IconEvaluation />
                </BoxRoute>
              </Col>
            ) : (
              ''
            )}
            {/*FINISH PHYSICAL ASSESSMENT*/}

            {/*NEW EVALUATED*/}
            {makeEvaluationsPermission ? (
              <Col xl={3} className="col-lg" md={6} xs={6}>
                <BoxRoute
                  defaultMessage="Novo Avaliado"
                  idMessage="app.global.evaluated.new"
                  link="/NewEvaluated"
                >
                  <IconNewEvaluated />
                </BoxRoute>
              </Col>
            ) : (
              ''
            )}
            {/*FINISH NEW EVALUATED*/}

            {/*EVALUATORS*/}
            {manageEvaluators ? (
              <Col xl={3} className="col-lg" md={6} xs={6}>
                <BoxRoute
                  defaultMessage="Avaliadores"
                  idMessage="app.global.evaluators"
                  link="/Evaluators"
                >
                  <IconEvaluated />
                </BoxRoute>
              </Col>
            ) : (
              ''
            )}
            {/*FINISH EVALUATORS*/}

            {/*NEW EVALUATOR*/}
            {manageEvaluators ? (
              <Col xl={3} className="col-lg" md={6} xs={6}>
                <BoxRoute
                  defaultMessage="Novo avaliador"
                  idMessage="app.global.evaluator.new"
                  link="/NewEvaluator"
                >
                  <IconAddPerson />
                </BoxRoute>
              </Col>
            ) : (
              ''
            )}
            {/*FINISH NEW EVALUATOR*/}

            {/*REGISTRATION*/}
            {registrationPermission ? (
              <Col xl={3} className="col-lg" md={6} xs={6}>
                <BoxRoute
                  defaultMessage="Cadastros"
                  idMessage="app.global.registrations"
                  link="/Registration"
                >
                  <IconRegistrations />
                </BoxRoute>
              </Col>
            ) : (
              ''
            )}
            {/*FINISH REGISTRATION*/}

            {/*MANAGERUSERS*/}
            {managerUsers ? (
              <Col xl={3} className="col-lg" md={6} xs={6}>
                <BoxRoute
                  defaultMessage="Usuários"
                  idMessage="app.global.users"
                  link="/DashboardUsers"
                >
                  <IconClients />
                </BoxRoute>
              </Col>
            ) : (
              ''
            )}
            {/*FINISH MANAGERUSERS*/}
          </Row>
        </div>
      ) : (
        <DashboardEvaluated {...props} />
      )}
    </div>
  );
};

export default ClientPermissionRender;
