import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';

const initialState = Immutable.from({
  muscles: [],
  loading: false,
  shouldGetMuscles: true,
});

export default function muscles(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_MUSCLES_PENDING:
      const loading = state.updateIn(['loading'], () => true);
      return loading;
    case Actions.GET_MUSCLES_REJECTED:
      const rej = state.updateIn(['loading'], () => false);
      return rej;
    case Actions.GET_MUSCLES:
      const variabs = state
        .updateIn(['muscles'], () => action.payload)
        .updateIn(['loading'], () => false)
        .updateIn(['shouldGetMuscles'], () => false);
      return variabs;
    default:
      return state;
  }
}
