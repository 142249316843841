import React from 'react';

const IconCardiorespiratory = () => {
  return (
    <img
      src={require(`../../images/icon-cardiorespiratory.png`)}
      width="120"
      alt="Módulo Cardiorrespiratório"
    />
  )
}

export default IconCardiorespiratory;