import * as Actions from '../../constant/constants';

// MODAL
export function openModalAction(type) {
  return {
    type: Actions.OPEN_MODAL,
    payload: {
      status: true,
      type,
    },
  };
}

export function closeModalAction() {
  return {
    type: Actions.CLOSE_MODAL,
    payload: {
      status: false,
      type: '',
    },
  };
}
