import React from 'react';

const IconIMC = (props) => (
  <svg
    className="icon-imc"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-49 141 512 512"
    width={props.width}
    height={props.height}
  >
    <circle className="st0" cx="207" cy="397" r="256" />
    <path
      className="st1"
      d="M320.8 181.8c0 43.1-26.3 41.6-26.3 114.8 0 72.6 60.6 123.2 60.6 198.8s-44.8 116.8-44.8 116.8H100.8S56 570.9 56 495.4s60.6-126.2 60.6-198.8c0-73.1-26.3-71.7-26.3-114.8h230.5z"
    />
    <path
      className="st2"
      d="M200.3 612.2v-101c0-3.6 2.9-6.6 6.6-6.6 3.6 0 6.6 2.9 6.6 6.6v101h-13.2z"
    />
    <path
      className="st3"
      d="M-8.2 319.9h44.4c2.1 0 3.9-1.7 3.9-3.9v-15.7c0-3.5 4.2-5.2 6.6-2.7L74 325.7c1.5 1.5 1.5 4 0 5.5l-25.6 25.6c-2.4 2.4-6.6.7-6.6-2.7V344c0-2.1-1.7-3.9-3.9-3.9H-8.2v-20.2zm430.4 14.5h-44.4c-2.1 0-3.9 1.7-3.9 3.9V354c0 3.5-4.2 5.2-6.6 2.7L340 328.6c-1.5-1.5-1.5-4 0-5.5l25.6-25.6c2.4-2.4 6.6-.7 6.6 2.7v10.1c0 2.1 1.7 3.9 3.9 3.9h46.1v20.2z"
    />
    <path
      className="st2"
      d="M331.6 410.1l-96.9 104.7c-15.6 15.6-41 15.6-56.7 0L79.5 410.1h252.1zm-124.5-56.2c-3.6 0-6.6-2.9-6.6-6.6v-13.9c0-3.6 2.9-6.6 6.6-6.6 3.6 0 6.6 2.9 6.6 6.6v13.9c0 3.6-3 6.6-6.6 6.6z"
    />
    <path
      className="st4"
      d="M331.6 410.1l-96.9 95.8c-15.6 15.6-41 15.6-56.7 0l-98.6-95.8h252.2z"
    />
  </svg>
);

IconIMC.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconIMC;
