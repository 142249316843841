import React from 'react';

const IconCardIMC = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 195.989 195.989"
    width={props.width}
    height={props.height}
  >
    <path d="M195.935 84.745c-2.07-15.79-20.983-37.722-20.983-37.722-4.933-12.69-17.677-8.47-17.677-8.47l-8.507 2.295c-8.42 2.533-8.025 13.555-4.372 15.79 1.602.977 6.297 1.232 7.685 0 .42-.375.1-2.166.1-2.166 8.94.487 9.59-4.688 9.59-4.688l3.04-.606c3.05-1.665 3.72 5.395 3.72 5.395-2.07 20.01 6.6 27.334 6.6 27.334-1.25 3.973-5.62 3.206-5.62 3.206-13.85-7.197-24.13 6.403-24.13 6.403-7.83-6.67-23.99 5.148-23.99 5.148-9.05 1.79-9.59-9.106-9.59-9.106s-.42-6.94-.71-7.578c-.424-1.084 1.927-.536 1.927-.536 7.963-14.495 0-12.56 0-12.56 1.93-25.007-19.99-19.758-19.99-19.758-16.87 4.622-10.69 21.418-10.69 21.418-3.703-.904-1.93 4.616-1.93 4.616.958 8.473 3.987 6.552 3.987 6.552.39 2.968 2.057 7.054 2.057 7.054l.254 6.808C84.8 104.872 72.87 95.5 72.87 95.5c-6.994-9.86-24.534-4.344-24.534-4.344C39.274 77.676 25 85.37 25 85.37c1.515-3.35-.257-20.01-.257-20.01 1.77-2.05 5.33-13.71 5.33-13.71 1.522 2.06 8.39 2.42 8.39 2.42.522 3.09 2.73 4.35 2.73 4.35 4.664 1.94 2.73-13.33 2.73-13.33 1.22-4.844-6.573-6.01-6.573-6.01-13.595-3.74-16.743 4.85-16.743 4.85l-3.547 7.71C11.993 57.15 2.49 75.998 2.49 75.998c-5.468 15.002-.23 17.71-.23 17.71l9.864 7.664c16.736 16.204 26.85 13.878 26.85 13.878 13.46-.26 12.352 8.45 12.352 8.45.536 13.34 9.852 27.18 9.852 27.18.685 2.324 1.172 4.784 1.656 7.22h63.81c1.183-2.636 2.413-5.097 3.51-6.625 5.224-7.38 12.36-16.95 14.99-23.297 5.15-12.478 7.594-12.186 7.594-12.186 18.383 0 28.527-13.33 28.527-13.33 3.014-3.86 7.593-8.615 10.948-10.52 4.513-2.583 3.722-7.41 3.722-7.41z" />
  </svg>
);

IconCardIMC.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconCardIMC;
