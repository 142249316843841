import React from 'react';

const IconHeart = (props) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 37 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.23 14.6593C32.5558 20.9746 22.9731 28.1845 18.516 31C9.84067 25.0795 2.27958 18.6063 1.16531 12.449C0.0510473 6.29161 4.74689 1.79201 9.92027 1.08155C14.059 0.513174 17.3752 3.05506 18.516 4.39705C21.3017 1.08155 27.0322 -0.339375 31.5689 2.66036C36.1055 5.6601 36.8218 10.5544 35.23 14.6593Z"
      stroke="black"
      strokeWidth="1.4"
      strokeLinejoin="round"
    />
    <path
      d="M7 14.5401H16.2945L17.7123 20L20.6267 9L22.1233 14.5401H30"
      stroke="black"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconHeart.defaultProps = {
  width: 37,
  height: 32,
  color: 'default',
};

export default IconHeart;
