import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';

const initialState = Immutable.from({
  plans: [],
  planSelected: {},
  loading: false,
  shouldGetPlans: true,
});

export default function plans(state = initialState, action = {}) {
  switch (action.type) {
    case Actions.GET_PLANS_PENDING:
      const loadingPlans = state.updateIn(['loading'], () => true);
      return loadingPlans;
    case Actions.GET_PLANS_REJECTED:
      const rejPlans = state.updateIn(['loading'], () => false);
      return rejPlans;
    case Actions.GET_PLANS:
      const plans = state
        .updateIn(['plans'], () => action.payload)
        .updateIn(['loading'], () => false)
        .updateIn(['shouldGetPlans'], () => false);
      return plans;
    case Actions.ADD_REGISTER_PLAN:
      const newState = Immutable.merge({
        ...state,
        plans: [...state.plans, action.payload],
      });
      return newState;
    case Actions.REGISTER_SELECTED_PLAN:
      const planSelected = state.set('planSelected', action.payload);
      return planSelected;
    case Actions.UPDATE_REGISTER_PLAN:
      const planIndex = state.plans.findIndex(plan => plan.idplano === action.payload.idplano);
      const newStatePlan = state
        .updateIn(['plans', planIndex], () => action.payload)
      return newStatePlan;
    case Actions.DELETE_REGISTER_PLAN:
      const index = state.plans.findIndex(plan => plan.idplano === action.payload.idplano);
      const deletePlan = state.update('plans', pla => pla.slice(0, index).concat(pla.slice(index + 1)));
      return deletePlan;
    default:
      return state;
  }
}
