import React from 'react';
import { Row, Col } from 'reactstrap';
import BoxDialog from './BoxDialog';
import AssistentDialog from './AssistentDialog';

const HeaderDialog = (props) => {
  let hasDialog = true;
  if ('hasDialog' in props) hasDialog = props.hasDialog;
  return (
    <Row className="header-dialog">
      <AssistentDialog {...props} />
      {hasDialog && (
        <Col md={6} xl={7} className="align-self-center">
          <BoxDialog {...props} />
        </Col>
      )}
    </Row>
  );
};

export default HeaderDialog;
