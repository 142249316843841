import { ADD_LISTENER, REMOVE_LISTENER } from '../../constant/constants';

const initialState = {
  sessionClosed: false,
  listeners: {},
};

export default function listeners(state = initialState, action = {}) {
  switch (action.type) {
    case ADD_LISTENER:
      if (typeof state.listeners[action.payload.event] !== 'undefined') return state;
      window.addEventListener(action.payload.event, action.payload.func);
      const list = {
        ...state,
        listeners: { ...state.listeners, [action.payload.event]: action.payload.func },
      };
      return list;
    case REMOVE_LISTENER:
      var obj = { ...state.listeners };
      for (const eventName in obj) {
        if (obj.hasOwnProperty(eventName)) {
          window.removeEventListener(eventName, obj[eventName]);
        }
      }
      const remove = {
        ...state,
        sessionClosed: action.payload,
      };
      return remove;
    default:
      return state;
  }
}
