/* eslint-disable no-use-before-define */
import axios from 'axios';
import * as Actions from '../../constant/constants';
import socket from '../../socket';
import { GENDERS_DEFAULT_MESSAGES } from '../../constant/defaultMessages';

export function getEvaluators(idclient) {
  return {
    type: Actions.GET_EVALUATORS,
    payload: socket.sendMessage(Actions.GET_EVALUATORS, { idclient }),
  };
}

export function getEvaluatorEvaluations(idevaluator) {
  return {
    type: Actions.GET_EVALUATOR_EVALUATIONS,
    payload: socket.sendMessage(Actions.GET_EVALUATOR_EVALUATIONS, { idevaluator }),
  };
}

export function transferEvaluationsOwnership(idevaluator, newOwnerId) {
  return {
    type: Actions.TRANSFER_EVALUATIONS_OWNERSHIP,
    payload: socket.sendMessage(Actions.TRANSFER_EVALUATIONS_OWNERSHIP, {
      idevaluator,
      newOwnerId,
    }),
  };
}

export function setEvaluators(event) {
  let evaluators = [];
  if (typeof event === 'undefined') socket.sendMessage(Actions.GET_EVALUATORS, []);
  else if (event.data) {
    evaluators = event.data.map((evaluator) => {
      convertMessages(evaluator, GENDERS_DEFAULT_MESSAGES);
      return evaluator;
    });
  }
  return { type: Actions.GET_EVALUATORS, payload: evaluators };
}

// SELECT REGISTER
export function selectRegister(register) {
  const evaluatedRegister = convertMessages(register, GENDERS_DEFAULT_MESSAGES);
  return { type: Actions.REGISTER_SELECTED_EVALUATOR, payload: evaluatedRegister };
}

// ADD EVALUATOR
export function addEvaluator(event) {
  let evaluator = {};
  evaluator = event.data;
  return { type: Actions.ADD_REGISTER_EVALUATOR, payload: evaluator };
}

export function addRegister(data, formateddate, idclient) {
  return {
    type: Actions.ADD_REQUEST,
    payload: socket.sendMessage(Actions.ADD_EVALUATOR, {
      birthday: formateddate,
      cel: data.cellphone,
      city: '',
      complement: '',
      country: '',
      doc: data.document,
      email: data.email,
      password: data.password,
      idclient,
      idgender: data.gender.id,
      locale: data.locale,
      name: data.nameComplete,
      district: '',
      number: '',
      phone1: data.phoneOne,
      phone2: data.phoneTwo,
      picture: '',
      postalcode: '',
      state: '',
      street: '',
      timezone: data.timezone,
      instagram: data.instagram,
      twitter: data.twitter,
      facebook: data.facebook,
      linkedin: data.linkedin,
    }),
  };
}

// ADD HTTP EVALUATOR
export function addHttpEvaluator(
  data,
  formateddate,
  idclient,
  externalEvaluatorsRegister,
) {
  const evaluator = {
    birthday: formateddate,
    cel: data.cellphone,
    city: '',
    complement: '',
    country: '',
    doc: data.document,
    email: data.email,
    password: data.password,
    idclient,
    idgender: data.gender.id,
    locale: data.locale,
    name: data.nameComplete,
    district: '',
    number: '',
    phone1: data.phoneOne,
    phone2: data.phoneTwo,
    picture: '',
    postalcode: '',
    state: '',
    street: '',
    timezone: data.timezone,
    instagram: data.instagram,
    twitter: data.twitter,
    facebook: data.facebook,
    linkedin: data.linkedin,
    externalEvaluatorsRegister,
  };
  return (dispatch) => {
    dispatch({
      type: Actions.ADD_HTTP_EVALUATOR,
      payload: axios
        .post(`${Actions.URL_SERVER}/api/register/ExternalEvaluator`, evaluator)
        .then((response) => {
          if (response.data !== null) {
            dispatch(addedHttpEvaluator(data));
          }
        }),
    });
  };
}

export function addedHttpEvaluator(addedHttpEvaluator) {
  const addedHttpEvaluatorEvent = new Event(Actions.ADDED_HTTP_EVALUATOR);
  addedHttpEvaluatorEvent.data = addedHttpEvaluator;
  window.dispatchEvent(addedHttpEvaluatorEvent);

  return {
    type: Actions.ADDED_HTTP_EVALUATOR,
    payload: addedHttpEvaluator,
  };
}

// EDIT EVALUATOR
export function editEvaluator(event) {
  let evaluatorEdit = {};
  evaluatorEdit = event.data;
  return { type: Actions.EDIT_REGISTER_EVALUATOR, payload: evaluatorEdit };
}

export function editRegister(
  data,
  idEvaluator,
  formateddate,
  idclient,
  idperson,
  address,
  idcontact,
  picture,
) {
  return {
    type: Actions.UPDATE_REQUEST,
    payload: socket.sendMessage(Actions.UPDATE_EVALUATOR, {
      birthday: formateddate,
      cel: data.cellphone,
      city: address.city,
      complement: address.complement,
      country: address.country,
      doc: data.document,
      email: data.email,
      id: idEvaluator,
      idaddress: address.id,
      idclient,
      idcontact,
      idgender: data.gender.id,
      idperson,
      locale: data.locale,
      name: data.nameComplete,
      district: address.district,
      number: address.number,
      phone1: data.phoneOne,
      phone2: data.phoneTwo,
      picture,
      postalcode: address.postalcode,
      state: address.state,
      street: address.street,
      timezone: data.timezone,
      instagram: data.instagram,
      twitter: data.twitter,
      facebook: data.facebook,
      linkedin: data.linkedin,
    }),
  };
}

// DELETE EVALUATOR
export function deleteEvaluator(event) {
  let evaluatorDelete = {};
  evaluatorDelete = event.data;
  return {
    type: Actions.DELETE_REGISTER_EVALUATOR,
    payload: evaluatorDelete,
  };
}
export function deleteRegister(idperson, idevaluator) {
  return {
    type: Actions.DELETE_REQUEST,
    payload: socket.sendMessage(Actions.DELETE_EVALUATOR, {
      idperson,
      idevaluator,
    }),
  };
}

export function setEvaluatorPerson(idClient, idPerson) {
  return {
    type: Actions.SET_EVALUATOR_PERSON_REQUEST,
    payload: socket.sendMessage(Actions.SET_EVALUATOR_PERSON, {
      idclient: idClient,
      idperson: idPerson,
    }),
  };
}

function convertMessages(data, constMessage) {
  const nameField = data.person.gender.name;
  const nameFieldNoSpace = nameField.replace(/\s/g, '');
  data.person.gender.idMessageGender = `app.global.gender.${nameFieldNoSpace.toLowerCase()}`;
  data.person.gender.defaultMessageGender =
    constMessage[nameFieldNoSpace] || data.person.gender.name;
  return data;
}
