import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';

const initialState = Immutable.from({ methods: [], loading: false });

export default function formulas(state = initialState, action = {}) {
  switch (action.type) {
    case Actions.GET_FORMULA_METHODS_PENDING:
      const loading = state.updateIn(['loading'], () => true);
      return loading;
    case Actions.GET_FORMULA_METHODS_REJECTED:
      const rej = state.updateIn(['loading'], () => false);
      return rej;
    case Actions.GET_FORMULA_METHODS:
      const methods = state
        .updateIn(['methods'], () => action.payload)
        .updateIn(['loading'], () => false);
      return methods;
    default:
      return state;
  }
}
