import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';
import NotClientRender from '../components/Home/NotClientRender';
import ClientPermissionRender from '../components/Home/ClientPermissionRender';
import { redirectToLogin } from '../store/login/action';
import Login from './Login';
import { openModalAction } from '../store/modal/action';

// Navigation
import { navigationGrandParent } from '../store/navigation/action';
// import NewVersion from './NewVersion';

class Home extends Component {
  componentWillMount() {
    if (!this.props.isAuthenticated) {
      this.props.redirectToLogin();
    }
  }

  componentDidMount() {
    
    this.props.navigationGrandParent();
    /*const dataRaw = JSON.parse(getCookie('startGuide') !== '' ? getCookie('startGuide') : '[]');

    const profilePermission = getProfilePermissionLevel(this.props.profile.user.permission, this.props.profile.user.person);
    
    if (!isEmptyObj(this.props.profile.person)) {
      if (dataRaw.length === 0) {
        this.props.openModalAction('startGuide');
      } else {
        let profileCookieIndex = dataRaw.map(c => c.user).indexOf(this.props.profile.id);
        if (profileCookieIndex === -1) {
          this.props.openModalAction('startGuide');
        } else if (!(profilePermission.rule in dataRaw[profileCookieIndex].perm)) this.props.openModalAction('startGuide');
      }
    }*/
  }

  render() {
    // if (this.props.isAuthenticating || !this.props.profile)
    //   return <Loading/>
    if (!this.props.isAuthenticated) return <Login />;
    return (
      <div className="">
        {this.props.profile.permission.client ? (
          <ClientPermissionRender
            {...this.props.profile}
            accountType={this.props.accountType}
          />
        ) : (
          <NotClientRender {...this.props.profile} accountType={this.props.accountType} />
        )}
        {/* <StartGuideDetails /> */}
        {/* <NewVersion {...this.props.profile} /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.profile.user,
    accountType: state.profile.accountType,
    user: state.users.userSet,
    modals: state.util.modalSet,
    isAuthenticated: state.profile.isAuthenticated,
    isAuthenticating: state.profile.isAuthenticating,
    navigation: state.navigation,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      redirectToLogin: redirectToLogin,
      navigationGrandParent,
      openModalAction,
    },
    dispatch,
  ),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Home));
