import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';

const initialState = Immutable.from({
  clientStatistics: [],
  clientUsageStatistics: [],
  clientPlanStatistics: [],
  clientArrearsStatistics: [],
  clientAccessLog: [],
  clientIdDashboardSelected: 0,
  loadingClientStatistics: false,
  loadingClientUsageStatistics: false,
  shouldGetClientStatistics: true,
  shouldGetClientUsageStatistics: true
});

export default function dashboard(state = initialState, action = {}) {
  switch (action.type) {
    case Actions.GET_CLIENT_STATISTICS_PENDING:
      const loadingClientStatistics = state.updateIn(['loadingClientStatistics'], () => true);
      return loadingClientStatistics;
    case Actions.GET_CLIENT_STATISTICS_REJECTED:
      const rejClientStatistics = state.updateIn(['loadingClientStatistics'], () => false);
      return rejClientStatistics;
    case Actions.GET_CLIENT_STATISTICS:
      const clientStatistics = state
        .updateIn(['clientStatistics'], () => action.payload)
        .updateIn(['loadingClientStatistics'], () => false);
      return clientStatistics;
    case Actions.GET_CLIENT_USAGE_STATISTICS_PENDING:
      const loadingClientUsageStatistics = state.updateIn(['loadingClientUsageStatistics'], () => true);
      return loadingClientUsageStatistics;
    case Actions.GET_CLIENT_USAGE_STATISTICS_REJECTED:
      const rejClientUsageStatistics = state.updateIn(['loadingClientUsageStatistics'], () => false);
      return rejClientUsageStatistics;
    case Actions.GET_CLIENT_USAGE_STATISTICS:
      const clientUsageStatistics = state
        .updateIn(['clientUsageStatistics'], () => action.payload)
        .updateIn(['loadingClientUsageStatistics'], () => false);
      return clientUsageStatistics;
    case Actions.SELECT_DASHBOARD_CLIENT:
      const clientIdDashboardSelected = state
        .set('clientIdDashboardSelected', action.payload)        
      return clientIdDashboardSelected;
    case Actions.GET_CLIENT_ACCESS_LOG:
      const clientAcessLog = state.updateIn(['clientAccessLog'], () => action.payload);
      return clientAcessLog;
    default:
      return state;
  }
}