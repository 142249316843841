import React from 'react';

import { FormattedMessage } from 'react-intl';

const AlertInvalidVoucher = ({ animation = 'alert-open', onClose }) => (
  <div className={`alert-container ${animation}`}>
    <div
      role="alert"
      className="alert alert-warning col-12 col-lg-12 col-md-12 shadow-lg p-2 mb-5 rounded d-flex justify-content-between align-items-center"
    >
      <div className="box-alert-payment-problem mr-2">
        <p className="m-0 p-0 text-center">
          <FormattedMessage
            id="message.error.promotional.links.invalid.voucher"
            defaultMessage="A avaliação gratuita do Physical Test expirou. Realize a assinatura do sistema para prosseguir com o acesso."
          />
        </p>
      </div>

      <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={onClose}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
);

export default AlertInvalidVoucher;
