import socket from '../../socket';
import * as Actions from '../../constant/constants';

export function addFeatureViewed(idperson, version) {
  return {
    type: Actions.ADD_FEATURE_VIEWED,
    payload: socket.sendMessage(Actions.ADD_FEATURE_VIEWED, { idperson, version }),
  };
}

export function getFeaturesViewedByPerson(idperson) {
  return {
    type: Actions.GET_FEATURES_VIEWED_BY_PERSON,
    payload: socket.sendMessage(Actions.GET_FEATURES_VIEWED_BY_PERSON, { idperson }),
  };
}

export function setFeaturesViewedByPerson(res) {
  return {
    type: Actions.GET_FEATURES_VIEWED_BY_PERSON,
    payload: res.data,
  };
}

export function setNewFeatures(hasNewFeatures) {
  return {
    type: Actions.SET_NEW_FEATURES,
    payload: hasNewFeatures,
  };
}
