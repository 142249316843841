import * as Actions from '../../constant/constants';
import socket from '../../socket';

export function getCardioVariables() {
  return {
    type: Actions.GET_CARDIO_VARIABLES,
    payload: socket.sendMessage(Actions.GET_CARDIO_VARIABLES, []),
  };
}

export function setCardioVariables(event) {
  let variables = [];
  if (typeof event === 'undefined') socket.sendMessage(Actions.GET_CARDIO_VARIABLES, []);
  else {
    variables = event.data.map((v) => convertMessages(v));
  }
  return {
    type: Actions.GET_CARDIO_VARIABLES,
    payload: variables,
  };
}

export function convertMessages(data) {
  const nameField = data.code;
  const nameFieldNoSpace = nameField.replace(/\s/g, '').toLocaleLowerCase();
  data.idMessageName = `app.formula.variable.${nameFieldNoSpace}`;
  data.defaultMessageName = data.name;
  return data;
}
