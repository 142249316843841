import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';

const initialState = Immutable.from({
  maturations: [],
  loading: false,
  shouldGetMaturations: true,
});

export default function maturations(state = initialState, action = {}) {
  switch (action.type) {
    case Actions.GET_SEXUAL_MATURATIONS_PENDING:
      const loading = state.updateIn(['loading'], () => true);
      return loading;
    case Actions.GET_SEXUAL_MATURATIONS_REJECTED:
      const rej = state.updateIn(['loading'], () => false);
      return rej;
    case Actions.GET_SEXUAL_MATURATIONS:
      const maturations = state
        .updateIn(['maturations'], () => action.payload)
        .updateIn(['loading'], () => false)
        .updateIn(['shouldGetMaturations'], () => false);
      return maturations;
    default:
      return state;
  }
}
