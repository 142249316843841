import Immutable from 'seamless-immutable';

const initialState = Immutable.from({
  password: '',
});

export default function user(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
