import React from 'react';

const IconCardBodyMass = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width={props.width}
    height={props.height}
  >
    <path d="M216.16 191.953v249.923c0 12.386-10.078 22.463-22.465 22.463H70.125c-12.387 0-22.464-10.08-22.464-22.47V190.21c0-12.385 10.08-22.462 22.47-22.462h49.77V152.11H70.13c-21.008 0-38.1 17.093-38.1 38.1v251.666c0 21.008 17.092 38.1 38.1 38.1H193.7c21.01 0 38.102-17.092 38.102-38.1V191.953h-15.64zm225.716-39.843H392.1v15.638h49.776c12.386 0 22.463 10.077 22.463 22.463v251.67c0 12.39-10.08 22.47-22.47 22.47H318.3c-12.387 0-22.464-10.076-22.464-22.462v-249.93H280.2V441.88c0 21.007 17.094 38.1 38.1 38.1h123.573c21.008 0 38.1-17.093 38.1-38.1V190.21c0-21.007-17.092-38.1-38.1-38.1z" />
    <path d="M467.64 0H44.36C19.9 0 0 19.9 0 44.36v423.28C0 492.1 19.9 512 44.36 512h423.28c24.46 0 44.36-19.9 44.36-44.36V44.36C512 19.9 492.1 0 467.64 0zM15.636 103.796H140.8c-1.34 2.727-2.585 5.508-3.733 8.34H15.637v-8.34zM467.64 496.363H44.36c-15.838 0-28.723-12.885-28.723-28.724V127.77H131.88c-2.68 10.278-4.107 21.054-4.107 32.157v24.137h256.453V159.93c0-11.103-1.428-21.878-4.106-32.157h116.242V467.64c0 15.836-12.885 28.723-28.723 28.723zm-323.155-351.91c3.345-24.083 14.376-45.748 30.54-62.445L188.2 95.182l11.056-11.058-12.59-12.59c17.072-13.344 38.09-21.884 60.993-23.563V69.4h15.63V47.91C286.6 49.4 308 57.99 325.33 71.538l-12.59 12.59 11.055 11.055L336.97 82.01c16.166 16.696 27.198 38.36 30.54 62.444h-23.94v15.637h25.02v8.34H263.295v-16.68h-15.637v16.68h-104.25v-8.34h25.02v-15.63h-23.943zm351.878-32.317h-121.43c-1.148-2.83-2.394-5.613-3.734-8.34h125.16v8.34zm0-23.978h-134.28C338.99 54.303 300.055 32.023 256 32.023c-44.057 0-82.992 22.28-106.085 56.135H15.637V44.36c0-15.838 12.885-28.723 28.724-28.723h423.28c15.84 0 28.73 12.885 28.73 28.724v43.8z" />
    <path d="M256 120.248c-6.02 0-11.73 1.356-16.854 3.764l-33.66-33.66-11.056 11.058 32.018 32.02c-6.39 7.077-10.29 16.438-10.29 26.66h15.638c0-13.552 10.858-24.205 24.205-24.205 13.35 0 24.21 10.652 24.21 24.205h15.64c0-21.892-17.87-39.842-39.84-39.842zM40.204 40.204h95.91V55.84h-95.91zm415.95 0h15.636V55.84h-15.637zm-32.32 0h16.68V55.84h-16.68zm-31.274 0h15.64V55.84h-15.64z" />
  </svg>
);

IconCardBodyMass.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconCardBodyMass;
