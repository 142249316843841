import React from 'react';

const IconCardHeight = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 53.545 53.545"
    width={props.width}
    height={props.height}
  >
    <g fill="#010002">
      <circle cx="26.686" cy="4.507" r="4.507" />
      <path d="M28.256 11.163c-1.123-.228-2.344-.218-3.447.042-7.5.878-9.93 9.55-9.24 16.164.29 2.85 4.8 2.88 4.5 0-.25-2.41-.15-6.05 1.14-8.64v9.42c0 .11.01.215.01.322-.006.05-.018.093-.018.145 0 7.48-.013 14.953-.32 22.426-.138 3.322 5.013 3.31 5.15 0 .24-5.857.302-11.717.316-17.578.244.013.49.013.732 0 .013 5.86.072 11.72.312 17.575.137 3.31 5.288 3.323 5.15 0-.31-7.472-.32-14.95-.32-22.427 0-.234-.03-.445-.078-.648-.008-3.248-.13-6.498-.094-9.743 1.534 2.598 1.674 6.56 1.41 9.126-.3 2.886 4.21 2.857 4.505 0 .703-6.75-1.847-15.65-9.72-16.205z" />
    </g>
  </svg>
);

IconCardHeight.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconCardHeight;
