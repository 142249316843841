import * as Actions from '../../constant/constants';
import socket from '../../socket';
import { CLASSIFICATION_DEFAULT_MESSAGES } from '../../constant/defaultMessages';

export function getClassifications(e) {
  return {
    type: Actions.GET_CLASSIFICATIONS,
    payload: socket.sendMessage(Actions.GET_CLASSIFICATIONS, []),
  };
}

export function setClassifications(event) {
  let classifications = [];
  if (typeof event === 'undefined') socket.sendMessage(Actions.GET_CLASSIFICATIONS, []);
  else {
    classifications = event.data.map((classification) => {
      convertMessages(classification, CLASSIFICATION_DEFAULT_MESSAGES);
      return classification;
    });
  }
  return { type: Actions.GET_CLASSIFICATIONS, payload: classifications };
}

export function selectRegister(register) {
  return { type: Actions.REGISTER_SELECTED_CLASSIFICATION, payload: register };
}

export function addClassification(event) {
  let classification = {};
  classification = event.data;
  convertMessages(classification, CLASSIFICATION_DEFAULT_MESSAGES);
  return { type: Actions.ADD_REGISTER_CLASSIFICATION, payload: classification };
}

export function addRegister(name) {
  return {
    type: Actions.ADD_REQUEST,
    payload: socket.sendMessage(Actions.ADD_CLASSIFICATION, { name }),
  };
}

export function editClassification(event) {
  let classificationEdit = {};
  classificationEdit = event.data;
  convertMessages(classificationEdit, CLASSIFICATION_DEFAULT_MESSAGES);
  return { type: Actions.EDIT_REGISTER_CLASSIFICATION, payload: classificationEdit };
}

export function editRegister(id, name) {
  return {
    type: Actions.UPDATE_REQUEST,
    payload: socket.sendMessage(Actions.UPDATE_CLASSIFICATION, {
      id,
      name,
    }),
  };
}

export function deleteClassification(event) {
  let classificationDelete = {};
  classificationDelete = event.data;
  return { type: Actions.DELETE_REGISTER_CLASSIFICATION, payload: classificationDelete };
}

export function deleteRegister(id, name) {
  return {
    type: Actions.DELETE_REQUEST,
    payload: socket.sendMessage(Actions.DELETE_CLASSIFICATION, {
      id,
      name,
    }),
  };
}

export function convertMessages(data, constMessage) {
  const nameClassification = data.name;
  const nameClassificationNoSpace = nameClassification.replace(/\s/g, '');
  data.idMessageName = `app.classification.${nameClassificationNoSpace}`;
  data.defaultMessageName = constMessage[nameClassificationNoSpace] || data.name;
  return data;
}
