import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { closeModalAction } from '../../store/modal/action';
import { bindActionCreators } from 'redux';
import { injectIntl } from 'react-intl';

class ModalDialog extends Component {
  state = {
    reloadPage: false,
    hasHeader: true,
  };

  componentDidMount() {
    if ('hasHeader' in this.props) {
      if (this.state.hasHeader !== this.props.hasHeader) {
        this.setState({
          hasHeader: this.props.hasHeader,
        });
      }
    }
  }

  render() {
    const close = this.props.closeModal
      ? this.props.closeModal
      : () => this.props.closeModalAction();
    
    const toggleModal = this.props.toggleModal
      ? this.props.toggleModal
      : () => this.props.closeModalAction();

    let isOpen = false;

    if (this.props.isOpen !== undefined) {
      isOpen = this.props.isOpen;
    } else {
      isOpen = this.props.modals.modal.status;
    }

    return (
      <Modal
        size={this.props.size ? this.props.size : 'md'}
        isOpen={isOpen}
        toggle={() => toggleModal()}
        className={`${this.props.className} ${this.props.isCentered ? 'modal-dialog-centered' : ''}`}
        backdrop={this.props.backdrop ?? true}
      >
        {this.state.hasHeader && (
          <ModalHeader
            toggle={() => {
              if (!window.location.pathname.split('/')[1] === 'FormulaEditor') {
                if (this.state.reloadPage) {
                  if (
                    window.confirm(
                      this.props.intl.formatMessage({
                        id: 'app.message.exit.form',
                        defaultMessage: 'Deseja Sair?',
                      }),
                    )
                  ) {
                    close();
                  }
                } else {
                  close();
                }
              } else {
                close();
              }
            }}
          >
            {this.props.title}
          </ModalHeader>
        )}
        <ModalBody>{this.props.children}</ModalBody>
        <ModalFooter>
          {this.props.cancel && (
            <button className="btn btn-danger" onClick={this.props.onClickCancel}>
              {this.props.loading ? <img src={require('../../images/loading.svg')} alt='Loading' /> : ''}
              {this.props.cancel}
            </button>
          )}
          {this.props.success && (
            <button
              className="btn btn-success"
              onClick={this.props.onClickSuccess}
              disabled={this.props.disabled}              
            >
              {this.props.loading ? <img src={require('../../images/loading.svg') } alt='Loading' /> : ''}
              {this.props.success}
            </button>
          )}
          {this.props.danger && (
            <button
              className="btn btn-danger"
              onClick={this.props.onClickDanger}
              disabled={this.props.disabled}
            >
              {this.props.loading ? <img src={require('../../images/loading.svg')} alt='Loading' /> : ''}
              {this.props.danger}
            </button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    modals: state.util.modalSet,
  };
}
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ closeModalAction }, dispatch),
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ModalDialog));
