import React from 'react';
import { Route } from 'react-router-dom';
import * as routes from './routes/routing.js';
import Home from './containers/Home';
import Login from './containers/Login';
import ConnectedSwitch from './utils/connected-switch';
import { PrivateRoute } from './PrivateRoute.js';

class MyRoutes extends React.Component {
 
  render() {
    return (
      <ConnectedSwitch>
        <Route exact path="/Login" component={Login} />
        <Route exact path="/AddMeasure" component={routes.AddMeasure} />
        <Route exact path="/Authors" component={routes.Authors} />
        <Route exact path="/Anamnesis" component={routes.Anamnesis} />
        <Route exact path="/BodyMeasures" component={routes.BodyMeasures} />
        <Route exact path="/Classification" component={routes.Classification} />
        <Route exact path="/Client/:clientId/Edit" component={routes.EditClient} />
        <Route exact path="/Client/:clientId" component={routes.ProfileClient} />
        <Route exact path="/Clients" component={routes.Clients} />
        <PrivateRoute
          notAllowed={this.props.isNotSysAdmin}
          redirectTo="/"
          path="/ClientsApproval"
          component={routes.ClientsApproval}
        />
        <Route
          exact
          path="/Comparisons/:clientId/:evaluatedId"
          component={routes.Comparisons}
        />
        <Route exact path="/DashboardFinancial" component={routes.DashboardFinancial} />
        <Route exact path="/DashboardClients" component={routes.DashboardClients} />
        <PrivateRoute
          notAllowed={this.props.isNotSysAdmin}
          redirectTo="/"
          path="/Plans"
          component={routes.Plans}
        />
        <PrivateRoute
          notAllowed={this.props.isNotSysAdmin}
          redirectTo="/"
          path="/PromotionalLinks"
          component={routes.PromotionalLinks}
        />
        <PrivateRoute
          notAllowed={this.props.isNotSysAdmin}
          redirectTo="/"
          path="/EditPromotionalLink/:linkId"
          component={routes.NewPromotionalLink}
        />
        <PrivateRoute
          notAllowed={this.props.isNotSysAdmin}
          redirectTo="/"
          path="/NewPromotionalLink"
          component={routes.NewPromotionalLink}
        />
        <Route exact path="/DashboardUsers" component={routes.DashboardUsers} />
        <Route exact path="/Ethnicity" component={routes.Ethnicity} />
        <Route
          exact
          path="/Evaluated/:clientId/:evaluatedId"
          component={routes.ProfileEvaluated}
        />
        <Route
          exact
          path="/Evaluated/Edit/:clientId/:evaluatedId"
          component={routes.DataEvaluated}
        />
        <Route exact path="/Evaluated" component={routes.Evaluated} />
        <Route exact path="/EvaluatedImc" component={routes.EvaluatedImc} />
        <Route exact path="/Evaluators" component={routes.Evaluators} />
        <Route exact path="/ExternalRegister" component={routes.ExternalRegister} />
        <Route exact path="/ForgotPassword/:auth" component={routes.ForgotPassword} />
        <Route exact path="/SetEvaluatedPassword/:auth" component={routes.SetEvaluatedPassword} />
        <Route exact path="/FormulaEditor" component={routes.FormulaEditor} />
        <Route exact path="/FormulaEditor/:formulaId" component={routes.FormulaEditor} />
        <Route exact path="/Formulas" component={routes.Formulas} />
        <Route exact path="/Goals" component={routes.Goals} />
        <Route exact path="/Measures" component={routes.Measures} />
        <Route exact path="/EditMeasure/:measureId" component={routes.EditMeasure} />
        <Route exact path="/MyAccount/ChangePassword" component={routes.ChangePassword} />
        <Route
          exact
          path="/MyAccount/EditDataProfile"
          component={routes.EditDataProfile}
        />
        <Route exact path="/MyAccount/Notifications" component={routes.Notifications} />
        <PrivateRoute
          notAllowed={!this.props.planModuleIsEnabled || (this.props.registrationPhase !== undefined && this.props.registrationPhase !== 2)}
          path="/MyAccount/PaymentHistory"
          component={routes.PaymentHistory}
          redirectTo="/"
        />
        <Route exact path="/MyAccount/Plan" component={routes.ProfilePlan} />
        <Route exact path="/MyAccount" component={routes.MyAccount} />
        <Route exact path="/NewClient" component={routes.NewClient} />
        <PrivateRoute
          notAllowed={this.props.paymentProblem}
          showProblemMessage={this.props.showPaymentProblemMessage}
          path="/NewEvaluated"
          component={routes.NewEvaluated}
          redirectTo="Evaluated"
        />
        <PrivateRoute
          notAllowed={this.props.paymentProblem}
          showProblemMessage={this.props.showPaymentProblemMessage}
          path="/NewEvaluator"
          component={routes.NewEvaluator}
          redirectTo="Evaluators"
        />
        <Route exact path="/NewUser" component={routes.NewUser} />
        <Route
          exact
          path="/NewExternalEvaluator/:token"
          component={routes.NewExternalEvaluator}
        />
        <Route exact path="/NewTableFatness" component={routes.NewTableFatness} />
        <PrivateRoute
          notAllowed={this.props.isNotSysAdmin}
          redirectTo="/"
          path="/EditPlan/:planId"
          component={routes.NewPlan}
        />
        <Route
          exact
          path="/PhysicalAssessment/:clientId/:evaluatedId/:evaluationId/Edit/Measures"
          component={routes.PhysicalAssessmentMeasures}
        />
        <Route
          exact
          path="/PhysicalAssessment/:clientId/:evaluatedId/:evaluationId/Edit/Composition"
          component={routes.PhysicalAssessmentComposition}
        />
        <Route
          exact
          path="/PhysicalAssessment/:clientId/:evaluatedId/:evaluationId/Edit/Anamnesis"
          component={routes.PhysicalAssessmentAnamnesis}
        />
        <Route
          exact
          path="/PhysicalAssessment/:clientId/:evaluatedId/:evaluationId/Edit/ParQ"
          component={routes.PhysicalAssessmentParQ}
        />
        <Route
          exact
          path="/PhysicalAssessment/:clientId/:evaluatedId/:evaluationId/Edit/Cardiorespiratory"
          component={routes.PhysicalAssessmentCardiorespiratory}
        />
        <Route
          exact
          path="/PhysicalAssessment/:clientId/:evaluatedId/:evaluationId/Edit/Neuromotors"
          component={routes.PhysicalAssessmentNeuromotors}
        />
        <Route
          exact
          path="/PhysicalAssessment/:clientId/:evaluatedId/:evaluationId/Edit/"
          component={routes.PhysicalAssessmentNew}
        />
        <Route
          exact
          path="/PhysicalAssessment/:clientId/:evaluatedId/:evaluationId"
          component={routes.PhysicalAssessmentNew}
        />
        <Route
          exact
          path="/PhysicalAssessment/:clientId/:evaluatedId/:evaluationId/Report"
          component={routes.ReportEvaluation}
        />
        <Route
          exact
          path="/PhysicalAssessmentReport/:auth"
          component={routes.PhysicalAssessmentReport}
        />
        <Route exact path="/Registration" component={routes.Registration} />
        <Route exact path="/SessionControl" component={routes.SessionControl} />
        <Route exact path="/TablesFatnessIdeal" component={routes.TablesFatnessIdeal} />
        <Route
          exact
          path="/UserPermissions/:clientId/:userId"
          component={routes.UserPermissions}
        />
        <Route exact path="/UsersManagement" component={routes.UsersManagement} />
        <Route
          exact
          path="/ManageExternalEvaluators"
          component={routes.ManageExternalEvaluators}
        />
        <Route exact path="/Reports/Measures" component={routes.MeasuresReport} />
        <PrivateRoute
          notAllowed={this.props.isNotSysAdmin}
          redirectTo="/"
          path="/NewPlan"
          component={routes.NewPlan}
        />
        <PrivateRoute
          notAllowed={!this.props.planModuleIsEnabled}
          path="/Subscription"
          component={routes.Subscription}
          redirectTo="/"
        />
        <PrivateRoute
          notAllowed={!this.props.planModuleIsEnabled}
          path="/UpdatePaymentData"
          component={routes.Subscription}
          redirectTo="/"
        />
        <Route exact path="/Exercises" component={routes.Exercises} />
        <Route exact path="/AddExercise" component={routes.EditExercise} />
        <Route exact path="/EditExercise/:exerciseId" component={routes.EditExercise} />
        <Route exact path="/AddPresetWorkout" component={routes.EditPresetWorkout} />
        <Route exact path="/AddPresetWorkout/:evaluatedId" component={routes.EditPresetWorkoutEvaluated} />
        <Route exact path="/EditPresetWorkout/:presetWorkoutId" component={routes.EditPresetWorkout} />
        <Route exact path="/EditPresetWorkout/:evaluatedId/:presetWorkoutId" component={routes.EditPresetWorkoutEvaluated} />
        <Route exact path="/PresetWorkoutsEvaluated" component={routes.PresetWorkoutsEvaluated} />
        <Route exact path="/PresetWorkouts" component={routes.PresetWorkouts} />
        <Route exact path="/WorkoutEvaluated" component={routes.WorkoutEvaluated} />
        <Route exact path="/" component={Home} />
      </ConnectedSwitch>
    );
  }
}
export default MyRoutes;
