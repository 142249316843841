import * as Actions from '../../constant/constants';
import socket from '../../socket';
// import { } from '../../constant/defaultMessages';

export function getFatPercentagesIdeal(event) {
  let fatPercentagesIdeal = [];
  if (typeof event === 'undefined') socket.sendMessage(Actions.GET_FAT_PERCENTAGES_IDEAL, []);
  else fatPercentagesIdeal = event.data;
  return {
    type: Actions.GET_FAT_PERCENTAGES_IDEAL,
    payload: fatPercentagesIdeal,
  };
}

export function addFatPercentageIdeal(idclient, minage, maxage, values) {
  const result = {
    minage,
    maxage,
    values,
  };
  if (idclient) {
    result.idclient = idclient;
  }
  return {
    type: Actions.UPDATE_REQUEST,
    payload: socket.sendMessage(Actions.ADD_FAT_PERCENTAGE_IDEAL, result),
  };
}

export function setAddFatPercentageIdeal(event) {
  let fatPercentageIdeal = {};
  if (typeof event === 'undefined') socket.sendMessage(Actions.ADD_FAT_PERCENTAGE_IDEAL, []);
  else fatPercentageIdeal = event.data;
  return {
    type: Actions.ADD_FAT_PERCENTAGE_IDEAL,
    payload: fatPercentageIdeal,
  };
}

export function updateFatPercentageIdeal(idclient, minage, maxage, values) {
  const result = {
    minage,
    maxage,
    values,
  };
  if (idclient) {
    result.idclient = idclient;
  }
  return {
    type: Actions.UPDATE_REQUEST,
    payload: socket.sendMessage(Actions.UPDATE_FAT_PERCENTAGE_IDEAL, result),
  };
}

export function setUpdateFatPercentageIdeal(event) {
  let fatPercentageIdeal = {};
  if (typeof event === 'undefined') socket.sendMessage(Actions.UPDATE_FAT_PERCENTAGE_IDEAL, []);
  else fatPercentageIdeal = event.data;
  return {
    type: Actions.UPDATE_FAT_PERCENTAGE_IDEAL,
    payload: fatPercentageIdeal,
  };
}

export function selectDeleteFatPercentage(fats) {
  return {
    type: Actions.DELETE_REGISTER_FAT_PERCENTAGES_IDEAL,
    payload: fats,
  };
}

export function deleteRegisterFatPercentage(ids) {
  return {
    type: Actions.DELETE_REQUEST,
    payload: socket.sendMessage(Actions.DELETE_FAT_PERCENTAGE_IDEAL, { ids }),
  };
}

export function deleteFatPercentagesIdeal(event) {
  let fPersDelete = [];
  fPersDelete = event.data;
  return {
    type: Actions.DELETE_FAT_PERCENTAGE_IDEAL,
    payload: fPersDelete,
  };
}

// function convertMessages(data, constMessage) {
//     let name = data.name,
//         nameDot = name.replace( /\s/g, '.' ),
//         nameNoSpace = name.replace(/\s|-/g, '');
//     data.idMessageName = 'app.fatcategories.'+nameDot;
//     data.defaultMessageName = constMessage[nameNoSpace];
//     return data;
// }
