import React from 'react';

const IconChooseProtocol = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 508 508"
    width={props.width}
    height={props.height}
  >
    <circle cx="254" cy="254" r="254" fill="#324A5E" />
    <path
      d="M395.6 146.4c0-7.6-6-13.6-13.6-13.6h-78.4c-2.8 0-5.6.8-8 2.4l-30.4 21.6c-2.4 1.6-5.2 2.4-8 2.4h-142c-7.6 0-13.6 6-13.6 13.6v202.4c0 7.6 6 13.6 13.6 13.6H382c7.6 0 13.6-6 13.6-13.6V146.4z"
      fill="#F9B54C"
    />
    <path fill="#FFF" d="M145.6 82.8h205.6v233.6H145.6z" />
    <path
      d="M417.2 230.8H293.6c-3.6 0-7.2 1.2-10 4L236.4 276c-2.4 2-5.6 3.2-9.2 3.2H90c-7.6 0-12.4 5.6-10.8 12.8l19.6 86c1.6 6 8.8 11.2 16 11.2h266.8c7.6 0 14.8-4.8 16-11.2L428 244.8c2.4-7.6-2.4-14-10.8-14z"
      fill="#FFD05B"
    />
    <path
      d="M171.2 112.8H326v11.6H171.2zm0 28.8H326v11.6H171.2zm0 29.2H326v11.6H171.2zm0 29.2H326v11.6H171.2zm0 28.8h77.6v11.6h-77.6z"
      fill="#84DBFF"
    />
  </svg>
);

IconChooseProtocol.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconChooseProtocol;
