import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';

const initialState = Immutable.from({
  formulas: [],
  formulaTest: {},
  formulaSelected: {},
  loading: false,
  shouldGetFormulas: true,
});

export default function formulas(state = initialState, action = {}) {
  switch (action.type) {
    case Actions.GET_FORMULAS_PENDING:
      const loading = state.updateIn(['loading'], () => true);
      return loading;
    case Actions.GET_FORMULAS:
      const formulas = state
        .updateIn(['formulas'], () => action.payload)
        .updateIn(['loading'], () => false)
        .updateIn(['shouldGetFormulas'], () => false);
      return formulas;
    case Actions.ADD_REGISTER_FORMULA:
      const newState = Immutable.merge({
        ...state,
        formulas: [...state.formulas, action.payload],
      });
      return newState;
    case Actions.REGISTER_TEST_FORMULA:
      const formulaTest = state.set('formulaTest', action.payload);
      return formulaTest;
    case Actions.REGISTER_SELECTED_FORMULA:
      const formulaSelected = state.set('formulaSelected', action.payload);
      return formulaSelected;
    case Actions.EDIT_REGISTER_FORMULA:
      const formulaIndex = state.formulas.findIndex(
        formula => formula.id === action.payload.id,
      );
      const newStateFormula = state.updateIn(
        ['formulas', formulaIndex],
        () => action.payload,
      );
      return newStateFormula;
    case Actions.DELETE_REGISTER_FORMULA:
      const index = state.formulas.findIndex(
        formula => formula.id === action.payload.id,
      );
      const deleteFormula = state.update('formulas', aus => aus.slice(0, index).concat(aus.slice(index + 1)));
      return deleteFormula;
    default:
      return state;
  }
}
