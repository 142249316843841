import React from 'react';

const IconEvaluated = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512.002 512.002"
    width={props.width}
    height={props.height}
  >
    <path
      fill="#6598CB"
      d="M329.584 206.183c0-.596-.017-1.178-.035-1.758-.004-.081-.002-.166-.006-.247-.013-.295-.036-.58-.056-.871-.024-.37-.041-.742-.072-1.107-.015-.153-.033-.298-.049-.449-.049-.497-.097-.992-.16-1.475-.004-.042-.013-.081-.019-.124-1.328-9.916-5.842-17.159-10.917-22.094-.022-.022-.047-.046-.069-.069-.267-.255-.533-.508-.802-.753-.131-.121-.265-.239-.401-.357-.155-.138-.311-.277-.465-.41-.256-.223-.515-.438-.776-.648-.028-.021-.058-.045-.084-.067-3.257-2.633-6.516-4.346-9.069-5.23-.013-.002-.022-.007-.033-.011l-.068-.025c-.009-.002-.017-.005-.022-.007l-33.535-13.446-8.376 8.694-8.57 8.346-8.565-8.345-8.378-8.694-33.534 13.446s-.016.005-.026.007l-.065.025c-.011.003-.022.009-.022.009-2.566.887-5.823 2.6-9.079 5.232-.028.022-.058.046-.084.067-.259.21-.519.425-.776.648-.158.134-.314.272-.469.41-.134.118-.267.236-.401.357-.266.246-.533.498-.8.753-.022.024-.046.047-.072.069-5.073 4.935-9.587 12.177-10.914 22.094-.006.043-.014.082-.02.124-.063.483-.111.979-.16 1.475-.015.151-.036.296-.049.449-.029.364-.048.737-.072 1.107-.017.292-.042.576-.056.871-.002.081 0 .166-.004.247-.021.58-.037 1.162-.037 1.758v5.328h.002V227.8c.218 2.541 1.534 7.011 8.106 7.011h130.953c6.574 0 7.887-4.469 8.107-7.011v-16.288l-.001-5.329z"
    />
    <path
      fill="#4982AA"
      d="M238.893 157.412l-33.358 13.063-.105.035c-8.546 3.05-23.013 13.993-23.013 35.672v21.617c.218 2.541 1.534 7.011 8.106 7.011h1.689v-28.628c0-21.679 14.468-32.622 23.013-35.672l.107-.035 26.369-10.329-2.808-2.734z"
    />
    <path
      fill="#694B4B"
      d="M257.727 35.063h-3.616c-24.692 0-44.779 20.09-44.779 44.78v21.007s10.195-1.012 11.45-5.954l-.009-.006c.432-.468.792-1.002 1.046-1.598 5.781-.173 32.797-.383 48.224-19.016 1.839 5.385 8.069 15.583 20.051 18.796.248.649.613 1.235 1.062 1.743l-.099.081c1.253 4.943 11.448 5.954 11.448 5.954V79.842c.002-24.689-20.088-44.779-44.778-44.779zm33.761 56.618l.432-.313h.06l-.701.52.209-.207zm-.218.132l.048-.151.152-.295h.06l-.178.37-.082.076zm-1.19.92l-.015-.022 1.102-.798-.26.251-.807.597-.02-.028zm.912-.26l-.345.716-.004-.002-.051-.022c-.006-.003-.019-.009-.023-.013l.563-1.122.015-.014-.155.457z"
    />
    <path
      fill="#563B3B"
      d="M217.225 91.406V79.844c0-23.974 18.94-43.604 42.641-44.725-.709-.034-1.421-.056-2.139-.056h-3.616c-24.692 0-44.779 20.09-44.779 44.78v21.007s4.506-.449 7.893-2.202v-7.242z"
    />
    <path
      fill="#FBCB99"
      d="M297.128 94.36c-1.713-.534-3.574-.222-5.02.839-1.442 1.062-2.297 2.749-2.297 4.542v16.067c0 1.794.854 3.478 2.297 4.54.98.721 2.154 1.096 3.34 1.096.563 0 1.129-.082 1.677-.254 1.082-.335 2.143-.824 3.157-1.444.595-.366 1.118-.841 1.541-1.396.622-.322 1.179-.755 1.643-1.278 2.277-2.577 3.534-5.878 3.534-9.299.002-6.182-3.967-11.574-9.872-13.413z"
    />
    <path
      fill="#EDBA89"
      d="M219.896 95.198c-1.446-1.061-3.31-1.373-5.023-.841-5.902 1.842-9.87 7.233-9.87 13.416 0 6.185 3.968 11.575 9.87 13.417.551.172 1.116.254 1.679.254 1.187 0 2.361-.376 3.344-1.096 1.444-1.062 2.297-2.746 2.297-4.54v-16.07c0-1.791-.854-3.478-2.297-4.54z"
    />
    <path
      fill="#FBCB99"
      d="M271.396 143.817c-1.11-1.061-2.597-1.618-4.142-1.562l-.193.01c-.345.012-.687.057-1.022.13-2.179.483-4.398.731-6.592.731h-6.891c-2.261 0-4.544-.261-6.789-.776-1.674-.386-3.432.015-4.775 1.085-1.341 1.069-2.124 2.695-2.124 4.41v16.4l-.004.057c-.089 1.626.528 3.213 1.694 4.35l11.329 11.035c1.054 1.026 2.468 1.597 3.935 1.597h.091c1.499-.024 2.93-.645 3.967-1.726l11.674-12.116c1.012-1.049 1.577-2.451 1.577-3.908V147.89c.001-1.539-.625-3.012-1.735-4.073z"
    />
    <path
      fill="#EDBA89"
      d="M256.966 177.993l-11.329-11.034c-1.169-1.137-1.787-2.722-1.699-4.35l.006-.056v-16.402c0-1.443.557-2.816 1.534-3.853-1.588-.277-3.219.129-4.484 1.136-1.341 1.069-2.124 2.695-2.124 4.41v16.4l-.004.057c-.089 1.626.528 3.213 1.694 4.35l11.329 11.035c1.054 1.026 2.468 1.597 3.935 1.597h.091c1.499-.023 2.93-.645 3.967-1.726l.051-.053c-1.111-.193-2.149-.713-2.967-1.511z"
    />
    <path
      fill="#FBCB99"
      d="M288.962 89.757c-.683-.253-1.324-.55-1.953-.865-9.474-4.421-13.405-10.171-14.991-13.601-.093-.128-.186-.272-.284-.45-1.133-2.082-2.898-.765-2.898-.765l-.006-.004c-8.848 6.786-18.512 10.754-26.548 13.063-3.126.924-6.121 1.593-8.832 2.078-5.384.989-8.848 1.118-8.848 1.118-2.947.213-5.233 2.668-5.233 5.625v23.004c0 18.3 14.889 33.184 33.187 33.184h6.891c18.296 0 33.184-14.884 33.184-33.184V95.04c.001-2.353-1.464-4.461-3.669-5.283z"
    />
    <path
      fill="#EDBA89"
      d="M226.136 118.961V95.957c0-2.957 2.285-5.412 5.231-5.625 0 0 3.464-.129 8.848-1.118 2.71-.485 5.706-1.154 8.835-2.078 7.109-2.044 15.497-5.391 23.469-10.838-.189-.351-.357-.688-.503-1.005-.093-.128-.186-.272-.284-.45-1.133-2.082-2.898-.765-2.898-.765l-.006-.004c-8.848 6.786-18.512 10.754-26.548 13.063-3.126.924-6.121 1.593-8.832 2.078-5.384.989-8.848 1.118-8.848 1.118-2.947.213-5.233 2.668-5.233 5.625v23.004c0 18.3 14.889 33.184 33.187 33.184h6.762c-18.293 0-33.18-14.885-33.18-33.185z"
    />
    <path
      fill="#6598CB"
      d="M420.792 327.245c0-.595-.017-1.177-.036-1.757-.004-.082-.002-.167-.006-.249-.013-.294-.035-.579-.054-.87-.022-.37-.04-.742-.072-1.107-.014-.153-.034-.299-.048-.45-.049-.497-.098-.992-.161-1.474-.004-.042-.013-.081-.019-.124-1.327-9.916-5.841-17.159-10.917-22.094-.023-.022-.047-.046-.069-.071-.267-.254-.532-.507-.801-.752-.134-.122-.266-.239-.401-.357-.155-.138-.311-.278-.466-.411-.257-.222-.516-.437-.777-.648-.028-.021-.057-.045-.085-.067-3.255-2.633-6.514-4.346-9.067-5.23-.013-.002-.023-.007-.034-.011l-.067-.025s-.018-.006-.024-.009l-33.534-13.446-8.377 8.695-8.568 8.345-8.566-8.345-8.378-8.695-33.534 13.446s-.016.006-.025.009l-.065.025c-.012.003-.023.009-.033.011-2.556.883-5.813 2.597-9.069 5.23-.028.022-.058.047-.085.067-.257.21-.518.425-.774.648-.157.134-.313.273-.468.411-.134.117-.268.235-.401.357-.267.246-.533.498-.8.752-.023.025-.047.048-.072.071-5.074 4.935-9.588 12.177-10.916 22.094-.006.043-.013.082-.018.124-.065.482-.114.977-.161 1.474-.014.151-.035.296-.048.45-.03.364-.05.737-.073 1.107-.017.291-.042.576-.054.87-.002.081 0 .166-.005.249-.021.58-.037 1.162-.037 1.757v5.329h.002v16.288c.218 2.541 1.534 7.011 8.106 7.011h130.949c6.574 0 7.888-4.469 8.107-7.011v-21.617h.001z"
    />
    <path
      fill="#4982AA"
      d="M330.102 278.475l-33.36 13.063-.103.036c-8.546 3.049-23.014 13.993-23.014 35.67v21.618c.218 2.541 1.534 7.011 8.106 7.011h1.69v-28.629c0-21.678 14.466-32.621 23.013-35.67l.106-.036 26.37-10.328-2.808-2.735z"
    />
    <path
      fill="#694B4B"
      d="M348.935 156.126h-3.615c-24.692 0-44.779 20.09-44.779 44.78v21.007s10.193-1.012 11.449-5.955l-.007-.005c.431-.468.79-1.003 1.047-1.598 5.781-.174 32.796-.385 48.223-19.016 1.839 5.385 8.068 15.583 20.051 18.796.248.649.614 1.235 1.063 1.743l-.098.08c1.252 4.944 11.448 5.955 11.448 5.955v-21.008c-.002-24.689-20.091-44.779-44.782-44.779zm33.762 56.616l.431-.312h.06l-.701.52.21-.208zm-.219.134l.049-.151.151-.295h.061l-.179.37-.082.076zm-1.189.92l-.016-.022 1.102-.798-.26.251-.808.596-.018-.027zm.912-.26l-.346.716-.004-.002-.051-.022c-.006-.003-.018-.009-.024-.013l.563-1.122.016-.014-.154.457z"
    />
    <path
      fill="#563B3B"
      d="M308.433 212.469v-11.561c0-23.974 18.939-43.604 42.64-44.726-.709-.033-1.421-.054-2.139-.054h-3.615c-24.692 0-44.779 20.09-44.779 44.78v21.007s4.505-.45 7.893-2.201v-7.245z"
    />
    <path
      fill="#FBCB99"
      d="M388.336 215.423c-1.713-.535-3.574-.222-5.021.837-1.442 1.062-2.297 2.75-2.297 4.543v16.067c0 1.792.854 3.478 2.297 4.54.981.721 2.155 1.096 3.342 1.096.562 0 1.128-.082 1.676-.255 1.082-.334 2.144-.823 3.157-1.443.595-.365 1.12-.841 1.54-1.395.623-.322 1.18-.755 1.644-1.278 2.279-2.577 3.534-5.88 3.534-9.3.001-6.181-3.966-11.573-9.872-13.412z"
    />
    <path
      fill="#EDBA89"
      d="M311.103 216.26c-1.446-1.06-3.31-1.373-5.022-.84-5.904 1.842-9.872 7.233-9.872 13.415 0 6.185 3.967 11.576 9.872 13.416.551.173 1.116.255 1.679.255 1.187 0 2.36-.376 3.342-1.096 1.444-1.062 2.297-2.747 2.297-4.54v-16.069c.001-1.79-.852-3.479-2.296-4.541z"
    />
    <path
      fill="#FBCB99"
      d="M362.603 264.879c-1.109-1.061-2.596-1.618-4.142-1.562l-.192.01c-.345.013-.687.057-1.022.13-2.179.484-4.397.731-6.591.731h-6.892c-2.261 0-4.544-.26-6.788-.774-1.675-.386-3.432.015-4.774 1.085-1.342 1.069-2.125 2.693-2.125 4.409v16.401l-.004.057c-.089 1.627.529 3.213 1.695 4.35l11.329 11.034c1.053 1.027 2.467 1.598 3.933 1.598h.092c1.5-.025 2.93-.645 3.968-1.726l11.673-12.116c1.012-1.05 1.578-2.451 1.578-3.909v-15.644c-.001-1.539-.628-3.013-1.738-4.074z"
    />
    <path
      fill="#EDBA89"
      d="M348.173 299.056l-11.326-11.034c-1.169-1.137-1.787-2.722-1.699-4.351l.006-.056v-16.401c0-1.443.558-2.817 1.534-3.853-1.587-.277-3.219.129-4.483 1.137-1.342 1.069-2.125 2.693-2.125 4.409v16.401l-.004.057c-.089 1.627.529 3.213 1.695 4.35l11.329 11.034c1.053 1.027 2.467 1.598 3.933 1.598h.092c1.5-.025 2.93-.645 3.968-1.726l.05-.053c-1.114-.193-2.15-.714-2.97-1.512z"
    />
    <path
      fill="#FBCB99"
      d="M380.171 210.82c-.683-.252-1.325-.549-1.954-.865-9.473-4.421-13.404-10.17-14.991-13.6-.093-.128-.186-.272-.284-.451-1.132-2.082-2.897-.764-2.897-.764l-.006-.004c-8.848 6.786-18.512 10.753-26.548 13.063-3.127.924-6.122 1.593-8.832 2.078-5.384.989-8.847 1.117-8.847 1.117-2.948.213-5.234 2.67-5.234 5.626v23.004c0 18.299 14.889 33.184 33.187 33.184h6.892c18.296 0 33.184-14.885 33.184-33.184v-23.922c-.001-2.353-1.466-4.46-3.67-5.282z"
    />
    <path
      fill="#EDBA89"
      d="M317.345 240.024V217.02c0-2.957 2.284-5.413 5.231-5.626 0 0 3.463-.128 8.847-1.117 2.71-.485 5.707-1.154 8.835-2.078 7.109-2.044 15.497-5.392 23.468-10.838-.188-.351-.355-.689-.502-1.005-.093-.128-.186-.272-.284-.451-1.132-2.082-2.897-.764-2.897-.764l-.006-.004c-8.848 6.786-18.512 10.753-26.548 13.063-3.127.924-6.122 1.593-8.832 2.078-5.384.989-8.847 1.117-8.847 1.117-2.948.213-5.234 2.67-5.234 5.626v23.004c0 18.299 14.889 33.184 33.187 33.184h6.763c-18.295 0-33.181-14.886-33.181-33.185z"
    />
    <path
      fill="#6598CB"
      d="M238.375 327.246c0-.595-.017-1.177-.035-1.757-.004-.083-.002-.167-.006-.248-.013-.294-.036-.58-.056-.87-.021-.37-.041-.742-.072-1.108-.014-.153-.033-.298-.048-.449-.049-.497-.098-.993-.161-1.475-.004-.042-.013-.081-.019-.123-1.327-9.916-5.841-17.159-10.917-22.095-.022-.022-.047-.046-.069-.07-.267-.254-.533-.507-.801-.751-.132-.123-.266-.24-.401-.357-.155-.138-.311-.278-.466-.411-.257-.223-.516-.438-.774-.649-.029-.021-.058-.044-.085-.067-3.256-2.633-6.514-4.346-9.068-5.23-.012-.002-.023-.006-.034-.011l-.067-.025c-.009-.002-.018-.006-.018-.006l-33.54-13.447-8.377 8.694-8.568 8.346-8.565-8.346-8.378-8.694-33.534 13.445c-.006.002-.016.006-.016.006l-.075.027c-.012.004-.022.009-.032.011-2.558.884-5.814 2.598-9.07 5.23-.028.023-.058.046-.084.067-.259.21-.519.425-.776.649-.156.134-.312.273-.468.411-.134.116-.268.234-.4.357-.268.245-.534.497-.801.751-.024.025-.046.048-.071.07-5.075 4.936-9.59 12.178-10.916 22.095-.006.042-.013.081-.019.123-.064.482-.112.978-.161 1.475-.014.151-.034.296-.047.449-.031.365-.05.738-.073 1.108-.018.289-.042.576-.054.87-.004.081-.002.165-.006.248-.021.58-.037 1.162-.037 1.757v5.328h.002v16.288c.218 2.541 1.534 7.011 8.106 7.011h130.954c6.574 0 7.888-4.469 8.107-7.011v-21.616h-.004z"
    />
    <path
      fill="#4982AA"
      d="M147.686 278.475l-33.36 13.065-.104.035c-8.547 3.05-23.014 13.994-23.014 35.672v21.617c.218 2.541 1.534 7.011 8.106 7.011h1.69v-28.628c0-21.678 14.467-32.621 23.013-35.672l.106-.035 26.37-10.328-2.807-2.737z"
    />
    <path
      fill="#694B4B"
      d="M166.518 156.126h-3.615c-24.692 0-44.779 20.09-44.779 44.78v21.007s10.194-1.012 11.449-5.954l-.007-.006c.43-.468.79-1.002 1.046-1.598 5.782-.173 32.796-.383 48.223-19.016 1.839 5.385 8.069 15.583 20.052 18.797.247.648.613 1.235 1.062 1.742l-.098.081c1.252 4.943 11.448 5.954 11.448 5.954v-21.008c0-24.689-20.089-44.779-44.781-44.779zm33.762 56.616l.43-.311h.06l-.701.519.211-.208zm-.219.134l.049-.151.151-.294h.06l-.178.37-.082.075zm-1.189.921l-.016-.022 1.102-.799-.26.252-.807.595-.019-.026zm.912-.261l-.346.716c-.002 0-.002 0-.004-.002l-.051-.021c-.006-.004-.018-.009-.022-.013l.563-1.123.016-.013-.156.456z"
    />
    <path
      fill="#563B3B"
      d="M126.017 212.469v-11.562c0-23.974 18.939-43.603 42.64-44.726-.709-.033-1.421-.054-2.139-.054h-3.615c-24.692 0-44.779 20.09-44.779 44.78v21.007s4.505-.449 7.893-2.201v-7.244z"
    />
    <path
      fill="#FBCB99"
      d="M205.92 215.424c-1.713-.536-3.574-.223-5.02.836-1.442 1.062-2.297 2.75-2.297 4.544v16.067c0 1.792.854 3.478 2.297 4.54.981.72 2.154 1.095 3.342 1.095.561 0 1.127-.081 1.675-.254 1.082-.335 2.144-.823 3.157-1.444.595-.365 1.12-.841 1.541-1.396.622-.322 1.179-.755 1.644-1.277 2.276-2.577 3.533-5.88 3.533-9.301.001-6.18-3.966-11.572-9.872-13.41z"
    />
    <path
      fill="#EDBA89"
      d="M128.687 216.26c-1.444-1.06-3.31-1.373-5.021-.839-5.904 1.841-9.873 7.232-9.873 13.414 0 6.186 3.968 11.577 9.873 13.418.55.173 1.115.254 1.678.254 1.188 0 2.36-.376 3.343-1.095 1.444-1.062 2.297-2.747 2.297-4.54v-16.069c0-1.792-.854-3.481-2.297-4.543z"
    />
    <path
      fill="#FBCB99"
      d="M180.189 264.88c-1.11-1.062-2.596-1.619-4.142-1.563l-.193.011c-.345.013-.686.057-1.022.129-2.179.484-4.397.731-6.59.731h-6.893c-2.261 0-4.544-.259-6.789-.774-1.674-.386-3.432.015-4.774 1.085s-2.125 2.693-2.125 4.409v16.401l-.004.057c-.089 1.627.529 3.213 1.695 4.35l11.33 11.035c1.053 1.027 2.465 1.598 3.933 1.598h.091c1.5-.025 2.93-.644 3.97-1.727l11.672-12.116c1.012-1.049 1.578-2.449 1.578-3.908v-15.644c-.002-1.539-.629-3.012-1.737-4.074z"
    />
    <path
      fill="#EDBA89"
      d="M165.757 299.057l-11.328-11.035c-1.169-1.137-1.787-2.721-1.699-4.35l.007-.057v-16.4c0-1.444.557-2.817 1.533-3.853-1.586-.278-3.219.129-4.483 1.136-1.342 1.07-2.125 2.693-2.125 4.409v16.401l-.004.057c-.089 1.627.529 3.213 1.695 4.35l11.33 11.035c1.053 1.027 2.465 1.598 3.933 1.598h.091c1.5-.025 2.93-.644 3.97-1.727l.049-.052c-1.113-.193-2.15-.715-2.969-1.512z"
    />
    <path
      fill="#FBCB99"
      d="M197.755 210.82c-.683-.252-1.325-.549-1.954-.864-9.473-4.421-13.404-10.17-14.991-13.601-.093-.127-.186-.271-.284-.451-1.133-2.082-2.897-.764-2.897-.764l-.006-.004c-8.848 6.788-18.511 10.753-26.548 13.063-3.127.924-6.122 1.594-8.833 2.078-5.384.989-8.846 1.118-8.846 1.118-2.948.213-5.235 2.668-5.235 5.625v23.004c0 18.3 14.889 33.184 33.187 33.184h6.893c18.293 0 33.183-14.885 33.183-33.184v-23.921c0-2.354-1.466-4.46-3.669-5.283z"
    />
    <path
      fill="#EDBA89"
      d="M134.93 240.024V217.02c0-2.957 2.284-5.412 5.231-5.625 0 0 3.463-.129 8.847-1.118 2.71-.484 5.706-1.154 8.835-2.078 7.109-2.042 15.497-5.391 23.469-10.838-.189-.35-.355-.689-.503-1.005-.093-.127-.186-.271-.284-.451-1.133-2.082-2.897-.764-2.897-.764l-.006-.004c-8.848 6.788-18.511 10.753-26.548 13.063-3.127.924-6.122 1.594-8.833 2.078-5.384.989-8.846 1.118-8.846 1.118-2.948.213-5.235 2.668-5.235 5.625v23.004c0 18.3 14.889 33.184 33.187 33.184h6.763c-18.296 0-33.18-14.885-33.18-33.185z"
    />
    <path
      fill="#6598CB"
      d="M512 448.309c0-.595-.017-1.177-.035-1.757-.004-.081-.002-.167-.006-.248-.013-.294-.036-.579-.057-.871-.021-.37-.04-.742-.07-1.108-.015-.153-.034-.298-.048-.449-.05-.497-.098-.991-.161-1.474-.005-.042-.014-.082-.019-.124-1.326-9.916-5.84-17.159-10.916-22.094-.024-.023-.047-.046-.071-.069-.266-.255-.532-.51-.801-.753-.131-.122-.265-.239-.4-.357-.156-.138-.312-.278-.466-.411-.256-.222-.516-.437-.774-.647-.029-.021-.059-.045-.085-.068-3.257-2.633-6.515-4.346-9.069-5.23-.012-.002-.022-.007-.033-.011l-.067-.025c-.009-.002-.019-.005-.025-.007l-33.533-13.447-8.377 8.695-8.567 8.344-8.566-8.344-8.379-8.695-33.533 13.447c-.007.002-.016.005-.025.007l-.066.025c-.012.003-.022.009-.032.011-2.558.883-5.814 2.597-9.071 5.23-.027.022-.057.047-.084.068-.257.21-.518.425-.776.647-.156.134-.312.273-.466.411-.134.117-.268.235-.401.357-.268.244-.533.498-.8.753-.023.023-.046.047-.072.069-5.074 4.935-9.588 12.177-10.916 22.094-.006.042-.013.082-.018.124-.065.483-.112.977-.161 1.474-.014.151-.035.296-.048.449-.03.365-.049.738-.073 1.108-.017.291-.041.576-.054.871-.003.081-.001.167-.005.248-.021.58-.037 1.162-.037 1.757v5.329h.002v16.288c.218 2.54 1.533 7.011 8.106 7.011h130.949c6.574 0 7.889-4.471 8.108-7.011v-21.617H512z"
    />
    <path
      fill="#4982AA"
      d="M421.31 399.54l-33.36 13.063-.104.036c-8.547 3.049-23.014 13.993-23.014 35.67v21.618c.218 2.54 1.533 7.011 8.106 7.011h1.69V448.31c0-21.678 14.467-32.621 23.013-35.67l.105-.036 26.37-10.328-2.806-2.736z"
    />
    <path
      fill="#694B4B"
      d="M440.142 277.19h-3.616c-24.691 0-44.778 20.09-44.778 44.78v21.007s10.194-1.012 11.449-5.955l-.007-.005c.431-.468.79-1.003 1.046-1.598 5.783-.174 32.796-.385 48.223-19.016 1.841 5.385 8.07 15.583 20.052 18.796.248.65.614 1.235 1.063 1.743l-.098.08c1.252 4.944 11.448 5.955 11.448 5.955v-21.008c-.002-24.689-20.09-44.779-44.782-44.779zm33.763 56.616l.431-.312h.06l-.701.52.21-.208zm-.219.134l.048-.151.151-.295h.061l-.178.37-.082.076zm-1.19.92l-.016-.023 1.102-.798-.261.251-.805.597-.02-.027zm.913-.26l-.346.717-.004-.003-.051-.022c-.006-.003-.018-.009-.022-.013l.563-1.122.016-.014-.156.457z"
    />
    <path
      fill="#563B3B"
      d="M399.641 333.533v-11.561c0-23.974 18.939-43.604 42.64-44.725-.709-.034-1.421-.056-2.139-.056h-3.616c-24.691 0-44.778 20.09-44.778 44.78v21.007s4.505-.45 7.893-2.201v-7.244z"
    />
    <path
      fill="#FBCB99"
      d="M479.544 336.487c-1.712-.535-3.574-.222-5.02.839-1.443 1.062-2.297 2.749-2.297 4.542v16.067c0 1.792.852 3.478 2.297 4.54.98.721 2.154 1.096 3.341 1.096.562 0 1.128-.082 1.676-.255 1.082-.335 2.143-.823 3.157-1.444.595-.365 1.118-.841 1.541-1.395.622-.322 1.179-.755 1.644-1.278 2.276-2.577 3.533-5.88 3.533-9.299.001-6.183-3.966-11.574-9.872-13.413z"
    />
    <path
      fill="#EDBA89"
      d="M402.31 337.325c-1.445-1.061-3.309-1.374-5.021-.841-5.903 1.842-9.872 7.233-9.872 13.416 0 6.184 3.968 11.575 9.872 13.416.551.173 1.116.255 1.679.255 1.188 0 2.359-.376 3.341-1.096 1.444-1.062 2.297-2.747 2.297-4.54v-16.069c.001-1.791-.852-3.479-2.296-4.541z"
    />
    <path
      fill="#FBCB99"
      d="M453.812 385.943c-1.11-1.061-2.597-1.617-4.142-1.562l-.193.01c-.345.013-.687.057-1.023.13-2.178.484-4.396.732-6.59.732h-6.892c-2.261 0-4.544-.261-6.789-.774-1.674-.386-3.432.015-4.773 1.085-1.342 1.069-2.125 2.694-2.125 4.41v16.401l-.004.057c-.089 1.626.528 3.213 1.695 4.35l11.33 11.034c1.052 1.027 2.466 1.598 3.933 1.598h.09c1.5-.025 2.931-.645 3.971-1.726l11.672-12.116c1.012-1.05 1.578-2.451 1.578-3.909v-15.644c-.002-1.541-.629-3.015-1.738-4.076z"
    />
    <path
      fill="#EDBA89"
      d="M439.38 420.12l-11.328-11.034c-1.17-1.137-1.787-2.722-1.698-4.351l.006-.054v-16.402c0-1.443.557-2.817 1.532-3.853-1.585-.277-3.217.129-4.482 1.137-1.342 1.069-2.125 2.694-2.125 4.41v16.401l-.004.057c-.089 1.626.528 3.213 1.695 4.35l11.33 11.034c1.052 1.027 2.466 1.598 3.933 1.598h.09c1.5-.025 2.931-.645 3.971-1.726l.049-.053c-1.111-.195-2.148-.716-2.969-1.514z"
    />
    <path
      fill="#FBCB99"
      d="M471.378 331.884c-.682-.252-1.324-.549-1.953-.865-9.474-4.421-13.405-10.17-14.991-13.6-.093-.128-.186-.272-.284-.45-1.133-2.083-2.898-.765-2.898-.765l-.006-.004c-8.848 6.786-18.51 10.753-26.547 13.063-3.127.924-6.123 1.593-8.833 2.078-5.384.989-8.846 1.117-8.846 1.117-2.949.213-5.235 2.67-5.235 5.626v23.004c0 18.299 14.89 33.185 33.187 33.185h6.892c18.295 0 33.184-14.886 33.184-33.185v-23.922c0-2.353-1.465-4.46-3.67-5.282z"
    />
    <path
      fill="#EDBA89"
      d="M408.553 361.088v-23.004c0-2.957 2.284-5.413 5.231-5.626 0 0 3.463-.128 8.847-1.117 2.71-.485 5.707-1.154 8.835-2.078 7.11-2.044 15.498-5.392 23.469-10.839-.189-.351-.355-.689-.502-1.005-.093-.128-.186-.272-.284-.45-1.133-2.083-2.898-.765-2.898-.765l-.006-.004c-8.848 6.786-18.51 10.753-26.547 13.063-3.127.924-6.123 1.593-8.833 2.078-5.384.989-8.846 1.117-8.846 1.117-2.949.213-5.235 2.669-5.235 5.626v23.004c0 18.299 14.89 33.185 33.187 33.185h6.762c-18.294 0-33.18-14.886-33.18-33.185z"
    />
    <path
      fill="#6598CB"
      d="M329.584 448.309c0-.595-.017-1.177-.035-1.757-.004-.081-.002-.167-.006-.248-.013-.294-.036-.579-.056-.871-.024-.37-.041-.742-.072-1.108-.015-.153-.033-.298-.049-.449-.049-.497-.097-.991-.16-1.474-.004-.042-.013-.082-.019-.124-1.328-9.916-5.842-17.159-10.917-22.094-.022-.023-.047-.046-.069-.069-.267-.255-.533-.51-.802-.753-.131-.122-.265-.239-.401-.357-.155-.138-.311-.278-.465-.411-.256-.222-.515-.437-.776-.647-.028-.021-.058-.045-.084-.068-3.257-2.633-6.516-4.346-9.069-5.23-.013-.002-.022-.007-.033-.011l-.068-.025c-.009-.002-.017-.005-.022-.007l-33.535-13.447-8.376 8.695-8.57 8.344-8.565-8.344-8.378-8.695-33.534 13.447s-.016.005-.026.007l-.065.025c-.011.003-.022.009-.022.009-2.566.886-5.823 2.599-9.079 5.231-.028.022-.058.047-.084.068-.259.21-.519.425-.776.647-.158.134-.314.273-.469.411-.134.117-.267.235-.401.357-.266.244-.533.498-.8.753-.022.023-.046.047-.072.069-5.073 4.935-9.587 12.177-10.914 22.094-.006.042-.014.082-.02.124-.063.483-.111.977-.16 1.474-.015.151-.036.296-.049.449-.029.365-.048.738-.072 1.108-.017.291-.042.576-.056.871-.002.081 0 .167-.004.248-.021.58-.037 1.162-.037 1.757v5.329h.002v16.288c.218 2.54 1.534 7.011 8.106 7.011h130.953c6.574 0 7.887-4.471 8.107-7.011v-16.288l-.001-5.328z"
    />
    <path
      fill="#4982AA"
      d="M238.893 399.54l-33.358 13.063-.105.036c-8.546 3.049-23.013 13.993-23.013 35.67v21.618c.218 2.54 1.534 7.011 8.106 7.011h1.689V448.31c0-21.678 14.468-32.621 23.013-35.67l.107-.036 26.369-10.328-2.808-2.736z"
    />
    <path
      fill="#694B4B"
      d="M257.727 277.19h-3.616c-24.692 0-44.779 20.09-44.779 44.78v21.007s10.195-1.012 11.45-5.955l-.009-.005c.432-.468.792-1.003 1.046-1.598 5.781-.174 32.797-.385 48.224-19.016 1.839 5.385 8.069 15.583 20.051 18.796.248.65.613 1.235 1.062 1.743l-.099.08c1.253 4.944 11.448 5.955 11.448 5.955v-21.008c.002-24.689-20.088-44.779-44.778-44.779zm33.761 56.616l.432-.312h.06l-.701.52.209-.208zm-.218.134l.048-.151.152-.295h.06l-.178.37-.082.076zm-1.19.92l-.015-.023 1.102-.798-.26.251-.807.597-.02-.027zm.912-.26l-.345.717s-.002-.002-.004-.003l-.051-.022c-.006-.003-.019-.009-.023-.013l.563-1.122.015-.014-.155.457z"
    />
    <path
      fill="#563B3B"
      d="M217.225 333.533v-11.561c0-23.974 18.94-43.604 42.641-44.725-.709-.034-1.421-.056-2.139-.056h-3.616c-24.692 0-44.779 20.09-44.779 44.78v21.007s4.506-.45 7.893-2.201v-7.244z"
    />
    <path
      fill="#FBCB99"
      d="M297.128 336.487c-1.713-.535-3.574-.222-5.02.839-1.442 1.062-2.297 2.749-2.297 4.542v16.067c0 1.792.854 3.478 2.297 4.54.98.721 2.154 1.096 3.34 1.096.563 0 1.129-.082 1.677-.255 1.082-.335 2.143-.823 3.157-1.444.595-.365 1.118-.841 1.541-1.395.622-.322 1.179-.755 1.643-1.278 2.277-2.577 3.534-5.88 3.534-9.299.002-6.183-3.967-11.574-9.872-13.413z"
    />
    <path
      fill="#EDBA89"
      d="M219.896 337.325c-1.446-1.061-3.31-1.374-5.023-.841-5.902 1.842-9.87 7.233-9.87 13.416 0 6.184 3.968 11.575 9.87 13.416.551.173 1.116.255 1.679.255 1.187 0 2.361-.376 3.344-1.096 1.444-1.062 2.297-2.747 2.297-4.54v-16.069c0-1.791-.854-3.479-2.297-4.541z"
    />
    <path
      fill="#FBCB99"
      d="M271.396 385.943c-1.11-1.061-2.597-1.617-4.142-1.562l-.193.01c-.345.013-.687.057-1.022.13-2.179.484-4.398.732-6.592.732h-6.891c-2.261 0-4.544-.261-6.789-.774-1.674-.386-3.432.015-4.775 1.085-1.341 1.069-2.124 2.694-2.124 4.41v16.401l-.004.057c-.089 1.626.528 3.213 1.694 4.35l11.329 11.034c1.054 1.027 2.468 1.598 3.935 1.598h.091c1.499-.025 2.93-.645 3.967-1.726l11.674-12.116c1.012-1.05 1.577-2.451 1.577-3.909v-15.644c.001-1.541-.625-3.015-1.735-4.076z"
    />
    <path
      fill="#EDBA89"
      d="M256.966 420.12l-11.329-11.034c-1.169-1.137-1.787-2.722-1.699-4.351l.006-.054v-16.402c0-1.443.557-2.817 1.534-3.853-1.588-.277-3.219.129-4.484 1.137-1.341 1.069-2.124 2.694-2.124 4.41v16.401l-.004.057c-.089 1.626.528 3.213 1.694 4.35l11.329 11.034c1.054 1.027 2.468 1.598 3.935 1.598h.091c1.499-.025 2.93-.645 3.967-1.726l.051-.053c-1.111-.195-2.149-.716-2.967-1.514z"
    />
    <path
      fill="#FBCB99"
      d="M288.962 331.884c-.683-.252-1.324-.549-1.953-.865-9.474-4.421-13.405-10.17-14.991-13.6-.093-.128-.186-.272-.284-.45-1.133-2.083-2.898-.765-2.898-.765l-.006-.004c-8.848 6.786-18.512 10.753-26.548 13.063-3.126.924-6.121 1.593-8.832 2.078-5.384.989-8.848 1.117-8.848 1.117-2.947.213-5.233 2.67-5.233 5.626v23.004c0 18.299 14.889 33.185 33.187 33.185h6.891c18.296 0 33.184-14.886 33.184-33.185v-23.922c.001-2.353-1.464-4.46-3.669-5.282z"
    />
    <path
      fill="#EDBA89"
      d="M226.136 361.088v-23.004c0-2.957 2.285-5.413 5.231-5.626 0 0 3.464-.128 8.848-1.117 2.71-.485 5.706-1.154 8.835-2.078 7.109-2.044 15.497-5.392 23.469-10.839-.189-.351-.357-.689-.503-1.005-.093-.128-.186-.272-.284-.45-1.133-2.083-2.898-.765-2.898-.765l-.006-.004c-8.848 6.786-18.512 10.753-26.548 13.063-3.126.924-6.121 1.593-8.832 2.078-5.384.989-8.848 1.117-8.848 1.117-2.947.213-5.233 2.669-5.233 5.626v23.004c0 18.299 14.889 33.185 33.187 33.185h6.762c-18.293 0-33.18-14.886-33.18-33.185z"
    />
    <path
      fill="#6598CB"
      d="M147.169 448.309c0-.595-.018-1.177-.036-1.757-.004-.081-.002-.167-.006-.248-.013-.294-.036-.579-.056-.871-.022-.37-.04-.742-.072-1.108-.014-.153-.034-.298-.047-.449-.05-.497-.099-.991-.162-1.474-.004-.042-.013-.082-.019-.124-1.327-9.916-5.841-17.159-10.917-22.094-.022-.023-.047-.046-.069-.069-.267-.255-.532-.51-.801-.753-.132-.122-.266-.239-.401-.357-.155-.138-.311-.278-.466-.411-.257-.222-.515-.437-.776-.647-.028-.021-.058-.045-.085-.068-3.256-2.633-6.515-4.346-9.068-5.23-.013-.002-.022-.007-.034-.011l-.067-.025c-.009-.002-.018-.005-.024-.007L90.53 399.159l-8.378 8.695-8.568 8.344-8.566-8.344-8.378-8.695-33.533 13.447c-.009.002-.017.005-.026.007l-.066.025c-.012.003-.022.009-.032.011-2.558.883-5.813 2.597-9.07 5.23-.027.022-.058.047-.084.068-.259.21-.518.425-.776.647-.156.134-.313.273-.468.411-.134.117-.267.235-.4.357-.268.244-.534.498-.8.753-.025.023-.047.047-.073.069-5.074 4.935-9.588 12.177-10.915 22.094-.007.042-.014.082-.018.124-.065.483-.113.977-.162 1.474-.014.151-.034.296-.048.449-.03.365-.049.738-.073 1.108-.017.291-.041.576-.053.871-.003.081-.001.167-.005.248-.021.58-.038 1.162-.038 1.757v5.329h.002v16.288c.218 2.54 1.535 7.011 8.107 7.011h130.953c6.574 0 7.889-4.471 8.108-7.011v-21.617z"
    />
    <path
      fill="#4982AA"
      d="M56.479 399.54l-33.36 13.063-.104.036C14.469 415.688.001 426.632.001 448.309v21.618c.218 2.54 1.535 7.011 8.107 7.011h1.689V448.31c0-21.678 14.467-32.621 23.014-35.67l.105-.036 26.37-10.328-2.807-2.736z"
    />
    <path
      fill="#694B4B"
      d="M75.312 277.19h-3.616c-24.692 0-44.779 20.09-44.779 44.78v21.007s10.194-1.012 11.449-5.955l-.007-.005c.431-.468.79-1.003 1.046-1.598 5.782-.174 32.796-.385 48.223-19.016 1.841 5.385 8.069 15.583 20.051 18.796.248.65.614 1.235 1.064 1.743l-.099.08c1.253 4.944 11.449 5.955 11.449 5.955v-21.008c-.001-24.689-20.091-44.779-44.781-44.779zm33.76 56.616l.43-.312h.06l-.701.52.211-.208zm-.219.134l.049-.151.151-.295h.061l-.179.37-.082.076zm-1.188.92l-.017-.023 1.103-.798-.261.251-.807.597-.018-.027zm.912-.26l-.346.717-.004-.003-.05-.022c-.006-.003-.019-.009-.023-.013l.563-1.122.016-.014-.156.457z"
    />
    <path
      fill="#563B3B"
      d="M34.811 333.533v-11.561c0-23.974 18.939-43.604 42.64-44.725-.709-.034-1.421-.056-2.139-.056h-3.616c-24.692 0-44.779 20.09-44.779 44.78v21.007s4.505-.45 7.894-2.201v-7.244z"
    />
    <path
      fill="#FBCB99"
      d="M114.712 336.487c-1.713-.535-3.574-.222-5.02.839-1.443 1.062-2.297 2.749-2.297 4.542v16.067c0 1.792.852 3.478 2.297 4.54.98.721 2.154 1.096 3.341 1.096.563 0 1.128-.082 1.676-.255 1.082-.335 2.144-.823 3.157-1.444.596-.365 1.12-.841 1.541-1.395.623-.322 1.179-.755 1.644-1.278 2.277-2.577 3.533-5.88 3.533-9.299.001-6.183-3.965-11.574-9.872-13.413z"
    />
    <path
      fill="#EDBA89"
      d="M37.479 337.325c-1.446-1.061-3.309-1.374-5.022-.841-5.903 1.842-9.872 7.233-9.872 13.416 0 6.184 3.968 11.575 9.872 13.416.551.173 1.116.255 1.679.255 1.188 0 2.36-.376 3.343-1.096 1.444-1.062 2.297-2.747 2.297-4.54v-16.069c0-1.791-.853-3.479-2.297-4.541z"
    />
    <path
      fill="#FBCB99"
      d="M88.981 385.943c-1.11-1.061-2.597-1.617-4.142-1.562l-.193.01c-.345.013-.687.057-1.022.13-2.179.484-4.397.732-6.591.732H70.14c-2.26 0-4.543-.261-6.789-.774-1.675-.386-3.432.015-4.774 1.085-1.342 1.069-2.124 2.694-2.124 4.41v16.401l-.004.057c-.09 1.626.528 3.213 1.694 4.35l11.33 11.034c1.052 1.027 2.465 1.598 3.933 1.598h.091c1.5-.025 2.93-.645 3.97-1.726l11.672-12.116c1.012-1.05 1.578-2.451 1.578-3.909v-15.644c0-1.541-.628-3.015-1.736-4.076z"
    />
    <path
      fill="#EDBA89"
      d="M74.549 420.12L63.22 409.086c-1.169-1.137-1.787-2.722-1.698-4.351l.006-.054v-16.402c0-1.443.557-2.817 1.534-3.853-1.586-.277-3.219.129-4.483 1.137-1.342 1.069-2.124 2.694-2.124 4.41v16.401l-.004.057c-.09 1.626.528 3.213 1.694 4.35l11.33 11.034c1.052 1.027 2.465 1.598 3.933 1.598h.091c1.5-.025 2.93-.645 3.97-1.726l.049-.053c-1.113-.195-2.149-.716-2.969-1.514z"
    />
    <path
      fill="#FBCB99"
      d="M106.547 331.884c-.683-.252-1.325-.549-1.953-.865-9.474-4.421-13.405-10.17-14.991-13.6-.094-.128-.187-.272-.284-.45-1.133-2.083-2.898-.765-2.898-.765l-.006-.004c-8.848 6.786-18.511 10.753-26.548 13.063-3.126.924-6.121 1.593-8.831 2.078-5.385.989-8.847 1.117-8.847 1.117-2.949.213-5.235 2.67-5.235 5.626v23.004c0 18.299 14.889 33.185 33.187 33.185h6.892c18.296 0 33.183-14.886 33.183-33.185v-23.922c0-2.353-1.463-4.46-3.669-5.282z"
    />
    <path
      fill="#EDBA89"
      d="M43.722 361.088v-23.004c0-2.957 2.284-5.413 5.233-5.626 0 0 3.462-.128 8.846-1.117 2.71-.485 5.707-1.154 8.836-2.078 7.11-2.044 15.497-5.392 23.468-10.839-.188-.351-.355-.689-.501-1.005-.094-.128-.187-.272-.284-.45-1.133-2.083-2.898-.765-2.898-.765l-.006-.004c-8.848 6.786-18.511 10.753-26.548 13.063-3.126.924-6.121 1.593-8.831 2.078-5.385.989-8.847 1.117-8.847 1.117-2.949.213-5.235 2.669-5.235 5.626v23.004c0 18.299 14.889 33.185 33.187 33.185h6.763c-18.297 0-33.183-14.886-33.183-33.185z"
    />
  </svg>
);

IconEvaluated.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconEvaluated;
