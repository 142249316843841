import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';

import clone from '../../utils/deepClone';

const initialState = Immutable.from({
  evaluatedWorkouts: [],
  evaluatedWorkoutSelected: {},
  loading: false,
  shouldGetEvaluatedWorkouts: true
});

export default function evaluatedWorkouts(state = initialState, action = {}) {
  switch (action.type) {
    case Actions.GET_EVALUATED_WORKOUTS_PENDING: {
      const newState = state.updateIn(['loading'], () => true);
      return newState;
    }
    case Actions.GET_EVALUATED_WORKOUTS_REJECTED: {
      const newState = state.updateIn(['loading'], () => false);
      return newState;
    }
    case Actions.GET_EVALUATED_WORKOUTS: {
      const evaluatedWorkouts = state
        .updateIn(['evaluatedWorkouts'], () => action.payload)
        .updateIn(['loading'], () => false)
        .updateIn(['shouldGetEvaluatedWorkouts'], () => false);
      return evaluatedWorkouts;
    }
    case Actions.ADD_REGISTER_EVALUATED_WORKOUT: {
      const newState = Immutable.merge({
        ...state,
        evaluatedWorkouts: [...state.evaluatedWorkouts, action.payload]
      });
      return newState;
    }
    case Actions.REGISTER_SELECTED_EVALUATED_WORKOUT: {
      const evaluatedWorkoutSelected = state.set('evaluatedWorkoutSelected', action.payload);
      return evaluatedWorkoutSelected;
    }
    case Actions.UPDATE_REGISTER_EVALUATED_WORKOUT: {
      const evaluatedWorkouts = state.evaluatedWorkouts;
      for (const workout of evaluatedWorkouts) {
        if (workout.id === action.payload.id) {
          Object.assign(clone(workout), clone(action.payload));
          break;
        }
      }
      const newState = state.updateIn(['evaluatedWorkouts'], () => evaluatedWorkouts);
      return newState;
    }
    case Actions.DELETE_REGISTER_EVALUATED_WORKOUT: {
      const evaluatedWorkouts = state.evaluatedWorkouts.filter((workout) => workout.id !== action.payload.id);
      const newState = state.updateIn(['evaluatedWorkouts'], () => evaluatedWorkouts);
      return newState;
    }
    default:
      return state;
  }
}
