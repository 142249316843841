import React from 'react';

const IconAvatarBlue = (props) => {
  return (
    <img
      src={require(`../../images/avatar-blue.png`)}
      width="125"
      className="icon-avatar icon-avatar--blue"
      alt="Avatar"
    />
  );
};

export default IconAvatarBlue;
