import * as Actions from '../../constant/constants';
import socket from '../../socket';

function compareQuestions(a, b) {
  if (a.question.description.toLowerCase() < b.question.description.toLowerCase()) {
    return -1;
  }
  if (a.question.description.toLowerCase() > b.question.description.toLowerCase()) {
    return 1;
  }
  return 0;
}

function compareTemplates(a, b) {
  if (a.template.title.toLowerCase() < b.template.title.toLowerCase()) {
    return -1;
  }
  if (a.template.title.toLowerCase() > b.template.title.toLowerCase()) {
    return 1;
  }
  return 0;
}

/** Add Question
 * Envia para a API a nova questão, para ser adicionada
 */
export function addQuestion(data = {}) {
  return {
    type: Actions.ADD_REQUEST,
    payload: socket.sendMessage(Actions.ADD_ANAMNESIS_QUESTION, { ...data.question }),
  };
}

export function setOneQuestion(event) {
  let question = {};
  if (typeof event !== 'undefined') {
    question = event.data;
  }
  return {
    type: Actions.ADD_ANAMNESIS_QUESTION,
    payload: question,
    sortQuestions: compareQuestions,
  };
}

/** Update Question
 * Envia para a API as informações atualizadas
 */
export function updateQuestion(data = {}) {
  return {
    type: Actions.UPDATE_REQUEST,
    payload: socket.sendMessage(Actions.UPDATE_ANAMNESIS_QUESTION, { ...data.question }),
  };
}

export function setUpdatedQuestion(event) {
  return {
    type: Actions.UPDATE_ANAMNESIS_QUESTION,
    payload: event.data,
  };
}

export function setUpdateTemplate(event) {
  return {
    type: Actions.UPDATE_ANAMNESIS_TEMPLATE,
    payload: event.data,
  };
}

/** Get All Questions
 * Solicita para a API as perguntas
 */
export function getAllQuestions() {
  return {
    type: Actions.GET_ANAMNESIS_QUESTIONS,
    ws: socket.sendMessage(Actions.GET_ANAMNESIS_QUESTIONS, []),
  };
}

/** Select Register
 * Seleciona o registro que será tratado
 */
export function selectRegister(question = '', options = '', template = '') {
  let typeRegister;
  if (question !== '') {
    typeRegister = Actions.GET_ANAMNESIS_QUESTION;
  } else {
    typeRegister = Actions.GET_ANAMNESIS_TEMPLATE;
  }
  return {
    type: typeRegister,
    payload: { question, options, template },
  };
}

/** Delete Question
 * Envia para a API a solicitação de mudança de estado da pergunta
 */
export function deleteQuestion(id = -666) {
  return {
    type: Actions.REMOVE_ANAMNESIS_QUESTION,
    ws: socket.sendMessage(Actions.REMOVE_ANAMNESIS_QUESTION, { idquestion: id }),
    payload: id,
  };
}

/** Set Question
 *  Recebe as perguntas que foram solicitadas pelo Get
 */
export function setQuestions(event) {
  let questions = [];
  if (typeof event === 'undefined') {
    socket.sendMessage(Actions.GET_ANAMNESIS_QUESTIONS, []);
  } else {
    questions = event.data;
    if (questions !== null) {
      questions.sort(compareQuestions);
    } else if (questions === null) {
      questions = [];
    }
  }
  return {
    type: Actions.GET_ANAMNESIS_QUESTIONS,
    payload: questions,
  };
}

export function setTemplates(event) {
  let templates = [];
  if (typeof event === 'undefined') {
    socket.sendMessage(Actions.GET_ANAMNESIS_TEMPLATES, []);
  } else {
    templates = event.data;
    if (templates !== null) {
      templates.sort(compareTemplates);
    } else if (templates === null) {
      templates = [];
    }
  }
  return {
    type: Actions.GET_ANAMNESIS_TEMPLATES,
    payload: templates,
  };
}

/** Should Get Anamnesis
 * Verifica se pode solicitar o Get de Anamnesis
 */
export function shouldGetAnamnesis() {
  return {
    type: Actions.SHOULD_GET_ANAMNESIS_TEMPLATE,
  };
}

/**
 * ### TEMPLATES ###
 */
export function addTemplate(data = {}, isPromisse = false) {
  if (!isPromisse) {
    return {
      type: Actions.ADD_REQUEST,
      payload: socket.sendMessage(Actions.ADD_ANAMNESIS_TEMPLATE, { ...data.template }),
    };
  }
  return {
    type: Actions.ADD_ANAMNESIS_TEMPLATE,
  };
}

export function setOneTemplate(event) {
  let template = {};
  if (typeof event !== 'undefined') {
    template = event.data;
  }
  return {
    type: Actions.ADD_ANAMNESIS_TEMPLATE,
    payload: template,
    sortTemplates: compareTemplates,
  };
}

/** Get All Templates
 * Solicita para a API os modelos
 */
export function getAllTemplates() {
  return {
    type: Actions.GET_ANAMNESIS_TEMPLATES,
    ws: socket.sendMessage(Actions.GET_ANAMNESIS_TEMPLATES, []),
  };
}

/** Update Template
 * Envia para a API as informações atualizadas
 */
export function updateTemplate(data) {
  return {
    type: Actions.UPDATE_REQUEST,
    payload: socket.sendMessage(Actions.UPDATE_ANAMNESIS_TEMPLATE, { ...data.template }),
  };
}

/** Delete Template
 * Envia para a API a solicitação de mudança de estado do modelo
 */
export function deleteTemplate(id) {
  return {
    type: Actions.REMOVE_ANAMNESIS_TEMPLATE,
    ws: socket.sendMessage(Actions.REMOVE_ANAMNESIS_TEMPLATE, { id }),
    payload: id,
  };
}
