import React from 'react';

import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const AlertPaymentProblem = ({ animation = 'alert-open', onClose }) => (
  <div className={`alert-container ${animation}`}>
    <div
      role="alert"
      style={{ zIndex: 10 }}
      className="alert alert-danger col-12 shadow-lg p-2 mb-5 rounded d-flex justify-content-between align-items-center"
    >
      <div className="box-alert-payment-problem mr-2">
        <p className="m-0 p-0 text-center" style={{ maxWidth: '65%' }}>
          <FormattedMessage
            id="message.error.subscription.payment"
            defaultMessage="Há um problema com os dados de pagamento informados em sua conta. Escolha uma das ações para ajustar o pagamento do seu plano."
          />
        </p>

        <div className="mt-1 box-btn-alert-payment-problem">
          <Link to="/UpdatePaymentData" className="btn btn-danger btn-alert-payment-problem" role="button" onClick={onClose}>
            <p className="m-0 p-0">
              <FormattedMessage id="app.myaccount.plan.button.updatecreditcarddetails" defaultMessage="Atualizar dados de pagamento" />
            </p>
          </Link>
        </div>
      </div>
      <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={onClose}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
);

export default AlertPaymentProblem;
