import Immutable from 'seamless-immutable';
import * as Actions from '../../constant/constants';

const initialState = Immutable.from({
  featureViewed: [],
  loading: false,
  shouldGetFeatureViewed: true,
  hasNewFeatures: false,
});

export default function featureViewed(state = initialState, action) {
  switch (action.type) {
    case Actions.GET_FEATURES_VIEWED_BY_PERSON_PENDING: {
      const loading = state.updateIn(['loading'], () => true);
      return loading;
    }
    case Actions.GET_FEATURES_VIEWED_BY_PERSON_REJECTED: {
      const rej = state.updateIn(['loading'], () => false);
      return rej;
    }
    case Actions.GET_FEATURES_VIEWED_BY_PERSON: {
      const newState = state
        .updateIn(['featureViewed'], () => action.payload)
        .updateIn(['loading'], () => false)
        .updateIn(['shouldGetFeatureViewed'], () => false);
      return newState;
    }
    case Actions.SET_NEW_FEATURES: {
      const newState = state.updateIn(['hasNewFeatures'], () => action.payload);
      return newState;
    }
    default:
      return state;
  }
}
