import axios from 'axios';
import { routerActions } from 'react-router-redux';
import Axios from 'axios';
import moment from 'moment';
import { updateIntl } from 'react-intl-redux';
import socket from '../../socket';
import * as Actions from '../../constant/constants';
import { Err, Log } from '../../utils/conlog';
import {
  deleteCookie,
  getProfilePermissionLevel,
} from '../../utils/functions';
import messages from '../../i18n/messages';
import { parseUnauthorizedLoginReason } from './loginPolice';

// GET PUBLIC KEY
export function getKey(onSuccess = () => {}) {
  return {
    type: Actions.GET_PUBLIC_KEY,
    payload: axios.get(`${Actions.URL_SERVER}/login/public_key`).then(onSuccess()),
  };
}

// um action listener genérico para operações que causam uma alteração na prop plan do state
export function subscriptionStateUpdate(action, payload) {
  return {
    type: action,
    payload,
  };
}

// LOGIN

/* RECEBE LOGIN,
ENCRIPITA PASSWORD,
DISPARA A FUNCAO LOGIN */
export function requestLogin(user, pass, platform, publicKey, connected, redirectTo) {
  return login(user, pass, platform, connected, redirectTo);
}

export function openNewUserRequest() {
  return (dispatch) => {
    dispatch({
      type: Actions.NEW_ACCOUNT_REQUEST,
      payload: true,
    });
  };
}

export function closeNewUserRequest() {
  return (dispatch) => {
    dispatch({
      type: Actions.NEW_ACCOUNT_REQUEST,
      payload: false,
    });
  };
}

export function allowAccessToThePlansModule(data = true) {
  return {
    type: Actions.ACCESS_CONTROL_PLANS_MODULE,
    payload: data,
  };
}

export function enableFreeTrialPeriod(data) {
  return {
    type: Actions.ENABLE_FREE_TRIAL_PERIOD,
    payload: data,
  }
}

export function findForActivePlanByClientId(id) {
  return (dispatch) => {
    dispatch({
      type: Actions.GET_CLIENT_PLAN,
      payload: socket.sendMessage(Actions.GET_CLIENT_PLAN, { idcliente: id }),
    });
  };
}

export function findForActivePlanByClientIdResponse(data) {
  return {
    type: Actions.GET_CLIENT_PLAN_REQUEST_FULFILLED,
    payload: data,
  };
}

export function changingTheRegistrationPhase(data) {
  return {
    type: Actions.CREATE_CLIENT_PLAN_FULFILLED,
    payload: data,
  };
}

export function sendNewUserRequest(email, name) {
  return (dispatch) => {
    dispatch({
      type: Actions.SEND_NEW_ACCOUNT_REQUEST,
      payload: Axios({
        method: 'POST',
        url: `${Actions.URL_SERVER}/api/register/generateToken`,
        data: {
          email,
          name,
        },
      })
        .then((response) => {
          if (response.data.status === 200) {
            Log(response);
            dispatch(closeNewUserRequest());
            dispatch({
              type: Actions.NEW_REGISTER_SUCCESS,
              payload: true,
            });
          } else {
            Err(response);
            dispatch(userHasAlreadyBeenRegistered(response));
          }
        })
        .catch((error) => {
          Err(error);
          dispatch(userHasAlreadyBeenRegistered(error));
        }),
    });
  };
}

export function closeAnotherSession(user, pass, platform, connected, redirect) {
  return (dispatch) => {
    dispatch({
      type: Actions.CLOSE_SESSION,
      payload: axios({
        method: 'DELETE',
        url: `${Actions.URL_SERVER}/close`,
        data: {
          username: user,
          password: pass,
          platform,
          keepconnection: connected,
        },
      })
        .then((response) => {
          socket.newConnection(response.data);
          dispatch(loginUserSuccess(response.data, connected));
          dispatch(routerActions.push(redirect));
        })
        .catch((err) => {
          const defaultCase = () => dispatch(
            loginUnauthorized(err, err.response.data?.reason, user, pass, connected),
          );

          parseUnauthorizedLoginReason(
            { err, user, pass, connected, reason: err.response.data?.reason },
            dispatch,
            defaultCase,
          );
        }),
    });
  };
}

export function forgotPassword(email, tokenGoogle) {
  return function (dispatch) {
    dispatch({
      type: Actions.ASK_PASSWORD,
      payload: axios({
        method: 'post',
        url: `${Actions.URL_SERVER}/forgotpassword/ask`,
        data: {
          username: email,
          'g-recaptcha-response': tokenGoogle,
        },
      })
        .then((response) => {
          dispatch(responseEmailForgotPassword(response.data));
        })
        .catch((error) => {
          Err(error);
        }),
    });
  };
}

export function responseEmailForgotPassword(response) {
  return { type: Actions.RESPONSE_EMAIL_FORGOT_PASSWORD, payload: response };
}

/* RECEBE USUARIO,
SENHA,
LEMBRAR-ME,
REDIRECT,
DISPACHA A FUNCTION DE LOADING E
FAZ POST PRO LOGIN NO SERVIDOR */
export function login(user, pass, platform, connected, redirect = '/') {
  return (dispatch) => {
    dispatch(loginUserRequest());
    dispatch({
      type: Actions.LOGIN,
      payload: axios({
        method: 'post',
        url: `${Actions.URL_SERVER}/login`,
        data: {
          username: user,
          password: pass,
          platform,
          keepconnection: connected,
        },
      })
        .then((res) => {
          const profilePermission = getProfilePermissionLevel(
            res.data.permission,
            res.data.person,
          );

          socket.newConnection(res.data);
          dispatch(loginUserSuccess(res.data, connected));
          if (profilePermission.rule !== 'sysadmin') {
            dispatch(findForActivePlanByClientId(res.data.permissions[0].client.id));
          }
          dispatch(routerActions.push(redirect));
        })
        .catch((err) => {
          const defaultCase = () => dispatch(
            loginUserFailure({
              response: {
                status: Actions.RESPONSE_CODES_FAILED,
                statusText: 'Invalid token',
              },
            }),
          );

          parseUnauthorizedLoginReason(
            { err, user, pass, connected, reason: err.response.data.reason },
            dispatch,
            defaultCase,
          );
        }),
    });
  };
}

// LOGIN UNAUTHORIZED
export function loginUnauthorized(error, reason = '', user, pass, connected) {
  return {
    type: Actions.RESPONSE_CODES_UNAUTHORIZED,
    payload: {
      error,
      unauthorizedReason: reason,
      user,
      pass,
      connected,
    },
  };
}

export function userHasAlreadyBeenRegistered(error) {
  return {
    type: Actions.USER_HAS_ALREADY_BEEN_REGISTERED,
    payload: {
      error,
    },
  };
}

// LOGIN SUCCESS
export function loginUserSuccess(data) {
  const { locale } = data.person;

  localStorage.setItem('token', data.token);

  if (locale) {
    localStorage.setItem('locale', locale);
  }

  return (dispatch) => {
    dispatch({
      type: Actions.LOGIN_FULFILLED,
      payload: data,
    });
    // Descomentar quando for mexer nas traduções
    // QUEBRA O BROADCAST CASO ESTEJA DESCOMENTADO
    dispatch(updateIntl({ locale, messages: messages[locale] }));
    window.localStorage.setItem('locale', locale);
    moment.locale(locale);
    // dispatch({
    //   type: Actions.SET_LOCALE,
    //   payload: socket.sendMessage(Actions.SET_LOCALE, {
    //     locale: locale,
    //     idperson: data.person.id
    //   })
    // });
  };
}

// TODO: Verificar se é necessário excluir o cookie do token nesta função
export function reissueToken(data) {
  localStorage.setItem('token', data.token);
  return { type: Actions.LOGIN_FULFILLED, payload: data };
}

export function updateUser(data) {
  return { type: Actions.UPDATE_PROFILE, payload: data };
}

export function updateUserLocale(event) {
  return { type: Actions.UPDATE_USER_LOCALE, payload: event.data };
}

// SET PICTURE
export function setPicture(response, img, token) {
  const verify =
    /^\s*data:([a-z]+\/[a-z0-9\-+]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,[a-z0-9!$&',()*+,;=\-._~:@/?%\s]*\s*$/i;
  const test = verify.test(img);
  let data;
  data = response;
  return (dispatch) => {
    if (test) {
      dispatch({
        type: Actions.SET_PICTURE_REQUEST,
        payload: axios({
          method: 'post',
          url: `${Actions.URL_SERVER}/profile/${data.id}`,
          data: {
            idperson: data.id,
            token,
            img,
          },
        })
          .then((response) => {
            dispatch(updateUser(response.data));
          })
          .catch((error) => {
            Err(error);
          }),
      });
    } else {
      dispatch(updateUser(data));
    }
  };
}

// REMOVE PICTURE
export function removePicture(response, token) {
  return function (dispatch) {
    dispatch({
      type: Actions.SET_PICTURE_REQUEST,
      payload: axios({
        method: 'post',
        url: `${Actions.URL_SERVER}/profile/${response.id}`,
        data: {
          idperson: response.id,
          token,
          img: '',
        },
      })
        .then((response) => {
          dispatch(updateUser(response.data));
        })
        .catch((error) => {
          Err(error);
        }),
    });
  };
}

// LOADING
export function loginUserRequest() {
  return { type: Actions.LOGIN_USER_REQUEST };
}

// LOGIN ERROR
export function loginUserFailure(error) {
  localStorage.removeItem('token');
  deleteCookie('Authorization');
  return {
    type: Actions.LOGIN_USER_FAILURE,
    payload: {
      status: error.response.status,
      statusText: error.response.statusText,
    },
  };
}

// LOGOUT
export function logout() {
  localStorage.removeItem('token');
  deleteCookie('Authorization');
  socket.finish();
  return (dispatch) => {
    dispatch({
      type: Actions.LOGOUT_USER,
    });
  };
}

export function redirectToLogin() {
  return routerActions.push('/Login');
}

export function logoutAndRedirect() {
  return (dispatch) => {
    dispatch(logout());
    dispatch(routerActions.push('/Login'));
  };
}
export function logoutSocket(redirect = () => {}, idSession = 0, opc = false) {
  return (dispatch) =>
    axios({
      method: 'post',
      url: `${Actions.URL_SERVER}/logout`,
      data: {
        ID: idSession,
      },
    })
      .then(() => {
        dispatch({
          type: Actions.REMOVE_LISTENER,
          payload: opc,
        });
        dispatch(logout());
        dispatch(routerActions.push('/Login'));
        redirect();
      })
      .catch((error) => {
        Err(error);
      });
}

export function validateTokenRequest() {
  return {
    type: Actions.VALIDATE_TOKEN_REQUEST,
  };
}

// VALIDATE TOKEN
export function validateToken(token, redirect = '/') {
  return (dispatch) => {
    dispatch(validateTokenRequest());
    return fetch(`${Actions.URL_SERVER}/login/auth?token=${token}`, { method: 'POST' })
      .then((response) => response.json())
      .then((res) => {
        if (res.token) {
          socket.finish();
          socket.newConnection(res);
          dispatch(loginUserSuccess(res));
          const profilePermission = getProfilePermissionLevel(res.permission, res.person);
          if (profilePermission.rule !== 'sysadmin') {
            dispatch(findForActivePlanByClientId(res.permissions[0].client.id));
          }
          return res;
        }
        // Token inválido, apaga tudo e vai para tela de login
        dispatch(logoutSocket());
      })
      .catch((error) => {
        Err(error);
      });
  };
}

export function accessNewPasswordSuccess(data) {
  return { type: Actions.ACCESS_NEW_PASSWORD_SUCCESS, payload: data };
}

export function accessNewPasswordRejected(data) {
  return { type: Actions.ACCESS_NEW_PASSWORD_REJECTED, payload: data };
}

// ACESSS NEW PASSWORD
export function accessNewPassword(url) {
  return function (dispatch) {
    dispatch({
      type: Actions.ACCESS_NEW_PASSWORD_REQUEST,
      payload: axios({
        method: 'post',
        url: Actions.URL_SERVER + url,
      })
        .then((response) => {
          if (response.data) {
            return dispatch(accessNewPasswordSuccess(response.data));
          }
          return dispatch(accessNewPasswordRejected(response.data));
        })
        .catch((error) => {
          Err(error);
        }),
    });
  };
}

export function changeForgotPassword(newPass, auth) {
  return function (dispatch) {
    dispatch({
      type: Actions.CHANGE_PASS,
      payload: axios({
        method: 'post',
        url: `${Actions.URL_SERVER}/forgotpassword/set`,
        data: {
          password: newPass,
          auth,
        },
      })
        .then((response) => {
          if (response.data) {
            window.setTimeout(() => {
              dispatch(routerActions.push('/Login'));
            }, 3000);
          }
        })
        .catch((error) => {
          Err(error);
        }),
    });
  };
}

// External evaluator success
export function externalEvaluatorRegisteredSuccess() {
  return { type: Actions.EXTERNAL_EVALUATOR_REGISTERED_SUCCESS };
}
