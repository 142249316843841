import React from 'react';

const IconBodyMeasures = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width={props.width}
    height={props.height}
  >
    <path
      d="M439.752 192.7H349.82c-3.653 0-6.924 2.252-8.23 5.663l-1.493 3.91c-.425 1.128-.83 2.207-1.218 3.287-.46 1.247-.89 2.497-1.32 3.754l-.2.562c-.35.998-.7 2.002-1.03 3.004-.34 1.017-.66 2.037-.98 3.058l-.56 1.74c-.27.83-.54 1.678-.79 2.52-.33 1.095-.65 2.2-.96 3.3l-.57 1.982c-.17.59-.36 1.214-.53 1.835l-.76 2.78c-.73 2.65-.18 5.485 1.49 7.67.19.25.4.486.61.714-.22.22-.42.46-.62.71-1.67 2.18-2.22 5.02-1.49 7.67l.76 2.78c.17.62.35 1.24.52 1.83l.57 1.98c.31 1.1.63 2.2.96 3.3.25.84.53 1.69.79 2.52l.55 1.74c.32 1.02.64 2.04.98 3.05.333 1 .68 2 1.03 3l.193.56c.43 1.25.864 2.5 1.32 3.75.384 1.08.79 2.16 1.213 3.29l1.492 3.91c1.303 3.41 4.58 5.66 8.23 5.66h89.93c4.863 0 8.81-3.95 8.81-8.81V201.5c-.005-4.862-3.947-8.806-8.81-8.806z"
      fill="#F8981D"
    />
    <path
      d="M349.82 192.7c-3.653 0-6.924 2.252-8.23 5.663l-1.493 3.91c-.425 1.128-.83 2.207-1.218 3.287-.46 1.247-.89 2.497-1.32 3.754l-.2.562c-.35.998-.7 2.002-1.03 3.004-.34 1.017-.66 2.037-.98 3.058l-.56 1.74c-.27.83-.54 1.678-.79 2.52-.33 1.095-.65 2.2-.96 3.3l-.57 1.982c-.17.59-.36 1.214-.53 1.835l-.76 2.78c-.73 2.65-.18 5.485 1.49 7.67.19.25.4.486.61.714-.22.22-.42.46-.62.71-1.67 2.18-2.22 5.02-1.49 7.67l.76 2.78c.17.62.35 1.24.52 1.83l.57 1.98c.31 1.1.63 2.2.96 3.3.25.84.53 1.69.79 2.52l.55 1.74c.32 1.02.64 2.04.98 3.05.333 1 .68 2 1.03 3l.193.56c.43 1.25.864 2.5 1.32 3.75.384 1.08.79 2.16 1.213 3.29l1.492 3.91c1.303 3.41 4.58 5.66 8.23 5.66h25.89c-.11-.34-.23-.68-.34-1.02-.39-1.18-.77-2.367-1.15-3.56l-.55-1.714c-.858-2.676-2.92-4.71-5.46-5.61l-.14-.66-.22-1.06c-.448-2.1-.88-4.205-1.3-6.308l-.204-1.01c-.13-.65-.26-1.295-.38-1.936-.55-2.84-1.08-5.68-1.588-8.508l-.7-3.97h9.995c4.863 0 8.81-3.945 8.81-8.81V192.7H349.8z"
      opacity=".19"
      fill="#202121"
    />
    <path
      d="M371.76 193.51v32.14c0 4.562 3.7 8.26 8.26 8.26s8.258-3.7 8.258-8.26v-32.14c0-.273-.016-.545-.04-.81H371.8c-.027.265-.04.536-.04.81zm32.083 0v19.277c0 4.56 3.7 8.258 8.258 8.258 4.57 0 8.26-3.695 8.26-8.258V193.51c0-.273-.01-.545-.04-.81h-16.43c-.02.265-.04.536-.04.81z"
      fill="#773F07"
    />
    <path
      d="M107.866 145.87l1.456 9.312h220.423l1.457-9.314c1.195-7.66 2.78-15.4 4.714-23.016l2.692-10.606-10.593-2.76c-33.054-8.6-70.57-13.143-108.484-13.143s-75.42 4.545-108.48 13.142l-10.59 2.76 2.7 10.605c1.94 7.62 3.52 15.363 4.72 23.02z"
      fill="#FCCC9A"
    />
    <path
      d="M107.866 145.87l1.456 9.312h33.588l-1.457-9.31c-1.195-7.657-2.78-15.403-4.715-23.023l-2.062-8.13-2.552-7.91c-.217-.67-.437-1.34-.658-2.01-6.963 1.39-13.783 2.95-20.418 4.67l-10.593 2.76 2.696 10.6c1.94 7.62 3.53 15.36 4.72 23.02z"
      opacity=".19"
      fill="#202121"
    />
    <path
      d="M86.318 75.224c1.457 3.2 4.63 5.16 8.017 5.16.713 0 1.435-.087 2.155-.268l1.78-.45c37.056-9.347 78.986-14.288 121.26-14.288s84.206 4.94 121.264 14.288l1.75.44c4.067 1.024 8.296-.963 10.103-4.75l.777-1.628.06-.127.048-.1c15.02-32.88 16.368-53.65 15.836-63.1l-.117-2.07c-.26-4.67-4.12-8.32-8.79-8.32H78.61c-4.672 0-8.53 3.65-8.795 8.32l-.117 2.08c-.53 9.46.82 30.23 15.832 63.1l.788 1.74z"
      fill="#69C"
    />
    <path
      d="M119.736 74.852l-.62-1.364c-15.012-32.867-16.362-53.64-15.83-63.095l.118-2.08c.262-4.66 4.122-8.313 8.794-8.313H78.61c-4.672 0-8.53 3.65-8.795 8.314l-.117 2.08c-.53 9.456.82 30.227 15.832 63.094l.79 1.736c1.456 3.2 4.628 5.16 8.016 5.16.713 0 1.435-.087 2.155-.268l1.79-.45c6.99-1.764 14.16-3.368 21.47-4.814z"
      opacity=".19"
      fill="#202121"
    />
    <g fill="#FCCC9A">
      <path d="M125.582 272.017l.55-1.715c.855-2.685 2.92-4.726 5.473-5.62 1.35-6.3 2.618-12.68 3.757-19.004l1.73-9.64 198.245-1.382-.042-.234-231.79 1.616-1.732 9.64c-1.23 6.84-2.602 13.754-4.077 20.555l-3.258 15.03h28.064c1.06-3.08 2.095-6.166 3.08-9.246z" />
      <path d="M341.374 266.214c-.16-.732-.313-1.463-.468-2.195l-.222-1.07c-.443-2.1-.875-4.2-1.295-6.31l-.21-1.01-.39-1.94c-.55-2.85-1.09-5.68-1.59-8.51l-1.92-10.78-231.79 1.61-1.74 9.64c-1.23 6.84-2.61 13.75-4.08 20.55l-3.26 15.03h236.26l13.95-.04-3.27-15.01z" />
    </g>
    <path
      d="M133.62 243.402l1.36-7.582-31.476.22-1.732 9.638c-1.23 6.84-2.602 13.755-4.077 20.556l-3.258 15.03h31.378l3.466-15.99c1.57-7.237 3.03-14.594 4.34-21.872z"
      opacity=".19"
      fill="#202121"
    />
    <path
      d="M346.504 149.673H72.248c-4.864 0-8.81 3.945-8.81 8.81v73.942c0 4.864 3.946 8.808 8.81 8.808h274.256c4.864 0 8.81-3.944 8.81-8.808v-73.943c0-4.864-3.946-8.81-8.81-8.81z"
      fill="#F8981D"
    />
    <path
      d="M86.946 179.637v-.002c0-4.865 3.943-8.81 8.81-8.81h259.558V158.48c0-4.862-3.945-8.807-8.81-8.807H72.25c-4.864 0-8.81 3.945-8.81 8.81v73.942c0 4.864 3.946 8.808 8.81 8.808h14.698v-61.595z"
      opacity=".19"
      fill="#202121"
    />
    <path
      d="M355.55 296.104c-2.685-6.974-5.152-13.882-7.34-20.533-.39-1.18-.77-2.36-1.15-3.55l-.55-1.71c-1.167-3.65-4.558-6.12-8.388-6.12h-237.19c-3.83 0-7.225 2.48-8.392 6.13l-.546 1.72c-2.575 8.06-5.426 16.16-8.48 24.09L82.026 300c-1.043 2.71-.684 5.76.96 8.15 1.643 2.396 4.36 3.826 7.263 3.826h258.57c2.9 0 5.61-1.43 7.26-3.82 1.64-2.393 2-5.44.95-8.15l-1.49-3.865z"
      fill="#69C"
    />
    <path
      d="M92.622 90.228l.867 2.233c.42 1.12.84 2.24 1.24 3.35l.49 1.34c.37 1.03.74 2.07 1.09 3.1l.58 1.68.87 2.61c.25.77.5 1.53.75 2.3l3.45 10.71 10.07-2.62c32.68-8.54 69.842-13.05 107.462-13.05 37.622 0 74.785 4.517 107.47 13.055l10.053 2.627 4.01-12.34c.306-.94.613-1.877.907-2.744l.3-.87c.467-1.34.93-2.68 1.418-4.02l.047-.13c.556-1.535 1.126-3.08 1.7-4.58l.1-.254c.522-1.38 1.06-2.76 1.61-4.144l4.675-11.75-12.287-3.017c-36.797-9.027-78.294-13.8-120-13.8s-83.2 4.773-120 13.8l-12.28 3.01 4.68 11.75c.23.595.46 1.185.69 1.79z"
      fill="#485D6D"
    />
    <path
      d="M92.622 90.228l.867 2.233c.42 1.12.84 2.24 1.24 3.35l.49 1.34c.37 1.03.74 2.07 1.09 3.1l.58 1.68.87 2.61c.25.77.5 1.53.75 2.3l3.45 10.71 10.07-2.62c32.68-8.54 69.842-13.05 107.462-13.05 37.622 0 74.785 4.517 107.47 13.055l10.053 2.627 4.01-12.34c.306-.94.613-1.877.907-2.744l.3-.87c.467-1.34.93-2.68 1.418-4.02l.047-.13c.556-1.535 1.126-3.08 1.7-4.58l.1-.254c.522-1.38 1.06-2.76 1.61-4.144l4.675-11.75-12.287-3.017c-36.797-9.027-78.294-13.8-120-13.8s-83.2 4.773-120 13.8l-12.28 3.01 4.68 11.75c.23.595.46 1.185.69 1.79z"
      fill="#485D6D"
    />
    <path
      d="M115.943 307.02c-1.077-2.188-1.217-4.743-.327-7.055l1.488-3.864c3.05-7.92 5.904-16.02 8.477-24.08l.55-1.71c.86-2.68 2.93-4.72 5.48-5.62.04-.17.07-.34.11-.51h-30.78c-3.83 0-7.22 2.48-8.39 6.13l-.55 1.71c-2.57 8.06-5.42 16.16-8.48 24.09l-1.49 3.87c-1.04 2.71-.68 5.76.96 8.15 1.65 2.39 4.36 3.82 7.27 3.82h20.12c1.05-2.42 3.11-4.2 5.57-4.92zM98.537 106.815l3.456 10.71 10.072-2.626c6.85-1.8 13.907-3.4 21.112-4.83l-1.052-3.26c-.247-.77-.5-1.53-.753-2.3-.29-.87-.577-1.74-.876-2.61l-.584-1.68c-.36-1.03-.722-2.07-1.097-3.1l-.49-1.34c-.408-1.11-.82-2.23-1.25-3.35l-.864-2.24c-.23-.605-.46-1.194-.69-1.79l-4.61-11.58c-.38-.505-.72-1.04-.99-1.63l-.79-1.74c-.58-1.28-1.14-2.538-1.69-3.784-6.07 1.22-12.05 2.535-17.9 3.97l-12.29 3.02 4.68 11.75c.24.597.47 1.186.7 1.79l.87 2.235c.43 1.12.84 2.234 1.25 3.344l.49 1.33c.38 1.03.74 2.063 1.1 3.092l.59 1.675c.3.87.59 1.74.88 2.61.25.76.51 1.53.75 2.29z"
      opacity=".19"
      fill="#202121"
    />
    <path
      d="M363.002 313.74l-.54-1.418c-1.3-3.414-4.576-5.67-8.23-5.67H84.836c-3.653 0-6.93 2.256-8.23 5.67l-.54 1.42c-24.316 63.797-7.41 146.564 4.83 190.225l.453 1.606c1.06 3.8 4.53 6.426 8.47 6.426h123.65c2.34 0 4.47-.92 6.05-2.42 1.57 1.49 3.71 2.41 6.05 2.41h123.65c3.95 0 7.41-2.63 8.48-6.43l.45-1.61c12.24-43.66 29.14-126.42 4.83-190.23z"
      fill="#485D6D"
    />
    <path
      d="M114.935 505.574l-.45-1.606c-12.243-43.66-29.15-126.428-4.834-190.225l.55-1.42c1-2.614 3.16-4.54 5.75-5.303-.06-.12-.1-.245-.16-.368H84.84c-3.653 0-6.93 2.257-8.23 5.67l-.54 1.42c-24.316 63.798-7.41 146.565 4.83 190.226l.453 1.606c1.066 3.8 4.533 6.426 8.48 6.426h33.59c-3.95 0-7.415-2.627-8.484-6.426zM247.065 512h12.11c-2.346 0-4.476-.916-6.057-2.41-1.577 1.494-3.71 2.41-6.054 2.41zm.418-99.282c3.168-3.26 3.104-8.47-.146-11.652-3.26-3.192-8.49-3.136-11.68.12-.6.61-9.282 9.668-16.12 25.793-6.834-16.13-15.52-25.18-16.118-25.8-3.19-3.25-8.38-3.3-11.64-.13-3.27 3.16-3.34 8.39-.19 11.66.19.2 19.68 20.84 19.68 55.3V512h2.19c2.34 0 4.47-.916 6.05-2.41 1.57 1.494 3.71 2.41 6.05 2.41h2.21v-43.983c-.01-34.41 19.43-55.04 19.68-55.3z"
      opacity=".19"
      fill="#202121"
    />
    <path
      d="M86.978 149.673v31.205c0 4.56 3.7 8.258 8.26 8.258s8.258-3.698 8.258-8.258v-31.205H86.978zm32.082 0v18.343c0 4.56 3.7 8.258 8.26 8.258s8.257-3.698 8.257-8.258v-18.343H119.06zm32.082 0v31.205c0 4.56 3.698 8.258 8.26 8.258 4.56 0 8.257-3.698 8.257-8.258v-31.205h-16.52zm32.082 0v18.343c0 4.56 3.698 8.258 8.26 8.258 4.558 0 8.257-3.698 8.257-8.258v-18.343h-16.51zm32.082 0v31.205c0 4.56 3.698 8.258 8.26 8.258 4.558 0 8.257-3.698 8.257-8.258v-31.205h-16.517zm32.082 0v18.343c0 4.56 3.698 8.258 8.26 8.258 4.56 0 8.257-3.698 8.257-8.258v-18.343h-16.517zm32.082 0v31.205c0 4.56 3.697 8.258 8.258 8.258s8.258-3.698 8.258-8.258v-31.205H279.47zm32.08 0v18.343c0 4.56 3.7 8.258 8.26 8.258s8.258-3.698 8.258-8.258v-18.343H311.55z"
      fill="#773F07"
    />
  </svg>
);

IconBodyMeasures.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconBodyMeasures;
