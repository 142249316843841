import React from 'react';
import Image from './Images/Image';
import { FormattedMessage } from 'react-intl';
const Loading = (props) => (
  <div className="loading">
    <Image src={require('../images/loading-tables.gif')} />
    <p>
      {props.message || (
        <FormattedMessage id="app.global.loading" defaultMessage="Carregando" />
      )}
    </p>
  </div>
);
export default Loading;
