import moment from 'moment';
import { asMutable } from 'seamless-immutable';
import { URL_SERVER, SUBSCRIPTION_STATUS } from '../constant/constants';
import clone from './deepClone';

export function compareJson(prop, order) {
  return function (a, b) {
    let maior;
    let menor;
    if (order === 'asc') {
      maior = 1;
      menor = -1;
    } else {
      maior = -1;
      menor = 1;
    }
    const astring = a[prop].toString().toLowerCase();
    const bstring = b[prop].toString().toLowerCase();
    if (astring > bstring) return maior;
    if (astring < bstring) return menor;
    return 0;
  };
}

export function compare(order) {
  return function (a, b) {
    let maior;
    let menor;
    if (order === 'asc') {
      maior = 1;
      menor = -1;
    } else {
      maior = -1;
      menor = 1;
    }
    const astring = a.toString().toLowerCase();
    const bstring = b.toString().toLowerCase();
    if (astring > bstring) return maior;
    if (astring < bstring) return menor;
    return 0;
  };
}

export function orderObjectByKeys(lines) {
  const result = [];
  Object.keys(lines)
    .sort(compare('asc'))
    .map((ageKey, i) => {
      lines[ageKey].id = i;
      result.push(lines[ageKey]);
      return false;
    });
  return result;
}

export function removeAccents(fromWhat) {
  return fromWhat
    .replace(/[áãàâä]/g, 'a')
    .replace(/[ÁÃÁÂÄ]/g, 'A')
    .replace(/[éêẽèë]/g, 'e')
    .replace(/[ÉÊẼÈË]/g, 'E')
    .replace(/[íîïĩì]/g, 'i')
    .replace(/[ÍÎÏĨÌ]/g, 'I')
    .replace(/[óôõöò]/g, 'o')
    .replace(/[ÓÔÕÖÒ]/g, 'O')
    .replace(/[úüùûũ]/g, 'u')
    .replace(/[ÚÜÙÛŨ]/g, 'U')
    .replace(/[ç]/g, 'c')
    .replace(/[Ç]/g, 'C');
}

export function isEmpty(str) {
  return !str || str.length === 0;
}

export function convertDateEnPt(data) {
  let tmp;
  let hour = '';
  if (isEmpty(data)) return data + hour;
  if (data.indexOf(' ') !== -1) {
    // existe espaco, logo tem hora. deve ser ignorada a hora
    tmp = data.split(' ');
    data = tmp[0];
    hour = ` ${tmp[1]}`;
  }
  if (data.indexOf('-') === -1) {
    // nao existe -
    return data + hour;
  }
  tmp = data.split('-');
  return `${tmp[2]}/${tmp[1]}/${tmp[0]}${hour}`;
}

export function convertDatePtEn(data) {
   let tmp;
   let hour = '';
   if (isEmpty(data)) return data + hour;
   if (data.indexOf(' ') !== -1) {
    tmp = data.split(' ');
    data = tmp[0];
    hour = ` ${tmp[1]}`;
  }
  if (data.indexOf('/') === -1) {
    return data + hour;
  }
  tmp = data.split('/');
  return `${tmp[2]}-${tmp[1]}-${tmp[0]}${hour}`;
}

export function powerFilter(data, textInput, columnsSearch) {
  if (typeof textInput === 'undefined' || textInput == null || isEmpty(textInput)) {
    return data;
  }
  let tmpSearch;
  const regex = /[^" ]+|("[^"]*")/g;
  let i;
  tmpSearch = removeAccents(textInput).toLowerCase().match(regex);
  if (tmpSearch === null) return true;
  if (tmpSearch.length > 1) {
    for (i = 0; i < tmpSearch.length; i++) {
      data = powerFilter(data, tmpSearch[i], columnsSearch);
    }
    return data;
  }
  return data.filter((datum) => {
    let tmpNormalized;
    let i;
    let size;
    for (let j = 0; j < columnsSearch.length; j++) {
      if (
        !Array.isArray(columnsSearch[j].name) &&
        (datum[columnsSearch[j].name] === null || datum[columnsSearch[j].name] === undefined)
      )
        continue;
      if (!Array.isArray(columnsSearch[j].name)) {
        tmpNormalized = removeAccents(datum[columnsSearch[j].name].toString()).toLowerCase();
      }
      if (Array.isArray(columnsSearch[j].name)) {
        tmpNormalized = columnsSearch[j].name.reduce(
          (currentObj, key) => (currentObj && currentObj[key] !== undefined ? currentObj[key] : ''),
          datum
        );
        tmpNormalized = removeAccents(tmpNormalized.toString()).toLowerCase();
      }
      for (i = 0; i < tmpSearch.length; i++) {
        tmpSearch[i] = tmpSearch[i].replace(/"/g, '');
        size = tmpSearch[i].length;
        if (size === 0) continue;
        if (
          typeof columnsSearch[j].type !== 'undefined'
          && columnsSearch[j].type === 'date'
        ) {
          tmpNormalized = convertDateEnPt(tmpNormalized);
        }

        if (tmpNormalized.indexOf(tmpSearch[i]) > -1) return true;
      }
    }
    return false;
  });
}

export function filterByType(type, item) {
  if (item.measuretype.measuretypename === type) return true;
  return false;
}

export function sortByMatchingInfoProtocol(infoEvaluated, protocols) {
  if (
    infoEvaluated === null ||
    typeof infoEvaluated !== 'object' ||
    "age" in infoEvaluated === false ||
    infoEvaluated.age === null ||
    "gender" in infoEvaluated === false ||
    infoEvaluated.gender === null ||
    "classification" in infoEvaluated === false ||
    infoEvaluated.classification === null ||
    "ethnicity" in infoEvaluated === false ||
    infoEvaluated.ethnicity === null ||
    "sexualmaturation" in infoEvaluated === false
  ) {
    return {
      appropriateProtocolIdList: null,
      appropriateProtocolList: protocols
    };
  }

  const validators = [
    {
      expression: (evaluated, protocol) => {
        if (protocol.minage === 0 && protocol.maxage === 0) return true;
        return evaluated.age >= protocol.minage && evaluated.age <= protocol.maxage;
      }
    },
    {
      expression: (evaluated, protocol) => {
        return protocol[evaluated.gender] === true;
      }
    },
    {
      expression: (evaluated, protocol) => {
        if (protocol.classifications === undefined) return true;
        return !!protocol.classifications.find((c) => c.id === evaluated.classification);
      }
    },
    {
      expression: (evaluated, protocol) => {
        if (protocol.ethnicities === undefined) return true;
        return !!protocol.ethnicities.find((p) => p.id === evaluated.ethnicity);
      }
    },
    {
      expression: (evaluated, protocol) => {
        if (protocol.sexualmaturations === undefined || protocol.sexualmaturations === null) return true;
        if (evaluated.sexualmaturation === null) return false;
        return !!protocol.sexualmaturations.find((s) => s.id === evaluated.sexualmaturation);
      }
    }
  ];

  const matchesInfo = (evaluated, protocol) => {
    for (const validator of validators) {
      if (!validator.expression(evaluated, protocol)) {
        return false;
      }
    }
    return true;
  };

  const sortedArray = protocols.reduce(
    (acc, protocol) => {
      if (matchesInfo(infoEvaluated, protocol)) {
        acc.appropriateProtocolList.unshift(protocol);
        acc.appropriateProtocolIdList.unshift(protocol.id);
      } else {
        acc.appropriateProtocolList.push(protocol);
      }

      return acc;
    },
    { appropriateProtocolIdList: [], appropriateProtocolList: [] }
  );

  return sortedArray;
}

export function returnIdElement(element) {
  return element.id;
}

export function filterSessionsConnections(vision, item) {
  switch (vision) {
    case 'all':
      return true;
    case 'connected':
      return item.totalconnections > 0;
    case 'disconnected':
      return item.totalconnections === 0;
    default:
      return true;
  }
}

export function addLocalize(receiveListProps, formatMessageObject, name = 'name') {
  if (Array.isArray(receiveListProps)) {
    return receiveListProps.map((thisArgument) => {
      if (typeof thisArgument.defaultMessageName !== 'undefined') {
        thisArgument.localized = formatMessageObject({
          id: thisArgument.idMessageName,
          defaultMessage: thisArgument.defaultMessageName,
        });
      } else {
        thisArgument.localized = thisArgument[name];
      }
      return thisArgument;
    });
  }

  if (typeof receiveListProps.defaultMessageName !== 'undefined') {
    receiveListProps.localized = formatMessageObject({
      id: receiveListProps.idMessageName,
      defaultMessage: receiveListProps.defaultMessageName,
    });
  } else {
    receiveListProps.localized = receiveListProps[name];
  }

  return receiveListProps;
}

export function addLabelAndValue(receiveListProps, localized = 'localized', parameter) {
  if (receiveListProps instanceof Array) {
    return receiveListProps.map((thisArgument) => {
      if (parameter) {
        thisArgument.label = thisArgument[localized][parameter];
        thisArgument.value = thisArgument[localized][parameter];
      } else {
        thisArgument.label = thisArgument[localized];
        thisArgument.value = thisArgument[localized];
      }
      if (typeof thisArgument.label === 'undefined') {
        thisArgument.label = thisArgument.measure;
      }
      if (typeof thisArgument.value === 'undefined') {
        thisArgument.value = thisArgument.measure;
      }
      return thisArgument;
    });
  }
  if (parameter) {
    receiveListProps.label = receiveListProps[localized][parameter];
    receiveListProps.value = receiveListProps[localized][parameter];
  } else {
    receiveListProps.label = receiveListProps[localized];
    receiveListProps.value = receiveListProps[localized];
  }
  if (typeof receiveListProps.label === 'undefined') {
    receiveListProps.label = receiveListProps.measure;
  }
  if (typeof receiveListProps.value === 'undefined') {
    receiveListProps.value = receiveListProps.measure;
  }
  return receiveListProps;
}

export function addlabelAndValueTranslationSupport(data, formatMessageObject, name) {
  if (data === null) return data;
  const dataClone = clone(data);
  const dataAddLocalize = addLocalize(dataClone, formatMessageObject, name);
  const dataAddLabelAndValue = addLabelAndValue(dataAddLocalize);
  return dataAddLabelAndValue;
}

// calculo idade
export function calculatesAge(date) {
  const birth = moment(date);
  const today = moment();
  return today.diff(birth, 'years');
}

// Cálculo da idade do avaliado quando a avaliação foi feita
export function ageEvaluatedEvaluation(dateBirth, dateEvaluation) {
  const age = moment(dateBirth);
  const evaluation = moment(dateEvaluation);
  return evaluation.diff(age, 'years');
}

export function genderEvaluatedEvaluation(genderId){
  if(genderId === 1) {
    return 1;
  }
  if(genderId === 2){
    return 0;
  }
  return null;
}

// calculo período grátis
export function calculateFreeTrial(date) {
  if (date) {
    const dateFinishTrial = moment(date);
    const today = moment();
    return dateFinishTrial.diff(today, 'days');
  }
}

// calculo data reavaliação
export function calcReavaliationDate(daysReavaliation) {
  const today = moment();
  return today.add(daysReavaliation, 'days');
}

// covert em numero
export function convertNumber(value, decimal) {
  if (typeof value === 'number') {
    return value;
  } // .toFixed(decimal);
  let valueTransformInt = value.replace(/[^0-9-]/g, '');
  const i = valueTransformInt.indexOf('-');
  if (i > -1) {
    valueTransformInt = valueTransformInt.replace(/-/g, '') * -1;
  }

  const divisor = Math.pow(10, decimal);
  const num = valueTransformInt / divisor;
  return num; // .toFixed(decimal);
}
// convert numero em string no formato do país
export function formatNumber(value, decimal = 1) {
  return new Intl.NumberFormat(window.localStorage.locale, {
    maximumFractionDigits: decimal,
    minimumFractionDigits: decimal,
  }).format(value);
}

export function convertAndFormatNumber(value, decimal) {
  const convertedNumber = convertNumber(value, decimal);
  const formattedValue = formatNumber(convertedNumber, decimal);
  return formattedValue;
}

// calculo IMC
export function calcIMC(weight, height) {
  const imc = weight / Math.pow(height / 100, 2);
  const fixedIMC = parseFloat(imc.toFixed(2));
  return fixedIMC;
}

export function areEqual(a, b) {
  const typeA = typeof a;
  const typeB = typeof b;
  if (typeA !== typeB) {
    return false;
  }
  if (typeA !== 'object' || a === null || b === null) {
    return a === b;
  }

  for (const key in a) {
    if (!areEqual(a[key], b[key])) {
      return false;
    }
  }
  return true;
}

export function getBase64Image(img) {
  const canvas = document.createElement('canvas');

  canvas.width = img.width;
  canvas.height = img.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(img, 0, 0);

  const dataURL = canvas.toDataURL('image/png');

  return dataURL;
}

export function decodeBase64FileExtension(base64) {
  if (base64 === '') return null;
  return base64.match(/[^:/]\w+(?=;|,)/)[0] || null;
}

export function isBase64(img) {
  const imagesTestingRegex =
    /^\s*data:([a-z]+\/[a-z0-9\-+]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,[a-z0-9!$&',()*+,;=\-._~:@/?%\s]*\s*$/i;
  return imagesTestingRegex.test(img);
}

// formatDateSend
export function formatDateSend(date) {
  return moment(date, moment.localeData()._longDateFormat.L).format('YYYY-MM-DD');
}

export function orderedObject(unordered) {
  const ordered = {};

  Object.keys(unordered)
    .sort()
    .forEach((key) => {
      ordered[key] = unordered[key];
    });

  return ordered;
}

export function mergeMeasures(measures, evaluation, positionIndex, evaluatedSelected) {
  const indexMeasure = measures.findIndex((m) => m.id === evaluation.id);
  if (indexMeasure < 0) return measures;
  measures[indexMeasure].value = evaluation.value;
  measures[indexMeasure].positions[positionIndex] = evaluation.positions[0];
  measures[indexMeasure].positions[positionIndex].idgender = evaluatedSelected.person.gender.id;
  return measures;
}

// Format Data Select
export function formatLocalizeAndSelect(data, intl, compare = '') {
  // Transforma os dados em mutáveis
  const dataTransformMutable = asMutable(data, { deep: true });
  // Pega o id feito do convertmessages(vide Redux Store) e adicioa uma chave localized ao objeto
  const dataAddLocalize = addLocalize(dataTransformMutable, intl);
  // Adiciona as chaves label e value para o select ao objeto a partir da chave localized
  const dataListSelect = addLabelAndValue(dataAddLocalize);
  // Caso tenha algum que precise vir selecionado ao carregar a página procuramos o indice baseado no valor de compare que passar
  let index;
  if (compare) {
    index = dataListSelect.findIndex(data => data.id === compare);
  }

  // Retorna a lista pronta para o select, e o index para trazer um valor selecionado caso tenha
  return { list: dataListSelect, index };
}

// Procura a posição que modificaram no canvas e destroi
export function searchPositionAndDestroy(measuresTmp, m) {
  const sizeMeasures = measuresTmp.length;
  for (let i = 0; i < sizeMeasures; i++) {
    if (m.id === measuresTmp[i].id) {
      m.positions[0].x = measuresTmp[i].positions[0].x;
      m.positions[0].y = measuresTmp[i].positions[0].y;
      m.positions[1].x = measuresTmp[i].positions[1].x;
      m.positions[1].y = measuresTmp[i].positions[1].y;
      measuresTmp = measuresTmp.slice(0, i).concat(measuresTmp.slice(i + 1));
      break;
    }
  }
  return { marray: measuresTmp, justm: m };
}
// Acrescenta preenchimento para saber qual elemeto é
export function leftPadding(element, size, character = '0') {
  // Rebebe o elemento e converte para string
  const elementString = element.toString();
  // Enquanto o elemento for menor que o size que você mandou continuar acrecentando o caractere na frente
  // while (elementString.length < size) elementString = character + elementString;
  return elementString.padStart(size, character);
}

export function getOffset(element) {
  // retorna o tamanho de um elemento e sua posição relativa ao viewport
  const bounding = element.getBoundingClientRect();

  return {
    top: bounding.top + document.body.scrollTop,
    left: bounding.left + document.body.scrollLeft,
  };
}

export function getMeasureIndexWithString(list, stringComparison) {
  return list.findIndex(element => element.measure === stringComparison);
}

export function roundNumber(number, decimal = 2) {
  // Recebe quantidade de casas decimais,
  // eleva 10 ao expoente para construir o divisor com a quantidade de zeros necessários.
  const divisor = Math.pow(10, decimal);
  return Math.round(number * divisor) / divisor;
}

export function deleteCookie(name) {
  window.document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

export function classificationBMI(bmiValue) {
  if (bmiValue < 17) {
    return 'Muito abaixo do peso';
  }
  if (bmiValue >= 17 && bmiValue < 18.5) {
    return 'Abaixo do peso';
  }
  if (bmiValue >= 18.5 && bmiValue < 25) {
    return 'Peso normal';
  }
  if (bmiValue >= 25 && bmiValue < 30) {
    return 'Acima do peso';
  }
  if (bmiValue >= 30 && bmiValue < 35) {
    return 'Obesidade I';
  }
  if (bmiValue >= 35 && bmiValue < 40) {
    return 'Obesidade II(severa)';
  }
  if (bmiValue >= 40) {
    return 'Obesidade III(mórbida)';
  }
  return 'Erro';
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isEmptyObj(obj) {
  if (obj !== null || obj !== undefined) return Object.keys(obj).length === 0;
  return true;
}

export function handlePersonPicture(picture) {
  if (!!picture === false) {
    return `${URL_SERVER}/profile/1/avatar.png`;
  }
  if (picture.indexOf('data:') !== -1) {
    return picture;
  }
  return URL_SERVER + picture;
}

export function getQueryString(field, url) {
  const href = url || window.location.href;
  const reg = new RegExp(`[?&]${field}=([^&#]*)`, 'i');
  const string = reg.exec(href);
  return string ? string[1] : null;
}

export function isToday(date) {
  const today = new Date();
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  return (
    date.getDate() === today.getDate()
    && date.getMonth() === today.getMonth()
    && date.getFullYear() === today.getFullYear()
  );
}

export function checkDuplicity(itemList = [], propKey = '', isDeepObject = false) {
  const compareList = isDeepObject
    ? itemList.map(i => i[propKey[0]][propKey[1]])
    : itemList.map(i => i[propKey]);
  return compareList.some((value, index) => compareList.indexOf(value) !== index);
}

export function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function getProfilePermissionLevel(profilePermissions = {}, person = {}) {
  let rule = 'n/a';
  let permissions = {};
  if (!isEmptyObj(profilePermissions)) {
    const {
      evaluation,
      evaluator,
      evaluatorperm,
      registration,
      user,
      financial,
      plan,
      sysadmin,
    } = profilePermissions;
    permissions = {
      makeEvaluationsPermission: evaluation && evaluator,
      manageEvaluators: evaluatorperm,
      registrationPermission: registration,
      managerUsers: user,
      justEvaluated:
        !evaluation
        && !evaluator
        && !evaluatorperm
        && !financial
        && !plan
        && !registration
        && !user,
    };
    if (profilePermissions.client && person) {
      if (profilePermissions.client.person) {
        if (profilePermissions.client.person.id === person.id && profilePermissions.client.tipoCliente !== 3) {
          return {
            rule: 'clientAdmin',
            permissions,
          };
        }
      }
    }

    if (permissions.justEvaluated) {
      rule = 'evaluated';
    } else if (sysadmin === true) {
      rule = 'sysadmin';
    } else if (permissions.makeEvaluationsPermission && !permissions.manageEvaluators) {
      rule = 'evaluator';
    } else if (permissions.makeEvaluationsPermission && permissions.manageEvaluators) {
      rule = 'clientEvaluator';
    } else {
      rule = 'client';
    }
  }

  return {
    rule,
    permissions,
  };
}

export function setAppropriateTestingFilter(evaluatedSelected) {
  return {
    age: calculatesAge(evaluatedSelected.person.birthday),
    gender: evaluatedSelected.person.gender.name,
    ethnicity: evaluatedSelected.ethnicity.id,
    classification: evaluatedSelected.classification.id,
    sexualmaturation: evaluatedSelected.sexualmaturation?.id || null,
  };
}

 /**
   * Formata e localiza valores decimais de acordo com seu tipo, se é valor monetário ou um valor comum, de alguma medida por exemplo.
   *
   * @param {string | number} value valor a ser formatado
   * @param {"decimal" | "currency"} type tipo do valor: decimal ou moeda
   * @param {number} decimal número de casas decimais para a formatação 
   * @returns string
   */
export function formatNumberBRL(value, type = "decimal", decimal = 1) {
  let valueDecimal
  
  const formatOptions = {
    style: type,
    useGrouping: true,
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  };
  if (formatOptions.style === 'currency') {
    formatOptions.currency = 'BRL';
    formatOptions.maximumFractionDigits = 2;
    formatOptions.minimumFractionDigits = 2;
  };

  if (typeof value === 'string') {
    if (value.trim() === '') return value;
    valueDecimal = parseFloat(value);
  } else {
    valueDecimal = value
  }
  
  if(isNaN(valueDecimal)) return "-"

  const formattedNumber = valueDecimal.toLocaleString('pt-BR', formatOptions);

  return formattedNumber;
}

export const parseSubscriptionStatus = (status) => {
  return SUBSCRIPTION_STATUS[status] !== undefined ? SUBSCRIPTION_STATUS[status] : ' ';
};

export function convertingPaymentHistoryStatus(status) {
  const statusMapper = {
    PENDING: 'Pendente', 
    RECEIVED: 'Recebido', 
    CONFIRMED: 'Recebido', 
    OVERDUE: 'Atrasado', 
    REFUNDED: 'Devolvido', 
    RECEIVED_IN_CASH: 'Recebido em dinheiro', 
    REFUND_REQUESTED: 'Reembolso solicitado', 
    REFUND_IN_PROGRESS: 'Reembolso em andamento', 
    CHARGEBACK_REQUESTED: 'Pedido de estorno', 
    CHARGEBACK_DISPUTE: 'Disputa de estorno', 
    AWAITING_CHARGEBACK_REVERSAL: 'Aguardando reversão de estorno', 
    DUNNING_REQUESTED: 'Cobrança solicitada', 
    DUNNING_RECEIVED: 'Cobrança recebida', 
    AWAITING_RISK_ANALYSIS: 'Aguardando análise de risco',
  };
  return statusMapper[status] ? statusMapper[status] : status;
}

export function dispatchEvent(name, data) {
  const event = new Event(name);
  event.data = data;

  window.dispatchEvent(event);
}

export const calculateSkinfoldsSomatory = (skinfolds) => {
  return skinfolds.reduce((prevValue, skinfold) => {
    return prevValue + skinfold.value;
  }, 0);
};

// caso a data seja `"0001-01-01T00:00:00Z"`, desconsidera
export const formatDateToView = (date) => {
 return moment(new Date(date)).unix() !== -62135596800
      ? moment(new Date(date)).format('L')
      : '-'
}

export const convertLanguageIdentifiers = (value) => {
  let valuesCountry = {};
  valuesCountry['BRA'] = 'pt-BR';
  valuesCountry['AR'] = 'es';
  valuesCountry['EUA'] = 'en';

  return valuesCountry[value] ? valuesCountry[value] : value;
};

 /**
   * Formata e padroniza as url das redes sociais.
   *
   * @param {string} value valor a ser formatado
   * @param {"instagram" | "twitter" | "facebook" | "linkedin"} socialMedia tipo do valor: "instagram", "twitter", "facebook", "linkedin"
   * @returns string
   */
 export function formatSocialMediaUrl(username, socialMedia) {
  let value = username;
  const url = {
    instagram: 'instagram.com/',
    twitter: 'twitter.com/',
    facebook: 'facebook.com/',
    linkedin: 'linkedin.com/in/',
  };

  if (!value) {
    return '';
  }

  if (!username.includes(url[socialMedia])) {
    value = `${url[socialMedia]}${username}`
  }

  return value;
}

export function convertToTime(number) {
  if (typeof number !== 'number' || Number.isNaN(number)) return number;
  const min = Math.floor(+number);
  let sec = Math.round((+number - min) * 100) / 100;
  sec = Math.floor(sec * 100);

  if (min === 0) {
    return `${sec}s`
  } else if (sec === 0) {
    return `${min}min`;
  } else if (sec < 10) {
    return `${min}min0${sec}s`;
  } else {
    return `${min}min${sec}s`;
  }
}

export function zeroPad(value) {
  return value < 10 ? `0${value}` : value;
}

export function formatWithComma(number) {
  if (typeof number !== 'number' || Number.isNaN(number)) return number;
  return number.toFixed(2).toString().replace('.', ',');
} 

export function generateEvaluationEditUrl(clientId, evaluatedId, evaluationId, goTo) {
  const baseUrl = `/PhysicalAssessment/${clientId}/${evaluatedId}/${evaluationId}/Edit`;

  const routing = {
    bodyMeasures: '/Measures',
    composition: '/Composition',
    anamnesis: '/Anamnesis',
    parq: '/ParQ',
    cardiorespiratory: '/Cardiorespiratory',
    neuromotors: '/Neuromotors',
  };

  return {
    url: `${baseUrl}${routing[goTo] || ''}`,
    editPage: routing[goTo] || ''
  };
}

export function transformWorkoutDataInArray(workouts) {
  let normalizedWorkouts = [];

  for (const w of Object.keys(workouts)) {
    normalizedWorkouts = [
      ...normalizedWorkouts,
      {
        description: w,
        workoutExercises: workouts[w].map((exercise, i) => ({
          serie: Number(exercise.series),
          repetition: Number(exercise.repetition),
          weight: convertNumber(exercise.weight, 1),
          rest: Number(exercise.rest),
          distance: convertNumber(exercise.distance, 1),
          speed: convertNumber(exercise.speed, 1),
          inclination: convertNumber(exercise.inclination, 1),
          time: exercise.time,
          maxfc: Number(exercise.maxfc),
          minfc: Number(exercise.minfc),
          order: i,
          idexercise: Number(exercise.id)
        }))
      }
    ];
  }

  return normalizedWorkouts;
}

export function transformWorkoutDataInObj(workouts) {
  let normalizedWorkouts = [];

  for (const w of workouts) {
    normalizedWorkouts = {
      ...normalizedWorkouts,
      [w.description]: w.workoutExercises.map((e) => ({
        id: e.exercise.id,
        name: e.exercise.name,
        type: e.exercise.exerciseType.id,
        series: Number(e.serie),
        repetition: Number(e.repetition),
        weight: convertAndFormatNumber(e.weight, 1),
        rest: Number(e.rest),
        distance: convertAndFormatNumber(e.distance, 1),
        speed: convertAndFormatNumber(e.speed, 1),
        inclination: convertAndFormatNumber(e.inclination, 1),
        time: e.time,
        maxfc: Number(e.maxfc),
        minfc: Number(e.minfc)
      }))
    };
  }

  return normalizedWorkouts;
}

/**
 * Ordena uma lista de objetos com base no valor de uma determinada coluna.
 *
 * @param {Array} list - A lista de objetos a ser ordenada.
 * @param {string} key - A chave da coluna pela qual a lista deve ser ordenada.
 * @param {string} type - O tipo de dado primitivo da coluna ('text', 'date' ou 'number').
 * @param {string} orderType - O tipo de ordenação ('asc' para ascendente, 'desc' para descendente).
 * @returns {Array} - A lista ordenada.
 *
 * @example
 * const data = [
 *   { name: 'John', age: 30, date: '2022-01-24' },
 *   { name: 'Alice', age: 25, date: '2022-01-25' },
 *   { name: 'Bob', age: 35, date: '2022-01-23' }
 * ];
 *
 * const sortedData = sortColumn(data, 'name', 'text', 'asc');
 * console.log(sortedData);
 * // Output: [{ name: 'Alice', age: 25, date: '2022-01-25' }, { name: 'Bob', age: 35, date: '2022-01-23' }, { name: 'John', age: 30, date: '2022-01-24' }]
 */
export function sortColumn(list, key, type, orderType) {
  if (!Array.isArray(list)) return [];
  const listClone = clone(list);

  const sortingFunctions = {
    text: (a, b) => a[key].localeCompare(b[key]),
    date: (a, b) => moment.utc(a[key]).diff(b[key]),
    number: (a, b) => a[key] - b[key]
  };

  if (!sortingFunctions[type]) {
    return listClone;
  }

  const sortOrder = orderType === 'desc' ? -1 : 1;

  const orderedList = listClone.sort((a, b) => sortOrder * sortingFunctions[type](a, b));

  return orderedList;
}

export function generateYouTubeEmbedUrl(videoId) {
  return `https://www.youtube.com/embed/${videoId}`;
}

export function isValidUrl(string) {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
}

export function filtersAnnouncementsByProfileType(list, profileType) {
  if (!Array.isArray(list) && !profileType) return [];
  const newAnnouncementList = list.reduce((acc, item) => {
    const announcements = Array.isArray(item.announcements)
      ? item.announcements.filter(
          (c) => c.profiles && c.profiles.includes(profileType)
        )
      : [];

    if (announcements.length > 0) {
      acc.push({ ...item, announcements });
    }

    return acc.sort(
      (a, b) =>
        moment(b.announcementDate).valueOf() -
        moment(a.announcementDate).valueOf()
    );
  }, []);

  return newAnnouncementList;
}

export function filtersFeatureByProfileType(list, profileType) {
  if (!Array.isArray(list) && !profileType) return list;
  const newFeaturesList = list.reduce((acc, item) => {
    const changes = Array.isArray(item.changes)
      ? item.changes.filter(
          (c) => c.profiles && c.profiles.includes(profileType)
        )
      : [];

    if (changes.length > 0) {
      acc.push({ ...item, changes });
      return acc.sort((a, b) =>
        a.version && b.version ? b.version.localeCompare(a.version) : 1
      );
    }

    return acc;
  }, []);

  return newFeaturesList;
}

export function checkNewFeatures(featuresList, featuresViewed){
  const releaseVersions = featuresList.reduce((arr, item) => {
    if(item.version){
      arr.push(item.version);
    }
    return arr;
  }, []);

  if (releaseVersions.every(releaseVersion => featuresViewed.findIndex(fv => fv === releaseVersion) !== -1)) {
    return false;
  }
  return true;
}