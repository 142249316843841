import React from 'react';

const IconClassification = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 509.287 509.287"
    width={props.width}
    height={props.height}
  >
    <circle cx="254.644" cy="254.644" r="254.644" fill="#F0F1F5" />
    <path
      fill="#646B79"
      d="M359.078 255.661V95.958H152.922v159.703H54.591v161.06h13.902V269.563H248.88v147.158h14.241V269.563h180.387v147.158h13.902v-161.06h-98.332zM166.824 109.86h178.352v145.801H166.824V109.86z"
    />
    <path fill="#EE4A68" d="M205.817 56.625h100.705v83.751H205.817z" />
    <path fill="#FBD303" d="M109.521 207.513h100.705v83.751H109.521z" />
    <path
      fill="#3EB9E3"
      d="M11.189 369.25h100.705v83.751H11.189zm386.204 0h100.705v83.751H397.393zm-191.576 0h100.705v83.751H205.817z"
    />
    <path fill="#FBD303" d="M301.775 207.513H402.48v83.751H301.775z" />
  </svg>
);

IconClassification.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconClassification;
