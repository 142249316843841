import React from 'react';

const IconClients = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width={props.width}
    height={props.height}
  >
    <ellipse cx="438.86" cy="207.24" fill="#785550" rx="32.508" ry="28.444" />
    <path
      fill="#694B4B"
      d="M430.73 207.238c0-11.931 8.414-22.112 20.317-26.335-3.77-1.338-7.872-2.109-12.19-2.109-17.954 0-32.508 12.735-32.508 28.444s14.554 28.444 32.508 28.444c4.318 0 8.421-.771 12.19-2.109-11.903-4.223-20.317-14.404-20.317-26.335z"
    />
    <path
      fill="#E6AF78"
      d="M498.522 369.023l-26.3-13.149c-5.507-2.753-8.986-8.382-8.985-14.54l.001-24.381h-48.762v24.382c0 6.156-3.478 11.784-8.985 14.538l-26.3 13.15c-8.26 4.13-13.477 12.572-13.477 21.807v48.028c0 4.488 3.638 8.127 8.127 8.127h130.032c4.489 0 8.127-3.639 8.127-8.127V390.83c0-9.235-5.218-17.677-13.478-21.807z"
    />
    <path
      fill="#D29B6E"
      d="M438.857 349.46c8.751 0 17.132-1.804 24.816-5.015-.202-1.024-.437-2.043-.437-3.111l.001-24.381h-48.762v24.382c0 1.073-.235 2.097-.439 3.126 7.69 3.194 16.068 4.999 24.821 4.999z"
    />
    <path
      fill="#D5DCED"
      d="M498.522 369.023l-18.736-9.368c-8.688 13.416-23.756 22.313-40.929 22.313s-32.242-8.898-40.93-22.314l-18.736 9.369c-8.26 4.13-13.477 12.572-13.477 21.807v48.027c0 4.488 3.639 8.127 8.127 8.127h130.032c4.488 0 8.127-3.639 8.127-8.127V390.83c0-9.235-5.218-17.677-13.478-21.807z"
    />
    <circle cx="438.86" cy="260.06" r="56.889" fill="#5A4146" />
    <path
      fill="#694B4B"
      d="M495.213 252.177c-3.126-23.183-20.888-42.565-43.731-47.609-4.167-.92-8.269-1.368-12.27-1.393-27.255-.17-36.356 37.463-11.854 49.401 1.317.642 2.153.93 2.153.93l28.31 51.255s7.544.515 15.152.75c15.727-11.825 25.177-31.55 22.24-53.334z"
    />
    <path
      fill="#F0C087"
      d="M438.857 333.206c-24.792 0-45.262-18.501-48.357-42.45-.448-3.47 1.219-6.872 4.343-8.448 3.742-1.888 9.179-5.07 14.385-9.715 5.797-5.173 9.198-10.628 11.119-14.632 1.654-3.449 5.457-5.399 9.163-4.453 28.615 7.298 48.096 22.186 55.163 28.263 1.898 1.632 2.991 4.038 2.796 6.534-1.968 25.125-22.98 44.901-48.612 44.901z"
    />
    <path
      fill="#E6AF78"
      d="M484.673 281.77c-7.067-6.078-26.549-20.965-55.163-28.263-3.706-.945-7.509 1.005-9.163 4.453-1.455 3.033-3.859 6.897-7.427 10.841-.001.009-.003.018-.004.027-1.107 1.255-2.286 2.512-3.688 3.764-5.206 4.646-10.643 7.827-14.385 9.715-3.124 1.576-4.791 4.979-4.343 8.448 2.78 21.508 19.611 38.5 40.964 41.764-9.73-6.62-16.988-15.593-16.988-31.822V289.11c1.846-1.363 3.705-2.722 5.573-4.389 4.517-4.031 8.46-8.693 11.623-13.712 21.89 6.831 36.731 18.208 42.357 23.046 1.54 1.351 5.362 4.874 9.834 9.069 1.924-4.63 3.196-9.598 3.605-14.819.196-2.496-.897-4.903-2.795-6.535z"
    />
    <path
      fill="#C7CFE2"
      d="M507.046 376.18c3.131 4.154 4.954 9.252 4.954 14.65v48.027c0 4.488-3.639 8.127-8.127 8.127h-24.381v-40.95c0-4.938 2.245-9.608 6.1-12.692l21.454-17.162z"
    />
    <path
      fill="#5A4146"
      d="M24.381 235.683v5.703c0 6.988 1.127 13.93 3.336 20.56l12.918 38.753h24.381v-89.397H48.762c-13.465 0-24.381 10.915-24.381 24.381z"
    />
    <path
      fill="#694B4B"
      d="M135.657 252.565l-13.752 48.133-65.016-56.888c-8.977 0-16.254-7.277-16.254-16.254 0-13.465 10.916-24.381 24.381-24.381h56.889c8.977 0 16.254 7.277 16.254 16.254v15.275c0 6.041-.842 12.052-2.502 17.861z"
    />
    <path fill="#E6AF78" d="M56.889 325.08h48.762v35.969H56.889z" />
    <path
      fill="#D29B6E"
      d="M56.889 340.008c7.538 3.063 15.757 4.787 24.381 4.787s16.843-1.724 24.381-4.787v-14.929H56.889v14.929z"
    />
    <path
      fill="#D5DCED"
      d="M144.856 355.997l-39.208-11.201-24.378 16.178-24.381-16.179-39.206 11.202C7.216 358.987 0 368.554 0 379.44v59.418c0 4.488 3.639 8.127 8.127 8.127h146.286c4.488 0 8.127-3.639 8.127-8.127V379.44c0-10.886-7.217-20.453-17.684-23.443z"
    />
    <path fill="#AFB9D2" d="M89.397 446.984H73.143l4.063-85.935h8.127" />
    <path
      fill="#F0C087"
      d="M81.27 333.206c-26.931 0-48.762-21.831-48.762-48.762v-9.521c0-4.311 1.712-8.445 4.761-11.493l14.737-14.737c3.121-3.121 7.388-4.915 11.799-4.776 25.979.819 48.974 6.385 61.544 18.662 3.077 3.005 4.683 7.209 4.683 11.51v10.355c0 26.931-21.832 48.762-48.762 48.762z"
    />
    <path
      fill="#E6AF78"
      d="M56.889 276.939c0-9.375 7.873-16.827 17.229-16.23 16.407 1.047 40.751 3.789 55.688 10.973-.488-3.429-1.961-6.665-4.457-9.104-12.57-12.277-35.565-17.842-61.544-18.662h-.001c-4.41-.139-8.678 1.656-11.798 4.776L37.269 263.43c-3.049 3.048-4.761 7.183-4.761 11.494v9.521c0 22.238 14.9 40.961 35.251 46.824-6.774-8.358-10.87-18.966-10.87-30.57v-23.76z"
    />
    <path
      fill="#C7CFE2"
      d="M32.508 403.656c0-6.466-2.569-12.668-7.141-17.24l-20.92-20.92C1.651 369.492 0 374.304 0 379.44v59.418c0 4.488 3.639 8.127 8.127 8.127h24.381v-43.329z"
    />
    <path
      fill="#959CB5"
      d="M85.333 377.303h-8.127c-2.244 0-4.063-1.819-4.063-4.064v-12.19h16.254v12.19c0 2.244-1.819 4.064-4.064 4.064z"
    />
    <path
      fill="#C7CFE2"
      d="M81.27 360.974l-13.775 10.522c-2.373 1.813-5.803 1.086-7.237-1.533l-12.357-22.566 4.974-7.587c1.103-1.683 3.454-1.969 4.929-.601l23.466 21.765zm0 0l13.775 10.522c2.373 1.813 5.803 1.086 7.237-1.533l12.357-22.566-4.974-7.587c-1.103-1.683-3.454-1.969-4.929-.601L81.27 360.974z"
    />
    <path
      fill="#5A4146"
      d="M260.064 349.46c53.17 0 100.905-21.741 116.534-29.664 3.187-1.615 4.948-5.069 4.358-8.593-2.522-15.07-9.311-60.208-15.242-140.537-4.824-65.344-47.301-105.651-105.651-105.651s-100.827 40.307-105.651 105.651c-5.93 80.33-12.719 125.467-15.242 140.537-.59 3.524 1.171 6.977 4.358 8.593 15.631 7.923 63.366 29.664 116.536 29.664z"
    />
    <path
      fill="#694B4B"
      d="M376.598 319.797c3.187-1.616 4.948-5.069 4.358-8.593-2.522-15.07-9.311-60.208-15.242-140.537-4.824-65.344-47.302-105.651-105.651-105.651-4.089 0-8.1.198-12.025.589-45.422 4.524-52.242 68.666-8.585 81.994.695.212 1.08.312 1.08.312l16.247 201.468c.022.021.036.034.059.055 54.455.909 103.814-21.555 119.759-29.637z"
    />
    <path
      fill="#E6AF78"
      d="M121.905 438.857v-43.836c0-12.874 7.598-24.535 19.374-29.737l68.462-30.238c5.888-2.601 9.687-8.431 9.687-14.868v-35.734h81.27v35.734c0 6.437 3.799 12.268 9.687 14.868l68.462 30.238c11.777 5.201 19.374 16.863 19.374 29.737v43.836c0 4.488-3.639 8.127-8.127 8.127H130.032c-4.489 0-8.127-3.638-8.127-8.127z"
    />
    <path
      fill="#00C3FF"
      d="M378.848 365.284l-50.497-22.303c-10.501 27.525-37.074 47.114-68.288 47.114s-57.787-19.588-68.288-47.114l-50.497 22.303c-11.776 5.201-19.374 16.863-19.374 29.737v43.836c0 4.488 3.638 8.127 8.127 8.127h260.063c4.489 0 8.127-3.639 8.127-8.127v-43.836c.001-12.874-7.596-24.535-19.373-29.737z"
    />
    <path
      fill="#D29B6E"
      d="M219.429 308c12.383 5.691 26.114 8.952 40.635 8.952s28.252-3.261 40.635-8.952v-23.556h-81.27V308z"
    />
    <path
      fill="#F0C087"
      d="M333.138 186.927c-13.05-11.349-42.348-31.418-92.605-39.016-6.955-1.052-13.969 2.371-17.063 8.689-5.156 10.527-16.122 27.383-37.897 42.237-4.322 2.948-6.78 7.964-6.78 13.195v4.613c0 43.286 32.664 81.081 75.86 83.878 47.333 3.065 86.68-34.42 86.68-81.094v-14.258c0-6.985-2.925-13.661-8.195-18.244z"
    />
    <path
      fill="#E6AF78"
      d="M337.69 192.532c-1.249-2.055-2.697-3.992-4.553-5.605-13.05-11.35-42.349-31.418-92.605-39.016-6.955-1.052-13.968 2.371-17.063 8.688-1.243 2.538-2.906 5.495-4.901 8.654-6.239 9.91-16.413 22.242-32.866 33.493-4.361 2.982-6.91 8.002-6.91 13.285v4.613c0 41.776 30.458 78.339 71.411 83.361-13.999-14.6-22.649-34.371-22.649-56.195v-51.094c0-8.362 6.279-15.344 14.598-16.184 19.539-1.972 56.304-2.141 95.538 16z"
    />
    <path
      fill="#00AAF0"
      d="M134.06 369.742c-7.547 6.078-12.155 15.293-12.155 25.279v43.836c0 4.488 3.639 8.127 8.127 8.127h48.762v-22.409c0-6.466-2.569-12.668-7.141-17.24l-37.593-37.593zm252.007 0c7.547 6.078 12.155 15.293 12.155 25.279v43.836c0 4.488-3.639 8.127-8.127 8.127h-48.762v-22.409c0-6.466 2.569-12.668 7.141-17.24l37.593-37.593z"
    />
  </svg>
);

IconClients.defaultProps = {
  width: 130,
  color: 'default',
};

export default IconClients;
