import Axios from 'axios';
import * as Actions from '../../constant/constants';
import socket from '../../socket';
import {  requestLogin } from '../login/action';

export function getClients() {
  return {
    type: Actions.GET_CLIENTS,
    payload: socket.sendMessage(Actions.GET_CLIENTS, []),
  };
}

export function setClients(event) {
  let clients = [];
  clients = event.data;
  return { type: Actions.GET_CLIENTS, payload: clients };
}

export function getClientSafeInfo(idclient) {
  return {
    type: Actions.GET_CLIENTS,
    payload: socket.sendMessage(Actions.GET_CLIENT_SAFE_INFO, { idclient }),
  };
}

export function setClientSafeInfo(event) {
  return {
    type: Actions.GET_CLIENT_SAFE_INFO,
    payload: event.data,
  };
}

// ADD CLIENT
export function addClient(data) {
  return { type: Actions.ADD_REGISTER_CLIENT, payload: data };
}

export function blockClient(idclient) {
  return {
    type: Actions.BLOCK_CLIENT,
    payload: socket.sendMessage(Actions.BLOCK_CLIENT, { idclient }),
  };
}

export function setBlockedClient(e) {
  return {
    type: Actions.BLOCK_CLIENT,
    payload: e.data,
  };
}

export function approveOrRejectClient(data) {
  return {
    type: Actions.APPROVE_OR_REJECT_CLIENT,
    payload: socket.sendMessage(Actions.APPROVE_OR_REJECT_CLIENT, data)
  }
}

export function setProcessedClient(e) {
  return {
    type: Actions.APPROVE_OR_REJECT_CLIENT,
    payload: e.data
  }
}

export function addRegister(data, platform, formateddate, opc = false, key = null) {
  if (opc === true) {
    return (dispatch) => {
      dispatch({
        type: Actions.ADD_REQUEST,
        payload: Axios({
          method: 'POST',
          url: `${Actions.URL_SERVER}/api/register/newClient/`,
          data: {
            birthday: formateddate,
            cel: data.cellphone,
            city: data.city,
            complement: data.complement,
            country: data.country.value,
            doc: data.document,
            email: data.email,
            password: data.password,
            tipoCliente: data.type,
            idgender: data.gender.id,
            idtheme: data.theme,
            linkCode: data.linkCode,
            locale: data.locale,
            name: data.nameComplete,
            nameclient: data.tradeName,
            razao_social: data.razao_social,
            nomefantasia: data.tradeName,
            district: data.district,
            number: data.number,
            phone1: data.phoneOne,
            phone2: data.phoneTwo,
            picture: '',
            postalcode: data.postalcode,
            state: data.region,
            street: data.street,
            timezone: data.timezone,
            instagram: data.instagram,
            twitter: data.twitter,
            facebook: data.facebook,
            linkedin: data.linkedin,

          },
        }).then(({data}) => {
            setTimeout(() => {
              dispatch(
                requestLogin(
                  data.user,
                  data.password,
                  platform,
                  key,
                  false,
                  '/',
                ),
              );
            }, 5000);
        }),
      });
    };
  }
  return {
    type: Actions.ADD_REQUEST,
    payload: socket.sendMessage(Actions.ADD_CLIENT, {
      birthday: formateddate,
      cel: data.cellphone,
      city: data.city,
      complement: data.complement,
      country: data.country.value,
      doc: data.document,
      email: data.email,
      tipoCliente: data.type,
      idgender: data.gender.id,
      idtheme: data.theme,
      locale: data.locale,
      name: data.nameComplete,
      nameclient: data.tradeName,
      razao_social: data.razao_social,
      tradeName: data.tradeName,
      district: data.district,
      number: data.number,
      phone1: data.phoneOne,
      phone2: data.phoneTwo,
      picture: '',
      postalcode: data.postalcode,
      state: data.region,
      street: data.street,
      timezone: data.timezone,
      instagram: data.instagram,
      twitter: data.twitter,
      facebook: data.facebook,
      linkedin: data.linkedin,
    }),
  };
}

// SELECT REGISTER
export function selectRegister(register) {
  return {
    type: Actions.REGISTER_SELECTED_CLIENT,
    payload: register,
  };
}

// EDIT CLIENT
export function editClient(data) {
  return { type: Actions.UPDATE_REGISTER_CLIENT, payload: data };
}

export function editRegister(
  data,
  formateddate,
  idclient,
  idperson,
  idcontact,
  idaddress,
) {
  return {
    type: Actions.UPDATE_REQUEST,
    payload: socket.sendMessage(Actions.UPDATE_CLIENT, {
      birthday: formateddate,
      cel: data.cellphone,
      city: data.city,
      complement: data.complement,
      country: data.country.value,
      doc: data.document,
      email: data.email,
      idaddress,
      idclient,
      tipoCliente: data.type,
      idcontact,
      idgender: data.gender.id,
      idperson,
      idtheme: data.theme,
      locale: data.locale,
      name: data.nameComplete,
      razao_social: data.razao_social,
      nameclient: data.tradeName,
      tradeName: data.tradeName,
      district: data.district,
      number: data.number,
      phone1: data.phoneOne,
      phone2: data.phoneTwo,
      picture: '',
      postalcode: data.postalcode,
      state: data.region,
      street: data.street,
      timezone: data.timezone,
      instagram: data.instagram,
      twitter: data.twitter,
      facebook: data.facebook,
      linkedin: data.linkedin,
    }),
  };
}

// DELETE CLIENT
export function deleteClient(data) {
  return { type: Actions.DELETE_REGISTER_CLIENT, payload: data };
}

export function deleteRegister(idclient) {
  return {
    type: Actions.DELETE_REQUEST,
    payload: socket.sendMessage(Actions.DELETE_CLIENT, {
      idclient,
    }),
  };
}

// SET LOGO
export function setLogo(idclient, token, img) {
  const imagesTestingRegex =
    /^\s*data:([a-z]+\/[a-z0-9\-+]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,[a-z0-9!$&',()*+,;=\-._~:@/?%\s]*\s*$/i;
  const imageIsValid = imagesTestingRegex.test(img);

  return (dispatch) => {
    if (img && !imageIsValid) {
      dispatch(setLogoError({ error: 'Invalid image' }));
      return;
    }

    dispatch({
      type: Actions.SET_LOGO_REQUEST,
      payload: Axios({
        method: 'POST',
        url: `${Actions.URL_SERVER}/client/${idclient}`,
        data: { idclient, token, img },
      })
        .then((res) => {
          dispatch(setLogoSuccess(res.data));
        })
        .catch((err) => {
          dispatch(setLogoError(err));
        }),
    });
  };
}

export function setLogoSuccess(dataReceivedAfterUpdate) {
  return {
    type: Actions.SET_LOGO,
    payload: dataReceivedAfterUpdate,
  };
}

export function setLogoError(err) {
  return {
    type: Actions.SET_LOGO_REJECTED,
    payload: err,
  };
}
