import * as Actions from '../../constant/constants';
import socket from '../../socket';
import { VARIABLES_DEFAULT_MESSAGES } from '../../constant/defaultMessages';

export function getVariables(event) {
  return {
    type: Actions.GET_VARIABLES,
    payload: socket.sendMessage(Actions.GET_VARIABLES, []),
  };
}

export function setVariables(event) {
  let variabls = [];
  if (typeof event === 'undefined') socket.sendMessage(Actions.GET_MEASURES, []);
  else {
    variabls = event.data.map((v) => {
      convertMessages(v, VARIABLES_DEFAULT_MESSAGES);
      return v;
    });
  }
  return {
    type: Actions.GET_VARIABLES,
    payload: variabls,
  };
}

export function convertMessages(data, constMessage = {}) {
  const nameField = data.name;
  const nameFieldNoSpace = nameField.replace(/\s/g, '');
  data.idMessageName = `app.formula.variable.${nameFieldNoSpace}`;
  data.defaultMessageName = constMessage[nameFieldNoSpace] || data.name;
  return data;
}
