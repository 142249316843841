import React from 'react';

const IconAddNewEvaluation = (props) => (
  <svg
    className="icon-add-new-evaluation"
    viewBox="-49 141 512 512"
    width={props.width}
    height={props.height}
  >
    <clipPath id="SVGID_2_">
      <circle id="SVGID_1_" cx="207" cy="397" r="255.4" />
    </clipPath>
    <circle className="st0" cx="207" cy="397" r="255.4" />
    <g className="st1">
      <path
        className="st2"
        d="M336.6 200.4H3.4c-.8 0-1.5.7-1.5 1.5v110.5c0 .8.7 1.5 1.5 1.5h333.3c.8 0 1.5-.7 1.5-1.5V201.8c-.1-.8-.8-1.4-1.6-1.4zm16.1 333l-5.1-2.8c-9.3-5-16.2-13.7-19.1-23.8l-2.1-7.4c-.2-.7-.8-1.1-1.5-1.1l-7.7.2c-8.1-.1-14.6-6.7-14.6-14.8 0-.6 0-1.3.1-1.9l1.5-11.1c.1-.4-.1-.8-.4-1.2-.3-.3-.7-.5-1.1-.5H3.4c-.8 0-1.5.7-1.5 1.5V581c0 .8.7 1.5 1.5 1.5h284.7l3.4.6h.2c.3 0 .5-.1.8-.2l3.1-1.9c2.1-1.3 4.3-2.4 6.2-3l50.7-19.9c.6-.2.9-.8.9-1.4v-22c.1-.5-.2-1-.7-1.3zm-16.1-198.7H3.4c-.8 0-1.5.7-1.5 1.5v110.5c0 .8.7 1.5 1.5 1.5h297.1c.8 0 1.4-.6 1.5-1.3l.8-8.9c1.7-17.9 11.6-26.4 29.3-35.7l5.3-2.8c.5-.3.8-.8.8-1.3v-62c-.1-.9-.8-1.5-1.6-1.5z"
      />
      <path
        className="st3"
        d="M201.8 251.7h-81.6c-4.1 0-7.4-3.3-7.4-7.4s3.3-7.4 7.4-7.4h81.6c4.1 0 7.4 3.3 7.4 7.4s-3.3 7.4-7.4 7.4zm81.5 32.7H120.2c-4.1 0-7.4-3.3-7.4-7.4s3.3-7.4 7.4-7.4h163.1c4.1 0 7.4 3.3 7.4 7.4s-3.3 7.4-7.4 7.4zM201.8 386h-81.6c-4.1 0-7.4-3.3-7.4-7.4 0-4.1 3.3-7.4 7.4-7.4h81.6c4.1 0 7.4 3.3 7.4 7.4 0 4.1-3.3 7.4-7.4 7.4zm79.7 32.7H120.2c-4.1 0-7.4-3.3-7.4-7.4 0-4.1 3.3-7.4 7.4-7.4h161.3c4.1 0 7.4 3.3 7.4 7.4 0 4.1-3.3 7.4-7.4 7.4zm-79.7 101.7h-81.6c-4.1 0-7.4-3.3-7.4-7.4s3.3-7.4 7.4-7.4h81.6c4.1 0 7.4 3.3 7.4 7.4s-3.3 7.4-7.4 7.4zm78.6 32.6H120.2c-4.1 0-7.4-3.3-7.4-7.4 0-4.1 3.3-7.4 7.4-7.4h160.2c4.1 0 7.4 3.3 7.4 7.4 0 4.1-3.3 7.4-7.4 7.4z"
      />
      <path
        className="st4"
        d="M85.1 292.6H33.7c-4.1 0-7.4-3.3-7.4-7.4v-49c0-4.1 3.3-7.4 7.4-7.4H71c4.1 0 7.4 3.3 7.4 7.4 0 4.1-3.3 7.4-7.4 7.4H41.1v34.2h36.6V272c0-4.1 3.3-7.4 7.4-7.4 4.1 0 7.4 3.3 7.4 7.4v13.2c0 4-3.3 7.4-7.4 7.4z"
      />
      <path
        className="st5"
        d="M64.5 275.4c-2.1 0-4.1-.9-5.4-2.4l-13-14.1c-2.8-3-2.6-7.7.4-10.4 3-2.8 7.7-2.6 10.4.4l7.6 8.3 24.6-26.1c2.8-3 7.5-3.1 10.5-.3 3 2.8 3.1 7.5.3 10.5l-30 31.9c-1.4 1.4-3.4 2.2-5.4 2.2z"
      />
      <path
        className="st4"
        d="M85.1 426.9H33.7c-4.1 0-7.4-3.3-7.4-7.4v-49c0-4.1 3.3-7.4 7.4-7.4H71c4.1 0 7.4 3.3 7.4 7.4 0 4.1-3.3 7.4-7.4 7.4H41.1v34.2h36.6v-5.8c0-4.1 3.3-7.4 7.4-7.4 4.1 0 7.4 3.3 7.4 7.4v13.2c0 4.1-3.3 7.4-7.4 7.4z"
      />
      <path
        className="st5"
        d="M64.5 409.7c-2.1 0-4.1-.9-5.4-2.4l-13-14.1c-2.8-3-2.6-7.7.4-10.4 3-2.8 7.7-2.6 10.4.4l7.6 8.3 24.6-26.1c2.8-3 7.5-3.1 10.5-.3 3 2.8 3.1 7.5.3 10.5l-30 31.9c-1.4 1.4-3.4 2.2-5.4 2.2z"
      />
      <path
        className="st4"
        d="M85.1 561.2H33.7c-4.1 0-7.4-3.3-7.4-7.4v-49c0-4.1 3.3-7.4 7.4-7.4H71c4.1 0 7.4 3.3 7.4 7.4 0 4.1-3.3 7.4-7.4 7.4H41.1v34.2h36.6v-5.8c0-4.1 3.3-7.4 7.4-7.4 4.1 0 7.4 3.3 7.4 7.4v13.2c0 4.1-3.3 7.4-7.4 7.4z"
      />
      <path
        className="st5"
        d="M64.5 544c-2.1 0-4.1-.9-5.4-2.4l-13-14.1c-2.8-3-2.6-7.7.4-10.4 3-2.8 7.7-2.6 10.4.4l7.6 8.3 24.6-26.1c2.8-3 7.5-3.1 10.5-.3 3 2.8 3.1 7.5.3 10.5l-30 31.8c-1.4 1.5-3.4 2.3-5.4 2.3z"
      />
      <path
        className="st6"
        d="M455 615.1v-2.6c0-.4 0-.8-.1-1.2 0-.5-.1-1-.1-1.5 0-.2 0-.4-.1-.6-.1-.7-.1-1.3-.2-1.9v-.2c-1.8-13.1-7.7-22.7-14.4-29.2l-.1-.1c-.4-.3-.7-.7-1.1-1-.2-.2-.4-.3-.5-.5-.2-.2-.4-.4-.6-.5-.3-.3-.7-.6-1-.9l-.1-.1c-4.3-3.5-8.6-5.7-12-6.9h-.1l-44.3-17.8-11.1 11.5-11.3 11-11.3-11-11.1-11.5-44.3 17.8h-.1c-3.4 1.2-7.7 3.4-12 6.9l-.1.1c-.3.3-.7.6-1 .9-.2.2-.4.4-.6.5-.2.2-.4.3-.5.5-.4.3-.7.7-1.1 1l-.1.1c-6.7 6.5-12.7 16.1-14.4 29.2v.2c-.1.6-.1 1.3-.2 1.9 0 .2 0 .4-.1.6 0 .5-.1 1-.1 1.5 0 .4-.1.8-.1 1.2v31.1c.3 3.4 2 9.3 10.7 9.3h173c8.7 0 10.4-5.9 10.7-9.3v-21.5l-.2-7z"
      />
      <path
        className="st7"
        d="M335.2 550.7L291.1 568h-.1c-11.3 4-30.4 18.5-30.4 47.1v28.6c.3 3.4 2 9.3 10.7 9.3h2.2v-37.8c0-28.6 19.1-43.1 30.4-47.1h.1l34.8-13.6-3.6-3.8z"
      />
      <path
        className="st8"
        d="M360.1 389.1h-4.8c-32.6 0-59.2 26.5-59.2 59.2V476s13.5-1.3 15.1-7.9c.6-.6 1-1.3 1.4-2.1 7.6-.2 43.3-.5 63.7-25.1 2.4 7.1 10.7 20.6 26.5 24.8.3.9.8 1.6 1.4 2.3l-.1.1c1.7 6.5 15.1 7.9 15.1 7.9v-27.7c0-32.7-26.5-59.2-59.1-59.2zm44.6 74.8l.6-.4h.1l-.9.7.2-.3zm-.3.1l.1-.2.2-.4h.1l-.2.5-.2.1zm-1.6 1.2l1.4-1.1-.3.3-1.1.8zm1.2-.3l-.5.9h-.1l.7-1.5-.1.6z"
      />
      <path
        className="st9"
        d="M306.6 463.5v-15.3c0-31.7 25-57.6 56.3-59.1-.9 0-1.9-.1-2.8-.1h-4.8c-32.6 0-59.2 26.5-59.2 59.2V476s6-.6 10.4-2.9v-9.6h.1z"
      />
      <path
        className="st10"
        d="M412.1 467.4c-2.3-.7-4.7-.3-6.6 1.1-1.9 1.4-3 3.6-3 6v21.2c0 2.4 1.1 4.6 3 6 1.3 1 2.8 1.5 4.4 1.5.7 0 1.5-.1 2.2-.3 1.4-.4 2.8-1.1 4.2-1.9.8-.5 1.5-1.1 2-1.8.8-.4 1.6-1 2.2-1.7 3-3.4 4.7-7.8 4.7-12.3 0-8.3-5.3-15.4-13.1-17.8z"
      />
      <path
        className="st11"
        d="M310.1 468.5c-1.9-1.4-4.4-1.8-6.6-1.1-7.8 2.4-13 9.6-13 17.7 0 8.2 5.2 15.3 13 17.7.7.2 1.5.3 2.2.3 1.6 0 3.1-.5 4.4-1.4 1.9-1.4 3-3.6 3-6v-21.2c0-2.4-1.1-4.6-3-6z"
      />
      <path
        className="st10"
        d="M378.1 532.7c-1.5-1.4-3.4-2.1-5.5-2.1h-.3c-.5 0-.9.1-1.3.2-2.9.6-5.8 1-8.7 1h-9.1c-3 0-6-.3-9-1-2.2-.5-4.5 0-6.3 1.4-1.8 1.4-2.8 3.6-2.8 5.8v21.8c-.1 2.2.7 4.2 2.2 5.7l15 14.6c1.4 1.4 3.3 2.1 5.2 2.1h.1c2 0 3.9-.9 5.2-2.3l15.4-16c1.3-1.4 2.1-3.2 2.1-5.2V538c.1-1.9-.7-3.9-2.2-5.3z"
      />
      <path
        className="st11"
        d="M359.1 577.9l-15-14.6c-1.5-1.5-2.4-3.6-2.2-5.7v-21.8c0-1.9.7-3.7 2-5.1-2.1-.4-4.2.2-5.9 1.5-1.8 1.4-2.8 3.6-2.8 5.8v21.8c-.1 2.2.7 4.2 2.2 5.7l15 14.6c1.4 1.4 3.3 2.1 5.2 2.1h.1c2 0 3.9-.9 5.2-2.3l.1-.1c-1.5-.2-2.9-.9-3.9-1.9z"
      />
      <path
        className="st10"
        d="M401.3 461.3c-.9-.3-1.8-.7-2.6-1.1-12.5-5.8-17.7-13.4-19.8-18-.1-.2-.2-.4-.4-.6-1.5-2.7-3.8-1-3.8-1-11.7 9-24.5 14.2-35.1 17.3-4.1 1.2-8.1 2.1-11.7 2.7-7.1 1.3-11.7 1.5-11.7 1.5-3.9.3-6.9 3.5-6.9 7.4v30.4c0 24.2 19.7 43.8 43.8 43.8h9.1c24.2 0 43.8-19.7 43.8-43.8v-31.6c.2-3.1-1.8-5.9-4.7-7z"
      />
      <path
        className="st11"
        d="M318.3 499.9v-30.4c0-3.9 3-7.1 6.9-7.4 0 0 4.6-.2 11.7-1.5 3.6-.6 7.5-1.5 11.7-2.7 9.4-2.7 20.5-7.1 31-14.3-.2-.5-.5-.9-.7-1.3-.1-.2-.2-.4-.4-.6-1.5-2.7-3.8-1-3.8-1-11.7 9-24.5 14.2-35.1 17.3-4.1 1.2-8.1 2.1-11.7 2.7-7.1 1.3-11.7 1.5-11.7 1.5-3.9.3-6.9 3.5-6.9 7.4V500c0 24.2 19.7 43.8 43.8 43.8h8.9c-24-.1-43.7-19.7-43.7-43.9z"
      />
    </g>
    <circle className="st12" cx="15" cy="589.2" r="63.4" />
    <path
      className="st13"
      d="M29 639c-35.1 0-63.4-28.5-63.4-63.4 0-14 4.5-26.9 12.2-37.4-15.8 11.4-26.2 30.2-26.2 51.2 0 35.1 28.5 63.4 63.4 63.4 21.1 0 39.8-10.4 51.2-26.2C55.9 634.3 43 639 29 639z"
    />
    <path
      className="st3"
      d="M47.2 583.2H20.7V557c0-2-1.8-3.6-4-3.6h-4c-2.2 0-4 1.6-4 3.6v26.2h-26.2c-2 0-3.6 1.8-3.6 4v4c0 2.2 1.6 4 3.6 4H8.7v26.2c0 2 1.8 3.6 4 3.6h4c2.2 0 4-1.6 4-3.6v-26.2h26.7c2 0 3.6-1.8 3.6-4v-4c-.2-2.1-1.8-4-3.8-4z"
    />
  </svg>
);

IconAddNewEvaluation.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconAddNewEvaluation;
