import React from 'react';

const IconParQ = (props) => (
  <svg
    fill="none"
    width={props.width}
    height={props.height}
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4_2)">
      <path
        d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
        fill="#273B7A"
      />
      <path
        d="M158.7 401.2H353.2C361.8 401.2 368.8 394.2 368.8 385.6V126.5C368.8 117.9 361.8 110.9 353.2 110.9H158.7C150.1 110.9 143.1 117.9 143.1 126.5V385.6C143.1 394.2 150.1 401.2 158.7 401.2Z"
        fill="#FF7F4F"
      />
      <path
        d="M353.3 110.8H255.5V401.2H353.3C361.9 401.2 368.9 394.2 368.9 385.6V126.5C368.9 117.8 361.9 110.8 353.3 110.8Z"
        fill="#D35933"
      />
      <path d="M158.6 128.4H353.4V380.1H158.6V128.4Z" fill="#E4E4E4" />
      <path d="M255.4 128.4H353.4V380.1H255.4V128.4Z" fill="#CDCDCD" />
      <path
        d="M308.7 99.1H284.1C282 85.5 270.2 75 256 75C241.8 75 230 85.5 227.9 99.1H203.3C195.3 99.1 188.8 105.6 188.8 113.6V132.8C188.8 140.8 195.3 147.3 203.3 147.3H308.7C316.7 147.3 323.2 140.8 323.2 132.8V113.6C323.2 105.6 316.7 99.1 308.7 99.1ZM256 100C252.7 100 250 97.3 250 94C250 90.7 252.7 88 256 88C259.3 88 262 90.7 262 94C262 97.3 259.3 100 256 100Z"
        fill="#D0D1D3"
      />
      <path
        d="M308.7 99.1H284.1C282 85.5 270.2 75 256 75H255.4V88C255.6 88 255.8 87.9 256 87.9C259.3 87.9 262 90.6 262 93.9C262 97.2 259.3 99.9 256 99.9C255.8 99.9 255.6 99.9 255.4 99.8V147.3H308.7C316.7 147.3 323.2 140.8 323.2 132.8V113.6C323.2 105.6 316.7 99.1 308.7 99.1Z"
        fill="#A6A8AA"
      />
      <rect x="200" y="155" width="109" height="12" rx="6" fill="#FCCC3B" />
      <rect x="203" y="318" width="109" height="12" rx="6" fill="#FCCC3B" />
      <rect x="203" y="344" width="109" height="12" rx="6" fill="#FCCC3B" />
      <rect x="203" y="291" width="109" height="12" rx="6" fill="#FCCC3B" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M238 177H273V212H308V247H273V282H238V247H203V212H238V177Z"
        fill="#FF7F4F"
      />
    </g>
    <defs>
      <clipPath id="clip0_4_2">
        <rect width="512" height="512" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconParQ.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconParQ;
