import React from 'react';

const IconEvaluation = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width={props.width}
    height={props.height}
  >
    <path
      fill="#E4F1FB"
      d="M378.251.305H1.671C.749.305 0 1.053 0 1.976v124.909c0 .923.749 1.671 1.671 1.671h376.58c.923 0 1.671-.749 1.671-1.671V1.976c0-.923-.749-1.671-1.671-1.671zm18.148 376.315l-5.819-3.165c-10.46-5.688-18.31-15.495-21.54-26.905l-2.365-8.354c-.209-.738-.865-1.244-1.657-1.216l-8.703.253-.033.004c-9.115-.114-16.531-7.622-16.531-16.737 0-.701.049-1.413.15-2.178l1.652-12.592c.064-.478-.084-.958-.4-1.319-.318-.361-.775-.569-1.257-.569H1.671c-.923 0-1.671.749-1.671 1.671V430.42c0 .922.749 1.671 1.671 1.671h321.707l3.832.64c.091.016.184.022.275.022.307 0 .611-.085.876-.248l3.555-2.187c2.386-1.468 4.836-2.668 6.966-3.401l57.327-22.439c.641-.251 1.062-.868 1.062-1.555v-24.835c0-.613-.335-1.176-.872-1.468zm-18.148-224.547H1.671c-.923 0-1.671.749-1.671 1.671v124.907c0 .923.749 1.671 1.671 1.671h335.706c.862 0 1.584-.657 1.663-1.516l.94-10.11c1.885-20.272 13.081-29.802 33.081-40.296l5.965-3.131c.549-.289.895-.859.895-1.48v-70.046c.001-.921-.748-1.67-1.67-1.67z"
    />
    <path
      fill="#FFF"
      d="M225.879 58.359h-92.154c-4.616 0-8.356-3.74-8.356-8.356s3.74-8.356 8.356-8.356h92.154c4.616 0 8.356 3.74 8.356 8.356s-3.74 8.356-8.356 8.356zm92.154 36.878H133.725c-4.616 0-8.356-3.74-8.356-8.356s3.74-8.356 8.356-8.356h184.308c4.616 0 8.356 3.74 8.356 8.356s-3.741 8.356-8.356 8.356zm-92.154 114.887h-92.154c-4.616 0-8.356-3.74-8.356-8.356s3.74-8.356 8.356-8.356h92.154c4.616 0 8.356 3.74 8.356 8.356s-3.74 8.356-8.356 8.356zm90.125 36.88H133.725c-4.616 0-8.356-3.74-8.356-8.356s3.74-8.356 8.356-8.356h182.279c4.616 0 8.356 3.74 8.356 8.356 0 4.615-3.742 8.356-8.356 8.356zm-90.125 114.888h-92.154c-4.616 0-8.356-3.74-8.356-8.356s3.74-8.356 8.356-8.356h92.154c4.616 0 8.356 3.74 8.356 8.356s-3.74 8.356-8.356 8.356zm88.818 36.88H133.725c-4.616 0-8.356-3.74-8.356-8.356s3.74-8.356 8.356-8.356h180.972c4.616 0 8.356 3.74 8.356 8.356 0 4.615-3.74 8.356-8.356 8.356z"
    />
    <path
      fill="#324A5E"
      d="M94.078 104.49H35.977c-4.615 0-8.356-3.74-8.356-8.356V40.751c0-4.616 3.741-8.356 8.356-8.356h42.148c4.616 0 8.356 3.74 8.356 8.356s-3.74 8.356-8.356 8.356H44.333v38.671h41.389v-6.586c0-4.616 3.74-8.356 8.356-8.356s8.356 3.74 8.356 8.356v14.942c0 4.615-3.74 8.356-8.356 8.356z"
    />
    <path
      fill="#75BF4D"
      d="M70.762 85.095h-.055c-2.322-.016-4.531-.996-6.102-2.706L49.951 66.426c-3.121-3.399-2.895-8.685.507-11.807 3.398-3.12 8.684-2.896 11.805.506l8.579 9.347L98.599 35.02c3.165-3.361 8.454-3.514 11.811-.35 3.359 3.165 3.515 8.454.351 11.811L76.843 82.47c-1.578 1.677-3.78 2.625-6.081 2.625z"
    />
    <path
      fill="#324A5E"
      d="M94.078 256.256H35.977c-4.615 0-8.356-3.74-8.356-8.356v-55.383c0-4.616 3.741-8.356 8.356-8.356h42.148c4.616 0 8.356 3.74 8.356 8.356s-3.74 8.356-8.356 8.356H44.333v38.671h41.389v-6.586c0-4.616 3.74-8.356 8.356-8.356s8.356 3.74 8.356 8.356V247.9c0 4.616-3.74 8.356-8.356 8.356z"
    />
    <path
      fill="#75BF4D"
      d="M70.762 236.862h-.055c-2.322-.016-4.531-.996-6.102-2.706l-14.654-15.965c-3.121-3.399-2.895-8.685.507-11.807 3.398-3.12 8.684-2.896 11.805.506l8.579 9.347 27.757-29.451c3.165-3.36 8.454-3.514 11.811-.35 3.359 3.165 3.515 8.454.351 11.812l-33.918 35.989c-1.578 1.677-3.78 2.625-6.081 2.625z"
    />
    <path
      fill="#324A5E"
      d="M94.078 408.024H35.977c-4.615 0-8.356-3.741-8.356-8.356v-55.383c0-4.616 3.741-8.356 8.356-8.356h42.148c4.616 0 8.356 3.74 8.356 8.356s-3.74 8.356-8.356 8.356H44.333v38.671h41.389v-6.586c0-4.616 3.74-8.356 8.356-8.356s8.356 3.74 8.356 8.356v14.942c0 4.616-3.74 8.356-8.356 8.356z"
    />
    <path
      fill="#75BF4D"
      d="M70.762 388.631h-.055c-2.322-.016-4.531-.995-6.102-2.706L49.951 369.96c-3.121-3.399-2.895-8.685.507-11.807 3.398-3.12 8.684-2.896 11.805.506l8.579 9.347 27.757-29.451c3.165-3.361 8.454-3.514 11.811-.35 3.359 3.165 3.515 8.454.351 11.812l-33.918 35.988c-1.578 1.676-3.78 2.626-6.081 2.626z"
    />
    <path
      fill="#6598CB"
      d="M512 468.959c0-.888-.023-1.757-.055-2.624-.007-.121-.004-.246-.011-.368-.018-.44-.053-.866-.08-1.3-.033-.552-.061-1.109-.107-1.651-.019-.227-.051-.447-.071-.672-.072-.741-.147-1.481-.242-2.202-.008-.062-.02-.123-.028-.185-1.98-14.802-8.719-25.613-16.297-32.98-.035-.036-.068-.069-.105-.105-.399-.383-.794-.76-1.195-1.125-.197-.183-.396-.355-.597-.531-.231-.208-.465-.416-.695-.614-.384-.333-.77-.653-1.156-.966-.043-.033-.085-.07-.128-.103-4.859-3.93-9.723-6.487-13.537-7.806-.017-.006-.032-.013-.05-.018l-.1-.038c-.012-.002-.025-.007-.037-.012l-50.054-20.07-12.506 12.979-12.789 12.456-12.788-12.456-12.504-12.979-50.057 20.07c-.01.006-.025.01-.036.012l-.1.038c-.016.004-.033.012-.048.018-3.817 1.319-8.68 3.876-13.54 7.806-.04.032-.086.068-.125.103-.387.313-.774.633-1.156.966-.234.198-.468.406-.697.614-.202.175-.399.349-.597.531-.401.365-.798.742-1.195 1.125-.036.036-.068.068-.107.105-7.575 7.366-14.314 18.176-16.294 32.98-.009.062-.02.123-.028.185-.096.72-.169 1.461-.242 2.202-.02.224-.052.443-.07.672-.048.543-.075 1.1-.109 1.651-.027.435-.064.861-.082 1.3-.004.121-.002.246-.008.368-.031.867-.056 1.737-.056 2.624v7.956h.001v24.313c.326 3.793 2.291 10.466 12.102 10.466h195.475c9.81 0 11.772-6.673 12.101-10.466v-32.269z"
    />
    <path
      fill="#4982AA"
      d="M376.624 396.158l-49.795 19.502-.155.055c-12.758 4.551-34.355 20.887-34.355 53.246v32.269c.326 3.793 2.291 10.466 12.102 10.466h2.521V468.96c0-32.359 21.596-48.695 34.355-53.246l.154-.055 39.364-15.415-4.191-4.086z"
    />
    <path
      fill="#694B4B"
      d="M404.736 213.526h-5.398c-36.858 0-66.842 29.988-66.842 66.844v31.358s15.217-1.51 17.09-8.89l-.011-.008c.643-.697 1.181-1.496 1.561-2.385 8.632-.261 48.955-.575 71.984-28.386 2.749 8.039 12.045 23.261 29.934 28.056.365.969.914 1.844 1.583 2.603l-.148.119c1.871 7.379 17.09 8.89 17.09 8.89v-31.358c.001-36.855-29.986-66.843-66.843-66.843zm50.397 84.513l.642-.466h.092l-1.05.777.316-.311zm-.327.2l.074-.225.224-.441h.091l-.266.55-.123.116zm-1.774 1.372l-.023-.036 1.647-1.192-.388.377-1.206.89-.03-.039zm1.361-.387l-.518 1.067c-.004-.001-.004-.001-.007-.002l-.074-.035c-.011-.003-.026-.013-.035-.02l.842-1.671.022-.022-.23.683z"
    />
    <path
      fill="#563B3B"
      d="M344.279 297.63v-17.26c0-35.787 28.27-65.089 63.651-66.763-1.06-.05-2.124-.081-3.193-.081h-5.398c-36.858 0-66.842 29.988-66.842 66.844v31.358s6.724-.67 11.782-3.284V297.63z"
    />
    <path
      fill="#FBCB99"
      d="M463.553 302.037c-2.557-.798-5.336-.332-7.495 1.252-2.154 1.587-3.427 4.106-3.427 6.782v23.983c0 2.675 1.271 5.192 3.427 6.776 1.464 1.077 3.217 1.639 4.989 1.639.838 0 1.682-.125 2.501-.381 1.613-.502 3.2-1.228 4.712-2.156.888-.545 1.669-1.255 2.301-2.085.927-.479 1.761-1.126 2.451-1.907 3.403-3.845 5.277-8.773 5.277-13.881.002-9.226-5.921-17.274-14.736-20.022z"
    />
    <path
      fill="#EDBA89"
      d="M348.261 303.29c-2.157-1.584-4.937-2.05-7.493-1.253-8.814 2.749-14.738 10.796-14.738 20.024 0 9.233 5.923 17.28 14.738 20.029.819.255 1.663.381 2.505.381 1.774 0 3.522-.562 4.987-1.638 2.158-1.585 3.43-4.102 3.43-6.777V310.07c.002-2.675-1.271-5.194-3.429-6.78z"
    />
    <path
      fill="#FBCB99"
      d="M425.139 375.863c-1.654-1.584-3.874-2.414-6.181-2.33l-.287.014c-.514.018-1.026.082-1.525.194-3.253.722-6.565 1.09-9.839 1.09h-10.289c-3.374 0-6.781-.387-10.132-1.153-2.502-.578-5.124.019-7.125 1.617-2.004 1.598-3.173 4.022-3.173 6.582v24.483l-.006.081c-.134 2.43.789 4.797 2.53 6.495l16.913 16.47c1.571 1.533 3.678 2.385 5.872 2.385h.135c2.239-.037 4.372-.963 5.925-2.577l17.422-18.085c1.512-1.568 2.354-3.659 2.354-5.835v-23.351c-.001-2.297-.937-4.493-2.594-6.08z"
    />
    <path
      fill="#EDBA89"
      d="M403.6 426.88l-16.913-16.469c-1.744-1.697-2.665-4.064-2.531-6.495l.009-.082v-24.482c0-2.154.83-4.2 2.285-5.751-2.368-.411-4.801.194-6.689 1.693-2.004 1.598-3.173 4.022-3.173 6.582v24.483l-.006.081c-.134 2.43.789 4.797 2.53 6.495l16.913 16.47c1.571 1.533 3.678 2.385 5.872 2.385h.135c2.239-.037 4.372-.963 5.925-2.577l.074-.079c-1.659-.284-3.209-1.063-4.431-2.254z"
    />
    <path
      fill="#FBCB99"
      d="M451.363 295.168c-1.019-.379-1.979-.82-2.916-1.291-14.143-6.598-20.012-15.18-22.375-20.301-.142-.194-.281-.406-.427-.673-1.691-3.107-4.324-1.141-4.324-1.141l-.011-.007c-13.207 10.131-27.632 16.054-39.626 19.501-4.668 1.379-9.14 2.379-13.186 3.102-8.037 1.476-13.206 1.669-13.206 1.669-4.402.319-7.813 3.983-7.813 8.396v34.341c0 27.314 22.222 49.535 49.536 49.535h10.289c27.311 0 49.534-22.22 49.534-49.535v-35.709c.002-3.516-2.184-6.658-5.475-7.887z"
    />
    <path
      fill="#EDBA89"
      d="M357.581 338.764v-34.341c0-4.413 3.408-8.078 7.811-8.396 0 0 5.169-.193 13.207-1.669 4.047-.723 8.518-1.722 13.189-3.102 10.611-3.052 23.133-8.047 35.029-16.18-.28-.524-.528-1.025-.745-1.5-.142-.194-.281-.406-.427-.673-1.691-3.107-4.324-1.141-4.324-1.141l-.011-.007c-13.207 10.131-27.632 16.054-39.626 19.501-4.668 1.379-9.14 2.379-13.186 3.102-8.037 1.476-13.206 1.669-13.206 1.669-4.402.319-7.813 3.983-7.813 8.396v34.341c0 27.314 22.222 49.535 49.536 49.535h10.095c-27.307 0-49.529-22.221-49.529-49.535z"
    />
  </svg>
);

IconEvaluation.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconEvaluation;
