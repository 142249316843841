import React from 'react';

const IconAerobicsCapacity = (props) => (
  <svg
    className="icon-aerobics-capacity"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-223 25 512 512"
    width={props.width}
    height={props.height}
  >
    <circle className="st0" cx="33" cy="281" r="256" />
    <path className="st1" d="M-37.4 358.1H-116l73.4-197.6h26.3z" />
    <path
      className="st2"
      d="M156.9 414.9H-73.6c-27.3 0-49.4-22.1-49.4-49.4 0-27.3 22.1-49.4 49.4-49.4h230.5c27.3 0 49.4 22.1 49.4 49.4 0 27.3-22.2 49.4-49.4 49.4z"
    />
    <path
      className="st1"
      d="M-123 365.5c0 27.4 22.1 49.5 49.4 49.5h230.5c27.3 0 49.4-22.1 49.4-49.4v-.1H-123z"
    />
    <path className="st1" d="M-57.2 341.6h197.6v47.9H-57.2z" />
    <path className="st3" d="M-57.2 365.5h197.6v24H-57.2z" />
    <circle className="st4" cx="-73.6" cy="365.6" r="32.9" />
    <path
      className="st5"
      d="M-106.5 365.5s-.1 0 0 0c0 18.3 14.7 33 32.9 33s32.9-14.7 32.9-32.9v-.1h-65.8z"
    />
    <circle className="st4" cx="156.9" cy="365.6" r="32.9" />
    <path
      className="st5"
      d="M123.9 365.5c0 18.3 14.7 33 32.9 33s32.9-14.7 32.9-32.9v-.1h-65.8z"
    />
    <path
      className="st6"
      d="M-52.4 123.4l23.4 23.4c3 3 7.2 4.7 11.5 4.7h42.9c8.9 0 16.2 7.3 16.2 16.2v.5c0 8.9-7.3 16.2-16.2 16.2h-72.6c-6.1 0-11.7-3.5-14.5-9L-78.4 142c-5.4-10.8 2.4-23.4 14.5-23.4 4.3 0 8.4 1.7 11.5 4.8z"
    />
  </svg>
);

IconAerobicsCapacity.defaultProps = {
  width: 120,
  color: 'default',
};

export default IconAerobicsCapacity;
