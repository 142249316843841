import React, { Component } from 'react';
import BoxRoute from '../Cards/BoxRoute';
import PanelHome from './PanelHome';

export default class PanelEvaluated extends Component {
  _renderClients() {
    if (this.props.permission) {
      return (
        <PanelHome idMessage="physicaltest.clients">
          <BoxRoute
            defaultMessage="Avaliações"
            idMessage="physicaltest.clients"
            icon="users"
            link={`/Evaluated/${this.props.clientId}/${this.props.evaluated}`}
            color="bg-info"
            classe="heightnone"
            classNameFooter="bg-primary"
          />

          <BoxRoute
            defaultMessage="Progresso"
            idMessage="physicaltest.clients"
            icon="line-chart"
            link={`/Comparisons/${this.props.clientId}/${this.props.evaluated}`}
            color="bg-success"
            classe="heightnone"
            classNameFooter="bg-primary"
          />

          <BoxRoute
            defaultMessage="Treinos"
            idMessage="app.global.workouts"
            link="/WorkoutEvaluated"
            classe="heightnone"
            classNameFooter="bg-primary"
            color="bg-success"
          />
        </PanelHome>
      );
    }
  }

  render() {
    return <div>{this._renderClients()}</div>;
  }
}
