/** ErrorScreen
 * @author Carolina Vasconcelos
 * @copyright Grupo KRS
 * @description Tela de Erro do Sistema
 */
/** Importações */
// Principal
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { IS_DEV } from '../constant/constants';
// Componentes
import Loading from '../components/Loading';
// Utilitários

/** Classe */
class ErrorScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      info: null,
    };
  }
  /** REACT LIFECYCLE **/
  componentDidCatch(error, info) {
    this.setState({ hasError: true, error: error, info: info });
  }
  /** METODOS **/
  redirect(path = '/', time = 10000) {
    if (IS_DEV) {
      this.setState({
        hasError: false,
        error: null,
        info: null,
      });
    }
    window.setTimeout(() => {
      this.setState(
        {
          hasError: false,
          error: null,
          info: null,
        },
        this.props.history.push(path),
      );
    }, time);
  }
  /** RENDERS **/
  /** Render Default
   * Render Padrão do componente
   */
  _renderDefault() {
    if (this.state.hasError) {
      return (
        <div className="body-error">
          <div className="container-error">{this.redirect()}</div>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
  /** Render Loading
   * Mostra a terra de Load do sistema
   */
  _renderLoading(msg) {
    return <Loading message={msg} />;
  }
  /** Render Switch
   * Escolhe o que vai ser renderizado na tela
   * @param {bool} opc Opção a ser renderizada
   */
  _renderSwitch(opc) {
    switch (opc) {
      case true:
        return this._renderDefault();
      default:
        return this._renderLoading('Carregando');
    }
  }
  /** Render
   * React render padrão
   */
  render() {
    return this._renderSwitch(true);
  }
}

export default withRouter(ErrorScreen);
