import * as Actions from '../../constant/constants';
import socket from '../../socket';

export function getExerciseTypes() {
  return {
    type: Actions.GET_EXERCISE_TYPES,
    payload: socket.sendMessage(Actions.GET_EXERCISE_TYPES, {})
  };
}

export function setExerciseTypes(res) {
  const data = !Array.isArray(res.data) ? [] : res.data.map((exercise) => {
    return convertMessages(exercise);
  });

  return {
    type: Actions.GET_EXERCISE_TYPES,
    payload: data
  }
}

export function convertMessages(data) {
  const name = data.name;
  const nameWithoutSpaces = name.replace(/\s/g, '').toLocaleLowerCase();
  data.idMessageName = `app.exercise.type.${nameWithoutSpaces}`;
  data.defaultMessageName = data.name;
  return data;
}
